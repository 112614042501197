export const buttonStatusTabTestID = "button-status-tab";
export const buttonStatusTestID = "button-status";
export const buttonBackToTopTestID = "button-back-to-top";
export const fieldValuationTestID = "field-valuation";
export const fieldStateTestID = "field-state";
export const fieldSearchBarTestID = "field-search-bar";
export const FieldMotorIntelValuationTestID = "field-motorIntel-valuation";
export const customerInfoTestID = "customer-info";
export const cardVehicleTestID = "card-vehicle";
export const buttonInspectionTestID = "button-inspection-new";
export const cardCustomerTestID = "card-customer";
export const statusBarTestID = "status-bar";
export const modalRouteChangeTestID = "modal-route-change";
export const modalNotificationTestID = "modal-notification";
export const modalMissingInfoTestID = "modal-missing-info";
export const modalEditKMTestID = "modal-edit-km";
export const modalDeleteCarTestID = "modal-delete-car";
export const modalCarouselCarTestID = "modal-carousel-car";
export const loadVehicleTestID = "load-vehicle";
export const listCustomersTestID = "list-customers";
export const formVinRegoSearchTestID = "form-vin-vego-search";
export const formSelectVariantTestID = "form-select-variant";
export const formManualSearchTestID = "form-manual-search";
export const formConfirmVariantTestID = "form-confirm-variant";
export const modalValuationAddTestID = "modal-valuation-add";
export const modalOfferTestID = "modal-offer";
export const modalOfferAddTestID = "modal-offer-add";
export const modalMotorIntelLogTestID = "modal-motorIntel-log";
export const modalInternalValuationTestID = "modal-internal-valuation";
export const modalWholesaleValuationTestID = "modal-wholesale-valuation";
export const modalConfirmTestID = "modal-confirm";
export const modalSentValuationTestID = "modal-sent-valuation";
export const modalSendLinkTestID = "modal-send-link";
export const modalSearchVinRegoTestID = "modal-search-vin-rego";
export const modalSearchManualTestID = "modal-search-manual";
export const modalInspectionCreateTestID = "modal-inspection-create";
export const modalVinLocationTestID = "modal-vin-location";
export const modalPlatesLocationsTestID = "modal-plates-locations";
export const modalCompleteInspectionTestID = "modal-complete-inspection";
export const modalAdditionalVehicleTestID = "modal-additional-vehicle";
export const modalOfferNegotiationTestID = "modal-offer-negotiation";
export const formRetailSalesTestID = "form-retail-sales";
export const formNewDealershipSalesTestID = "form-new-dealership-sales";
export const formExternalSalesTestID = "form-external-sales";
export const confirmSaleTestID = "confirm-sale";
export const noAccessPageTestID = "no-access-page";
export const listPageTestID = "list-page";
export const listPageContentTestID = "list-page-content";
export const cardContainerTestID = "card-container";
export const cardImagePlaceholderTestID = "card-image-placeholder";
export const modalTenderBidsHistoryID = "modal-tender-bids-history";
export const modalTenderConfirmSaleID = "modal-tender-confirm-sale";
