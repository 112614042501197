import { Tab } from "@headlessui/react";

interface TabContentProps {
  id: number;
  content: JSX.Element;
}

export const TabContent = ({ id, content }: TabContentProps) => {
  return (
    <Tab.Panel key={id} className="rounded-xl h-full flex flex-col">
      {content}
    </Tab.Panel>
  );
};
