import classNames from "classnames";
import { useLocation } from "react-router-dom";

import { ButtonStatus } from "components/app/Button";
import { ButtonLink, Card, SvgImage } from "components/shared";

import { convertToDate, TENDER_STATUS_LIST } from "utils";

import { ITender } from "types";

import { ReactComponent as PinIcon } from "assets/images/pin.svg";

import { Notes } from "./Notes";

interface CardTenderProps {
  data: ITender;
  onEdit?: () => void;
  onClick: () => void;
}

export const CardTender = ({ data, onClick, onEdit }: CardTenderProps) => {
  const {
    dealership,
    status,
    name,
    location,
    starts_at,
    ends_at,
    created_by,
    notes,
    tender_id,
  } = data;

  const { pathname } = useLocation();

  const formatDate = (date: string) => {
    // replace "-" with "/" for cross browser compatibility
    const formattedDate = date.replace(/-/g, "/");
    return convertToDate(formattedDate, true);
  };

  return (
    <Card className="!overflow-visible" onClick={onClick}>
      <div
        className={classNames("card-vehicle relative !h-56", {
          "hover:!cursor-default": pathname.includes(tender_id),
        })}
      >
        <div className="card-vehicle__img">
          {dealership.logo_url ? (
            <img
              src={dealership.logo_url}
              alt="logo"
              className="!object-contain"
            />
          ) : (
            <SvgImage name="NoImageSvg" />
          )}
          <div className="card-vehicle__status !top-5">
            <ButtonStatus
              status={status}
              label={
                TENDER_STATUS_LIST.find((tStatus) => tStatus.key === status)
                  ?.label || ""
              }
            />
          </div>
        </div>
        <div className="card-vehicle__info">
          <div className="card-vehicle__section !p-6 !justify-start">
            <span className="card-vehicle__txt--title">{name}</span>
            <div className="card-vehicle__txt--desc !flex gap-1.5 no-overflow">
              <PinIcon />
              {location || "TBD"}
            </div>
            <div className="mt-3 grid grid-cols-2 gap-1">
              <span className="card-vehicle__txt--info">
                <b>Dealership Name</b>: {dealership.name}
              </span>
              <span className="card-vehicle__txt--info">
                <b>Starts:</b> {formatDate(starts_at)}
              </span>
              <span className="card-vehicle__txt--info">
                <b>Staff Name:</b> {created_by.name}
              </span>
              <span className="card-vehicle__txt--info">
                <b>Ends:</b> {formatDate(ends_at)}
              </span>
            </div>
            <div className="card-vehicle__txt--info mt-3 flex gap-2">
              <b>Notes: </b>
              {notes && <Notes notes={notes} />}
            </div>
          </div>
        </div>
        {onEdit && (
          <div className="absolute top-4 right-5">
            <ButtonLink onClick={onEdit}>
              <div className="flex gap-1 items-center text-secondary">
                Edit
                <SvgImage name="EditPen" width={12} />
              </div>
            </ButtonLink>
          </div>
        )}
      </div>
    </Card>
  );
};
