import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { Button } from "components/shared";

import { IMotorMarket } from "types/IMotorMarket";

import { ReactComponent as WatchlistAddIcon } from "assets/images/watchlist-add.svg";
import { ReactComponent as WatchlistRemoveIcon } from "assets/images/watchlist-remove.svg";

import { MotorMarketService } from "api/client";

interface WatchlistProps {
  selectedVehicle: IMotorMarket;
  watchlistID?: string;
  refetch: () => void;
}

export const Watchlist = ({
  selectedVehicle,
  watchlistID,
  refetch,
}: WatchlistProps) => {
  const { mutate: addWatchlist, isLoading: isLoadingAdd } = useMutation(
    (id: string) => MotorMarketService.addWatchList(id),
    {
      onSuccess: () => {
        setIsWatched(true);
        refetch();
      },
    }
  );

  const { mutate: deleteWatchList, isLoading: isLoadingDelete } = useMutation(
    (id: string) => MotorMarketService.deleteWatchList(id),
    {
      onSuccess: () => {
        setIsWatched(false);
        refetch();
      },
    }
  );

  const [isWatched, setIsWatched] = useState(selectedVehicle.is_watched);

  useEffect(() => {
    setIsWatched(!!watchlistID);
  }, [watchlistID]);

  return (
    <Button
      type="button"
      className="w-full !bg-white flex justify-center border border-[#EDEDF0] "
      onClick={() => {
        if (isWatched && watchlistID) {
          deleteWatchList(watchlistID);
        } else {
          addWatchlist(selectedVehicle.auction_vehicle_id);
        }
      }}
      disabled={isLoadingAdd || isLoadingDelete}
    >
      <span className="text-primary font-bold uppercase flex items-center gap-4">
        {isWatched ? (
          <>
            <WatchlistRemoveIcon /> Remove from watchlist
          </>
        ) : (
          <>
            <WatchlistAddIcon /> Add to watchlist
          </>
        )}
      </span>
    </Button>
  );
};
