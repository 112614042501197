import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import {
  FieldSearchBar,
  ListCustomers,
  ListDealerships,
  LoadVehicle,
  StatusBarTabs,
} from "components/app";
import { Breadcrumbs, Pagination } from "components/shared";

import { AuthContext } from "context/AuthContext";
import { useDealership } from "context/DealershipContext";
import { SearchContext } from "context/SearchContext";

import {
  DEFAULT_ITEMS_PER_PAGE,
  STATUS_LIST,
  CUSTOMERS,
  DEALERSHIPS,
} from "utils";
import { getPageTitle, SECTIONS } from "utils/pageSections";

import { ICustomer, IDealershipDetailResponse } from "types";

import { CustomerService, DealershipService } from "api/client";

import { useSessionStorage } from "hooks";

export const WholesaleDealershipList = () => {
  const { dealershipID } = useDealership();
  const { searchTxt } = useContext(SearchContext);
  const navigate = useNavigate();
  const [currentStatus, setCurrentStatus] = useState<string | number>(
    DEALERSHIPS.key
  );
  const { user, userID } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useSessionStorage(
    "WHOLESALE_DEALERSHIPS",
    1
  );
  const isDealershipView = currentStatus === DEALERSHIPS.key;

  useEffect(() => {
    if (searchTxt && currentPage !== 1) {
      setCurrentPage(1);
    }
  }, [searchTxt, currentPage, setCurrentPage]);

  const { data: dealershipsData, isLoading: dealershipsDataLoading } = useQuery<
    IDealershipDetailResponse,
    Error
  >(
    [
      `fetchDealerships_${searchTxt}`,
      user?.data.dealership?.id,
      { query: searchTxt, page: currentPage, dealership_id: dealershipID },
    ],
    DealershipService.getDealershipPool,
    {
      onSuccess: () => {
        setTimeout(() => {
          document
            .getElementsByClassName("dashboard")[0]
            .scrollTo({ behavior: "smooth", top: 0 });
        }, 500);
      },
      cacheTime: 0,
      enabled: isDealershipView,
    }
  );

  const { data: customersData, isLoading: customersDataLoading } = useQuery<
    ICustomer,
    Error
  >(
    [
      `fetchCustomers_${searchTxt}_${userID}`,
      { search: searchTxt, page: currentPage, dealership_id: dealershipID },
    ],
    CustomerService.get,
    {
      cacheTime: 0,
      enabled: !isDealershipView,
    }
  );

  const data = useMemo(() => {
    if (isDealershipView) {
      return {
        pageLimit: dealershipsData?.meta.per_page,
        total: dealershipsData?.meta.total,
        data: dealershipsData?.data,
        title: DEALERSHIPS.label,
      };
    }
    return {
      pageLimit: customersData?.meta.per_page,
      total: customersData?.meta.total,
      data: customersData?.data,
      title: CUSTOMERS.label,
    };
  }, [isDealershipView, dealershipsData, customersData]);

  const handleFetchNext = () => {
    setCurrentPage(currentPage + 1);
  };
  const handleFetchPrev = () => {
    setCurrentPage(currentPage - 1);
  };
  const handleFetchSelected = (index: number) => {
    setCurrentPage(index);
  };

  const handleGoSummary = (id: string, enable: boolean) => {
    enable && navigate(`/wholesale-dealership/clients/${id}/information`);
  };

  const handleChangeStatus = (status: string | number) => {
    setCurrentStatus(status);
    setCurrentPage(1);
  };

  const getContent = () => {
    if (isDealershipView && dealershipsDataLoading) {
      return <LoadVehicle />;
    }

    if (!isDealershipView && customersDataLoading) {
      return <LoadVehicle />;
    }

    if (data.data?.length === 0) {
      return (
        <div className="dashboard__content--empty">
          No {isDealershipView ? "Dealership" : "Customer"} Yet
        </div>
      );
    }
    if (isDealershipView) {
      return (
        <ListDealerships
          data={dealershipsData?.data ?? []}
          onSelect={(id) => handleGoSummary(id, false)}
        />
      );
    }
    return (
      <ListCustomers
        data={customersData?.data ?? []}
        onSelect={(id) => handleGoSummary(id, true)}
      />
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {getPageTitle("Dealerships", SECTIONS.WHOLESALE_DEALERSHIP)}
        </title>
      </Helmet>
      <div className="dashboard__container">
        <div className="flex w-full gap-5 pb-2">
          <div className="flex flex-col gap-2.5 pt-2 w-430">
            <Breadcrumbs
              paths={[
                {
                  title: "Wholesale Dealership",
                  route: "/wholesale-dealership",
                },
                {
                  title: "Clients",
                  route: "/wholesale-dealership/clients",
                },
              ]}
            />
            <div className="title">Clients</div>
          </div>
          <div className="flex flex-col gap-5">
            <FieldSearchBar />
            <StatusBarTabs
              currentStatus={currentStatus}
              options={STATUS_LIST.WholesaleClients}
              onSelect={handleChangeStatus}
            />
          </div>
        </div>
        <div className="dashboard__toolbar">
          <Pagination
            title={data.title}
            currentPage={currentPage}
            pageLimit={data?.pageLimit || DEFAULT_ITEMS_PER_PAGE}
            total={data?.total || 0}
            onNextPage={handleFetchNext}
            onPrevPage={handleFetchPrev}
            onIndexedPage={handleFetchSelected}
          />
        </div>
        <div className="dashboard__content">{getContent()}</div>
        <div className="dashboard__toolbar mb-4">
          <Pagination
            title={data.title}
            currentPage={currentPage}
            pageLimit={data?.pageLimit || DEFAULT_ITEMS_PER_PAGE}
            total={data?.total || 0}
            onNextPage={handleFetchNext}
            onPrevPage={handleFetchPrev}
            onIndexedPage={handleFetchSelected}
          />
        </div>
      </div>
    </>
  );
};
