import { FieldState } from "components/app";

import { IVehicleSummary } from "types";

interface TenderDealershipInformationProps {
  data: IVehicleSummary;
}

export const TenderDealershipInformation = ({
  data,
}: TenderDealershipInformationProps) => {
  const { contact, license, name, phone } = data.dealership || {};

  return (
    <div className="section">
      <div className="section__header">
        <span>Dealership Information</span>
      </div>
      <div className="disclosure__pannel--wide">
        <FieldState name="Dealership Name" value={name} />
        <FieldState name="Dealer License No." value={license} />
        <FieldState name="Contact Name" value={contact} />
        <FieldState name="Contact Number" value={phone} />
      </div>
    </div>
  );
};
