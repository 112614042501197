import { useMutation } from "@tanstack/react-query";

import { CardVehicle, LoadItem } from "components/app";

import { showSuccessToast, showErrorToast, ERROR_MESSAGE } from "utils";

import { IVehicleDetail } from "types";

import { InspectionService } from "api/client";

interface LinkSentProps {
  data?: IVehicleDetail[];
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
}

export const LinkSent = ({
  data,
  isLoading,
  isError,
  isSuccess,
}: LinkSentProps) => {
  const { mutate: resendLink } = useMutation(
    (inspectionId: string) => InspectionService.resendLink(inspectionId),
    {
      onSuccess: () => {
        showSuccessToast("Link Sent!");
      },
      onError: () => {
        showErrorToast("Failed to send link");
      },
    }
  );

  const renderLinkSent = () => {
    if (isLoading) {
      return <LoadItem />;
    }

    if (isError) {
      return <div className="text-sm text-center">{ERROR_MESSAGE}</div>;
    }

    if (isSuccess) {
      return (
        <>
          {data?.length ? (
            data?.map((vehicle: IVehicleDetail, index: number) => (
              <CardVehicle
                data={vehicle}
                key={index}
                onClick={() => {
                  if (vehicle.id) resendLink(vehicle.id);
                }}
              />
            ))
          ) : (
            <p className="text-sm text-label">
              This customer has no vehicles with transactions yet.
            </p>
          )}
        </>
      );
    }
  };

  return <div className="mt-5 flex flex-col gap-5">{renderLinkSent()}</div>;
};
