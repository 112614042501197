import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

import { LoadItem } from "components/app";
import { Modal } from "components/shared";

import {
  ACCEPTED,
  ACQUIRED,
  LOSTSALE,
  REJECTED,
  REVOKED,
  formatAmount,
} from "utils";

import { IDirectOfferLog } from "types";

import { DirectOfferService } from "api/client/DirectOfferService";

interface IDirectOfferLogModalProps {
  isOpen: boolean;
  onClose: () => void;
  offerID: string;
}

export const DirectOfferLogModal = ({
  isOpen,
  onClose,
  offerID,
}: IDirectOfferLogModalProps) => {
  const {
    data: { data } = {},
    isLoading,
    refetch,
  } = useQuery<{ data: IDirectOfferLog[] }, Error>(
    ["direct-offer-logs", offerID || ""],
    DirectOfferService.offerHistory
  );

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
  }, [isOpen]);

  return (
    <Modal
      open={isOpen}
      size="auto"
      title="Direct Offer Log"
      closeModal={onClose}
    >
      <div className="min-w-[300px]">
        {isLoading ? (
          <LoadItem />
        ) : (
          data?.map((item: IDirectOfferLog) => {
            const status = item.status.name;

            return (
              <div
                key={item.offer_id}
                className="pt-4 pb-3 border-b border-softPeach last:border-none"
              >
                <div className="flex justify-between gap-10">
                  <p className="text-sm">
                    {[REJECTED.key, ACQUIRED.key].includes(item.status.name)
                      ? item.buyer.name
                      : item.seller.name}
                  </p>
                  <p className="text-sm lowercase">
                    {status === REJECTED.key
                      ? REJECTED.label
                      : status === REVOKED.key
                      ? REVOKED.label
                      : status === ACQUIRED.key
                      ? ACCEPTED.label
                      : status === LOSTSALE.key
                      ? "Lost"
                      : formatAmount(item.offer_amount)}
                  </p>
                </div>
                <div className="flex justify-between gap-10">
                  <span className="text-sm text-label max-w-[220px]">
                    {item.agent?.name}
                  </span>
                  <span className="text-sm text-label">{item.created_at}</span>
                </div>
              </div>
            );
          })
        )}
      </div>
    </Modal>
  );
};
