import { Combobox, Transition } from "@headlessui/react";
import classNames from "classnames";
import {
  Fragment,
  SetStateAction,
  useState,
  forwardRef,
  useMemo,
  Ref,
} from "react";

import { SvgImage } from "components/shared/Image";

import { IOption } from "types";

import "./styles.scss";

export interface FieldDropdownProps {
  title?: string;
  placeholder?: string;
  isFilter?: boolean;
  disabled?: boolean;
  options: IOption[];
  value: string | undefined;
  onChange: (val: string) => void;
  onCreate?: () => void;
  prependOptions?: IOption[];
  className?: string;
  dropdownGreyBG?: boolean;
}

export const FieldDropdown = forwardRef(
  (
    {
      title,
      placeholder,
      isFilter = true,
      disabled = false,
      options,
      prependOptions,
      onChange,
      onCreate,
      value,
      className,
      dropdownGreyBG,
    }: FieldDropdownProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const [query, setQuery] = useState("");

    const filteredOptions = useMemo(() => {
      return query === ""
        ? options
        : options.filter((option) =>
            option.label
              .toString()
              .toLowerCase()
              .replace(/\s+/g, "")
              .includes(query.toLowerCase().replace(/\s+/g, ""))
          );
    }, [options, query]);

    const handleDisplayValue = (value: string) => {
      if (options.filter((item) => item.key === value).length > 0) {
        return options.filter((item) => item.key === value)[0].label.toString();
      } else {
        return searchValue;
      }
    };

    const [searchValue, setSearchValue] = useState<string>("");
    const handleMainInput = (e: {
      target: { value: SetStateAction<string> };
    }) => {
      setQuery(e.target.value);
      setSearchValue(e.target.value);
    };

    const renderOptions = (options: IOption[]) =>
      options.map((option) => (
        <Combobox.Option
          key={option.key}
          className={classNames("dropdown__option", {
            "hover:bg-white": dropdownGreyBG,
          })}
          value={option.key}
          hidden={option.isHidden}
        >
          {({ selected }: { selected: boolean; active: boolean }) => (
            <>
              <span className="dropdown__option__title !h-auto">
                {option.label}
              </span>
              {selected ? (
                <span className="dropdown__option__icon">
                  <SvgImage name="CheckIcon" />
                </span>
              ) : null}
            </>
          )}
        </Combobox.Option>
      ));

    return (
      <div className={disabled ? "dropdown--disabled" : "dropdown"}>
        {title && <label className="dropdown-label">{title}</label>}
        <Combobox value={value} onChange={onChange} disabled={disabled}>
          <div className="wrapper">
            <Combobox.Button className="input-container">
              <Combobox.Input
                ref={ref}
                className={classNames("dropdown__input", null, className)}
                placeholder={placeholder}
                displayValue={handleDisplayValue}
                onChange={handleMainInput}
              />
              <div className="dropdown__input__icon">
                <SvgImage name="SelectDown" />
              </div>
            </Combobox.Button>

            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery("")}
            >
              <Combobox.Options
                className={classNames("dropdown__options", {
                  "!bg-inputbg": dropdownGreyBG,
                })}
              >
                {Array.isArray(prependOptions) && renderOptions(prependOptions)}
                {isFilter && (
                  <div className="dropdown__search">
                    <span className="dropdown__search__icon">
                      <SvgImage name="ManualSearchIcon" />
                    </span>
                    <input
                      className={classNames("dropdown__search__input", {
                        "bg-transparent": dropdownGreyBG,
                      })}
                      placeholder="Searching..."
                      onChange={(event: {
                        target: { value: SetStateAction<string> };
                      }) => setQuery(event.target.value)}
                    />
                  </div>
                )}
                {onCreate && (
                  <li className="dropdown__new" onClick={onCreate}>
                    + Add New
                  </li>
                )}
                {filteredOptions.length === 0 && query !== "" ? (
                  <div className="dropdown__options__nothing">
                    Nothing found.
                  </div>
                ) : (
                  renderOptions(filteredOptions)
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
      </div>
    );
  }
);
