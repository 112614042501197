import { fieldValuationTestID } from "components/app/dataTestIDs";
import { SvgImage } from "components/shared";
import "../styles.scss";

export interface FieldValuationProps {
  title: string;
  value?: string;
  tool?: React.ReactNode;
  onView?: () => void;
  onEditValuation?: () => void;
  dataTestID?: string;
}

export const FieldValuationWholeRow = ({
  title,
  value,
  tool,
  onView,
  onEditValuation,
  dataTestID,
}: FieldValuationProps) => {
  return (
    <div
      className="valuation-field col-start-1 col-span-2"
      data-testid={dataTestID || fieldValuationTestID}
    >
      <label className="max-w-[170px]">
        {title}
        {value && onView && (
          <span
            className="hover:cursor-pointer ml-2"
            onClick={onView}
            data-testid={`${dataTestID || fieldValuationTestID}-view`}
          >
            <SvgImage name="EyeIcon" width={20} height={14} />
          </span>
        )}
      </label>
      <div className="valuation-field__icon text-start">
        {tool ? (
          tool
        ) : (
          <>
            {value ? value : "-"}
            {onEditValuation && (
              <span
                className="hover:cursor-pointer"
                onClick={onEditValuation}
                data-testid={`${dataTestID || fieldValuationTestID}-edit`}
              >
                <SvgImage name="EditPen" width={13} className="ml-2" />
              </span>
            )}
          </>
        )}
      </div>
    </div>
  );
};
