export const SUMMARY_STATES: any = {
  status: "Status",
  vin: "VIN",
  registration_plate: "REGO",
  customerName: "Customer Name",
  staffName: "Staff Name",
  odometer: "Kilometres",
  engine: "Engine",
  drive: "Drive Type",
  body_type: "Body Type",
  transmission: "Transmission",
  doors: "Doors",
  build_year: "Build Date",
  compliance_date: "Compliance Date",
  registration_expiry_date: "REGO Expiry",
  colour: "Colour",
};

export const SPECIFICATION_STATES = {
  year: "Vehicle Year",
  make: "Vehicle Make",
  model: "Vehicle Model",
  series: "Series",
  variant: "Badge",
  body_type: "Body Type",
  body_name: "Body Name",
  fuel: "Fuel",
  drive: "Drive",
  cylinders: "Cylinders",
  doors: "Doors",
  transmission: "Transmission",
  seats: "Seats",
  induction: "Induction",
  vehicle_type: "Vehicle Type",
  engine: "Engine",
  colour: "Colour",
  engine_size: "Engine Size",
};

export const COMPLIANCE_STATES = {
  build_year: "Build Date",
  compliance_date: "Compliance Date",
  vin: "VIN",
  engine_number: "Engine Number",
  registration_plate: "REGO Plate",
  registration_state: "REGO State",
  registration_status: "Registered",
  registration_expiry_date: "REGO Expiry Date",
};

export const FINANCE_STATES: any = {
  owing: "Finance Owing",
  owing_type: "Type Of Finance",
  institution: "Financial Institution",
};
