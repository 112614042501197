import { useState, useLayoutEffect } from "react";

interface UseIsOverFlowProps {
  ref: React.RefObject<HTMLParagraphElement>;
  callback?: (value: boolean) => void;
  text: string;
}

/**
 * @description - this is used for checking if ellipsis is available by checking
 * the scrollHeight against the  clientHeight
 */
export const useIsOverflow = ({ callback, ref, text }: UseIsOverFlowProps) => {
  const [isOverflow, setIsOverflow] = useState(false);

  useLayoutEffect(() => {
    const { current } = ref;

    const trigger = () => {
      const hasOverflow =
        text && current && current.scrollHeight > current.clientHeight;

      setIsOverflow(!!hasOverflow);
      if (callback) callback(!!hasOverflow);
    };

    if (current) {
      trigger();
    }
  }, [callback, ref, text]);

  return isOverflow;
};
