import { modalSentValuationTestID } from "components/app/dataTestIDs";
import { ButtonLink, SvgImage } from "components/shared";

import "../styles.scss";

export interface ModalSentValuationProps {
  onClose: () => void;
  dataTestID?: string;
}

export const ModalSentValuation = ({
  onClose,
  dataTestID,
}: ModalSentValuationProps) => {
  return (
    <div
      className="request-valuation"
      data-testid={dataTestID || modalSentValuationTestID}
    >
      <SvgImage name="ValuationSentIcon" width={32} height={32} />
      <h5 className="request-valuation__title">Valuation Request Sent!</h5>
      <div className="request-valuation__desc">
        Your vehicle inspection has successfully been sent for valuation.
        <br />
        <br />
        You will be notified when an offer amount (valuation) has been
        submitted.
      </div>
      <ButtonLink onClick={onClose}>
        <div className="request-valuation__footer">Close Vehicle Summary</div>
      </ButtonLink>
    </div>
  );
};
