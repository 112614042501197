import { useState } from "react";

import { ButtonStatus } from "components/app";
import { DirectOfferLogModal } from "components/Modal/DirectOfferLogModal/DirectOfferLogModal";
import { FieldCheckbox, SvgImage } from "components/shared";

import { ACQUIRED, REJECTED, REVOKED, formatAmount } from "utils";

import { IDirectOfferBuyer } from "types";

interface IDirectOfferDealershipSelectionItemProps {
  buyer: IDirectOfferBuyer;
  selected?: boolean;
  onSelect?: (buyerID: string) => void;
}

export const DirectOfferDealershipSelectionItem = ({
  buyer,
  selected,
  onSelect,
}: IDirectOfferDealershipSelectionItemProps) => {
  const [offerID, setOfferID] = useState("");
  const [showDirectOfferLogModal, setShowDirectOfferLogModal] = useState(false);
  const hasOffer = !!buyer.offer?.offer_amount;
  const offerStatusName = buyer.offer?.status.name;

  return (
    <>
      <div className="flex justify-evenly mb-2" key={buyer.id}>
        <div className="flex w-full">
          {onSelect ? (
            <FieldCheckbox
              key={buyer.id}
              checked={!!selected}
              label={buyer.name}
              onChange={() => onSelect?.(buyer.id)}
              disabled={false}
            />
          ) : (
            <span>{buyer.name}</span>
          )}
          {hasOffer && (
            <span
              className="hover:cursor-pointer ml-2 m-1"
              onClick={() => {
                setShowDirectOfferLogModal(true);
                setOfferID(buyer.offer.id);
              }}
            >
              <SvgImage name="EyeIcon" width={20} height={14} />
            </span>
          )}
        </div>
        {hasOffer && (
          <div className="w-full">
            <div className="flex">
              {offerStatusName === REJECTED.key ? (
                <ButtonStatus status="rejected" label="Rejected" />
              ) : offerStatusName === REVOKED.key ? (
                <ButtonStatus status="revoked" label="Revoked" />
              ) : offerStatusName === ACQUIRED.key ? (
                <ButtonStatus status="accepted" label="Accepted" />
              ) : (
                formatAmount(buyer.offer?.offer_amount)
              )}
            </div>
          </div>
        )}
      </div>
      <DirectOfferLogModal
        isOpen={showDirectOfferLogModal}
        onClose={() => setShowDirectOfferLogModal(false)}
        offerID={offerID}
      />
    </>
  );
};
