import { useMutation } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ChangeEvent, useState } from "react";
import { useForm, Controller } from "react-hook-form";

import { Button, FieldText, FieldTextarea, TextError } from "components/shared";

import { useDealership } from "context/DealershipContext";

import { ACTIVE, CLOSED, showErrorToast, showSuccessToast } from "utils";

import { ITenderForm, ITenderSummaryResponse } from "types";

import { TenderService } from "api/client";

interface ModalTenderCreateProps {
  existedData?: ITenderForm;
  tenderStatus?: string;
  tenderId?: string;
  onClose: (data: ITenderSummaryResponse) => void;
}

export const ModalTenderCreate = ({
  existedData,
  tenderStatus,
  tenderId,
  onClose,
}: ModalTenderCreateProps) => {
  const { dealershipID, showAllDealerships } = useDealership();
  const [startDate, setStartDate] = useState<string | undefined>();
  const [endMaxDate, setEndMaxDate] = useState<string | undefined>();
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    setFocus,
    control,
    formState: { errors },
  } = useForm<ITenderForm>({
    defaultValues: existedData || {
      name: "",
      startsAt: { date: "", time: "" },
      endsAt: { date: "", time: "" },
      location: "",
      notes: "",
    },
  });

  const isTenderActiveOrClosed =
    tenderStatus === CLOSED.key || tenderStatus === ACTIVE.key;

  const { mutate, isLoading } = useMutation(
    (data: any) => TenderService.createTender(data),
    {
      onSuccess: (data) => {
        showSuccessToast("Saved Successfully");
        onClose(data as ITenderSummaryResponse);
      },
      onError: () => {
        showErrorToast("Failed to Save");
      },
    }
  );

  const { mutate: updateTenderMutate, isLoading: isUpdating } = useMutation(
    (data: any) => TenderService.updateTender(data, tenderId || ""),
    {
      onSuccess: (data) => {
        showSuccessToast("Saved successfully!");
        onClose(data as ITenderSummaryResponse);
      },
      onError: (err) => {
        showErrorToast("Failed to Save");
        console.log("update tender error: ", err);
      },
    }
  );

  const handleCreateTender = (data: ITenderForm) => {
    const { endsAt, name, startsAt, location, notes } = data;

    const startDate = startsAt.date;
    const startTime = DateTime.fromFormat(startsAt.time, "HH:mm").toFormat(
      "HH:mm:ss"
    );
    const endDate = endsAt.date;
    const endTime = DateTime.fromFormat(endsAt.time, "HH:mm").toFormat(
      "HH:mm:ss"
    );

    const submittedData = {
      name,
      location,
      notes,
      starts_at: `${startDate}T${startTime}`,
      ends_at: `${endDate}T${endTime}`,
    };

    if (!isTenderActiveOrClosed) {
      if (
        DateTime.fromISO(submittedData.starts_at).toMillis() <
        DateTime.now().toMillis()
      ) {
        setError("startsAt.date", { type: "minDateValidate" });
        return;
      }

      if (
        DateTime.fromISO(submittedData.ends_at).toMillis() <=
        DateTime.fromISO(submittedData.starts_at).toMillis()
      ) {
        setError("endsAt.date", { type: "validate" });
        return;
      }

      if (
        DateTime.fromISO(submittedData.starts_at)
          .plus({ days: 14 })
          .toMillis() < DateTime.fromISO(submittedData.ends_at).toMillis()
      ) {
        setError("endsAt.date", { type: "maxDateExceed2weeksValidate" });
        return;
      }
    }

    const submittedDataWithDealership = {
      ...submittedData,
      dealership_id: !showAllDealerships ? dealershipID : undefined,
    };

    if (!existedData) {
      mutate(submittedDataWithDealership);
    } else {
      updateTenderMutate(submittedDataWithDealership);
    }
  };

  const handleChangeDateTime = (
    e: ChangeEvent<HTMLInputElement>,
    originValue: { date: string; time: string },
    field: "date" | "time",
    callback: (val: { date: string; time: string }) => void,
    start?: boolean
  ) => {
    const updatedValue = {
      ...originValue,
      [field]: e.target.value,
    };

    if (start) {
      setStartDate(
        (
          DateTime.fromFormat(updatedValue.date, "yyyy-MM-dd") || DateTime.now()
        ).toFormat("yyyy-MM-dd")
      );

      const newEndMaxDate = (
        DateTime.fromFormat(updatedValue.date, "yyyy-MM-dd") || DateTime.now()
      )
        .plus({ days: 14 })
        .toFormat("yyyy-MM-dd");

      setEndMaxDate(newEndMaxDate);
    }

    callback(updatedValue);
  };

  const errorChecker = (type: "Start" | "End", value?: string) => {
    switch (value) {
      case "validate":
        return "Entry Must Be After Start Date and Time";
      case "maxDateExceed2weeksValidate":
        return "Maximum Duration is 14 Days";
      case "minDateValidate":
        return "Start Date Must Be Current Date or After";
      default:
        return `${type} Date and Time Required`;
    }
  };

  const handleNextFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.title === "Start Date") {
      if (e.target.value >= e.target.min) {
        setFocus("startsAt.time");
      }
    }
    if (e.target.title === "End Date") {
      if (e.target.value <= e.target.max && e.target.value >= e.target.min) {
        setFocus("endsAt.time");
      }
    }
  };

  return (
    <div className="flex flex-col items-center w-672">
      <p className="text-xl font-bold text-txt leading-10">Tender Details</p>
      <div className="grid grid-cols-2 gap-6 w-full mb-4">
        <div>
          <FieldText
            type="text"
            maxLength={20}
            {...register("name", { required: true })}
            title="Tender Name"
            placeholder="Enter Tender Name"
            disabled={isTenderActiveOrClosed}
          />
          {errors.name && <TextError error="Tender Name Required" />}
        </div>
        <div>
          <FieldText
            type="text"
            maxLength={255}
            {...register("location")}
            title="Location"
            placeholder="Enter Location of Vehicles"
            disabled={isTenderActiveOrClosed}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-5 w-full mb-4">
        <div>
          <div className="grid grid-cols-2 gap-5">
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <FieldText
                  type="date"
                  {...register("startsAt.date", { required: true })}
                  min={DateTime.now().toFormat("yyyy-MM-dd")}
                  max="9999-12-31"
                  title="Start Date"
                  value={value.date}
                  disabled={isTenderActiveOrClosed}
                  onChange={(e) =>
                    handleChangeDateTime(e, value, "date", onChange, true)
                  }
                  onInput={handleNextFocus}
                />
              )}
              name="startsAt"
            />
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <FieldText
                  {...register("startsAt.time", { required: true })}
                  type="time"
                  title="Start Time"
                  value={value.time}
                  disabled={isTenderActiveOrClosed}
                  onChange={(e) =>
                    handleChangeDateTime(e, value, "time", onChange)
                  }
                />
              )}
              name="startsAt"
            />
          </div>
          {errors.startsAt && (
            <TextError
              error={errorChecker("Start", errors.startsAt?.date?.type)}
            />
          )}
        </div>
        <div>
          <div className="grid grid-cols-2 gap-5">
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <FieldText
                  type="date"
                  {...register("endsAt.date", { required: true })}
                  min={startDate || DateTime.now().toFormat("yyyy-MM-dd")}
                  max={endMaxDate || "9999-12-31"}
                  title="End Date"
                  value={value.date}
                  disabled={isTenderActiveOrClosed}
                  onChange={(e) =>
                    handleChangeDateTime(e, value, "date", onChange)
                  }
                  onInput={handleNextFocus}
                />
              )}
              name="endsAt"
            />
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <FieldText
                  {...register("endsAt.time", { required: true })}
                  type="time"
                  title="End Time"
                  value={value.time}
                  disabled={isTenderActiveOrClosed}
                  onChange={(e) =>
                    handleChangeDateTime(e, value, "time", onChange)
                  }
                />
              )}
              name="endsAt"
            />
          </div>
          {errors.endsAt && (
            <TextError error={errorChecker("End", errors.endsAt?.date?.type)} />
          )}
        </div>
      </div>
      <div className="w-full mb-9">
        <FieldTextarea
          title="Notes"
          {...register("notes")}
          className="h-16 min-h-16"
          maxLength={500}
          placeholder="Enter any additional PUBLIC notes here - these will be viewable to all bidders"
          onChange={(e) => {
            e.currentTarget.value.length === 500
              ? setError("notes", { type: "onChange" })
              : clearErrors("notes");
          }}
        />
        {errors.notes && <TextError error="500 character limit reached" />}
      </div>
      <div className="w-150">
        <Button
          type="button"
          isLoading={isLoading || isUpdating}
          onClick={handleSubmit(handleCreateTender)}
        >
          <span className="font-bold">SAVE</span>
        </Button>
      </div>
    </div>
  );
};
