import { ChangeEvent, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";

import { formVinRegoSearchTestID } from "components/app/dataTestIDs";
import { TextError, FieldText, Button, FieldDropdown } from "components/shared";

import { STATE_OPTIONS } from "utils";

import { IVehicle } from "types";

import { VehicleSearchService } from "api/client";

export interface VinRegoFormType {
  plate: string;
  state?: string;
}

export interface FormVinRegoSearchProps {
  onNextStep: (val: IVehicle) => void;
  dataTestID?: string;
}

export const FormVinRegoSearch = ({
  onNextStep,
  dataTestID,
}: FormVinRegoSearchProps) => {
  const [isSearching, setSearching] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      plate: "",
      state: "",
    },
  });

  const handleFindVehicle = async (data: VinRegoFormType) => {
    try {
      setSearching(true);
      const param = data.state !== "" ? data : { plate: data.plate };
      const searchResult = await VehicleSearchService.searchPlate(param);

      if (searchResult) {
        onNextStep(searchResult.data as IVehicle);
      }
    } catch (err) {
      toast.error("Failed to Find Vehicle", {
        theme: "colored",
        autoClose: 2000,
      });
    }
    setSearching(false);
  };

  const handleVinChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue("plate", e.target.value.split(" ").join("").toUpperCase());
    setIsDisabled(getValues("plate").length > 7 ? true : false);
  };

  return (
    <>
      <div
        className="vin-rego__form__row"
        data-testid={dataTestID || formVinRegoSearchTestID}
      >
        <div>
          <Controller
            render={({ field: { value } }) => (
              <FieldText
                title="VIN / Registration Plate*"
                type="text"
                value={value}
                onChange={(e) => handleVinChange(e)}
                placeholder="Enter your VIN / Registration Plate"
              />
            )}
            control={control}
            name="plate"
            rules={{ required: true }}
          />
          {errors.plate && <TextError error="VIN / REGO is required" />}
        </div>

        <div>
          <Controller
            render={({ field: { value, onChange } }) => (
              <FieldDropdown
                title="Registration State*"
                value={value}
                disabled={isDisabled}
                onChange={onChange}
                placeholder="Select State"
                options={STATE_OPTIONS}
              />
            )}
            control={control}
            name="state"
            rules={{ required: isDisabled ? false : true }}
          />
          {errors.state && <TextError error="Registration State is required" />}
        </div>
      </div>
      <div className="vin-rego__footer">
        <Button
          type="submit"
          isLoading={isSearching}
          onClick={handleSubmit(handleFindVehicle)}
          dataTestID={`${dataTestID || formVinRegoSearchTestID}-submit`}
        >
          <span className="vin-rego__footer__title">Find Vehicle</span>
        </Button>
      </div>
    </>
  );
};
