import { useQuery } from "@tanstack/react-query";
import { useContext, useMemo, useState } from "react";

import {
  FieldValuation,
  FieldMotorIntelValuation,
  ModalOfferLog,
  ModalInternalValuation,
  ModalWholesaleValuations,
  ModalMotorIntelLog,
  LoadItem,
  ModalTenderBidsHistory,
} from "components/app";
import { PricingInsightsModal } from "components/Modal/PricingInsightsModal";
import { ButtonLink, Modal, Tab } from "components/shared";
import { ITabItem } from "components/shared/Tab/Tab";

import { AuthContext } from "context/AuthContext";

import { formatNumber, SOLDTENDERED, STATUS_LIST } from "utils";

import { ITenderVehicle, IVehicleSummary } from "types";

import { ReactComponent as ArrowDownIcon } from "assets/images/chevron-down.svg";
import { ReactComponent as ArrowUpIcon } from "assets/images/chevron-up-big.svg";
import { ReactComponent as EyeIcon } from "assets/images/eye-icon.svg";

import { TenderService } from "api/client";

import "../Summary/styles.scss";

interface TenderDealInformationProps {
  data: ITenderVehicle;
  inspectionData: IVehicleSummary;
  defaultOpen?: boolean;
  onRefresh: () => void;
}

const AcquisitionDetails = ({ data }: { data: IVehicleSummary }) => {
  const { acquisitions, owner, seller } = data;
  const isExternal = data?.seller?.id && !data.owner?.id;

  const acquisitionAmount = acquisitions?.acquisition?.amount;
  const acquiredEmail = owner?.email ?? "-";
  const acquiredLastName = owner?.last_name ?? "-";
  const acquiredCompany = owner?.company_name ?? "-";
  const acquiredPhone = isExternal ? seller?.phone ?? "-" : owner?.phone ?? "-";
  const acquiredFrom = isExternal
    ? seller?.name ?? "-"
    : owner?.full_name ?? "-";
  const dealerLicenseNumber = seller.license ?? "-";

  if (!isExternal) {
    // if acquisition is by customer
    return (
      <div className="disclosure__pannel--wide">
        <FieldValuation
          title="Acquisition Price"
          value={`$${formatNumber(acquisitionAmount)}`}
        />
        <FieldValuation title="Email Address" value={acquiredEmail || "-"} />
        <FieldValuation title="Acquired From" value={acquiredFrom || "-"} />
        <FieldValuation title="Mobile Number" value={acquiredPhone || "-"} />
        <FieldValuation title="Last Name" value={acquiredLastName || "-"} />
        <FieldValuation title="Company Name" value={acquiredCompany || "-"} />
        {/* <FieldValuation
          title="Comments"
          value={comment?.comment ? comment.comment : "-"}
        /> */}
      </div>
    );
  }

  return (
    <div className="disclosure__pannel--wide">
      <FieldValuation
        title="Acquisition Price"
        value={`$${formatNumber(acquisitionAmount)}`}
      />
      <FieldValuation
        title="Dealer License No."
        value={dealerLicenseNumber || "-"}
      />
      <FieldValuation title="Acquired From" value={acquiredFrom || "-"} />
      <FieldValuation title="Contact Number" value={acquiredPhone || "-"} />
      {/* <FieldValuation
        title="Comments"
        value={comment?.comment ? comment.comment : "-"}
      /> */}
    </div>
  );
};

interface ValuationsDetailsProps {
  data?: IVehicleSummary;
  onRefresh: () => void;
}

const ValuationsDetails = ({ data, onRefresh }: ValuationsDetailsProps) => {
  const { user } = useContext(AuthContext);
  const [showOfferModal, setShowOfferModal] = useState<boolean>(false);
  const [showInternalModal, setShowInternalModal] = useState<boolean>(false);
  const [showMpLogModal, setShowMpLogModal] = useState<boolean>(false);
  const [showWholesaleModal, setShowWholesaleModal] = useState<boolean>(false);
  const [showPricingInsightsModal, setShowPricingInsightsModal] =
    useState<boolean>(false);

  const { contract_id, valuations, status, valuation_requests } = data || {};
  const offerAmount = valuations?.internal?.offer?.amount;
  const valuationAmount = valuations?.internal?.valuation?.amount
    ? `$${formatNumber(valuations?.internal?.valuation?.amount)}`
    : "";
  const hubOfferAmount = valuations?.hub?.offer
    ? `$${formatNumber(valuations?.hub?.offer?.amount)}`
    : "-";

  const handleOfferModal = () => {
    setShowOfferModal(true);
  };

  const handleInternalModal = () => {
    setShowInternalModal(true);
  };

  const handleMPLogModal = () => {
    setShowMpLogModal(true);
  };

  const handleWholesaleValuation = () => {
    setShowWholesaleModal(true);
  };

  const handleRequestDisabled = useMemo(
    () =>
      STATUS_LIST.SoldAcquisition.findIndex(
        (item) => item.key === status?.name
      ) !== -1,
    [status]
  );

  if (!user) {
    return <></>;
  }

  return (
    <div className="disclosure__pannel--wide">
      <FieldValuation
        title="Offer Amount"
        value={`$${formatNumber(offerAmount)}`}
        onView={handleOfferModal}
      />
      <FieldValuation
        title="Internal Valuation (Real)"
        value={valuationAmount}
        onView={handleInternalModal}
      />
      <FieldMotorIntelValuation
        rawAmount={valuations?.hub?.offer?.amount}
        hubOfferAmount={hubOfferAmount}
        requested={valuation_requests?.hub}
        isRequesting={false}
        onOpenMPLogModal={handleMPLogModal}
        contractId={contract_id}
        vehicleName={data?.vehicle.name}
        isTabView
        onRefresh={onRefresh}
        isMotorIntelDeclined={!!data?.valuation_requests?.hub_declined}
      />
      <FieldValuation
        title="Wholesale Partner Valuations"
        tool={
          <ButtonLink onClick={handleWholesaleValuation}>
            <span className="!text-sm">{`Request/View >`}</span>
          </ButtonLink>
        }
      />
      <FieldValuation
        title="Pricing Insights"
        tool={
          <ButtonLink onClick={() => setShowPricingInsightsModal(true)}>
            <span className={"!text-sm"}>{`View >`}</span>
          </ButtonLink>
        }
      />
      <PricingInsightsModal
        data={data?.trade}
        isOpen={showPricingInsightsModal}
        onClose={() => setShowPricingInsightsModal(false)}
        dateCreated={data?.created_at}
      />
      <Modal
        open={showOfferModal}
        title="Offer Log"
        description="This is the offer amount sent to the person who requested the valuation."
        size="auto"
        closeModal={() => setShowOfferModal(false)}
      >
        <ModalOfferLog
          open={showOfferModal}
          valuationID={valuations?.internal?.offer?.valuation_id!}
        />
      </Modal>
      <Modal
        open={showInternalModal}
        title="Internal Valuation (Real)"
        description="This is the estimated actual value of the vehicle, which is only visible to the managers of your dealership."
        size="auto"
        closeModal={() => setShowInternalModal(false)}
      >
        <ModalInternalValuation
          open={showInternalModal}
          valuation_id={valuations?.internal?.valuation?.valuation_id!}
        />
      </Modal>
      <Modal
        open={showMpLogModal}
        size="auto"
        closeModal={() => setShowMpLogModal(false)}
      >
        <ModalMotorIntelLog
          open={showMpLogModal}
          valuation_id={valuations?.hub?.offer?.valuation_id!}
        />
      </Modal>
      <Modal
        open={showWholesaleModal}
        title="Wholesale Partner Valuations"
        size="auto"
        closeModal={() => setShowWholesaleModal(false)}
      >
        <ModalWholesaleValuations
          contract_id={contract_id || ""}
          open={showWholesaleModal}
          requestDisabled={handleRequestDisabled}
        />
      </Modal>
    </div>
  );
};

const TenderSection = ({ data }: { data: ITenderVehicle }) => {
  const { userID } = useContext(AuthContext);

  const [selectedBidder, setSelectedBidder] = useState("");
  const { data: bids, isLoading } = useQuery(
    [`fetchTenderVehicleBids_${userID}`, data.tender_vehicle_id],
    TenderService.getTenderVehicleBids
  );

  const onOpenBidsLog = (id: string) => {
    setSelectedBidder(id);
  };

  const onCloseBidsModal = () => {
    setSelectedBidder("");
  };

  return (
    <div className="disclosure__pannel--wide">
      <div className="flex flex-col w-full -mt-4">
        {isLoading ? (
          <LoadItem />
        ) : (
          bids?.data?.map((bid) => (
            <div
              key={bid.id}
              className="flex flex-col w-full py-2 border-b border-softPeach gap-2"
            >
              <div className="flex justify-between">
                <div className="flex items-start text-sm">
                  {bid.dealership.name}
                  <div
                    className="cursor-pointer ml-2 mt-1 inline-block"
                    onClick={() => onOpenBidsLog(bid.id)}
                  >
                    <EyeIcon />
                  </div>
                </div>

                <div className="text-sm">${formatNumber(bid.amount)}</div>
              </div>
              <div className="flex justify-between -mt-1 text-sm">
                <div className="text-lightGrey">{bid.bidder.name}</div>
                <div className="text-lightGrey">{bid.created_at}</div>
              </div>
            </div>
          ))
        )}
      </div>
      <Modal
        open={!!selectedBidder}
        closeModal={onCloseBidsModal}
        title="Bids Log"
        size="auto"
      >
        <ModalTenderBidsHistory bidderID={selectedBidder} isSeller />
      </Modal>
    </div>
  );
};

const SaleDetails = ({ data }: { data: IVehicleSummary | undefined }) => {
  const { user } = useContext(AuthContext);

  const { acquisitions, buyer } = data || {};
  const salesAmount = acquisitions?.sale?.amount;
  const buyerName = buyer?.name;
  const buyerLicense = buyer?.license;
  const buyerContact =
    buyer?.contact || buyer?.full_name || buyer?.company_name;
  const buyerEmail = buyer?.email;
  const buyerPhone = buyer?.phone;

  if (!user) {
    return <></>;
  }

  return (
    <div className="disclosure__pannel--wide">
      <FieldValuation
        title="Sale Price"
        value={`$${formatNumber(salesAmount)}`}
      />
      <FieldValuation title="Dealership Name" value={buyerName || "-"} />
      <FieldValuation title="Dealer License No." value={buyerLicense || "-"} />
      <FieldValuation title="Contact Name" value={buyerContact || "-"} />
      <FieldValuation title="Contact Number" value={buyerPhone || "-"} />
      <FieldValuation title="Email Address" value={buyerEmail || "-"} />
    </div>
  );
};

export const TenderDealInformation = ({
  data,
  inspectionData,
  defaultOpen,
  onRefresh,
}: TenderDealInformationProps) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [showPanel, setShowPanel] = useState(defaultOpen);

  const handleToggleShowPanel = () => {
    setShowPanel((showPanel) => !showPanel);
  };

  const DealInformationTabs: ITabItem[] =
    data.status?.name !== SOLDTENDERED.key
      ? [
          {
            label: "ACQUISITION",
            key: "ACQUISITION",
            tabPanel: <AcquisitionDetails data={inspectionData} />,
          },
          {
            label: "VALUATIONS",
            key: "VALUATIONS",
            tabPanel: (
              <ValuationsDetails data={inspectionData} onRefresh={onRefresh} />
            ),
          },
        ]
      : [
          {
            label: "ACQUISITION",
            key: "ACQUISITION",
            tabPanel: <AcquisitionDetails data={inspectionData} />,
          },
          {
            label: "VALUATIONS",
            key: "VALUATIONS",
            tabPanel: (
              <ValuationsDetails data={inspectionData} onRefresh={onRefresh} />
            ),
          },
          {
            label: "TENDER",
            key: "TENDER",
            tabPanel: <TenderSection data={data} />,
          },
          {
            label: "SALE DETAILS",
            key: "SALE DETAILS",
            tabPanel: <SaleDetails data={inspectionData} />,
          },
        ];

  return (
    <div className={`section ${showPanel && "min-h-440"}`}>
      <div
        className="section__header cursor-pointer"
        onClick={handleToggleShowPanel}
      >
        <span>Deal Information</span>
        {showPanel ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </div>
      <div className="section__body--info">
        <Tab
          items={DealInformationTabs}
          defaultActiveTab={activeTab}
          showPanel={!!showPanel}
          onSetActiveTab={(index: number) => {
            setShowPanel(true);
            setActiveTab(index);
          }}
        />
      </div>
    </div>
  );
};
