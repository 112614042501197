import { NotificationPlaceholder } from "components/NotificationPlaceholder";

import { ERROR_MESSAGE } from "utils";

import { INotification } from "types/INotification";

import { useInfiniteScroll } from "hooks/useInfiniteScroll";

import { modalNotificationTestID } from "../dataTestIDs";

import "./styles.scss";

export interface ModalNotificationProps {
  hasNextPage?: boolean;
  isLoading: boolean;
  isError: boolean;
  notifications: INotification[];
  onMarkRead: (noti: INotification) => void;
  dataTestID?: string;
  onScrollHitBottom: () => void;
}

export const ModalNotification = ({
  hasNextPage,
  isLoading,
  isError,
  notifications,
  onMarkRead,
  dataTestID,
  onScrollHitBottom,
}: ModalNotificationProps) => {
  const renderContent = () => {
    if (isError) return <div>{ERROR_MESSAGE}</div>;

    return notifications.map((notification: INotification) => {
      return (
        <div
          key={notification.id}
          className={`${
            notification.read_at
              ? "notification__item--read"
              : `notification__item`
          }`}
          onClick={() => onMarkRead(notification)}
          data-testid={`notification-${notification.id}`}
        >
          <div className="notification__item__container">
            <h5 className="notification__item__title">
              {notification.body.title}
            </h5>
            <p className="notification__item__desc">
              {notification.body.message}
            </p>
            <label className="notification__item__date">
              {notification.created_at}
            </label>
          </div>
        </div>
      );
    });
  };

  const renderSkeletonPlaceholder = (hasNextPage || isLoading) && (
    <NotificationPlaceholder />
  );

  const { onScroll } = useInfiniteScroll({
    threshold: 400,
    preventInvokingCallback: isLoading,
    onScrollHitBottom,
  });

  return (
    <div
      className="notification"
      data-testid={dataTestID || modalNotificationTestID}
    >
      <div className="notification__header">Notifications</div>
      <div className="notification__content" onScroll={onScroll}>
        {renderContent()}
        {renderSkeletonPlaceholder}
      </div>
    </div>
  );
};
