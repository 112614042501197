import { LoadingTable } from "components/Loading/LoadingTable";

import { IDealershipDetail } from "types";

import { Row } from "./Row";

interface TableProps {
  data: IDealershipDetail[];
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  selected: { id: string; selected: boolean }[];
  onSelect: (id: string, selected: boolean) => void;
  canDeselect: boolean;
  canUpdateOrSave: boolean;
}

export const Table = ({
  data,
  isLoading,
  isError,
  isSuccess,
  selected,
  onSelect,
  canDeselect,
  canUpdateOrSave,
}: TableProps) => {
  const getContent = () => {
    if (isError)
      return (
        <div className="dashboard__content--error">
          Something went wrong. Please, try again.
        </div>
      );

    if (isLoading)
      return (
        <div className="mt-3">
          <LoadingTable />
        </div>
      );

    if (isSuccess) {
      if (!data.length)
        return (
          <div className="dashboard__content--empty">
            No Dealership Pool Created Yet
          </div>
        );

      return data.map((dealership: IDealershipDetail) => (
        <Row
          key={dealership.id}
          dealership={dealership}
          onSelect={onSelect}
          isSelected={!!selected.find((e) => e.id === dealership.id)?.selected}
          canDeselect={canDeselect}
          data={data}
          canUpdateOrSave={canUpdateOrSave}
        />
      ));
    }
  };

  return (
    <div>
      <div className="dashboard__content__header">
        <span>Dealership Name</span>
        <span>License No.</span>
        <span>Email</span>
        <span>Contact Name</span>
        <div>
          Tender
          <span>Default Selection</span>
        </div>
      </div>
      {getContent()}
    </div>
  );
};
