import classNames from "classnames";
import { forwardRef, InputHTMLAttributes, Ref } from "react";
import TextareaAutosize from "react-textarea-autosize";

import "./styles.scss";

export const FieldTextarea = forwardRef(
  (
    props: InputHTMLAttributes<HTMLTextAreaElement>,
    ref: Ref<HTMLTextAreaElement>
  ) => {
    return (
      <div className="textarea-wrapper">
        {props.title && <label className="textarea-label">{props.title}</label>}
        <TextareaAutosize
          ref={ref}
          {...props}
          className={classNames("textarea", props.className)}
          style={{
            boxSizing: "border-box",
          }}
        />
      </div>
    );
  }
);
