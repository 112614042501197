import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";
import { FC, Fragment } from "react";

import { modalDialogCloseButtonTestID } from "../dataTestIDs";
import { SvgImage } from "../Image";
import "./styles.scss";

export interface ModalProps {
  open: boolean;
  closeModal: () => void;
  title?: string;
  description?: string;
  canClose?: boolean;
  isCenter?: boolean;
  size?: "auto" | "responsive";
  modalClass?: string;
}

export const Modal: FC<ModalProps> = ({
  open,
  closeModal,
  title,
  children,
  canClose = true,
  isCenter = true,
  size = "responsive",
  modalClass,
  description,
}) => {
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="modal" onClose={closeModal}>
        <div className="min-h-screen lg:px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="modal__overlay" />
          </Transition.Child>

          {isCenter && (
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
          )}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={classNames(
                `modal__container ${
                  size === "auto" ? "w-auto" : `w-responsive`
                }`,
                { [`${modalClass}`]: modalClass }
              )}
            >
              {/* Modal Header */}
              <Dialog.Title
                as="div"
                className={`modal__header ${title ? "with-title" : ""}`}
              >
                <div className="flex flex-col justify-center text-center px-5 w-full">
                  <h3
                    className={classNames("modal__header--title", {
                      "pb-2": !!description,
                    })}
                  >
                    {title}
                  </h3>
                  {description && (
                    <p className="text-sm text-label w-min min-w-fit">
                      {description}
                    </p>
                  )}
                </div>

                {canClose && (
                  <button
                    className="modal__header--close"
                    onClick={closeModal}
                    data-testid={modalDialogCloseButtonTestID}
                  >
                    <SvgImage name="CloseIcon" />
                  </button>
                )}
              </Dialog.Title>

              {/* Modal Content */}
              <div className="modal__content">{children}</div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
