import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { ModalAdditionalVehicle } from "components/app";
import { SvgImage, Modal } from "components/shared";

import { AuthContext } from "context/AuthContext";

import { CustomerInspectionService } from "api/client";

import "../styles.scss";

const Complete = () => {
  const { userID } = useContext(AuthContext);
  const params = useParams();
  const inspectionId = params.id;
  const [showAdditionalVehicleModal, setShowAdditionalVehicleModal] =
    useState<boolean>(true);

  const { data } = useQuery(
    [`verify_inspection_link_${userID}`, inspectionId],
    CustomerInspectionService.verifyInspectionLInk
  );

  const { mutate } = useMutation(
    () =>
      CustomerInspectionService.sendLink(data?.data.customer.id || "", {
        shared_by: data?.data.salesperson.id,
        dealership_id: data?.data.dealership.id,
      }),
    {
      onSuccess: (res: any) => {
        const { link } = res.data as any;
        window.open(link, "_self");
      },
      onError: () => {
        toast.error("Failed to Regenerate", {
          theme: "colored",
          autoClose: 2000,
        });
      },
    }
  );

  const handleAdditionalConfirm = () => {
    mutate();
  };

  return (
    <div className="customer">
      <div className="customer__content--complete">
        <h5 className="complete__title">Congratulations!</h5>
        <div className="complete__desc">
          Your Inspection has been sent to{" "}
          <span>{data?.data.salesperson.name},</span>{" "}
          <span>{data?.data.dealership.name}.</span> They will contact you as
          soon as they have reviewed your inspection.
        </div>
        <div className="complete__footer">
          <span>You have completed</span>
          <span className="flex items-center">
            your Inspection with{" "}
            <SvgImage
              name="MPLogo"
              className="ml-2 mt-1"
              width={116}
              height={39}
            />
          </span>
        </div>
      </div>
      <Modal
        size="auto"
        open={showAdditionalVehicleModal}
        closeModal={() => setShowAdditionalVehicleModal(false)}
      >
        <ModalAdditionalVehicle
          onConfirm={handleAdditionalConfirm}
          closeModal={() => setShowAdditionalVehicleModal(false)}
        />
      </Modal>
    </div>
  );
};

export default Complete;
