import { useMutation } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ModalConfirm } from "components/app";
import { InformativeModal } from "components/Modal/InformativeModal";
import { ButtonLink, Modal } from "components/shared";

import { AuthContext } from "context/AuthContext";

import {
  ActionResult,
  ActionResultMessage,
  MOTOR_MARKET_SOLD_AUCTION,
  SOLDTENDERED,
  SOLDVIADIRECTOFFER,
  STATUS_LIST,
  showErrorToast,
  showSuccessToast,
} from "utils";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import { IVehicleSummary } from "types";

import { AcquisitionService } from "api/client";

import "./styles.scss";

type SaleDetailSectionProps = {
  data: IVehicleSummary;
  refetch: () => void;
};

export const SaleDetailSection = ({
  data,
  refetch,
}: SaleDetailSectionProps) => {
  const { user, hasPermission } = useContext(AuthContext);
  const navigate = useNavigate();

  const [showBackToAcquiredModal, setBackToAcquiredModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const { status } = data;

  const isDirectOffer = status.name === SOLDVIADIRECTOFFER.key;

  const { mutate: handleRevert, isLoading: isGoingBackNegotiating } =
    useMutation(
      (payload: { reason: string }) => {
        if (status.name === MOTOR_MARKET_SOLD_AUCTION.key) {
          return AcquisitionService.revertAuction(data.contract_id, payload);
        }
        return AcquisitionService.negotiationRevert(data.contract_id, payload);
      },
      {
        onSuccess: () => {
          if (isDirectOffer) {
            showSuccessToast("Sale Cancelled Successfully");
            refetch();
          } else {
            navigate(`/retail-dealership/stocklist`);
            showSuccessToast("Successfully Moved");
          }
        },
        onError: () => {
          if (isDirectOffer) {
            setShowErrorModal(true);
          } else {
            showErrorToast("Failed to Move");
          }
        },
      }
    );

  const handleBackToAcquisition = () => {
    setBackToAcquiredModal(true);
  };

  if (
    !user ||
    !STATUS_LIST.SoldAcquisition.map((soldStatus) => soldStatus.key).includes(
      status.name
    )
  ) {
    return <></>;
  }

  const hideModal = () => {
    setBackToAcquiredModal(false);
    setShowErrorModal(false);
  };

  const canCancelSale = () => {
    switch (status.name) {
      case SOLDVIADIRECTOFFER.key:
        return hasPermission(
          PermissionCategory.DIRECT_OFFERS,
          PermissionAction.REVERT
        );
      case SOLDTENDERED.key:
        return hasPermission(
          PermissionCategory.TENDER_SELLING,
          PermissionAction.SELL
        );
      case MOTOR_MARKET_SOLD_AUCTION.key:
        return hasPermission(
          PermissionCategory.MOTOR_MARKET_SELLING,
          PermissionAction.SELL
        );
      default:
        return true;
    }
  };

  if (!canCancelSale()) return <></>;

  return (
    <div>
      <div>
        <ButtonLink
          disabled={isGoingBackNegotiating}
          onClick={handleBackToAcquisition}
        >
          <span className="bg-white shadow-md uppercase text-lightGrey text-sm font-bold rounded-full px-5 py-2">
            Cancel Sale
          </span>
        </ButtonLink>
      </div>
      <Modal
        open={showBackToAcquiredModal || showErrorModal}
        size="auto"
        canClose={false}
        closeModal={hideModal}
      >
        {showErrorModal ? (
          <InformativeModal
            content={{
              title:
                ActionResultMessage[ActionResult.WarningCancelMotorMarketSale]
                  .title,
              message:
                ActionResultMessage[ActionResult.WarningCancelMotorMarketSale]
                  .message,
              icon: "FailedIcon",
            }}
            onClose={hideModal}
          />
        ) : (
          <ModalConfirm
            onCancel={() => setBackToAcquiredModal(false)}
            onConfirm={() => handleRevert({ reason: "Customer cancelled" })}
            description={
              isDirectOffer
                ? "This action will cancel the sale and direct offer history."
                : ""
            }
            isLoading={isGoingBackNegotiating}
          />
        )}
      </Modal>
    </div>
  );
};
