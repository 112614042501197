import { ModalConfirm } from "components/app";
import { Modal } from "components/shared";

type RemoveTenderVehicleModalProps = {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

export const RemoveTenderVehicleModal = ({
  open,
  onConfirm,
  onCancel,
}: RemoveTenderVehicleModalProps) => (
  <Modal open={open} closeModal={onCancel} size="auto">
    <ModalConfirm
      title="Remove Vehicle?"
      description="This vehicle will be removed from your Tender and returned to your Stocklist. If bids have been made they will be lost."
      onCancel={onCancel}
      onConfirm={onConfirm}
    />
  </Modal>
);
