import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";

import { Button, FieldText, TextError } from "components/shared";

import { checkIsValidNumber, formatNumber } from "utils";

import { VehicleService } from "api/client";

import "./styles.scss";
import { modalMissingInfoTestID } from "../dataTestIDs";

export interface ModalMissingInfoProps {
  onCloseModal: () => void;
  contract_id: string;
  dataTestID?: string;
}

export const ModalMissingInfo = ({
  onCloseModal,
  contract_id,
  dataTestID,
}: ModalMissingInfoProps) => {
  const [isNumber, setIsNumber] = useState<boolean>(true);
  const [checkValue, setCheckValue] = useState<boolean>(true);
  const [odometerVal, setOdometerVal] = useState("");
  const { mutate } = useMutation(
    (payload: any) => VehicleService.updateVehicle(payload, contract_id),
    {
      onSuccess: () => {
        toast.success("Saved successfully!", {
          autoClose: 2000,
          theme: "colored",
        });
        onCloseModal();
      },
      onError: () => {
        toast.error("Failed to Send", {
          autoClose: 2000,
          theme: "colored",
        });
      },
    }
  );

  const handleChangeOdometer = (value: string) => {
    setCheckValue(true);
    setOdometerVal(value.replaceAll(",", ""));
    setIsNumber(checkIsValidNumber(value.replaceAll(",", "")));
  };

  const handleSubmitOdometer = () => {
    if (odometerVal === "") {
      setCheckValue(false);
    } else {
      setCheckValue(true);
      mutate({ odometer: odometerVal.replaceAll(",", ""), type: "odometer" });
    }
  };

  return (
    <div
      className="valuation-add--secondary"
      data-testid={dataTestID || modalMissingInfoTestID}
    >
      <div className="valuation-add__desc">
        You need to complete these fields before sending this vehicle for
        valuation.
      </div>
      <div className="valuation-add__txt">
        <FieldText
          title="Kilometres*"
          type="text"
          value={formatNumber(odometerVal)}
          onChange={(e) => handleChangeOdometer(e.target.value)}
          placeholder="Enter km"
        />
        {isNumber === false && <TextError error="Please enter numbers only" />}
        {checkValue === false && <TextError error="Please enter km" />}
      </div>
      <div className="valuation-add__tool">
        <Button
          type="button"
          onClick={handleSubmitOdometer}
          dataTestID={`${dataTestID || modalMissingInfoTestID}-save`}
        >
          <span className="valuation-add__tool__title">SAVE</span>
        </Button>
      </div>
    </div>
  );
};
