import { INotificationResponseType } from "types/INotification";

import { api, url } from "api";

export const NotificationService = {
  getNotifications: ({ pageParam = 1 }: { pageParam?: number }) =>
    api.get(url.notifications, {
      page: pageParam,
    }) as Promise<INotificationResponseType>,

  handleMarkReadNotification: (id: string) =>
    api.put(`${url.notifications}/${id}`, {
      read: true,
    }) as Promise<any>,
};
