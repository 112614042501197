import { createContext, useState } from "react";

interface IContextProps {
  searchTxt: string | undefined;
  setSearchTxt: (txt: string) => void;
  updatedVehicles: Record<string, boolean>;
  setIsUpdated: (contractID: string, isUpdated: boolean) => void;
  isUpdated: (contractID: string) => boolean;
}

export const SearchContext = createContext<IContextProps>({
  searchTxt: "",
  setSearchTxt: (_txt: string) => {},
  updatedVehicles: {},
  setIsUpdated: (_contractID: string, _isUpdated: boolean) => {},
  isUpdated: (_contractID: string) => false,
});

const SearchProvider = ({ children }: any) => {
  const [searchTxt, setSearchTxt] = useState("");
  const [updatedVehicles, setUpdatedVehicles] = useState<
    Record<string, boolean>
  >({});

  const setIsUpdated = (contractID: string, isUpdated: boolean) => {
    setUpdatedVehicles((prev) => ({ ...prev, [contractID]: isUpdated }));
  };

  const isUpdated = (contractID: string) => {
    return updatedVehicles[contractID] || false;
  };

  return (
    <SearchContext.Provider
      value={{
        searchTxt,
        setSearchTxt,
        updatedVehicles,
        setIsUpdated,
        isUpdated,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default SearchProvider;
