import { ReactNode } from "react";

import { tooltipTestID } from "../dataTestIDs";

import "./styles.scss";

export interface TooltipProps {
  label: string;
  show?: boolean;
  children: ReactNode;
  dataTestID?: string;
}

export const Tooltip = ({
  label,
  show = false,
  dataTestID,
  children,
}: TooltipProps) => {
  return (
    <div className="tooltip" data-testid={dataTestID || tooltipTestID}>
      {children}
      <span className="tooltiptext" hidden={!show}>
        {label}
      </span>
    </div>
  );
};
