import { useCallback } from "react";
import { OnDragEndResponder } from "react-beautiful-dnd";

import { IImage } from "types";

type OnDragProps = {
  activeImages: IImage[];
  images?: { data: IImage[] };
  onActiveDragAndDrop: (dragAndDrop: { from: number; to: number }) => void;
  validLocationID: string;
};

export const useOnDrag = ({
  activeImages,
  images,
  onActiveDragAndDrop,
  validLocationID,
}: OnDragProps) => {
  const onDragEnd = useCallback(
    (result) => {
      const from = result.source.index;
      const to = result.destination?.index ?? undefined;
      const isValidLocation =
        result.destination?.droppableId.includes(validLocationID);

      if (typeof from === "number" && typeof to === "number") {
        onActiveDragAndDrop({
          from,
          to: isValidLocation
            ? to
            : activeImages?.length || images?.data?.length || 0,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeImages?.length, images?.data?.length, validLocationID]
  ) as OnDragEndResponder;

  return { onDragEnd };
};
