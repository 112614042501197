import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";

import { formExternalSalesTestID } from "components/app/dataTestIDs";
import {
  Button,
  ButtonLink,
  FieldRadio,
  Modal,
  SvgImage,
} from "components/shared";

import { formatNumber } from "utils";

import { IWholesaleDealership } from "types";

import { AcquisitionService } from "api/client";

import { SOLD_OPTIONS } from ".";
import { ConfirmSale } from "./ConfirmSale";

export interface FormExternalSalesProps {
  externalDealerships: IWholesaleDealership[];
  contract_id: string;
  onGoBack: () => void;
  onRefresh: () => void;
  dataTestID?: string;
}

export const FormExternalSales = ({
  externalDealerships,
  contract_id,
  onGoBack,
  onRefresh,
  dataTestID,
}: FormExternalSalesProps) => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [salePayload, setSalePayload] = useState<any>();
  const [selectedExDealership, setSelectedExDealership] = useState<string>();

  const { mutate, isLoading } = useMutation(
    (data: any) =>
      AcquisitionService.sellTo(contract_id, SOLD_OPTIONS[2].value, data),
    {
      onSuccess: () => {
        onRefresh();
        toast.success("Sold successfully", {
          autoClose: 2000,
          theme: "colored",
        });
      },
      onError: () => {
        toast.error("Failed to Sell", {
          autoClose: 2000,
          theme: "colored",
        });
      },
    }
  );

  const handleSell = () => {
    mutate(salePayload);
  };

  const handleConfirm = () => {
    const dealership = externalDealerships?.find(
      (d) => d.dealership.id === selectedExDealership
    );
    const payload = {
      sale_price: dealership?.valuation?.offer.amount,
      type: "dealership",
      dealership_id: selectedExDealership,
    };
    setSalePayload(payload);
    setOpenConfirmModal(true);
  };

  return (
    <div
      className="sale-details"
      data-testid={dataTestID || formExternalSalesTestID}
    >
      <div className="absolute top-11">
        <ButtonLink onClick={() => onGoBack()}>
          <SvgImage name="LeftRoundedIcon" />
        </ButtonLink>
      </div>
      <div>
        {externalDealerships.map((dealership) => (
          <div
            className="sale-details__field border-none"
            key={dealership.dealership.id}
          >
            <div className="flex-1">
              <FieldRadio
                name={dealership.dealership.id}
                label={dealership.dealership.name}
                checked={selectedExDealership === dealership.dealership.id}
                onChange={() =>
                  setSelectedExDealership(dealership.dealership.id)
                }
              />
            </div>
            {dealership.valuation && (
              <span>${formatNumber(dealership.valuation.offer.amount)}</span>
            )}
            {dealership.valuation_requested && !dealership.valuation && (
              <div className="flex items-center py-1 px-3 w-103 rounded-full bg-lightGold text-sm text-darkGold">
                Pending
                <SvgImage name="PendingIcon" className="ml-2" />
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="sale-details__footer">
        <Button type="submit" isLoading={isLoading} onClick={handleConfirm}>
          <span>SELL</span>
        </Button>
      </div>
      <Modal
        open={openConfirmModal}
        size="auto"
        closeModal={() => setOpenConfirmModal(false)}
      >
        <ConfirmSale
          onClose={() => setOpenConfirmModal(false)}
          onConfirm={handleSell}
        />
      </Modal>
    </div>
  );
};
