import ContentLoader from "react-content-loader";

export const LoadingTable = () => (
  <ContentLoader speed={1} width="100%" backgroundColor="#f3f3f3">
    <rect x="0" y="0" rx="3" ry="3" width="100%" height="30" />
    <rect x="0" y="40" rx="3" ry="3" width="100%" height="30" />
    <rect x="0" y="80" rx="3" ry="3" width="100%" height="30" />
    <rect x="0" y="120" rx="3" ry="3" width="100%" height="30" />
  </ContentLoader>
);
