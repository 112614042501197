import { confirmSaleTestID } from "components/app/dataTestIDs";
import { Button, SvgImage } from "components/shared";
import "../../styles.scss";

export interface ConfirmSaleProps {
  onClose: () => void;
  onConfirm: () => void;
  dataTestID?: string;
}

export const ConfirmSale = ({
  onClose,
  onConfirm,
  dataTestID,
}: ConfirmSaleProps) => {
  return (
    <div className="confirm-sale" data-testid={dataTestID || confirmSaleTestID}>
      <SvgImage name="ConfirmIcon" />
      <p className="text-xl font-semibold text-txt mt-5 mb-2.5">
        Confirm Sale?
      </p>
      <p className="text-xs font-medium text-lightBlue mb-14 text-center">
        This action will sell the vehicle to the buyer indicated. Do you want to
        proceed?
      </p>
      <div className="flex gap-1 items-center w-full">
        <Button variant="secondary" type="button" onClick={onClose}>
          CANCEL
        </Button>
        <Button className="btn-confirm" type="button" onClick={onConfirm}>
          Confirm
        </Button>
      </div>
    </div>
  );
};
