import { useMutation } from "@tanstack/react-query";
import imageCompression from "browser-image-compression";
import { ChangeEvent } from "react";

import { showErrorToast } from "utils";

import { ISection, OptimisticImage } from "types";

import { VehicleService } from "api/client";

type MultipleUploadProps = {
  contractID: string;
  onRefresh: () => void;
  section: ISection;
  placeholders: ISection[];
  addOptimisticImages?: (images: OptimisticImage[]) => void;
};

export const useMultipleUpload = ({
  contractID,
  onRefresh,
  section,
  placeholders,
  addOptimisticImages,
}: MultipleUploadProps) => {
  const updateOptimisticImages = (formData: FormData) => {
    const image = formData.getAll("images[]");
    const label = formData.getAll("label[]");

    return image.map((image, index) => {
      return {
        image: image,
        label: label[index] as string,
      };
    });
  };

  const { mutate } = useMutation(
    (formData: FormData) =>
      VehicleService.uploadMultiImage(formData, contractID),
    {
      onMutate: (data: FormData) => {
        addOptimisticImages?.(
          updateOptimisticImages(data) as OptimisticImage[]
        );
      },
      onSuccess: () => {
        onRefresh();
      },
      onError: () => {
        addOptimisticImages?.([]);
      },
    }
  );

  const onMultipleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files?.length) {
      const isAddMore = section.label === "Add More";

      try {
        if (e.target.files.length > 9) {
          showErrorToast("Add Max 9 Photos at a time");
          return;
        }

        const files = await Promise.all(
          Object.values(e.target.files).map((file: File) =>
            imageCompression(file, {
              maxSizeMB: 0.5,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
            })
          )
        );

        const formData = new FormData();
        files.forEach((file: File, index: number) => {
          formData.append("images[]", file);
          formData.append(
            "label[]",
            placeholders[index]?.label || "additional-image"
          );
        });

        formData.append("category", isAddMore ? "additional" : "masthead");

        mutate(formData);
      } catch (error) {
        showErrorToast((error as Error).message);
      }
    }
  };

  return { onMultipleUpload };
};
