import { useMutation } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  Button,
  Card,
  FieldText,
  SvgImage,
  TextError,
} from "components/shared";

import { AuthService } from "api/client";

import "./styles.scss";

export const ResetPassword = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [error, setError] = useState<"NOT_MATCH" | "EMPTY">();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { mutate, isLoading } = useMutation(
    (payload: any) => AuthService.resetPassword(payload),
    {
      onSuccess: () => {
        toast.success("The password has been updated", {
          autoClose: 2000,
          theme: "colored",
        });
        navigate("/login");
      },
      onError: () => {
        toast.error("Failed Reset", {
          autoClose: 2000,
          theme: "colored",
        });
      },
    }
  );

  const handleReset = () => {
    const { email, token } = params;
    if (!password) {
      setError("EMPTY");
      return;
    }
    if (confirmPassword !== password) {
      setError("NOT_MATCH");
      return;
    }
    const payload = {
      email,
      token,
      password,
      password_confirmation: confirmPassword,
    };
    mutate(payload);
  };

  const isCoolPassword = useMemo(() => {
    if (!!password && password === confirmPassword) {
      return true;
    }
    return false;
  }, [password, confirmPassword]);

  return (
    <div className="auth-container">
      <div className="w-[423px]">
        <Card>
          <div className="auth-card__content">
            <span className="auth-card__title">Reset Password</span>
            <span className="auth-card__desc pb-3">
              Reset your password to be used on MotorPlatform.
            </span>
            <div className="auth-card__form">
              <FieldText
                value={password}
                type={showPassword ? "text" : "password"}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="New Password"
              />
              <span
                className="auth-card__form__icon"
                onClick={() => setShowPassword(!showPassword)}
              >
                <SvgImage
                  name={`${showPassword ? "EyeShowIcon" : "EyeIcon"}`}
                />
              </span>
              {error === "EMPTY" && !isCoolPassword && (
                <TextError error="Please enter the new password" />
              )}
            </div>
            <div className="auth-card__form">
              <FieldText
                value={confirmPassword}
                type={showConfirmPassword ? "text" : "password"}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm Password"
              />
              <span
                className="auth-card__form__icon"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                <SvgImage
                  name={`${showConfirmPassword ? "EyeShowIcon" : "EyeIcon"}`}
                />
              </span>
              {error === "NOT_MATCH" && !isCoolPassword && (
                <TextError error="Your passwords don't match" />
              )}
            </div>
            <div className="auth-card__footer">
              <Button type="submit" isLoading={isLoading} onClick={handleReset}>
                <span className="text-lighttxt text-sm font-bold">SIGN IN</span>
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};
