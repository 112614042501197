import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext, useState } from "react";

import { AccessDenied } from "pages/AccessDenied";

import { FieldSearchBar, ModalConfirm } from "components/app";
import { Breadcrumbs, Button, Modal } from "components/shared";

import { AuthContext } from "context/AuthContext";
import { SearchContext } from "context/SearchContext";

import { showErrorToast, showSuccessToast } from "utils";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import { IDealershipDetail, IDealershipDetailResponse } from "types";

import { DealershipInspectionService } from "api/client";

import { SelectAll } from "./SelectAll";
import { Table } from "./Table";

import "./styles.scss";

type SelectedDealership = {
  id: string;
  selected: boolean;
};

export const Container = () => {
  const { user, userID, hasPermission } = useContext(AuthContext);
  const { searchTxt } = useContext(SearchContext);
  const [localData, setLocalData] = useState<SelectedDealership[]>([]);
  const [allSelected, setAllSelected] = useState<boolean | null>(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [selectionRequest, setSelectionRequest] = useState({ select: false });

  const { data, isLoading, isFetching, isError, isSuccess, refetch } = useQuery<
    IDealershipDetailResponse,
    Error
  >(
    [
      `dealershipPool_${userID}`,
      user?.data.dealership?.id || "",
      { query: searchTxt },
    ],
    DealershipInspectionService.getDealershipPools,
    {
      onSuccess: (data: IDealershipDetailResponse) => {
        setAllSelected(data?.meta.all_selected || false);

        const serverData = data?.data
          .filter(
            (dealership) => !localData.some((ids) => ids.id === dealership.id)
          )
          .map((dealership) => ({
            id: dealership.id,
            selected: dealership.is_default,
          })) as SelectedDealership[];

        setLocalData((items) => [...items, ...serverData]);

        return data;
      },
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: selectAll, isLoading: isSelectAllLoading } = useMutation(
    () =>
      DealershipInspectionService.setAllToDefault(user?.data.dealership?.id, {
        all_selected: !allSelected,
      }),
    {
      onSuccess: () => {
        setIsConfirmationOpen(false);
        showNotification(true);
        setLocalData([]);
        refetch();
      },
      onError: () => {
        setIsConfirmationOpen(false);
        showNotification(false);
      },
    }
  );

  const { mutate: save, isLoading: isSaveLoading } = useMutation(
    (data: string[]) =>
      DealershipInspectionService.updateDealershipPools(
        data,
        user?.data.dealership?.id
      ),
    {
      onSuccess: () => {
        showNotification(true);
        refetch();
      },
      onError: () => showNotification(false),
    }
  );

  const canUpdateOrSave =
    hasPermission(
      PermissionCategory.DEALERSHIP_POOL,
      PermissionAction.CREATE
    ) ||
    hasPermission(PermissionCategory.DEALERSHIP_POOL, PermissionAction.UPDATE);

  const canDeselect = hasPermission(
    PermissionCategory.DEALERSHIP_POOL,
    PermissionAction.UPDATE
  );

  const showNotification = (isSuccess: boolean) =>
    isSuccess
      ? showSuccessToast("Saved Successfully")
      : showErrorToast("Failed to Save");

  const onSelect = (id: string, selected: boolean) => {
    if (!selected && allSelected) setAllSelected(selected);

    setLocalData((dealerships) => {
      return [
        ...[...dealerships].filter((dealership) => dealership.id !== id),
        { id, selected },
      ];
    });
  };

  if (!hasPermission(PermissionCategory.DEALERSHIP_POOL)) {
    return <AccessDenied />;
  }

  return (
    <>
      <div className="dashboard__container">
        <div className="flex w-full gap-5">
          <div className="flex flex-col gap-2.5 pt-2 w-430">
            <Breadcrumbs
              paths={[
                { title: "Settings", route: "/settings" },
                {
                  title: "Dealership Pool",
                  route: "/settings/dealership-pool",
                },
              ]}
            />
            <h2 className="dashboard__title ">Dealership Pool</h2>
          </div>
          <div className="dashboard__toolbar">
            <FieldSearchBar />
          </div>
        </div>
        <div className="dashboard__content flex-col gap-5">
          <SelectAll
            isAllSelected={allSelected || false}
            isLoading={isSelectAllLoading}
            onSelectAll={(isAllSelected) => {
              setSelectionRequest({ select: isAllSelected });
              setIsConfirmationOpen(true);
            }}
            canUpdateOrSave={canUpdateOrSave}
            canDeselect={canDeselect}
          />
          <Table
            data={data?.data || ([] as IDealershipDetail[])}
            isLoading={isLoading || isFetching || isSaveLoading}
            isError={isError}
            isSuccess={isSuccess}
            selected={localData}
            onSelect={(id: string, selected: boolean) => onSelect(id, selected)}
            canDeselect={canDeselect}
            canUpdateOrSave={canUpdateOrSave}
          />
          <div className="dashboard__footer">
            <div className="dashboard__content__tool">
              <Button
                type="submit"
                onClick={() =>
                  save(
                    localData
                      .filter((dealership) => dealership.selected)
                      .map((dealership) => dealership.id)
                  )
                }
                isLoading={
                  isLoading || isFetching || isSaveLoading || isSelectAllLoading
                }
                disabled={!canUpdateOrSave}
              >
                <span className="dashboard__content__tool__txt">Save</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={isConfirmationOpen}
        closeModal={() => setIsConfirmationOpen(false)}
        size="auto"
      >
        <ModalConfirm
          title={`${selectionRequest.select ? "Select" : "Deselect"} All?`}
          description={`Confirming this action, all ${
            data?.data.length || ""
          } dealerships in your pool will be ${
            selectionRequest.select ? "selected" : "deselected"
          }.`}
          onCancel={() => setIsConfirmationOpen(false)}
          onConfirm={() => {
            setAllSelected(selectionRequest.select);
            selectAll();
          }}
        />
      </Modal>
    </>
  );
};
