import { Controller, useForm } from "react-hook-form";

import { FieldCheckbox, Prompt } from "components/shared";

import { IDealershipDetail } from "types";

interface RowProps {
  dealership: IDealershipDetail;
  isSelected?: boolean;
  onSelect: (id: string, isSelected: boolean) => void;
  canDeselect: boolean;
  data: IDealershipDetail[];
  canUpdateOrSave?: boolean;
}

export const Row = ({
  dealership,
  isSelected = false,
  onSelect,
  canDeselect,
  data,
  canUpdateOrSave,
}: RowProps) => {
  const {
    control,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      fields: dealership,
    },
  });

  const isChecked = data.filter(
    (detail: IDealershipDetail) =>
      detail.id === dealership.id && detail.is_default
  ).length;

  return (
    <>
      <Prompt isDirty={isDirty} />
      <div key={dealership.id} className="pool-control">
        <span>{dealership.name}</span>
        <span>{dealership.license}</span>
        <span>{dealership.sales?.email}</span>
        <span>{dealership.sales?.agent}</span>
        <div className="items-center flex justify-center">
          <Controller
            key={dealership.id}
            control={control}
            name={`fields.id`}
            render={({ field: { onChange } }) => (
              <FieldCheckbox
                id={`${dealership.id}-checkbox`}
                variant="secondary"
                checked={isSelected}
                onChange={(e) => {
                  onSelect(dealership.id, e.target.checked);
                  onChange(e.target.checked);
                }}
                disabled={(!!isChecked && !canDeselect) || !canUpdateOrSave}
              />
            )}
          />
        </div>
      </div>
    </>
  );
};
