import { useMutation, useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { useEffect, useState, useMemo, useContext } from "react";
import { toast } from "react-toastify";

import { LoadItem, ModalOfferLog } from "components/app";
import { modalWholesaleValuationTestID } from "components/app/dataTestIDs";
import { Button, FieldCheckbox, Modal, SvgImage } from "components/shared";

import { AuthContext } from "context/AuthContext";

import { formatNumber } from "utils";

import { IWholesaleDealership } from "types";

import { ValuationService } from "api/client";

import "../styles.scss";

export interface ModalWholesaleValuationsProps {
  contract_id: string;
  open: boolean;
  requestDisabled?: boolean;
  canUserView?: boolean;
  dataTestID?: string;
}

const PendingValuation = () => (
  <div className="btn-pending">
    Pending
    <SvgImage name="PendingIcon" className="ml-2" />
  </div>
);

export const ModalWholesaleValuations = ({
  contract_id,
  open,
  requestDisabled = false,
  canUserView = true,
  dataTestID,
}: ModalWholesaleValuationsProps) => {
  const { userID } = useContext(AuthContext);

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [successfulIds, setSuccessfulIds] = useState<string[]>([]);
  const [showValuationLogModal, setValuationLogModal] =
    useState<boolean>(false);
  const [selectedValuationId, setSelectedValuationId] = useState<
    string | undefined
  >();
  const {
    data: dealerships,
    refetch,
    isLoading: isFetchingItems,
  } = useQuery<{ data: IWholesaleDealership[] }, Error>(
    [`fetchWholesaleValuation_${contract_id}_${userID}`, contract_id],
    ValuationService.getWholesaleValuation,
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open, refetch]);

  const allChecked = useMemo(() => {
    return (
      dealerships?.data.every((item) => item.valuation_requested) ||
      successfulIds?.length === dealerships?.data?.length
    );
  }, [dealerships, successfulIds]);

  const handleChangeOption = (
    value: boolean,
    dealership: IWholesaleDealership
  ) => {
    let updatedIds = selectedIds.slice();
    if (!value) {
      updatedIds = updatedIds.filter(
        (id: string) => id !== dealership.dealership.id
      );
    } else {
      updatedIds = [...updatedIds, dealership.dealership.id];
    }
    setSelectedIds(updatedIds);
  };

  const { mutate: handleRequest, isLoading } = useMutation(
    () =>
      ValuationService.requestMPValuation(contract_id, {
        type: "wholesalers",
        dealership_ids: selectedIds,
      }),
    {
      onSuccess: () => {
        setSuccessfulIds((prev) => [...prev, ...selectedIds]);
        setSelectedIds([]);
        refetch();
        toast.success("Valuation Requested", {
          autoClose: 2000,
          theme: "colored",
        });
      },
      onError: () => {
        toast.error("Failed to Send", {
          autoClose: 2000,
          theme: "colored",
        });
        setSelectedIds([]);
      },
    }
  );

  const handleViewValuationLog = (valueationId: string | undefined) => {
    setValuationLogModal(true);
    setSelectedValuationId(valueationId);
  };

  return (
    <div
      className="wholesale"
      data-testid={dataTestID || modalWholesaleValuationTestID}
    >
      {isFetchingItems ? (
        <LoadItem />
      ) : (
        dealerships &&
        dealerships.data.map((dealership, idx) =>
          dealership.valuation_requested ||
          successfulIds.some((id) => id === dealership.dealership.id) ? (
            <div className="wholesale__item" key={idx}>
              <label
                className={classNames("flex items-center text-nowrap", {
                  "col-span-2": !dealership.valuation,
                })}
              >
                {dealership.dealership.name}
              </label>
              {dealership.valuation && (
                <div className="ml-2 mr-3 shrink-0">
                  {canUserView && (
                    <span
                      className="hover:cursor-pointer"
                      onClick={() =>
                        handleViewValuationLog(
                          dealership.valuation?.offer.valuation_id
                        )
                      }
                    >
                      <SvgImage name="EyeIcon" width={20} height={14} />
                    </span>
                  )}
                </div>
              )}
              {!canUserView ? (
                <PendingValuation />
              ) : dealership.valuation_declined ? (
                <div className="btn-declined">Declined</div>
              ) : dealership.valuation ? (
                <label className="text-right">
                  {`$${formatNumber(dealership.valuation.offer.amount)}` || 0}
                </label>
              ) : (
                <PendingValuation />
              )}
            </div>
          ) : (
            <div className="wholesale__item with-custom-check" key={idx}>
              <FieldCheckbox
                checked={selectedIds.includes(dealership.dealership.id)}
                onChange={(e) =>
                  handleChangeOption(e.target.checked, dealership)
                }
                label={dealership.dealership.name}
              />
            </div>
          )
        )
      )}
      <div className="wholesale__tool">
        <Button
          type="button"
          disabled={
            requestDisabled ||
            allChecked ||
            isFetchingItems ||
            !selectedIds.length
          }
          isLoading={isLoading}
          onClick={handleRequest}
        >
          <span className="wholesale__tool__title">REQUEST</span>
        </Button>
      </div>
      <Modal
        open={showValuationLogModal}
        title="Valuation Log"
        size="auto"
        closeModal={() => setValuationLogModal(false)}
      >
        <ModalOfferLog
          open={showValuationLogModal}
          valuationID={selectedValuationId!}
        />
      </Modal>
    </div>
  );
};
