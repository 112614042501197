import ContentLoader from "react-content-loader";

export const LoadItem = () => {
  return (
    <div className="my-5">
      <ContentLoader speed={2} viewBox="0 0 946 141">
        <rect width="511" height="141" rx="20" fill="#F3F3F3" />
        <rect x="550" y="13" width="300" height="40" rx="20" fill="#F3F3F3" />
        <rect x="550" y="63" width="300" height="20" rx="10" fill="#F3F3F3" />
        <rect x="550" y="93" width="300" height="33" rx="16.5" fill="#F3F3F3" />
        <rect width="511" height="141" rx="20" fill="#F3F3F3" />
      </ContentLoader>
    </div>
  );
};
