import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { useLocation } from "react-router-dom";

import { Button } from "components/shared";
import { Tabs } from "components/Tabs";

import { AuthContext } from "context/AuthContext";

import { INSPECTION_TYPE, showErrorToast, showSuccessToast } from "utils";

import { CustomerService, InspectionService } from "api/client";

import { InspectionCompleted } from "./InspectionCompleted";
import { LinkSent } from "./LinkSent";

interface VehiclesProps {
  customerID?: string;
}

export const Vehicles = ({ customerID }: VehiclesProps) => {
  const { userID } = useContext(AuthContext);
  const { pathname } = useLocation();

  const {
    data: linkSent,
    isLoading: isLoadingLinkSent,
    isError: isErrorLinkSent,
    isSuccess: isSuccessLinkSent,
    refetch: refetchLinkSent,
  } = useQuery(
    [
      `fetch_customer_contracts_${customerID}_incompleted_${userID}`,
      customerID,
      { status: "incomplete" },
    ],
    CustomerService.getCustomerContracts,
    {
      enabled: !!customerID,
    }
  );

  const {
    data: inspectionCompleted,
    isLoading: isLoadingInspectionCompleted,
    isError: isErrorInspectionCompleted,
    isSuccess: isSuccessInspectionCompleted,
    refetch: refetchInspectionCompleted,
  } = useQuery(
    [
      `fetch_customer_contracts_${customerID}_submitted_${userID}`,
      customerID,
      { with: "images,owner,valuations" },
    ],
    CustomerService.getCustomerContracts,
    {
      enabled: !!customerID,
    }
  );

  const { mutate: sendLink, isLoading: isLoadingSendLink } = useMutation(
    (id: string) =>
      InspectionService.sendLink(id, {
        inspection_type: pathname.includes("wholesale-dealership")
          ? INSPECTION_TYPE.WHOLESALE
          : INSPECTION_TYPE.RETAIL,
      }),
    {
      onSuccess: () => {
        showSuccessToast("Sent Link!");
        refetchLinkSent();
        refetchInspectionCompleted();
      },
      onError: (error: { data?: { message: string } }) => {
        const errorMessage = error.data?.message || "";

        if (errorMessage.includes("Email and Phone")) {
          showErrorToast("Please add email and phone");
        } else {
          showErrorToast("Failed to send link");
        }
      },
    }
  );

  const onSendLink = () => {
    if (customerID) {
      sendLink(customerID);
    } else {
      showErrorToast("Customer Infomation not provided yet.");
    }
  };

  const vehicleTabs = [
    {
      id: 0,
      title: "INSPECTIONS COMPLETED",
      content: (
        <InspectionCompleted
          data={inspectionCompleted?.data}
          isLoading={isLoadingInspectionCompleted}
          isError={isErrorInspectionCompleted}
          isSuccess={isSuccessInspectionCompleted}
        />
      ),
    },
    {
      id: 1,
      title: "LINKS SENT",
      content: (
        <LinkSent
          data={linkSent?.data}
          isLoading={isLoadingLinkSent}
          isError={isErrorLinkSent}
          isSuccess={isSuccessLinkSent}
        />
      ),
    },
  ];

  return (
    <div className="mt-10">
      <div className="text-lg text-txt font-semibold mb-4">Vehicles</div>
      <Tabs tabs={vehicleTabs} />
      {customerID && (
        <Button
          type="button"
          variant="primary"
          isLoading={isLoadingSendLink}
          className="!bg-white !w-72 shadow-md mt-6"
          onClick={onSendLink}
        >
          <span className="text-primary font-bold mt-5">
            SEND INSPECTION LINK
          </span>
        </Button>
      )}
    </div>
  );
};
