import { FC } from "react";

export interface ButtonWithIconProps {
  className?: string;
  onClick?: () => void;
}

export const ButtonWithIcon: FC<ButtonWithIconProps> = ({
  className,
  onClick,
  children,
}) => {
  return (
    <button className={`${className} outline-none`} onClick={onClick}>
      {children}
    </button>
  );
};
