import { ButtonStatus } from "components/app";
import "../styles.scss";
import { cardCustomerTestID } from "components/app/dataTestIDs";
import { Card } from "components/shared";

import { ICustomerDetail } from "types";

export interface CardCustomerProps {
  data: ICustomerDetail;
  onClick: () => void;
  dataTestID?: string;
}

export const CardCustomer = ({
  data,
  onClick,
  dataTestID,
}: CardCustomerProps) => {
  const { full_name, email, phone, contract } = data;
  return (
    <Card onClick={onClick} dataTestID={dataTestID || cardCustomerTestID}>
      <div className="card-vehicle !h-auto p-5">
        <div className="card-vehicle__info">
          <div className="card-vehicle__section--customer">
            <div className="bg-primary rounded-10 px-2 py-1 text-lg text-white uppercase font-semibold">
              {full_name
                ?.split(" ")
                .map((section) => section[0] || "")
                .join("")}
            </div>
            <div className="card-vehicle__txt--info font-semibold">
              {full_name}
            </div>
          </div>
          <div className="card-vehicle__section !justify-start !px-0">
            <div className="card-vehicle__txt--info">
              Phone: <span className="font-semibold">{phone}</span>
            </div>
            <div className="card-vehicle__txt--info">
              Email: <span className="font-semibold">{email}</span>
            </div>
          </div>
        </div>
        <div className="card-vehicle__section !justify-start !flex-1">
          <div className="card-vehicle__txt--info flex gap-2">
            Vehicle:{" "}
            <p className="font-semibold">{contract?.vehicle.name || "-"}</p>
          </div>
          <div className="w-fit ml-15">
            {contract && (
              <ButtonStatus
                status={contract.status.name}
                amount={0}
                label={contract.status.label}
              />
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};
