import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect } from "react";

import { LoadingSpinner } from "components/Loading/LoadingSpinner";

import { AuthContext } from "context/AuthContext";

import { StorageKeys, showErrorToast } from "utils";

import { IVinSpecifications } from "types/IVinSpecifications";

import { VehicleService } from "api/client";

import { DataContainer } from "./DataContainer";
import { DownloadContainer } from "./DownloadContainer";

interface FactoryOptionsProps {
  contractID: string;
  hasFactoryOptions?: boolean;
  vin?: string;
}

export const FactoryOptions = ({
  contractID,
  hasFactoryOptions,
  vin,
}: FactoryOptionsProps) => {
  const { userID } = useContext(AuthContext);

  const { data, isInitialLoading, isFetching, refetch, isError } = useQuery<
    { data: IVinSpecifications },
    Error
  >(
    [`${StorageKeys.VehicleInformationFactoryOptions}_${userID}`, contractID],
    VehicleService.getVehicleVinSpecifications,
    {
      enabled: false,
    }
  );

  useEffect(() => {
    if (hasFactoryOptions) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFactoryOptions]);

  useEffect(() => {
    if (isError) showErrorToast("Something went wrong. Try Again");
  }, [isError]);

  return (
    <>
      {isInitialLoading && hasFactoryOptions ? (
        <div className="flex h-full items-center justify-center">
          <LoadingSpinner size={40} />
        </div>
      ) : !data?.data.id ? (
        <DownloadContainer
          isLoading={isFetching}
          onDownload={() => {
            refetch().then((res) => {
              if (!res.data?.data.id && !res.isError)
                showErrorToast(
                  vin
                    ? "No Information Available"
                    : "Something went wrong. Try Again"
                );
            });
          }}
        />
      ) : (
        <DataContainer data={data?.data} />
      )}
    </>
  );
};
