import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { formManualSearchTestID } from "components/app/dataTestIDs";
import { Button, FieldDropdown, TextError } from "components/shared";

import { IOption } from "types";

import { VehicleSearchService } from "api/client";

export interface ISearchFormType {
  year: string;
  make: string;
  model: string;
}

export interface FormManualSearchProps {
  onNextStep: (val: ISearchFormType) => void;
  dataTestID?: string;
}

export const FormManualSearch = ({
  onNextStep,
  dataTestID,
}: FormManualSearchProps) => {
  const [yearOptions, setYearOptions] = useState<Array<IOption>>([]);
  const [makeOptions, setMakeOptions] = useState<Array<IOption>>([]);
  const [modelOptions, setModelOptions] = useState<Array<IOption>>([]);
  const [year, setYear] = useState<string>();
  const [make, setMake] = useState<string>();
  const [model, setModel] = useState<string>();
  const [error, setError] = useState({
    year: false,
    make: false,
    model: false,
  });
  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const handleSubmit = () => {
    setIsSubmit(true);
    setError({ year: !year, make: !make, model: !model });

    if (year && make && model) {
      onNextStep({ year, make, model });
    }
  };

  useEffect(() => {
    VehicleSearchService.searchYears()
      .then((res: any) => {
        const options = (res.data as Array<any>).map((item) => ({
          key: item.key,
          label: item.value,
        }));
        setYearOptions(options);
      })
      .catch(() => {
        toast.error("Failed to Find year", {
          autoClose: 2000,
          theme: "colored",
        });
      });
  }, []);

  useEffect(() => {
    if (year) {
      VehicleSearchService.searchMakes({ year })
        .then((res: any) => {
          const options = (res.data as Array<any>).map((item) => ({
            key: item.key,
            label: item.value,
          }));
          setMakeOptions(options);
        })
        .catch(() => {
          toast.error("Failed to Find make", {
            autoClose: 2000,
            theme: "colored",
          });
        });
    }
  }, [year]);

  useEffect(() => {
    isSubmit && setError({ year: !year, make: !make, model: !model });
  }, [year, make, model, isSubmit]);

  useEffect(() => {
    if (year && make) {
      VehicleSearchService.searchModels({ year, make })
        .then((res: any) => {
          const options = (res.data as Array<any>).map((item) => ({
            key: item.key,
            label: item.value,
          }));
          setModelOptions(options);
        })
        .catch(() => {
          toast.error("Failed to Find Model", {
            autoClose: 2000,
            theme: "colored",
          });
        });
    }
  }, [make, year]);

  return (
    <>
      <div
        className="search-form__row"
        data-testid={dataTestID || formManualSearchTestID}
      >
        <div>
          <FieldDropdown
            title="Year*"
            placeholder="Select year"
            options={yearOptions}
            value={year}
            onChange={setYear}
          />
          {error.year && <TextError error="Year is required" />}
        </div>

        <div>
          <FieldDropdown
            title="Make*"
            value={make}
            onChange={setMake}
            placeholder="Select make"
            options={makeOptions}
          />
          {error.make && <TextError error="Make is required" />}
        </div>

        <div>
          <FieldDropdown
            title="Model*"
            value={model}
            onChange={setModel}
            placeholder="Select model"
            options={modelOptions}
          />
          {error.model && <TextError error="Model is required" />}
        </div>
      </div>
      <div className="search-form__footer">
        <div className="search-form__footer__tool">
          <Button type="submit" onClick={handleSubmit}>
            <span className="search-form__footer__tool__title">
              Find Vehicle
            </span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default FormManualSearch;
