export enum FEATURES {
  DASHBOARD,
}

export const FEATURES_AVAILABILITY: { [key: string]: boolean } = {
  [FEATURES.DASHBOARD]: process.env.REACT_APP_DASHBOARD === "true",
};

export const isFeatureAvailable = (feature: FEATURES) => {
  return FEATURES_AVAILABILITY[feature];
};
