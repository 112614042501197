import { useQuery } from "@tanstack/react-query";
import { ChangeEvent, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ListVehicles, LoadVehicle, SearchBar } from "components/app";
import { LoadingSpinner } from "components/Loading/LoadingSpinner";
import { Pagination } from "components/shared";

import { SearchContext } from "context/SearchContext";

import { FLOW_TYPE } from "utils";

import { VehicleService } from "api/client";

export const SearchList = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const { searchTxt, setSearchTxt } = useContext(SearchContext);

  const { data, isLoading, isRefetching, isInitialLoading } = useQuery(
    [
      `fetch_search_global_search=${searchTxt}&page=${currentPage}`,
      searchTxt,
      currentPage,
    ],
    VehicleService.fetchGlobalSearch,
    {
      enabled: !!searchTxt,
    }
  );

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1);
    setSearchTxt(e.target.value);
  };

  const onSelect = (id: string) => {
    navigate(`/search/summary/${id}`);
  };

  const handleFetchNext = () => {
    setCurrentPage(currentPage + 1);
  };
  const handleFetchPrev = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleFetchSelected = (index: number) => {
    setCurrentPage(index);
  };

  const renderList = () => {
    if (isInitialLoading) {
      return <LoadVehicle />;
    }
    if (data?.data?.length === 0 || !searchTxt) {
      return (
        <div className="dashboard__content--empty">
          {searchTxt ? "No Inspections Created Yet" : "No search string"}
        </div>
      );
    }
    return (
      <ListVehicles
        vehicles={data?.data || []}
        onSelect={onSelect}
        flow={FLOW_TYPE.RETAIL_DEALERSHIP}
      />
    );
  };

  return (
    <div className="w-[1300px]">
      <div className="relative">
        <SearchBar onSearch={onSearch} value={searchTxt} />
      </div>
      <div className="my-3">
        <div className="title my-3">Global Search</div>
        <Pagination
          currentPage={currentPage}
          pageLimit={data?.meta?.per_page || 0}
          total={data?.meta?.total || 0}
          onNextPage={handleFetchNext}
          onPrevPage={handleFetchPrev}
          onIndexedPage={handleFetchSelected}
        />
      </div>
      {isRefetching && <LoadingSpinner />}
      <div className="dashboard__content">{renderList()}</div>
      <div className="pt-5 pb-10">
        <Pagination
          currentPage={currentPage}
          pageLimit={data?.meta?.per_page || 0}
          total={data?.meta?.total || 0}
          onNextPage={handleFetchNext}
          onPrevPage={handleFetchPrev}
          onIndexedPage={handleFetchSelected}
        />
      </div>
    </div>
  );
};
