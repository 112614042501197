import { modalPlatesLocationsTestID } from "components/app/dataTestIDs";

import PlatesImg from "assets/images/HighestQualityModalImage.png";
import PlatesImg2x from "assets/images/LargerVINLocationModalImage.png";

import "../styles.scss";

interface ModalPlatesLocationsProps {
  dataTestID?: string;
}

export const ModalPlatesLocations = ({
  dataTestID,
}: ModalPlatesLocationsProps) => {
  return (
    <div
      className="plates-location"
      data-testid={dataTestID || modalPlatesLocationsTestID}
    >
      <div className="plates-location__title">
        <h5>Plates Locations</h5>
      </div>

      <div className="plates-location__logo">
        <img
          src={PlatesImg2x}
          alt="Plates Location"
          className="hidden md:block"
        />
        <img
          src={PlatesImg}
          alt="Plates Location"
          className="block md:hidden"
        />
      </div>
      <ul className="plates-location__desc">
        <li>
          A <span>build plate</span> is usually found under the bonnet or on the
          firewall in the car’s engine bay.
        </li>
        <li>
          A <span>compliance plate</span> is usually placed in the car’s engine
          bay, door pillar or footwell. It includes the words  "manufactured to
          comply with".
        </li>
      </ul>
    </div>
  );
};
