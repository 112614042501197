import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

import { CreateListingModal } from "components/Modal/CreateListingModal";
import { Button, SvgImage } from "components/shared";

import { StorageKeys } from "utils";

export const CreateListingButton = () => {
  const queryClient = useQueryClient();
  const [isCreateListingModalOpen, setIsCreateListingModalOpen] =
    useState(false);
  return (
    <div className="w-110">
      <Button type="button" onClick={() => setIsCreateListingModalOpen(true)}>
        <div className="flex items-center justify-center gap-2">
          <SvgImage name="CreateIcon" />
          <span className="text-sm text-white font-semibold">CREATE</span>
        </div>
      </Button>
      <CreateListingModal
        isOpen={isCreateListingModalOpen}
        onClose={() => setIsCreateListingModalOpen(false)}
        refetch={() => {
          queryClient.refetchQueries({
            queryKey: [StorageKeys.MotorMarketMyListings],
          });
        }}
      />
    </div>
  );
};
