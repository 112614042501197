import { useState } from "react";

import { FieldState, ButtonStatus, ModalEditKM } from "components/app";
import { ButtonLink, SvgImage, Modal } from "components/shared";

import {
  formatNumber,
  TENDERED,
  SOLDTENDERED,
  TENDER_FLOW_TYPE,
  convertToDate,
  WON,
} from "utils";

import { ITenderVehicle, IVehicleSummary } from "types";

import "../Summary/styles.scss";

interface TenderSummaryHeaderProps {
  data: IVehicleSummary;
  summaryData?: ITenderVehicle;
  from?: TENDER_FLOW_TYPE;
  onRefresh: () => void;
}

export const TenderSummaryHeader = ({
  data,
  from = TENDER_FLOW_TYPE.SELLING,
  summaryData,
  onRefresh,
}: TenderSummaryHeaderProps) => {
  const [openKMModal, setOpenKMModal] = useState(false);

  const {
    vehicle: {
      name,
      odometer,
      compliance: {
        registration_expiry_date,
        registration_plate,
        registration_state,
      },
      specification: { colour },
    },
    status: { name: status, label: statusLabel } = {},
    valuations,
    created_at,
    created_by,
    acquisitions,
    contract_id,
    dealership,
    seller,
    owner,
  } = data;

  const isExternal = seller?.id && !owner?.id;

  const handleEditKM = () => {
    setOpenKMModal(true);
  };

  const closeKMModal = () => {
    setOpenKMModal(false);
    onRefresh();
  };

  const expiryDate =
    typeof registration_expiry_date === "string"
      ? registration_expiry_date
      : registration_expiry_date?.value;

  const getBidAmount = () => {
    if (summaryData?.status?.name === WON.key && summaryData.bid?.sale_amount) {
      return summaryData.bid?.sale_amount;
    }
    return summaryData?.bid?.amount;
  };

  return (
    <>
      <div className="mb-1">
        <div className="title">{name}</div>
        <div className="summary-details">
          {(registration_plate || registration_state) && (
            <>
              <span>{registration_plate}</span>
              <span>{registration_state}</span>
              <span>|</span>
            </>
          )}
          <div className="flex">
            Km: {formatNumber(odometer) || "-"}
            {data.status?.name === TENDERED.key &&
              from === TENDER_FLOW_TYPE.SELLING && (
                <div className="flex items-center ml-4">
                  <ButtonLink onClick={handleEditKM}>
                    <SvgImage name="EditPen" width={12} />
                  </ButtonLink>
                </div>
              )}
          </div>
          <span>|</span>
          <span>Colour: {colour || "-"}</span>
          <span>|</span>
          <span>Rego Expiry: {convertToDate(expiryDate)}</span>
        </div>
      </div>
      {from === TENDER_FLOW_TYPE.BUYING ? (
        <div className="section">
          <div className="section__body--header">
            <div className="flex flex-col flex-1 gap-3">
              <FieldState
                name="Status"
                style={{ alignItems: "center" }}
                value={
                  <div className="flex">
                    <ButtonStatus
                      status={summaryData?.status?.name || ""}
                      amount={getBidAmount()}
                      label={summaryData?.status?.label || ""}
                    />
                  </div>
                }
              />
              <FieldState name="Dealership" value={dealership?.name} />
              <FieldState name="Staff Name" value={created_by?.name} />
            </div>
            <div className="flex flex-col flex-1 gap-3"></div>
          </div>
        </div>
      ) : (
        <div className="section">
          <div className="section__body--header">
            <div className="flex flex-col flex-1 gap-3">
              <FieldState
                name="Status"
                style={{ alignItems: "center" }}
                value={
                  <div className="flex">
                    <ButtonStatus
                      status={status || ""}
                      amount={
                        status === SOLDTENDERED.key
                          ? acquisitions.sale?.amount
                          : ""
                      }
                      label={statusLabel || ""}
                    />
                  </div>
                }
              />
              <FieldState
                name={isExternal ? "Dealership" : "Customer"}
                value={isExternal ? seller.name : owner.full_name}
              />
              <FieldState name="Staff Name" value={created_by.name} />
            </div>
            <div className="flex flex-col flex-1 gap-3">
              <FieldState name="Created" value={created_at} />
              <FieldState
                name="Offer Sent"
                value={valuations.internal?.offer?.created_at}
              />
              <FieldState name="Acquired" value={acquisitions?.created_at} />
            </div>
          </div>
        </div>
      )}
      <Modal
        open={openKMModal}
        size="auto"
        title="Edit Kilometres"
        closeModal={() => setOpenKMModal(false)}
      >
        <ModalEditKM
          onCloseModal={closeKMModal}
          contract_id={contract_id}
          odometer={odometer}
        />
      </Modal>
    </>
  );
};
