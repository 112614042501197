import { useContext } from "react";
import { useLocation } from "react-router-dom";

import {
  ButtonInspectionNew,
  ButtonNotification,
  ButtonTenderNew,
} from "components/app";
import { DealershipSelector } from "components/DealershipSelector/DealershipSelector";
import { CreateListingButton } from "components/MotorMarket/CreateListingButton";
import { SvgImage } from "components/shared";

import { AuthContext } from "context/AuthContext";

import { canManageDealership, FeatureRoute, INSPECTION_TYPE } from "utils";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";
import "../styles.scss";

export const TheHeader = () => {
  const { pathname } = useLocation();
  const { user, hasPermission } = useContext(AuthContext);

  const canCreate = (permissionCategory: PermissionCategory) =>
    hasPermission(permissionCategory, PermissionAction.CREATE);

  const renderCreateButton = () => {
    if (
      pathname.includes(FeatureRoute.RetailDealership) &&
      canCreate(PermissionCategory.RETAIL_INSPECTIONS)
    ) {
      return <ButtonInspectionNew inspectionType={INSPECTION_TYPE.RETAIL} />;
    }

    if (
      pathname.includes(FeatureRoute.WholesaleDealership) &&
      !canManageDealership(user?.data.dealership?.type) &&
      canCreate(PermissionCategory.WHOLESALE_INSPECTIONS)
    ) {
      return <ButtonInspectionNew inspectionType={INSPECTION_TYPE.WHOLESALE} />;
    }

    if (
      pathname.includes(FeatureRoute.TenderSell) &&
      canCreate(PermissionCategory.TENDER_SELLING)
    ) {
      return <ButtonTenderNew />;
    }

    if (
      pathname.includes(FeatureRoute.MotorMarket) &&
      canCreate(PermissionCategory.MOTOR_MARKET_SELLING)
    ) {
      return <CreateListingButton />;
    }
  };

  return (
    <div className="topbar">
      <div className="topbar__section--menu">
        <SvgImage
          name="Logo"
          width={205}
          height={72}
          className="hidden lg:block"
        />
        <SvgImage
          name="LogoMd"
          width={73}
          height={34}
          className="hidden md:block lg:hidden"
        />
      </div>
      <div
        className={
          !pathname.includes("wholesale-dealership") &&
          !canManageDealership(user?.data.dealership?.type)
            ? "topbar__section--tool"
            : "topbar__section--auto"
        }
      >
        <DealershipSelector />
        {renderCreateButton()}
        <div className="flex items-center ml-6">
          <ButtonNotification />
        </div>
      </div>
    </div>
  );
};
