import { Transition } from "@headlessui/react";
import classNames from "classnames";
import { useState, useRef, useEffect } from "react";

import { ModalCarouselCar } from "components/app";

import { IImage } from "types";

import { ReactComponent as ArrowLeftIcon } from "assets/images/arrow-circle-left.svg";
import { ReactComponent as ArrowRightIcon } from "assets/images/arrow-circle-right.svg";

interface ImageSliderProps {
  images: IImage[];
}

export const ImageSlider = ({ images }: ImageSliderProps) => {
  const [isCarouselOpen, setIsCarouselOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [moving, setMoving] = useState("right");

  useEffect(() => {
    console.log("currentStep", currentStep);
  }, [currentStep]);

  const prevStep = () => {
    setMoving("left");
    setCurrentStep(currentStep - 1);
  };

  const nextStep = async () => {
    setMoving("right");
    setCurrentStep(currentStep + 1);
  };

  const wrapper = useRef(null);
  const [wrapperWidth, setWrapperWidth] = useState(1);

  const imagesSrc = images.map((image) => image.url);

  useEffect(() => {
    function handleResize() {
      if (wrapper.current !== null) {
        const wrapperElement = wrapper.current as HTMLElement;
        setWrapperWidth(wrapperElement.offsetWidth);
      }
    }
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="bg-white flex h-[400px]">
        <div className="flex-1 flex flex-col pt-5 h-full">
          <div
            className="flex items-start overflow-hidden w-full h-full relative"
            ref={wrapper}
          >
            <div className="flex flex-nowrap h-full">
              {imagesSrc.map((image, index) => {
                return (
                  <Transition
                    key={`image_${index}`}
                    appear={false}
                    unmount={false}
                    show={currentStep === index}
                    enter="transform transition ease-in-out duration-500"
                    enterFrom={
                      moving === "right"
                        ? `translate-x-96 opacity-0`
                        : `-translate-x-96 opacity-0`
                    }
                    enterTo={`translate-x-0 opacity-100`}
                    leave="transform transition ease-in-out duration-500 "
                    leaveFrom={`translate-x-0 opacity-100`}
                    leaveTo={
                      moving === "right"
                        ? `-translate-x-full opacity-0`
                        : `translate-x-full opacity-0`
                    }
                    className="w-0 bg-green-200"
                    as="div"
                  >
                    <div
                      className="flex justify-center items-center h-full"
                      style={{ width: `${wrapperWidth}px` }}
                      onClick={() => setIsCarouselOpen(true)}
                    >
                      <img
                        className=" h-400 object-cover"
                        src={image}
                        alt="Car Img"
                      />
                    </div>
                  </Transition>
                );
              })}
            </div>
            <nav
              className="flex items-center justify-center"
              aria-label="Progress"
            >
              <button
                type="button"
                disabled={currentStep === 0}
                onClick={() => prevStep()}
                className="absolute left-0 top-1/2 transform -translate-y-1/2"
              >
                <ArrowLeftIcon />
              </button>
              <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2">
                <ol className="mx-8 flex items-center space-x-5 ">
                  {imagesSrc.map((_image, index) => {
                    return (
                      <li key={`image_${index}`} className="w-3 h-3">
                        <div
                          className={`cursor-pointer w-full h-full rounded-full bg-transparent border-2 border-gray-300 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 border-opacity-0`}
                          onClick={() => setCurrentStep(index)}
                        >
                          <span
                            className={classNames(
                              "p-px flex w-full h-full rounded-full bg-white border-transparent",
                              { "bg-secondary": currentStep === index }
                            )}
                            aria-hidden="true"
                          ></span>
                        </div>
                      </li>
                    );
                  })}
                </ol>
              </div>

              <button
                type="button"
                disabled={currentStep === imagesSrc.length - 1}
                className="absolute right-0 top-1/2 transform -translate-y-1/2"
                onClick={() => nextStep()}
              >
                <ArrowRightIcon />
              </button>
            </nav>
          </div>
        </div>
      </div>
      <ModalCarouselCar
        open={isCarouselOpen}
        images={images}
        activeIndex={currentStep}
        closeModal={() => setIsCarouselOpen(false)}
      />
    </>
  );
};
