import { ButtonStatus } from "components/app/Button";
import { Card, SvgImage } from "components/shared";
import { Tooltip } from "components/Tooltip";

import { ACQUIRED } from "utils";

import { IMotorMarketVehicleListAcquired } from "types/IMotorMarket";

import { CardInfoChip } from "../CardTenderVehicle/CardInfoChip";

interface CardMotorMarketProps {
  data: IMotorMarketVehicleListAcquired;
  selected: boolean;
  onClick: () => void;
}

export const CardMotorMarket = ({
  data,
  selected,
  onClick,
}: CardMotorMarketProps) => {
  const {
    images,
    acquisitions,
    vehicle: {
      name,
      description,
      compliance: { vin, registration_plate },
    },
    created_by,
    seller,
    owner,
    retailed,
  } = data;

  return (
    <Card onClick={onClick}>
      <div className={`card-vehicle ${selected && "bg-lighterBlue"}`}>
        <div className="card-vehicle__img">
          {!images || images.length === 0 ? (
            <SvgImage name="NoImageSvg" />
          ) : (
            <img src={images[0].thumb} alt="Vehicle" />
          )}
          <div className="card-vehicle__status">
            <ButtonStatus
              status={ACQUIRED.key}
              amount={acquisitions?.acquisition?.amount || ""}
              label={ACQUIRED.label}
            />
          </div>
        </div>
        <div className="card-vehicle__info">
          <div className="card-vehicle__section">
            <span className="card-vehicle__txt--title">
              <Tooltip
                className="card-vehicle__txt--title--name"
                tooltip={name}
              >
                {name}
              </Tooltip>
            </span>
            <CardInfoChip label="VIN" value={vin} />
            <span className="card-vehicle__txt--desc">{description}</span>
          </div>
          <div className="card-vehicle__section relative">
            <CardInfoChip label="REGO" value={registration_plate} />
            <CardInfoChip
              label={`${retailed ? "Customer" : "Dealership"} Name`}
              value={retailed ? owner?.full_name : seller?.name}
            />
            <CardInfoChip label="Staff Name" value={created_by?.name} />
            <span className="card-vehicle__txt--desc">
              {acquisitions?.acquisition?.created_at}
            </span>
          </div>
        </div>
      </div>
    </Card>
  );
};
