import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import { BidsHistory } from "pages/MotorMarket/Summary/BidsHistory";
import { RemoveFromTender } from "pages/Tender/Seller/RemoveFromTender";

import {
  Footer,
  LoadVehicleSummary,
  NotifyUpdate,
  SellerInformation,
  SummaryHeader,
  ValuationsSection,
  VehicleInformation,
} from "components/app";
import { BidSection } from "components/app/Tender/BidSection";
import { RemoveFromMotorMarket } from "components/MotorMarket/RemoveFromMotorMarket";
import { Banner, Button } from "components/shared";
import { Timer } from "components/Timer";
import { VehicleComments } from "components/VehicleNotes";
import { VehiclePhotoContainer } from "components/VehiclePhotoContainer";

import {
  ACQUIRED,
  AWAITING,
  FLOW_TYPE,
  formatDateToJsDate,
  MOTOR_MARKET_AUCTION,
  TENDERED,
} from "utils";
import { getPageTitle } from "utils/pageSections";

import { ITenderSummaryResponse, IVehicleSummary } from "types";

import { TenderService, VehicleService } from "api/client";

export const SearchSummary = () => {
  const params = useParams();
  const contractID = params?.id;

  const [openRemoveModal, setOpenRemoveModal] = useState(false);

  const { data, refetch, isLoading } = useQuery(
    [`fetch_search_global_summary`, contractID],
    VehicleService.fetchGlobalSearchDetails,
    {
      enabled: !!contractID,
    }
  );

  const { data: tenderData } = useQuery<ITenderSummaryResponse | null>(
    [
      `tender-seller-summary-${data?.data?.tender_vehicle?.tender_id}`,
      data?.data?.tender_vehicle?.tender_id,
    ],
    TenderService.getTenderSummary,
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: !!data?.data?.tender_vehicle?.tender_id,
    }
  );

  const handleOpenRemoveModal = () => setOpenRemoveModal(true);

  const flow = data?.data.retailed
    ? FLOW_TYPE.RETAIL_DEALERSHIP
    : FLOW_TYPE.WHOLESALE_DEALERSHIP;

  if (isLoading) {
    return <LoadVehicleSummary />;
  }

  return (
    <div>
      <Helmet>
        <title>{getPageTitle("Summary")}</title>
      </Helmet>
      <div className="dashboard__container">
        <div className="dashboard__header"></div>
        <div className="dashboard__content">
          <div className="dashboard__content--left">
            <SummaryHeader
              data={(data?.data as IVehicleSummary) || {}}
              onRefresh={refetch}
            />
            <VehicleInformation
              data={(data?.data as IVehicleSummary) || {}}
              defaultOpen
              flow={FLOW_TYPE.RETAIL_DEALERSHIP}
              refetch={refetch}
              isLocked={false}
            />
            <SellerInformation
              data={data?.data || ({} as IVehicleSummary)}
              flow={flow}
            />
            <VehiclePhotoContainer
              data={data?.data as IVehicleSummary}
              refetch={refetch}
            />
          </div>
          <div className="dashboard__content--right">
            <Banner
              name={data?.data?.status?.name || ""}
              label={data?.data?.status?.label || ""}
            />
            <div className="right-container">
              {![MOTOR_MARKET_AUCTION.key, TENDERED.key].includes(
                data?.data?.status?.name || ""
              ) && (
                <ValuationsSection
                  data={data?.data || ({} as IVehicleSummary)}
                  onRefresh={refetch}
                />
              )}
              <Footer
                data={data?.data || ({} as IVehicleSummary)}
                flow={FLOW_TYPE.RETAIL_DEALERSHIP}
                onRefresh={refetch}
              />
              {data?.data?.status?.name === AWAITING.key && (
                <NotifyUpdate
                  contract_id={data?.data.contract_id}
                  hidden={data?.data?.updates_notified_to_valuers}
                  refetch={refetch}
                />
              )}
              {data?.data?.status?.name === MOTOR_MARKET_AUCTION.key && (
                <div>
                  {!!data?.data.auction?.ends_at && (
                    <Timer
                      targetDate={formatDateToJsDate(
                        data?.data?.auction?.ends_at
                      )}
                      isSummaryView
                    />
                  )}
                  <BidsHistory
                    vehicleAuctionID={data?.data?.auction_vehicle_id}
                    selectedVehicle={data?.data}
                    panelView
                    refetchSummary={refetch}
                  />
                </div>
              )}
              {data?.data?.status?.name === MOTOR_MARKET_AUCTION.key && (
                <RemoveFromMotorMarket
                  auctionVehicleId={data?.data?.auction_vehicle_id || ""}
                  onRefresh={refetch}
                />
              )}
              {data?.data?.status?.name === TENDERED.key && (
                <>
                  <BidSection
                    tenderStatus={tenderData?.data.status || ""}
                    tenderVehicleId={
                      data?.data.tender_vehicle?.tender_vehicle_id || ""
                    }
                  />
                  <RemoveFromTender
                    contractID={data?.data.contract_id}
                    tenderID={
                      data?.data.tender_vehicle?.tender_vehicle_id || ""
                    }
                  />
                </>
              )}
              <VehicleComments contractID={data?.data?.contract_id || ""} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
