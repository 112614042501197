import { useState, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { buttonInspectionTestID } from "components/app/dataTestIDs";
import { Button, Modal, SvgImage } from "components/shared";

import { AuthContext } from "context/AuthContext";

import { INSPECTION_TYPE } from "utils";

import {
  ModalInspectionCreate,
  ModalSendLink,
  ModalSearchManual,
  ModalSearchVinRego,
} from "../..";

import "../styles.scss";

interface ButtonInspectionNewProps {
  inspectionType: INSPECTION_TYPE;
}

export const ButtonInspectionNew = ({
  inspectionType,
}: ButtonInspectionNewProps) => {
  const { user } = useContext(AuthContext);
  const { permissions } = user?.data.user || {};

  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedMode, setSelectedMode] = useState<string | undefined>();

  const navigate = useNavigate();

  const toggleInspectionModal = useCallback((isOpen: boolean) => {
    setShowModal(isOpen);
  }, []);

  const handleSelectMode = (mode: string) => {
    toggleInspectionModal(false);
    setSelectedMode(mode);
  };

  const handleNewInspection = (contract_id: string) => {
    navigate(
      `/${
        inspectionType === INSPECTION_TYPE.WHOLESALE ? "wholesale" : "retail"
      }-dealership/valuation/${contract_id}/summary`
    );
    setSelectedMode(undefined);
  };

  return (
    <div className="btn-inspection-new" data-testid={buttonInspectionTestID}>
      <Button type="button" onClick={() => toggleInspectionModal(true)}>
        <div className="flex items-center justify-center py-2.5 lg:py-0">
          <SvgImage name="CreateIcon" />
          <span className="ml-2 hidden lg:block text-white text-sm font-bold uppercase">
            Create
          </span>
        </div>
      </Button>
      <Modal
        open={showModal}
        size="auto"
        closeModal={() => toggleInspectionModal(false)}
      >
        <ModalInspectionCreate
          onSelectMode={handleSelectMode}
          inspectionType={inspectionType}
        />
      </Modal>
      <Modal
        open={selectedMode === "LINK"}
        closeModal={() => setSelectedMode("")}
      >
        <ModalSendLink inspectionType={inspectionType} />
      </Modal>
      <Modal
        open={selectedMode === "MANUAL"}
        closeModal={() => setSelectedMode("")}
      >
        <ModalSearchManual
          inspectionType={inspectionType}
          onCreatedNew={handleNewInspection}
        />
      </Modal>
      <Modal
        open={selectedMode === "REGO"}
        closeModal={() => setSelectedMode("")}
      >
        <ModalSearchVinRego
          inspectionType={inspectionType}
          onCreatedNew={handleNewInspection}
        />
      </Modal>
    </div>
  );
};
