import { ModalContainer } from "components/ModalContainer";
import { Button, SvgImage } from "components/shared";

export interface ConfirmationModalProps {
  title?: string;
  description?: string;
  content?: React.ReactNode;
  dataTestID?: string;
  isLoading?: boolean;
  isOpen: boolean;
  canClose?: boolean;
  size?: "auto" | "responsive";
  cancelButtonLabel?: "NO" | "CANCEL";
  confirmButtonLabel?: "YES" | "CONTINUE";
  onClose: () => void;
  onConfirm: () => void;
}

export const ConfirmationModal = ({
  title,
  description,
  content,
  dataTestID,
  isLoading,
  isOpen,
  canClose = true,
  size = "auto",
  cancelButtonLabel = "CANCEL",
  confirmButtonLabel = "CONTINUE",
  onClose,
  onConfirm,
}: ConfirmationModalProps) => {
  return (
    <ModalContainer
      isOpen={isOpen}
      canClose={canClose}
      size={size}
      onClose={onClose}
    >
      <div className="w-[310px] -mx-3 -mt-4" data-testid={dataTestID}>
        <div className="flex items-center flex-col text-txt font-semibold text-xl mb-5 w-full">
          <SvgImage name="ConfirmIcon" />
          <label className="mt-4">{title}</label>
        </div>
        <p className="text-label font-normal text-center px-8">
          {description}
          <br />
        </p>
        {content && <div className="my-3 px-3">{content}</div>}
        <div className="flex items-center justify-center w-full space-x-6 font-bold mt-10">
          <div className="w-[126px]">
            <Button variant="secondary" type="button" onClick={onClose}>
              <span className="uppercase text-lightGrey text-sm">
                {cancelButtonLabel?.toLocaleUpperCase()}
              </span>
            </Button>
          </div>
          <div className="w-[126px]">
            <Button type="button" onClick={onConfirm} isLoading={isLoading}>
              <span>{confirmButtonLabel?.toLocaleUpperCase()}</span>
            </Button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};
