import { RETAIL } from "utils/inspectionOptions";

import {
  IQueryKeyProps,
  IRevertVehicleResponse,
  IVehicleResponse,
  IVehicleSummaryResponse,
} from "types";

import { api, url } from "api";

export const AcquisitionService = {
  getVehicleList: ({ queryKey }: IQueryKeyProps) =>
    api.get(url.acquisitions, queryKey[1]) as Promise<IVehicleResponse>,

  getAcquisitionSummary: ({ queryKey }: IQueryKeyProps) =>
    api.get(
      `${url.acquisitions}/${queryKey[1]}`,
      queryKey[2]
    ) as Promise<IVehicleSummaryResponse>,

  negotiationOffer: (contractId: string, payload: any) =>
    api.post(`${url.negotiations}/${contractId}`, payload) as Promise<any>,

  sellTo: (contractId: string, type: string, payload: any) =>
    api.post(
      `${url.acquisitions}/${contractId}/${type}`,
      payload
    ) as Promise<any>,

  negotiationRevert: (contractId: string, payload: any) =>
    api.delete(
      `${url.acquisitions}/${contractId}/revert`,
      payload
    ) as Promise<any>,

  toggleRetailWholesale: (contractId: string, to: string | number) =>
    api.post(
      `${url.contract}/${contractId}/${
        to === RETAIL.key ? "to-retail" : "to-wholesale"
      }`,
      {}
    ) as Promise<any>,

  revertAuction: (contractID: string, payload: { reason: string }) =>
    api.delete(
      `${url.acquisitions}/${contractID}/revert-auction`,
      payload
    ) as Promise<{ data: IRevertVehicleResponse }>,

  revertSale: (contractID: string, payload: { reason: string }) =>
    api.delete(`${url.acquisitions}/${contractID}/revert`, payload) as Promise<{
      data: IRevertVehicleResponse;
    }>,
};
