import { useNavigate } from "react-router-dom";

interface TenderSaleOptionsProps {
  route: string;
}

export const TenderSaleOptions = ({ route }: TenderSaleOptionsProps) => {
  const navigate = useNavigate();

  return (
    <div className="section">
      <div className="section__header">
        <span className="!text-xl font-semibold text-txt">Sale Options</span>
      </div>
      <p className="text-label text-sm">
        If you would like to sell this vehicle:
      </p>
      <button
        className="link-button text-left mt-4"
        onClick={() => navigate(route)}
      >
        <span className="text-secondary underline">
          View Acquired Vehicle in Wholesale Stocklist
        </span>
      </button>
    </div>
  );
};
