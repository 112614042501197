import { ChangeEvent, useState } from "react";

import { modalTenderConfirmSaleID } from "components/app/dataTestIDs";
import { Button, FieldText, SvgImage } from "components/shared";

import {
  formatNumberWithDecimal,
  MAX_TENDER_BID,
  convertToNumber,
} from "utils";

import "../styles.scss";

export interface ModalConfirmTenderSaleProps {
  soldTo: string;
  currentSalePrice: number;
  title?: string;
  dataTestID?: string;
  isLoading?: boolean;
  onCancel: () => void;
  onConfirm: (newPrice: number) => void;
}

export const ModalConfirmTenderSale = ({
  soldTo,
  currentSalePrice,
  title,
  dataTestID,
  isLoading,
  onCancel,
  onConfirm,
}: ModalConfirmTenderSaleProps) => {
  const [currentPrice, setCurrentPrice] = useState(
    currentSalePrice?.toString()
  );

  const handleChangeBidAmount = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const bidValueWithFormat = value.replace(/[^0-9.,]/g, "");
    const bidAmount = value.replace(/[^0-9.]/g, "");

    if (+bidAmount <= MAX_TENDER_BID) {
      setCurrentPrice(bidValueWithFormat);
    }
  };

  const handleOnConfirm = () => {
    onConfirm(+convertToNumber(currentPrice));
  };

  return (
    <div
      className="@apply w-[238px] -mx-3 -mt-4"
      data-testid={dataTestID || modalTenderConfirmSaleID}
    >
      <div className="flex items-center flex-col text-txt font-semibold text-xl mb-5 w-full">
        <SvgImage name="ConfirmIcon" />
        <label className="mt-4">{title || "Are You Sure?"}</label>
      </div>
      <p className="text-label text-sm font-normal text-center mb-7">
        Please confirm the sale details and update the sale price if required.
      </p>
      <div className="font-medium text-sm mb-1">Sell to:</div>
      <div className="text-label mb-4 text-sm">{soldTo}</div>
      <div className="font-medium text-sm mb-1">Sale Price:</div>
      <div className="relative w-full mb-10">
        <p className="absolute left-2 top-2.5 font-normal text-txt">$</p>
        <FieldText
          max={MAX_TENDER_BID}
          value={formatNumberWithDecimal(currentPrice)}
          onChange={handleChangeBidAmount}
          className="h-10 outline-none pl-7 rounded-4 w-full pt-1 text-txt bg-sidebarbg"
          placeholder="Enter Bid"
        />
        <div className="absolute right-2 top-3 font-normal text-txt">
          <SvgImage name="EditPen" width={12} />
        </div>
      </div>

      <div className="items-center justify-between w-full gap-x-2 flex">
        <div className="w-1/2">
          <Button
            type="button"
            onClick={onCancel}
            className="!bg-white shadow-md"
          >
            <span className="uppercase text-lightGrey text-sm font-bold">
              CANCEL
            </span>
          </Button>
        </div>
        <div className="ml-2 w-1/2">
          <Button
            type="button"
            onClick={handleOnConfirm}
            isLoading={isLoading}
            className="shadow-md"
          >
            <span className="uppercase text-sm font-bold">CONFIRM</span>
          </Button>
        </div>
      </div>
    </div>
  );
};
