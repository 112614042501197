export function NotificationPlaceholder() {
  return (
    <>
      {Array.from({ length: 2 }).map((_, index) => (
        <div key={index} className="notification__item">
          <div className="notification__item__container animate-pulse">
            <div className="notification__item__title w-2/3 h-15 bg-grey rounded mb-20" />
            <p className="notification__item__desc w-full h-3 bg-grey rounded" />
            <p className="notification__item__date w-1/3 h-2 bg-grey rounded mb-1" />
          </div>
        </div>
      ))}
    </>
  );
}
