import {
  HTMLProps,
  PropsWithChildren,
  memo,
  useEffect,
  useRef,
  useState,
} from "react";

interface TooltipProps extends HTMLProps<HTMLDivElement> {
  tooltip: string;
}

export const Tooltip = memo(
  ({ tooltip, ...props }: PropsWithChildren<TooltipProps>) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const element = ref.current;

      const handler = () => {
        const clientHeight = element?.clientHeight || 0;
        const scrollHeight = Math.max((element?.scrollHeight || 0) - 1, 0);

        setShowTooltip(clientHeight < scrollHeight);
      };

      handler();

      window.addEventListener("resize", handler);

      return () => window.removeEventListener("resize", handler);
    }, []);

    return (
      <div
        ref={ref}
        data-showtooltip={showTooltip}
        title={showTooltip ? tooltip : undefined}
        {...props}
      />
    );
  }
);
