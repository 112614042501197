import { modalAdditionalVehicleTestID } from "components/app/dataTestIDs";
import { Button } from "components/shared";
import "../styles.scss";

export interface ModalAdditionalVehicleProps {
  onConfirm: () => void;
  closeModal: () => void;
  dataTestID?: string;
}

export const ModalAdditionalVehicle = ({
  onConfirm,
  closeModal,
  dataTestID,
}: ModalAdditionalVehicleProps) => {
  const handleCancel = () => {
    closeModal();
  };

  const handleConfirm = () => {
    onConfirm();
    closeModal();
  };

  return (
    <div
      className="complete-inspection"
      data-testid={dataTestID || modalAdditionalVehicleTestID}
    >
      <h5>Additional Vehicle?</h5>
      <p>Do you want to inspect another vehicle for valuation?</p>

      <div className="complete-inspection__footer">
        <div className="w-202 mb-4">
          <Button
            type="button"
            onClick={handleConfirm}
            className="!bg-white shadow-md"
          >
            <span className="uppercase text-sm font-bold text-lightGrey">
              Yes, start now
            </span>
          </Button>
        </div>
        <div className="w-202">
          <Button type="button" onClick={handleCancel} className="shadow-md">
            <span className="uppercase text-sm font-bold">No, thank you</span>
          </Button>
        </div>
      </div>
    </div>
  );
};
