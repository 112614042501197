export enum NavigationMenuLabel {
  DASHBOARD = "Dashboard",
  RETAIL_DEALERSHIP = "Retail Dealership",
  WHOLESALE_DEALERSHIP = "Wholesale Dealership",
  TENDER = "MotorTender",
  SETTINGS = "Settings",
  MOTOR_MARKET = "Motor Market",
}

export type IAuth = {
  data: {
    access_token: string;
    token_type: string;
    user: {
      id: string;
      name: string;
      is_mp_user: boolean;
      profile?: string;
      permissions: string[];
    };
    dealership?: {
      id: string;
      phone: string;
      name: string;
      type: string;
      sales: {
        agent: string;
        phone: string;
      };
      assigned_dealerships: Record<string, AssignedDealership>;
      children?: Record<string, ChildrenDealership>;
    };
  };
  message: string;
};

export type AssignedDealership = {
  id: string;
  name: string;
  assigned_type: string;
};

export type ChildrenDealership = {
  id: string;
  name: string;
  children?: {
    [key: string]: ChildrenDealership;
  };
};
