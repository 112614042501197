import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, FieldCheckbox, Modal } from "components/shared";

import { AuthContext } from "context/AuthContext";

import { CLOSED, formatNumber, showSuccessToast } from "utils";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import { IBidDetails } from "types";

import { ReactComponent as EyeIcon } from "assets/images/eye-icon.svg";

import { TenderService } from "api/client";

import { ModalConfirmTenderSale } from "../Modal";
import { ModalTenderBidsHistory } from "../Modal/ModalTenderBidsHistory";

interface BidSectionProps {
  tenderVehicleId: string;
  tenderStatus: string;
}

export const BidSection = ({
  tenderVehicleId,
  tenderStatus,
}: BidSectionProps) => {
  const navigate = useNavigate();
  const { user, userID, hasPermission } = useContext(AuthContext);

  const [selectedBidID, setSelectedBidID] = useState<string>();
  const [selectedBidder, setSelectedBidder] = useState("");
  const [selectedCurrentPrice, setSelectedCurrentPrice] = useState(0);
  const [selectedSoldTo, setSelectedSoldTo] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const { data: bids } = useQuery(
    [`fetchTenderVehicleBids_${userID}`, tenderVehicleId],
    TenderService.getTenderVehicleBids
  );

  const { mutate: handleSell, isLoading } = useMutation(
    (updatedPrice: number) =>
      TenderService.bidWinner(selectedBidID || "", updatedPrice),
    {
      onSuccess: () => {
        showSuccessToast("Sold Successfully");
        navigate(-1);
      },
    }
  );

  const canUserSell = hasPermission(
    PermissionCategory.TENDER_SELLING,
    PermissionAction.SELL
  );

  const isSellable = useMemo(() => {
    if (
      bids?.data &&
      bids.data.length > 0 &&
      bids.data.findIndex((bid) => bid.is_winner) < 0 &&
      tenderStatus === CLOSED.key &&
      canUserSell
    ) {
      return true;
    }

    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bids, tenderStatus, user?.data.user.permissions]);

  const sortedBids = useMemo(() => {
    return bids?.data.sort((a, b) => (a.amount < b.amount ? 1 : -1));
  }, [bids]);

  const handleCloseConfirmModal = () => setOpenConfirmModal(false);
  const handleOpenConfirmModal = () => setOpenConfirmModal(true);

  const handleSelectBidder = (bid: IBidDetails) => {
    if (bid.id === selectedBidID) {
      setSelectedBidID("");
    } else {
      setSelectedBidID(bid.id);
      setSelectedCurrentPrice(bid?.amount || 0);
      setSelectedSoldTo(bid?.dealership?.name || "");
    }
  };

  const onOpenBidsLog = (id: string) => {
    setSelectedBidder(id);
  };

  const onCloseBidsModal = () => {
    setSelectedBidder("");
  };

  return (
    <div className="section">
      <div className="section__header">
        <span>Tender Bids</span>
      </div>
      <div className="flex flex-col w-full -mt-4">
        {sortedBids?.map((bid) => (
          <div
            key={bid.id}
            className="flex flex-col w-full pb-4 mt-4 border-b border-softPeach"
          >
            <div className="flex justify-between">
              <div className="flex">
                <FieldCheckbox
                  checked={bid.id === selectedBidID || bid.is_winner}
                  label={bid.dealership.name}
                  disabled={!isSellable}
                  onChange={() => handleSelectBidder(bid)}
                />
                <span
                  className="cursor-pointer ml-2 mt-1 inline-block"
                  onClick={() => onOpenBidsLog(bid.id)}
                >
                  <EyeIcon />
                </span>
              </div>
              <span>${formatNumber(bid.amount)}</span>
            </div>
            <div className="flex justify-between -mt-2">
              <span className="text-sm text-label">{bid.bidder.name}</span>
              <span className="text-sm text-label">{bid.updated_at}</span>
            </div>
          </div>
        ))}
      </div>
      <div className="w-36">
        {canUserSell && (
          <Button
            disabled={!isSellable || !selectedBidID}
            isLoading={isLoading}
            onClick={handleOpenConfirmModal}
          >
            <b>SELL</b>
          </Button>
        )}
      </div>
      <Modal
        open={openConfirmModal}
        closeModal={handleCloseConfirmModal}
        size="auto"
      >
        <ModalConfirmTenderSale
          title="Confirm Sale?"
          onCancel={handleCloseConfirmModal}
          onConfirm={(updatedPrice) => handleSell(updatedPrice)}
          currentSalePrice={selectedCurrentPrice || 0}
          soldTo={selectedSoldTo || ""}
          isLoading={isLoading}
        />
      </Modal>
      <Modal
        open={!!selectedBidder}
        closeModal={onCloseBidsModal}
        title="Bids Log"
        size="auto"
      >
        <ModalTenderBidsHistory bidderID={selectedBidder} isSeller />
      </Modal>
    </div>
  );
};
