import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";

import { FieldSearchBar, ListCustomers, LoadVehicle } from "components/app";
import { Breadcrumbs, Pagination } from "components/shared";

import { SearchContext } from "context/SearchContext";

import { DEFAULT_ITEMS_PER_PAGE, StorageKeys } from "utils";
import { getPageTitle, SECTIONS } from "utils/pageSections";

import { CustomerService } from "api/client";

import { useSessionStorage } from "hooks";
import { useListQuery } from "hooks/useListQuery";

import "./styles.scss";

export const CustomerList = () => {
  const navigate = useNavigate();
  const { searchTxt } = useContext(SearchContext);

  const [currentPage, setCurrentPage] = useSessionStorage(
    StorageKeys.RetailDealershipCustomerList,
    1
  );
  const [scrollPosition, setScrollPosition] = useSessionStorage(
    StorageKeys.RetailDealershipCustomerScroll,
    0
  );

  useEffect(() => {
    if (searchTxt && currentPage !== 1) {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTxt, currentPage]);

  const { data, isInitialLoading, isRefetching } = useListQuery(
    {
      queryKey: StorageKeys.RetailDealershipCustomerList,
      search: searchTxt,
      currentPage: currentPage,
      scrollPosition: scrollPosition,
    },
    CustomerService.get
  );

  const handleFetchNext = () => {
    setCurrentPage(currentPage + 1);
  };
  const handleFetchPrev = () => {
    setCurrentPage(currentPage - 1);
  };
  const handleFetchSelected = (index: number) => {
    setCurrentPage(index);
  };

  const handleGoSummary = (id: string) => {
    setScrollPosition(
      `${document.getElementsByClassName("dashboard")[0].scrollTop}`
    );
    navigate(`/retail-dealership/customers/${id}/information`);
  };

  return (
    <>
      <Helmet>
        <title>{getPageTitle("Customers", SECTIONS.RETAIL_DEALERSHIP)}</title>
      </Helmet>
      <div className="dashboard__container">
        <div className="flex w-full flex-col">
          <div className=" flex gap-5 flex-row">
            <div className="flex flex-col gap-2.5 pt-2 pb-2 w-430">
              <Breadcrumbs
                paths={[
                  { title: "Retail Dealership", route: "/retail-dealership" },
                  { title: "Customers", route: "/retail-dealership/customers" },
                ]}
              />
              <div className="title">Customers</div>
            </div>
            <div className="flex flex-col gap-5">
              <FieldSearchBar />
            </div>
          </div>
          <div className="dashboard__toolbar">
            <div className="flex flex-row gap-5 items-center">
              <Pagination
                title="Customers"
                currentPage={currentPage}
                pageLimit={data?.meta?.per_page || DEFAULT_ITEMS_PER_PAGE}
                total={data?.meta?.total || 0}
                onNextPage={handleFetchNext}
                onPrevPage={handleFetchPrev}
                onIndexedPage={handleFetchSelected}
              />
              {isRefetching && <ClipLoader color="#0c2146" size={20} />}
            </div>
          </div>
        </div>
        <div className="dashboard__content">
          {isInitialLoading ? (
            <LoadVehicle />
          ) : !data || data.data.length === 0 ? (
            <div className="dashboard__content--empty">No Customers Yet</div>
          ) : (
            <ListCustomers data={data.data} onSelect={handleGoSummary} />
          )}
        </div>
      </div>
    </>
  );
};
