import { FC, MouseEvent } from "react";

import { buttonLinkTestID } from "../dataTestIDs";

import "./styles.scss";

export interface ButtonLinkProps {
  onClick?: () => void;
  disabled?: boolean;
  dataTestID?: string;
}

export const ButtonLink: FC<ButtonLinkProps> = ({
  onClick = () => {},
  disabled,
  dataTestID,
  children,
}) => {
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (disabled) return;

    e.stopPropagation();
    onClick();
  };

  return (
    <button
      className="link-button disabled:opacity-50 disabled:pointer-events-none"
      onClick={handleClick}
      data-testid={dataTestID || buttonLinkTestID}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
