import { useMutation } from "@tanstack/react-query";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Button } from "components/shared";

import { AuthContext } from "context/AuthContext";
import { useDealership } from "context/DealershipContext";

import { DirectOfferService } from "api/client/DirectOfferService";

interface IDirectOfferRejectProps {
  offerID?: string;
  contractID: string;
  requestID: string;
}

export const DirectOfferReject = ({
  offerID,
  contractID,
  requestID,
}: IDirectOfferRejectProps) => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { showAllDealerships } = useDealership();

  const { mutate, isLoading } = useMutation({
    mutationFn: () => {
      return DirectOfferService.declineOffer(
        !showAllDealerships
          ? { dealership_id: user?.data.dealership?.id }
          : undefined,
        offerID
      );
    },
    onSuccess: () => {
      toast.success("Offer Rejected", {
        autoClose: 2000,
        theme: "colored",
      });
      navigate(
        `/wholesale-dealership/valued/${contractID}/${requestID}/summary`
      );
    },
    onError: () => {
      toast.error("Offer Failed to Reject", {
        autoClose: 2000,
        theme: "colored",
      });
    },
  });

  return (
    <div className="btn__direct__offer w-full">
      <Button
        type="button"
        isLoading={isLoading}
        variant="secondary"
        className="w-full"
        onClick={() => mutate()}
      >
        <span className="mx-3 px-3">Reject</span>
      </Button>
    </div>
  );
};
