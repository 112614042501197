import { ButtonStatus } from "components/app/Button";
import { fieldValuationTestID } from "components/app/dataTestIDs";
import { SvgImage } from "components/shared";

import "../styles.scss";

import { DECLINED_VALUATION } from "utils";

export interface FieldValuationProps {
  title: string;
  value?: string;
  declined?: boolean;
  tool?: React.ReactNode;
  canUserEdit?: boolean;
  onView?: () => void;
  onEditValuation?: () => void;
  dataTestID?: string;
}

export const FieldValuation = ({
  title,
  value,
  declined,
  tool,
  canUserEdit = true,
  onView,
  onEditValuation,
  dataTestID,
}: FieldValuationProps) => {
  return (
    <div
      className="valuation-field justify-between"
      data-testid={dataTestID || fieldValuationTestID}
    >
      <label>
        {title}
        {onView && (
          <span
            className="hover:cursor-pointer ml-2"
            onClick={onView}
            data-testid={`${dataTestID || fieldValuationTestID}-view`}
          >
            <SvgImage name="EyeIcon" width={20} height={14} />
          </span>
        )}
      </label>
      {declined ? (
        <ButtonStatus status={DECLINED_VALUATION.key} label={"Declined"} />
      ) : (
        <div className="valuation-field__icon flex justify-end">
          {tool ? (
            tool
          ) : (
            <>
              {value ? value : "-"}
              {onEditValuation && canUserEdit && (
                <span
                  className="hover:cursor-pointer"
                  onClick={onEditValuation}
                  data-testid={`${dataTestID || fieldValuationTestID}-edit`}
                >
                  <SvgImage name="EditPen" width={13} className="ml-2" />
                </span>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};
