import { useContext, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { List } from "components/pages";
import { NoData } from "components/pages/List/NoData";

import { AuthContext } from "context/AuthContext";
import { useDealership } from "context/DealershipContext";
import { SearchContext } from "context/SearchContext";

import {
  DEFAULT_ITEMS_PER_PAGE,
  MOTOR_MARKET,
  MOTOR_MARKET_BUYING,
  MOTOR_MARKET_ENDED,
  MOTOR_MARKET_LIVE,
  MOTOR_MARKET_LOST,
  MOTOR_MARKET_PENDING,
  MOTOR_MARKET_WATCHLIST,
  MOTOR_MARKET_WON,
  STATUS_LIST,
  StorageKeys,
} from "utils";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import { IMotorMarketBuyer } from "types/IMotorMarket";

import { MotorMarketService } from "api/client";

import { useSessionStorage } from "hooks";
import { useListQuery } from "hooks/useListQuery";

import { ListVehicles } from "./ListVehicles";

interface StateProps {
  filterStatus?: string;
}

export const Buying = () => {
  const { hasPermission } = useContext(AuthContext);
  const { searchTxt } = useContext(SearchContext);
  const { dealershipID } = useDealership();
  const navigate = useNavigate();

  const { state } = useLocation();
  const filterStatus = (state as StateProps)?.filterStatus;

  const [currentPage, setCurrentPage] = useSessionStorage(
    filterStatus || StorageKeys.MotorMarketBuying,
    1
  );

  const [selectedFilterStatus, setSelectedFilterStatus] = useSessionStorage(
    StorageKeys.MotorMarketBuyingFilterStatus,
    MOTOR_MARKET_LIVE.key
  );

  useEffect(() => {
    if (filterStatus) {
      setSelectedFilterStatus(filterStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStatus]);

  const [{ label: status }] = STATUS_LIST.MotorMarketBuying.concat(
    STATUS_LIST.MotorMarketBuyingEnded
  ).filter((item) => {
    return item.key === selectedFilterStatus;
  });

  const showExtraFilterStatusOptions = [
    MOTOR_MARKET_ENDED.key,
    MOTOR_MARKET_PENDING.key,
    MOTOR_MARKET_LOST.key,
    MOTOR_MARKET_WON.key,
  ].includes(selectedFilterStatus);

  const { data, isInitialLoading, isError, refetch } = useListQuery(
    {
      queryKey: StorageKeys.MotorMarketBuying,
      status: selectedFilterStatus,
      withParams: "bids",
      search: searchTxt,
      currentPage: currentPage,
      sort:
        selectedFilterStatus === MOTOR_MARKET_WON.key ? "date:desc" : undefined,
      dealershipID:
        selectedFilterStatus === MOTOR_MARKET_WATCHLIST.key
          ? dealershipID
          : undefined,
    },
    selectedFilterStatus === MOTOR_MARKET_WATCHLIST.key
      ? MotorMarketService.getWatchlist
      : MotorMarketService.getBuying
  );

  const filteredData = useMemo(() => {
    if (selectedFilterStatus === MOTOR_MARKET_WATCHLIST.key) {
      return data?.data.filter((item: IMotorMarketBuyer) => {
        return !item.has_withdrawn;
      });
    }

    return data?.data;
  }, [data, selectedFilterStatus]);

  const onFilterStatusChange = (status: string | number) => {
    setSelectedFilterStatus(status);
    setCurrentPage(1);
  };

  const onClick = (selectedVehicle: IMotorMarketBuyer) => {
    navigate(`/motor-market/buying/${selectedVehicle.contract_id}/summary`, {
      state: {
        selectedVehicle: {
          ...selectedVehicle,
          status:
            selectedFilterStatus === MOTOR_MARKET_LOST.key
              ? { label: MOTOR_MARKET_LOST.label, name: MOTOR_MARKET_LOST.key }
              : selectedVehicle.status,
        },
      },
    });
  };

  const canView = hasPermission(
    PermissionCategory.MOTOR_MARKET_BUYING,
    PermissionAction.VIEW
  );

  return (
    <>
      <List
        pageTitle={MOTOR_MARKET_BUYING}
        breadcrumbs={[
          { title: MOTOR_MARKET, route: "/motor-market/buying" },
          { title: MOTOR_MARKET_BUYING, route: "/motor-market/buying" },
        ]}
        pagination={{
          title: MOTOR_MARKET_BUYING,
          total: data?.meta?.total ?? 0,
          currentPage: currentPage,
          pageLimit: data?.meta?.per_page ?? DEFAULT_ITEMS_PER_PAGE,
          onNextPage: () => setCurrentPage(currentPage + 1),
          onPrevPage: () => setCurrentPage(currentPage - 1),
          onIndexedPage: (index) => setCurrentPage(index),
        }}
        selectedFilterStatus={selectedFilterStatus}
        filterStatusOptions={STATUS_LIST.MotorMarketBuying}
        isLoading={isInitialLoading}
        isError={isError}
        onFilterStatusChange={onFilterStatusChange}
        hasViewingPermission={canView}
        extraFilterStatusOptions={
          showExtraFilterStatusOptions
            ? STATUS_LIST.MotorMarketBuyingEnded
            : undefined
        }
        viewPermissionCategory={PermissionCategory.MOTOR_MARKET_BUYING}
      >
        {filteredData?.length ? (
          <ListVehicles
            data={filteredData}
            status={selectedFilterStatus}
            onClick={onClick}
            refetch={refetch}
            canView={canView}
          />
        ) : (
          <NoData message={`No ${status} Listings.`} />
        )}
      </List>
    </>
  );
};
