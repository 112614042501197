import { useMemo, useState } from "react";

import { IImage, ISection, OptimisticImage } from "types";

type RenderImagesProps = {
  activeImages: IImage[];
  images?: { data: IImage[] };
  activeDragAndDrop: { from: number; to: number };
  sectionData?: { data: ISection[] };
  setActiveImages: (images: IImage[]) => void;
  setActiveDragAndDrop: (dragAndDrop: { from: number; to: number }) => void;
  optimisticAddImages?: OptimisticImage[];
  deletedImage?: string;
};

export const useRenderImages = ({
  activeImages,
  images,
  activeDragAndDrop,
  sectionData,
  setActiveImages,
  setActiveDragAndDrop,
  optimisticAddImages,
  deletedImage,
}: RenderImagesProps) => {
  const [availablePlaceholders, setAvailablePlaceholders] = useState<
    ISection[]
  >([]);
  const addMorePlaceholder = { label: "Add More", key: "add-more" } as ISection;

  const renderedImages = useMemo<ISection[]>(
    () => {
      let uploadedImages = activeImages?.length
        ? activeImages
        : images?.data || [];

      uploadedImages = uploadedImages.filter(
        (image) => image.id !== deletedImage
      );

      const from = activeDragAndDrop.from;
      const to = activeDragAndDrop.to;

      if (from || to) {
        const images = [...uploadedImages];
        const item = images[from] as IImage;

        images.splice(from, 1);
        images.splice(to, 0, item);

        uploadedImages = images;

        setActiveImages(uploadedImages);
        setActiveDragAndDrop({ from: 0, to: 0 });
      }

      const optimisticImages = optimisticAddImages?.map(
        (image: OptimisticImage, index) => {
          return {
            id: `${image.label}-${index}`,
            key: `additional-${image.label}-${index}`,
            label: `${image.label}`,
            placeholder: URL.createObjectURL(image.image as unknown as Blob),
            isOptimistic: true,
          };
        }
      );

      const filteredPlaceholders =
        sectionData?.data?.filter(
          ({ label }) =>
            !uploadedImages?.find(
              (item) =>
                item?.label?.toLowerCase() ===
                label?.replaceAll("_", " ")?.toLowerCase()
            )?.id
        ) || [];

      setAvailablePlaceholders(filteredPlaceholders);

      return [
        ...uploadedImages.map(
          (image, index) =>
            ({
              id: image.id,
              key: `additional-${index}`,
              label: `${image.label}-${index}`,
              placeholder: image.url,
            } as ISection)
        ),
        ...(optimisticImages || []),
        ...filteredPlaceholders,
        addMorePlaceholder,
      ] as ISection[];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      activeImages,
      images?.data,
      activeDragAndDrop,
      sectionData?.data,
      setActiveImages,
      setActiveDragAndDrop,
      optimisticAddImages,
      deletedImage,
    ]
  );

  return { renderedImages, availablePlaceholders };
};
