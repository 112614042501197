import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ChangeEvent, useContext, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  Breadcrumbs,
  Button,
  ButtonLink,
  FieldCheckbox,
  FieldDropdown,
  FieldText,
  FieldTextarea,
  Prompt,
  SvgImage,
  TextError,
} from "components/shared";

import { AuthContext } from "context/AuthContext";
import { SearchContext } from "context/SearchContext";

import {
  COMPLIANCE_STATUS,
  CUSTOMER_MONTH_OPTIONS,
  FLOW_TYPE,
  STATE_OPTIONS,
  VEHICLE_ROUTES,
  VEHICLE_SUMMARY_TITLE,
  YEARS_OPTIONS,
  checkIsValidNumber,
  formatNumber,
  getVehicleInformationRoute,
} from "utils";

import { ICompliance, IFinance, IValue, IVehicleSummary } from "types";

import { DealershipInspectionService, VehicleService } from "api/client";

type IComplianceForm = {
  build_year: string;
  build_month: string;
  compliance_plate_year: string;
  compliance_place_month: string;
  vin: string;
  registration_plate: string;
  registration_state: string;
  registration_status: string;
  registration_expiry_date: string;
  wovr: string;
  comment: string;
  institution: string;
  owing: string;
  owing_type: string;
  compliance_date: string;
  type: string;
  expenses: {
    type: string;
    owing: string;
    owing_type: string;
    institution: string;
  };
  engine_number: string;
};

interface ComplianceProps {
  flow: FLOW_TYPE;
  route: VEHICLE_ROUTES;
}

export const Compliance = ({ flow, route }: ComplianceProps) => {
  const queryClient = useQueryClient();
  const { userID } = useContext(AuthContext);
  const navigate = useNavigate();
  const params = useParams();
  const { setIsUpdated } = useContext(SearchContext);

  const { title, path } = getVehicleInformationRoute(flow);
  const isValuedRoute = route === VEHICLE_ROUTES.Unvalued;

  const [isNumber, setIsNumber] = useState<boolean>(true);
  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    reset,
    formState: { isDirty },
  } = useForm<IComplianceForm>();

  const { data: dealershipInspectionData } = useQuery<
    { data: IVehicleSummary },
    Error
  >(
    [`fetchDealershipInspection_${userID}`, params.id],
    DealershipInspectionService.getDealershipSummary
  );
  const { mutate, isLoading } = useMutation(
    (data: IComplianceForm) =>
      VehicleService.updateVehicle(data, params.id || ""),
    {
      onSuccess: () => {
        toast.success("Saved Successfully", {
          theme: "colored",
          autoClose: 2000,
        });
        queryClient.invalidateQueries([`retail-summary-${params.id}`]);
        reset({}, { keepDirty: false, keepValues: true });
        setIsUpdated(params.id || "", true);
        navigate(-1);
      },
      onError: () => {
        toast.error("Failed to Save", {
          theme: "colored",
          autoClose: 2000,
        });
      },
    }
  );

  const compliance = useMemo(() => {
    if (dealershipInspectionData) {
      return dealershipInspectionData.data.vehicle.compliance;
    }

    return undefined;
  }, [dealershipInspectionData]);

  const expenses = useMemo(() => {
    if (dealershipInspectionData) {
      return dealershipInspectionData.data.expenses;
    }

    return undefined;
  }, [dealershipInspectionData]);

  useEffect(() => {
    if (compliance && expenses) {
      Object.keys(compliance).forEach((key) => {
        if (key === "build_year") {
          if (
            compliance.build_year.value &&
            compliance.build_year.value.includes(", ")
          ) {
            setValue("build_year", compliance.build_year.value.split(", ")[1]);
            setValue("build_month", compliance.build_year.value.split(", ")[0]);
          } else {
            setValue("build_year", compliance.build_year.value);
          }
        } else if (key === "compliance_date") {
          if (compliance.compliance_date) {
            setValue(
              "compliance_plate_year",
              compliance.compliance_date.value.split(", ")[1]
            );
            setValue(
              "compliance_place_month",
              compliance.compliance_date.value.split(", ")[0]
            );
          }
        } else {
          setValue(
            key as keyof ICompliance,
            (
              compliance?.[key as keyof ICompliance] as unknown as Record<
                keyof IValue,
                IValue
              >
            ).value as unknown as string
          );
        }
      });
      Object.keys(expenses).forEach((key) => {
        if (key !== "type") {
          if (key === "owing") {
            setValue("owing", formatNumber(expenses[key]));
          } else {
            setValue(
              key as keyof IFinance,
              expenses[key as keyof IFinance] as string
            );
          }
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compliance, expenses]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleSave = (data: IComplianceForm) => {
    const payload = {
      compliance: {
        build_year: data.build_month
          ? `${data.build_month}, ${data.build_year}`
          : data.build_year,
        compliance_date: `${data.compliance_place_month}, ${data.compliance_plate_year}`,
        registration_plate: data.registration_plate,
        registration_state: data.registration_state,
        vin: data.vin,
        registration_status: data.registration_status,
        registration_expiry_date: data.registration_expiry_date,
        comment: data.comment,
        engine_number: data.engine_number,
      },
      expenses: {
        type: "",
        owing: data.owing.replaceAll(",", ""),
        owing_type: data.owing_type,
        institution: data.institution || "",
      },
      type: "compliance",
    };
    mutate(payload as unknown as IComplianceForm);
  };

  const handleOwing = (value: string) => {
    setValue("owing", formatNumber(value.replaceAll(",", "")));
    setIsNumber(checkIsValidNumber(getValues("owing")));
  };

  const handleVinChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue("vin", e.target.value.split(" ").join("").toUpperCase());
  };

  return (
    <div className="dashboard__container">
      <div className="dashboard__header">
        <Breadcrumbs
          paths={[
            {
              title: title,
              route: path,
            },
            {
              title: route ?? "",
              route: isValuedRoute ? path : `${path}/${route?.toLowerCase()}`,
            },
            {
              title: VEHICLE_SUMMARY_TITLE,
              route: isValuedRoute
                ? `${path}/valuation/${params.id}/summary`
                : `${path}/${route?.toLowerCase()}/${params.id}/summary`,
            },
            {
              title: "Vehicle Information",
              route: isValuedRoute
                ? `${path}/valuation/${params.id}/summary`
                : `${path}/${route?.toLowerCase()}/${params.id}/summary`,
            },
            {
              title: "Compliance",
              route: isValuedRoute
                ? `${path}/${params.id}/features`
                : `${path}/${route?.toLowerCase()}/${params.id}/compliance`,
            },
          ]}
        />
        <ButtonLink onClick={handleGoBack}>
          <SvgImage name="LeftRoundedIcon" />
        </ButtonLink>
      </div>
      <div className="dashboard__content">
        <div className="compliance">
          <div className="compliance__content">
            <div className="compliance__content__row">
              <div>
                <label className="condition-title--small">Build Date*</label>
                <div className="flex justify-between gap-1">
                  <Controller
                    render={({ field: { value, onChange } }) => (
                      <FieldDropdown
                        placeholder="Select Month"
                        isFilter={false}
                        options={CUSTOMER_MONTH_OPTIONS}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                    name="build_month"
                    control={control}
                  />
                  <Controller
                    render={({ field: { value, onChange } }) => (
                      <FieldDropdown
                        placeholder="Year"
                        isFilter={false}
                        options={YEARS_OPTIONS}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                    control={control}
                    name="build_year"
                    rules={{
                      required:
                        compliance?.build_year.requirement === "required",
                    }}
                  />
                </div>
              </div>
              <div>
                <label className="condition-title--small">
                  Compliance Date*
                </label>
                <div className="flex justify-between gap-1">
                  <Controller
                    render={({ field: { value, onChange } }) => (
                      <FieldDropdown
                        placeholder="Select Month"
                        isFilter={false}
                        options={CUSTOMER_MONTH_OPTIONS}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                    control={control}
                    name="compliance_place_month"
                  />
                  <Controller
                    render={({ field: { value, onChange } }) => (
                      <FieldDropdown
                        placeholder="Year"
                        isFilter={false}
                        options={YEARS_OPTIONS}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                    control={control}
                    name="compliance_plate_year"
                    rules={{
                      required:
                        compliance?.compliance_date?.requirement === "required",
                    }}
                  />
                </div>
              </div>
              <div>
                <label className="condition-title--small">VIN*</label>
                <Controller
                  render={({ field: { value } }) => (
                    <FieldText
                      type="text"
                      value={value}
                      onChange={(e) => handleVinChange(e)}
                      placeholder="XXXXXXXXXXXXXXXXX"
                    />
                  )}
                  control={control}
                  name="vin"
                  rules={{
                    required: compliance?.vin.requirement === "required",
                  }}
                />
              </div>
              <div>
                <label className="condition-title--small">Engine Number</label>
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <FieldText
                      type="text"
                      value={value}
                      onChange={onChange}
                      placeholder="No Engine Number"
                    />
                  )}
                  control={control}
                  name="engine_number"
                />
              </div>
            </div>
            <div className="compliance__content__row">
              <div>
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <FieldCheckbox
                      checked={value === COMPLIANCE_STATUS.REGISTERED}
                      onChange={(e) => {
                        onChange(
                          e.target.checked
                            ? COMPLIANCE_STATUS.REGISTERED
                            : COMPLIANCE_STATUS.UNREGISTERED
                        );
                      }}
                      label="Registered"
                    />
                  )}
                  control={control}
                  name="registration_status"
                />
              </div>
              <div>
                <label className="condition-title--small">
                  Registration Plate*
                </label>
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <FieldText
                      type="text"
                      value={value}
                      onChange={(e) =>
                        onChange(
                          e.target.value.toUpperCase().replaceAll(" ", "")
                        )
                      }
                      placeholder="ABC123"
                    />
                  )}
                  control={control}
                  name="registration_plate"
                  rules={{
                    required:
                      compliance?.registration_plate.requirement === "required",
                  }}
                />
              </div>
              <div>
                <label className="condition-title--small">
                  Registration State*
                </label>
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <FieldDropdown
                      placeholder="Select State"
                      options={STATE_OPTIONS}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  control={control}
                  name="registration_state"
                  rules={{
                    required:
                      compliance?.registration_state.requirement === "required",
                  }}
                />
              </div>
              <div>
                <label className="condition-title--small">
                  Registration Expiry Date*
                </label>
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    const formattedDate = value
                      ? DateTime.fromJSDate(new Date(value)).toFormat(
                          "yyyy-MM-dd"
                        )
                      : "";
                    return (
                      <FieldText
                        type="date"
                        {...register("registration_expiry_date", {
                          required:
                            (compliance?.registration_expiry_date as IValue)
                              ?.requirement === "required",
                        })}
                        value={formattedDate}
                        onChange={onChange}
                      />
                    );
                  }}
                  name="registration_expiry_date"
                />
              </div>
            </div>
            <div className="compliance__content__row">
              <div>
                <label className="compliance-title--small">
                  Is Finance owed on this vehicle?
                </label>
              </div>

              <div>
                <label className="condition-title--small">
                  Type of Finance Owing
                </label>
                <FieldText
                  type="text"
                  {...register("owing_type")}
                  placeholder="e.g. novated lease"
                />
              </div>
              <div>
                <label className="condition-title--small">Finance Owing</label>
                <div className="condition__owing__txt">
                  <FieldText
                    type="text"
                    inputMode="numeric"
                    {...register("owing")}
                    onChange={(e) => handleOwing(e.target.value)}
                    placeholder="Enter Finance Owing (only numbers)"
                  />
                  <span className="condition__owing__txt__unit">$</span>
                  {isNumber === false && (
                    <TextError error="Please enter numbers only" />
                  )}
                </div>
              </div>
              <div>
                <label className="condition-title--small">
                  Financial Institution
                </label>
                <FieldText
                  type="text"
                  {...register("institution")}
                  placeholder="Select financial institution"
                />
              </div>
              <div>
                <label className="condition-title--small">
                  Compliance Info
                </label>
                <FieldTextarea
                  {...register("comment")}
                  placeholder="Enter compliance info"
                />
              </div>
            </div>
          </div>
          <div className="compliance__tool">
            <Button
              type="submit"
              isLoading={isLoading}
              onClick={handleSubmit(handleSave)}
            >
              <span className="compliance__tool__txt">Save</span>
            </Button>
          </div>
        </div>
      </div>
      <Prompt isDirty={isDirty} />
    </div>
  );
};
