import { useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";

import { Button, Card, FieldText, TextError } from "components/shared";

import { showErrorToast } from "utils";
import { getPageTitle } from "utils/pageSections";
import { pattern } from "utils/validation";

import { AuthService } from "api/client/AuthService";

type ForgotPasswordType = {
  email: string;
};

export const ForgotPassword = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [pwdSent, setPwdSent] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const handleResetPassword = async (data: ForgotPasswordType) => {
    try {
      setLoading(true);
      const email = data?.email?.toLowerCase();
      const { status } = (await AuthService.forgotPassword({
        email,
      })) as any;

      if (status) {
        setPwdSent(true);
      } else {
        showErrorToast("Failed to Send");
      }
    } catch (err) {
      showErrorToast("Failed to Send");
      console.log("sending verification link err: ", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>{getPageTitle("Forgot Password")}</title>
      </Helmet>
      <div className="auth-container">
        <div className="w-[423px]">
          <Card>
            {!pwdSent ? (
              <div className="auth-card__content">
                <span className="auth-card__title pb-2.5">
                  Forgot Password?
                </span>
                <span className="auth-card__desc pb-3">
                  Send a link to your email that will allow you to reset your
                  password.
                </span>
                <div className="auth-card__form pb-5">
                  <FieldText
                    {...register("email", {
                      required: true,
                      pattern: pattern.email,
                    })}
                    placeholder="Enter email address"
                  />
                  {errors.email?.type === "required" && (
                    <TextError error="Email is required" />
                  )}
                  {errors.email?.type === "pattern" && (
                    <TextError error="Please enter the correct email" />
                  )}
                </div>
                <div className="w-[130px]">
                  <Button
                    type="submit"
                    isLoading={isLoading}
                    onClick={handleSubmit(handleResetPassword)}
                  >
                    <span className="text-lighttxt text-sm font-bold">
                      SEND
                    </span>
                  </Button>
                </div>
              </div>
            ) : (
              <div className="auth-card__content">
                <span className="auth-card__title pb-6">Check your email.</span>
                <p className="auth-card__desc pb-6">
                  Please check your email (check your junk mail) for the
                  password reset link.
                  <br />
                  <br />
                  If you do not receive it within a few minutes, try resending
                  the link.
                </p>
                <div className="w-[145px]">
                  <Button
                    type="submit"
                    isLoading={isLoading}
                    onClick={handleSubmit(handleResetPassword)}
                  >
                    <span className="text-lighttxt text-sm font-bold">
                      Resend Link
                    </span>
                  </Button>
                </div>
              </div>
            )}
          </Card>
        </div>
      </div>
    </>
  );
};
