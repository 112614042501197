import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import {
  FieldSearchBar,
  ListTenders,
  LoadVehicle,
  StatusBarTabs,
} from "components/app";
import { LoadingSpinner } from "components/Loading/LoadingSpinner";
import { NoPermissionMessage } from "components/NoPermissionMessage";
import { Breadcrumbs, Pagination } from "components/shared";

import { AuthContext } from "context/AuthContext";
import { SearchContext } from "context/SearchContext";

import {
  ACTIVE,
  DEFAULT_ITEMS_PER_PAGE,
  StorageKeys,
  TENDER_MENU,
  TENDER_STATUS_LIST_BUYING,
} from "utils";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import { NavigationMenuLabel } from "types";

import { TenderService } from "api/client";

import { useListQuery } from "hooks/useListQuery";
import useSessionStorage from "hooks/useSessionStorage";

export const TenderBuyingList = () => {
  const { hasPermission } = useContext(AuthContext);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useSessionStorage("TENDER_BUYING", 1);
  const [currentStatus, setCurrentStatus] = useSessionStorage(
    "TENDER_FILTER_BUYING_SELECTION",
    ACTIVE.key
  );
  const [scrollPosition, setScrollPosition] = useSessionStorage(
    StorageKeys.TenderBuyingScroll,
    0
  );

  const { searchTxt } = useContext(SearchContext);

  const [{ label: statusLabel }] = TENDER_STATUS_LIST_BUYING.filter((item) => {
    return item.key === currentStatus;
  });

  const canView = hasPermission(
    PermissionCategory.TENDER_BUYING,
    PermissionAction.VIEW
  );

  const { data, isInitialLoading, isRefetching } = useListQuery(
    {
      queryKey: StorageKeys.TenderBuyingList,
      status: currentStatus,
      search: searchTxt,
      currentPage: currentPage,
      scrollPosition: scrollPosition,
    },
    TenderService.getTendersBuying,
    {
      enabled: canView,
    }
  );

  const handleFetchNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleFetchPrev = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleFetchSelected = (index: number) => {
    setCurrentPage(index);
  };

  const handleChangeStatus = (status: string | number) => {
    setCurrentStatus(status);
    setCurrentPage(1);
  };

  const handleGoSummary = (id: string) => {
    setScrollPosition(
      `${document.getElementsByClassName("dashboard")[0].scrollTop}`
    );
    navigate(`${id}/summary`);
  };

  return (
    <div className="dashboard__container">
      <div className="flex w-full gap-5">
        <div className="flex flex-col gap-2.5 pt-2 w-430">
          <Breadcrumbs
            paths={[
              {
                title: NavigationMenuLabel.TENDER,
                route: TENDER_MENU.buying.route,
              },
              { title: "Buying", route: TENDER_MENU.buying.route },
            ]}
          />
          <div className="flex items-center gap-2">
            <div className="title">Tenders</div>
            {isRefetching && <LoadingSpinner />}
          </div>
          <div className="flex flex-row gap-5 items-center">
            <Pagination
              title="Tenders"
              currentPage={currentPage}
              pageLimit={data?.meta?.per_page || DEFAULT_ITEMS_PER_PAGE}
              total={data?.meta?.total || 0}
              onNextPage={handleFetchNext}
              onPrevPage={handleFetchPrev}
              onIndexedPage={handleFetchSelected}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <FieldSearchBar />
          <StatusBarTabs
            currentStatus={currentStatus}
            options={TENDER_STATUS_LIST_BUYING}
            onSelect={handleChangeStatus}
          />
        </div>
      </div>
      <div className="dashboard__content">
        {!canView ? (
          <NoPermissionMessage
            viewPermissionCategory={PermissionCategory.TENDER_BUYING}
          />
        ) : isInitialLoading ? (
          <LoadVehicle />
        ) : !data || data.data.length === 0 ? (
          <div className="dashboard__content--empty">
            {`No ${statusLabel} Tenders`}
          </div>
        ) : (
          <ListTenders
            data={data.data}
            onSelect={handleGoSummary}
            currentStatus={currentStatus}
          />
        )}
      </div>
      <div className="dashboard__footer">
        <Pagination
          title="Tenders"
          currentPage={currentPage}
          pageLimit={data?.meta?.per_page || DEFAULT_ITEMS_PER_PAGE}
          total={data?.meta?.total || 0}
          onNextPage={handleFetchNext}
          onPrevPage={handleFetchPrev}
          onIndexedPage={handleFetchSelected}
        />
      </div>
    </div>
  );
};
