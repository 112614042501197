import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";

import { ConfirmationModal } from "components/Modal/ConfirmationModal";
import { Button } from "components/shared";

import { IDirectOfferRevokePayload } from "types";

import { DirectOfferService } from "api/client/DirectOfferService";

interface IDirectOfferRevokeProps {
  contractID: string;
  selectedBuyerIDs: string[];
  onRefresh?: () => void;
}

export const DirectOfferRevoke = ({
  contractID,
  selectedBuyerIDs,
  onRefresh,
}: IDirectOfferRevokeProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const { mutate, isLoading } = useMutation({
    mutationFn: (payload: IDirectOfferRevokePayload) => {
      return DirectOfferService.revokeOffer(payload, contractID);
    },
    onSuccess: () => {
      toast.success("Offer Revoked", {
        autoClose: 2000,
        theme: "colored",
      });
      setIsVisible(false);
      onRefresh && onRefresh();
    },
    onError: () => {
      toast.error("Offer Failed to Revoke", {
        autoClose: 2000,
        theme: "colored",
      });
    },
  });

  return (
    <>
      <Button
        type="button"
        className="white"
        onClick={() => setIsVisible(true)}
      >
        <span className="mx-3 px-3">Revoke</span>
      </Button>
      <ConfirmationModal
        title="Revoke?"
        description="This action will withdraw the offers sent to the selected dealership/s. Do you want to continue?"
        isOpen={isVisible}
        onClose={() => setIsVisible(false)}
        onConfirm={() => mutate({ dealership_ids: selectedBuyerIDs })}
        confirmButtonLabel="YES"
        cancelButtonLabel="NO"
        isLoading={isLoading}
      />
    </>
  );
};
