import { useContext, useEffect, useMemo, useState } from "react";

import { MultipleSelectDropdown } from "components/shared/Fields/Dropdown/MultipleSelectDropdown";

import { AuthContext } from "context/AuthContext";
import { useDealership } from "context/DealershipContext";

export const DealershipSelector = () => {
  const [dropdownSelected, setDropdownSelected] = useState<string[]>([]);

  const { user } = useContext(AuthContext);
  const {
    setDealershipID,
    setIsDealershipSelectorVisible,
    isDealershipSelectorVisible,
  } = useDealership();
  const dealership = user?.data.dealership;
  const children = user?.data?.dealership?.children;

  const options = useMemo(() => {
    const parentDealership = Object.values(children || {})
      .map((item) => {
        const childOptions = Object.values(item.children || {}).map(
          (child) => ({
            key: child.id,
            label: child.name,
            parent: item.id,
          })
        );

        return [{ key: item.id, label: item.name }, ...childOptions];
      })
      .flat();

    return [
      {
        key: "all",
        label: "VIEW ALL",
        isHidden: true,
      },
      {
        key: dealership?.id || "",
        label: dealership?.name || "",
      },
      ...parentDealership,
    ];
  }, [dealership]);

  const childrenOptions = useMemo(() => {
    if (!children) return [];
    const childrenDealerships = Object.values(children).map((value) => ({
      parent: value.id,
      key: value.id,
      children: Object.values(value.children || {}).map((child) => ({
        key: child.id,
        label: child.name.toUpperCase(),
      })),
    }));

    return childrenDealerships;
  }, [children]);

  const allOptions = useMemo(() => {
    const parentOptions = options?.map((option) => option.key);
    return [...parentOptions];
  }, [options, childrenOptions]);

  const prependOptions = useMemo(
    () => options.slice(0, 1).map((option) => ({ ...option, isHidden: false })),
    [options]
  );

  useEffect(() => {
    const isShowDealershipSelector = options?.length > 2;

    setIsDealershipSelectorVisible?.(isShowDealershipSelector);

    if (options[1].key && !isShowDealershipSelector) {
      setDealershipID([options[1].key].join(","));
      return;
    }

    if (options[1].key && isShowDealershipSelector) {
      // options will have View all and its own dealership, if the length is only 2 or less,
      // we need to hide the dropdown since it will always have the same dealership_id
      setDropdownSelected([...allOptions]);
      setDealershipID([...allOptions]?.splice(1).join(","));
    }
  }, [options, children, allOptions]);

  const onChange = (selectedKey: string[]) => {
    const isAllSelected = selectedKey.includes("all");
    const isPreviouslyAllSelected = dropdownSelected.includes("all");
    const filteredSelectedKey = selectedKey.filter((item) => item !== "all");

    if (isAllSelected && !isPreviouslyAllSelected) {
      setDropdownSelected([...allOptions]);
      setDealershipID([...allOptions]?.splice(1).join(","));
    } else if (isPreviouslyAllSelected && !isAllSelected) {
      setDropdownSelected([]);
      setDealershipID("");
    } else {
      setDropdownSelected(selectedKey.filter((item) => item !== "all"));
      setDealershipID(filteredSelectedKey.join(","));
    }
  };

  if (!isDealershipSelectorVisible) return null;

  return (
    <div className="mr-5">
      <MultipleSelectDropdown
        value={dropdownSelected}
        onChange={onChange}
        placeholder="Select dealership"
        options={options}
        prependOptions={prependOptions}
        className="!text-right !bg-transparent"
        dropdownGreyBG
      />
    </div>
  );
};
