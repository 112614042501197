import { modalConfirmTestID } from "components/app/dataTestIDs";
import { Button, SvgImage } from "components/shared";

import "../styles.scss";

export interface ModalConfirmProps {
  onCancel: () => void;
  onConfirm: () => void;
  title?: string;
  description?: string;
  dataTestID?: string;
  isLoading?: boolean;
  cancelButtonLabel?: "NO" | "CANCEL";
  confirmButtonLabel?: "YES" | "CONTINUE";
}

export const ModalConfirm = ({
  onCancel,
  onConfirm,
  title,
  description,
  dataTestID,
  isLoading,
  cancelButtonLabel = "CANCEL",
  confirmButtonLabel = "CONTINUE",
}: ModalConfirmProps) => {
  return (
    <div className="confirm" data-testid={dataTestID || modalConfirmTestID}>
      <div className="confirm__title">
        <SvgImage name="ConfirmIcon" />
        <label className="mt-4">{title || "Are You Sure?"}</label>
      </div>
      <p className="confirm__desc">
        {description ||
          "This action will revert the vehicle to the previous step for everyone who has access to the car."}
        <br />
      </p>
      <p className="confirm__desc">Do you want to continue?</p>
      <div className="confirm__footer flex">
        <div className="btn__cancel !w-1/2">
          <Button type="button" onClick={onCancel}>
            <span>{cancelButtonLabel?.toLocaleUpperCase()}</span>
          </Button>
        </div>
        <div className="btn__confirm !w-1/2">
          <Button type="button" onClick={onConfirm} isLoading={isLoading}>
            <span>{confirmButtonLabel?.toLocaleUpperCase()}</span>
          </Button>
        </div>
      </div>
    </div>
  );
};
