import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext, useState, useCallback } from "react";
import { Helmet } from "react-helmet";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import {
  Breadcrumbs,
  FieldCheckbox,
  TextError,
  FieldText,
  Button,
  SvgImage,
  Prompt,
} from "components/shared";

import { AuthContext } from "context/AuthContext";

import { getPageTitle } from "utils/pageSections";
import { pattern } from "utils/validation";

import { UserService } from "api/client";

import "./styles.scss";

interface IProfileForm {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  notifications_via?: string[];
}

export const Profile = () => {
  const { user, updateDisplayName } = useContext(AuthContext);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [updatePassword, setUpdatePassword] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    setError,
    reset,
  } = useForm<IProfileForm>();

  const { data, refetch } = useQuery(
    ["fetch_account", user?.data.user.id],
    UserService.showUser
  );

  const { mutate, isLoading } = useMutation(
    (payload: any) => UserService.updateUser(payload, data?.data.id || ""),
    {
      onSuccess: (res: any) => {
        const [first_name, ...last_name] = res.data.name.split(" ");
        updateDisplayName(res.data.name);
        reset(
          {
            first_name: first_name,
            last_name: last_name.join(" "),
            email: res.email,
            notifications_via: res.data.notifications_via,
            phone: res.data.phone,
          },
          { keepDirty: false }
        );
        refetch();
        toast.success("Saved Successfully", {
          autoClose: 2000,
          theme: "colored",
        });
      },
      onError: (e: any) => {
        if (typeof e === "object" && e.data.message) {
          toast.error(e.data.message, {
            theme: "colored",
            autoClose: 2000,
          });
          if (e.data.message.includes("Mobile Number")) {
            setError("phone", { type: "validate", message: e.data.message });
          }
        } else {
          toast.error("Failed to Save", {
            theme: "colored",
            autoClose: 2000,
          });
        }
      },
    }
  );

  const handleShowPwd = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const handleUpdatePwd = useCallback(() => {
    setUpdatePassword(!updatePassword);
  }, [updatePassword]);

  const handleToggleNotificationSetting = (
    val: boolean,
    notiSetting: string[] | undefined,
    fieldName: string,
    onChange: (val: string[]) => void
  ) => {
    if (
      val &&
      (!notiSetting || notiSetting.findIndex((noti) => noti === fieldName) < 0)
    ) {
      onChange([...(notiSetting || []), fieldName]);
    }

    if (
      !val &&
      !!notiSetting &&
      notiSetting.findIndex((noti) => noti === fieldName) > -1
    ) {
      onChange(notiSetting.filter((noti) => noti !== fieldName));
    }
  };

  const handleSubmitProfile = (data: IProfileForm) => {
    const { email, first_name, last_name, phone, notifications_via } = data;
    const payload: any = {
      name: `${first_name} ${last_name}`,
      email,
      phone,
      notifications_via,
    };
    if (!password || (password && password === confirmPassword)) {
      payload["password"] = password;
      payload["password_confirmation"] = password;
      mutate(payload);
    } else {
      toast.error("Passwords Do Not Match", {
        theme: "colored",
        autoClose: 2000,
      });
    }
  };

  if (!data) {
    return null;
  }

  const { name, email, phone, notifications_via } = data.data;
  const [first_name, ...last_name] = (name || "").split(" ");

  return (
    <>
      <Helmet>
        <title>{getPageTitle("Profile")}</title>
      </Helmet>
      <div className="dashboard__container">
        <div className="dashboard__header !gap-2">
          <Breadcrumbs paths={[{ title: "Profile", route: "/profile" }]} />
          <div className="title">Account Settings</div>
        </div>
        <div className="dashboard__content flex-col !gap-5">
          <div className="profile__content">
            <div className="profile__content__info">
              <div>
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FieldText
                      title="First Name*"
                      placeholder="Enter first name"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  name="first_name"
                  rules={{ required: true }}
                  defaultValue={first_name}
                />
                {errors.first_name && (
                  <TextError error="First Name is required" />
                )}
              </div>
              <div>
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FieldText
                      title="Last Name*"
                      placeholder="Enter last name"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  name="last_name"
                  rules={{ required: true }}
                  defaultValue={last_name.join(" ")}
                />
                {errors.last_name && (
                  <TextError error="Last Name is required" />
                )}
              </div>
              <div>
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FieldText
                      title="Email Address*"
                      placeholder="Enter their email address"
                      disabled
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  name="email"
                  rules={{
                    required: true,
                    pattern: pattern.email,
                  }}
                  defaultValue={email || ""}
                />
                {errors.email && (
                  <TextError error="Valid email address is required" />
                )}
              </div>
              <div>
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FieldText
                      title="Mobile Number*"
                      placeholder="Enter mobile number"
                      value={value}
                      onChange={({ target }) =>
                        onChange(target.value.replaceAll(" ", ""))
                      }
                    />
                  )}
                  name="phone"
                  rules={{
                    required: true,
                    minLength: 6,
                    maxLength: 12,
                    pattern: pattern.phone,
                  }}
                  defaultValue={phone || ""}
                />
                {errors.phone && (
                  <TextError
                    error={`${
                      errors.phone.type === "required"
                        ? "Phone number is required."
                        : "Please enter the correct phone number."
                    }`}
                  />
                )}
              </div>
            </div>
            <div className="profile__content__other">
              <div>
                <div className="mb-4">
                  <label className="condition-title">
                    Notification Preferences
                  </label>
                </div>
                <div className="profile__content__other__notification">
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FieldCheckbox
                        label="Push-Notification"
                        checked={
                          !!value &&
                          value.findIndex((val: string) => val === "push") > -1
                        }
                        onChange={(e) =>
                          handleToggleNotificationSetting(
                            e.target.checked,
                            value,
                            "push",
                            onChange
                          )
                        }
                      />
                    )}
                    name={`notifications_via`}
                    defaultValue={notifications_via}
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FieldCheckbox
                        label="SMS"
                        checked={
                          !!value &&
                          value.findIndex((val: string) => val === "sms") > -1
                        }
                        onChange={(e) =>
                          handleToggleNotificationSetting(
                            e.target.checked,
                            value,
                            "sms",
                            onChange
                          )
                        }
                      />
                    )}
                    name={`notifications_via`}
                    defaultValue={notifications_via}
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FieldCheckbox
                        label="Email"
                        checked={
                          !!value &&
                          value.findIndex((val: string) => val === "email") > -1
                        }
                        onChange={(e) =>
                          handleToggleNotificationSetting(
                            e.target.checked,
                            value,
                            "email",
                            onChange
                          )
                        }
                      />
                    )}
                    name={`notifications_via`}
                    defaultValue={notifications_via}
                  />
                </div>
              </div>
              <div>
                <div
                  className="profile__password cursor-pointer"
                  onClick={handleUpdatePwd}
                >
                  <label className="condition-title cursor-pointer">
                    Update Password
                  </label>

                  <span>
                    <SvgImage
                      name={`ChevronThinIcon`}
                      width={19}
                      height={8}
                      className={`${
                        updatePassword ? "transform rotate-180" : ""
                      }`}
                    />
                  </span>
                </div>
                {updatePassword && (
                  <>
                    <div className="profile__form">
                      <FieldText
                        title="Password"
                        type={showPassword ? "text" : "password"}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter Password"
                      />
                      <span
                        className="profile__form__icon"
                        onClick={handleShowPwd}
                      >
                        <SvgImage
                          name={`${showPassword ? "EyeShowIcon" : "EyeIcon"}`}
                        />
                      </span>
                    </div>
                    <div className="profile__form">
                      <FieldText
                        title="Confirm Password"
                        value={confirmPassword}
                        type={showConfirmPassword ? "text" : "password"}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Confirm Password"
                      />
                      <span
                        className="profile__form__icon"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      >
                        <SvgImage
                          name={`${
                            showConfirmPassword ? "EyeShowIcon" : "EyeIcon"
                          }`}
                        />
                      </span>
                      {password !== confirmPassword && (
                        <TextError error="Your passwords don't match" />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="dashboard__content__tool !m-0">
            <Button
              isLoading={isLoading}
              type="submit"
              onClick={handleSubmit(handleSubmitProfile)}
            >
              <span className="dashboard__content__tool__txt">Save</span>
            </Button>
          </div>
        </div>
        <Prompt isDirty={isDirty} />
      </div>
    </>
  );
};
