import { bannerTestID } from "../dataTestIDs";

import "./styles.scss";

export interface BannerProps {
  name: string;
  label: string;
  size?: "sm" | "md";
  dataTestID?: string;
}

export const Banner = ({
  name,
  label,
  size = "md",
  dataTestID,
}: BannerProps) => {
  return (
    <div
      className={`banner--${name} ${size === "sm" && "!py-1"}`}
      data-testid={dataTestID || bannerTestID}
    >
      {label}
    </div>
  );
};
