import { useMutation } from "@tanstack/react-query";
import { ChangeEvent, useState } from "react";

import { Button, FieldText } from "components/shared";

import { showErrorToast, showSuccessToast } from "utils";

import { INote } from "types";

import { TenderService } from "api/client";

interface ModalAddNotesProps {
  notes: INote[];
  tenderVehicleId: string;
  onRefresh: () => void;
}

export const ModalAddNotes = ({
  notes,
  tenderVehicleId,
  onRefresh,
}: ModalAddNotesProps) => {
  const [note, setNote] = useState("");

  const { mutate: addNotes } = useMutation(
    (payload: any) => TenderService.addNotes(payload, tenderVehicleId || ""),
    {
      onSuccess: () => {
        onRefresh();
        setNote("");
        showSuccessToast("Added notes successfully");
      },
      onError: () => {
        showErrorToast("Added notes failed");
      },
    }
  );

  const handleChangeNote = (e: ChangeEvent<HTMLInputElement>) => {
    setNote(e.target.value);
  };

  const handleCancel = () => setNote("");
  const handleAddNote = () => {
    if (note) {
      addNotes({ comment: note });
    }
  };

  return (
    <div className="flex flex-col w-430 gap-2">
      <p className="text-xl font-semibold w-full text-center">Notes</p>
      <FieldText
        className="shadow-md-inset h-10 rounded-4 w-full pl-3 mt-2 outline-none"
        placeholder="Enter your personal notes here"
        value={note}
        onChange={handleChangeNote}
      />
      {note && (
        <div className="flex items-center gap-5">
          <div className="w-110">
            <Button variant="secondary" onClick={handleCancel}>
              <span className="text-sm font-semibold">CANCEL</span>
            </Button>
          </div>
          <div className="w-110">
            <Button onClick={handleAddNote}>
              <span className="text-sm font-semibold">SAVE</span>
            </Button>
          </div>
        </div>
      )}
      <div className="py-1.5" />
      {notes &&
        notes.length > 0 &&
        notes.map((note, idx) => (
          <div
            className="flex flex-col w-full pb-5 border-b border-softPeach mb-5"
            key={idx}
          >
            <div className="flex w-full justify-between items-center">
              <span className="text-sm font-medium text-lightGrey">
                {note.commentator.name}
              </span>
              <span className="text-xs text-lightGrey">
                {note.commented_at}
              </span>
            </div>
            <p className="mt-2.5 text-sm font-light text-txt">{note.comment}</p>
          </div>
        ))}
    </div>
  );
};
