import { Card } from "components/shared";
import "../styles.scss";

import { IDealershipDetail } from "types";

export interface CardDealershipProps {
  data: IDealershipDetail;
  onClick: () => void;
  dataTestID?: string;
}

export const CardDealership = ({
  data,
  onClick,
  dataTestID,
}: CardDealershipProps) => {
  const { email, address, license, name, sales } = data;
  return (
    <Card onClick={onClick} dataTestID={dataTestID}>
      <div className="card-vehicle !h-auto p-5">
        <div className="grid grid-cols-3 gap-y-1 w-full">
          <div className="card-vehicle__section--customer !flex-grow-0">
            <div className="bg-primary rounded-10 px-1.5 text-lg text-white uppercase font-semibold">
              {name
                .split(" ")
                .map((section) => section[0] || "")
                .join("")}
            </div>
            <div className="card-vehicle__txt--info font-semibold">{name}</div>
          </div>
          <div className="card-vehicle__txt--info">
            Contact Name: <span className="font-semibold">{sales?.agent}</span>
          </div>
          <div className="card-vehicle__txt--info flex gap-2">
            Email Address: <p className="font-semibold">{email}</p>
          </div>
          <div className="card-vehicle__txt--info">
            License Number: <span className="font-semibold">{license}</span>
          </div>
          <div className="card-vehicle__txt--info">
            Contact Number:
            <span className="font-semibold">{sales?.phone}</span>
          </div>
          <div className="card-vehicle__txt--info flex gap-2">
            Address: <p className="font-semibold">{address}</p>
          </div>
        </div>
      </div>
    </Card>
  );
};
