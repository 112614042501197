import classNames from "classnames";
import { Link } from "react-router-dom";

import { navHeaderTestID } from "components/layouts/dataTestIDs";
import { SvgImage } from "components/shared";

import { NavigationMenuLabel } from "types";

import { ReactComponent as DashboardIcon } from "assets/images/menu-icons/dashboard.svg";
import { ReactComponent as DealershipIcon } from "assets/images/menu-icons/dealership.svg";
import { ReactComponent as WholesaleIcon } from "assets/images/menu-icons/external.svg";
import { ReactComponent as MotorMarketIcon } from "assets/images/menu-icons/motormarket-icon.svg";
import { ReactComponent as MotorMarketText } from "assets/images/menu-icons/motormarket-text.svg";
import { ReactComponent as SettingIcon } from "assets/images/menu-icons/setting.svg";
import { ReactComponent as TenderIcon } from "assets/images/menu-icons/tender.svg";

import "../../styles.scss";

export interface NavHeaderProps {
  open: boolean;
  label: NavigationMenuLabel;
  dataTestID?: string;
  isActive?: boolean;
  route?: string;
}

export const NavHeader = ({
  open,
  label,
  isActive = true,
  dataTestID,
  route,
}: NavHeaderProps) => {
  const content = (
    <div
      className={classNames("sidebar__menu-item disclosure__btn", [
        {
          "no-access": !isActive,
        },
      ])}
      data-testid={dataTestID || navHeaderTestID}
    >
      {isActive && (
        <SvgImage
          name="ChevronUpIcon"
          className={`absolute top-5 left-2 ${
            !open ? "transform rotate-180" : ""
          }`}
        />
      )}
      <div className="w-6 flex items-center justify-center mr-2">
        {label === NavigationMenuLabel.RETAIL_DEALERSHIP && <DealershipIcon />}
        {label === NavigationMenuLabel.WHOLESALE_DEALERSHIP && (
          <WholesaleIcon />
        )}
        {label === NavigationMenuLabel.TENDER && <TenderIcon />}
        {label === NavigationMenuLabel.SETTINGS && <SettingIcon />}
        {label === NavigationMenuLabel.MOTOR_MARKET && <MotorMarketIcon />}
        {label === NavigationMenuLabel.DASHBOARD && <DashboardIcon />}
      </div>
      <span className="capitalize">
        {label === NavigationMenuLabel.MOTOR_MARKET ? (
          <MotorMarketText />
        ) : (
          label
        )}
      </span>
    </div>
  );

  return route ? <Link to={route}>{content}</Link> : content;
};
