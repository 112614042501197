import { Button, FieldTextarea } from "components/shared";

interface ModalTenderViewNoteProps {
  notes: string;
  onClose: () => void;
}

export const ModalTenderNotes = ({
  notes,
  onClose,
}: ModalTenderViewNoteProps) => {
  return (
    <div className="flex flex-col items-center w-672">
      <p className="text-xl font-bold text-txt leading-10">Tender Notes</p>
      <div className="w-full mb-9">
        <FieldTextarea
          value={notes}
          title="Notes"
          className="min-h-28 h-28"
          disabled
        />
      </div>
      <div className="w-150">
        <Button type="button" onClick={onClose}>
          <span className="font-bold">CLOSE</span>
        </Button>
      </div>
    </div>
  );
};
