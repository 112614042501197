import Ably from "ably";
import { createContext } from "react";

interface INotificationContextProps {
  client: Ably.Realtime | null;
  channel: Ably.Types.RealtimeChannelCallbacks | undefined;
}

export const NotificationContext = createContext<INotificationContextProps>({
  client: null,
  channel: {} as Ably.Types.RealtimeChannelCallbacks,
});
