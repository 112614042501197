import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { InformativeModal } from "components/Modal/InformativeModal";
import { Button, Modal } from "components/shared";

import { useDealership } from "context/DealershipContext";

import { IVehicleSummaryResponse } from "types";

import { AcquisitionService } from "api/client";
import { DirectOfferService } from "api/client/DirectOfferService";

interface IDirectOfferBuyProps {
  offerID: string;
  contractID: string;
  onRefresh?: () => void;
}

export const DirectOfferBuy = ({
  offerID,
  contractID,
  onRefresh,
}: IDirectOfferBuyProps) => {
  const navigate = useNavigate();
  const { dealershipID, showAllDealerships } = useDealership();
  const [showModal, setShowModal] = useState(false);
  const [showFailedDialog, setShowFailedDialog] = useState(false);

  const { mutate, isLoading } = useMutation({
    mutationFn: () => {
      return DirectOfferService.acceptOffer(
        !showAllDealerships ? { dealership_id: dealershipID! } : undefined,
        offerID
      );
    },
    onSuccess: () => {
      setShowModal(true);
      setShowFailedDialog(false);
    },
    onError: () => {
      setShowModal(true);
      setShowFailedDialog(true);
    },
  });

  const { refetch } = useQuery<IVehicleSummaryResponse>(
    [`wholesale-summary-${contractID}`, contractID],
    AcquisitionService.getAcquisitionSummary,
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const modalContent = showFailedDialog
    ? {
        title: "Error",
        message:
          "The vehicle has just been sold to another dealership. Consider accepting as soon a s possible going forward.",
        icon: "FailedIcon",
      }
    : {
        title: "Vehicle Acquired!",
        message:
          "You have successfully purchased this vehicle. It is now located in your Stocklist.",
        icon: "SuccessIcon",
      };

  const onClose = async () => {
    setShowModal(false);
    if (showFailedDialog) {
      onRefresh?.();
    } else {
      const { data } = await refetch();
      const newContractID = data?.data.acquisitions?.new_contract_id;
      navigate(`/wholesale-dealership/stocklist/${newContractID}/summary`);
    }
  };

  return (
    <>
      <div className="btn__direct__offer w-full">
        <Button type="button" isLoading={isLoading} onClick={() => mutate()}>
          <span>Buy now</span>
        </Button>
      </div>
      <Modal open={showModal} closeModal={onClose} size="auto">
        <InformativeModal content={modalContent} />
      </Modal>
    </>
  );
};
