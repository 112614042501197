import { useNavigate } from "react-router-dom";

import { breadcrumbsFirstRouteTestID, breadcrumbsTestID } from "../dataTestIDs";

import "./styles.scss";

export interface Breadcrumb {
  title: string;
  route: string;
}

export interface BreadcrumbsProps {
  paths: Breadcrumb[];
  dataTestID?: string;
}

export const Breadcrumbs = ({ paths, dataTestID }: BreadcrumbsProps) => {
  const navigate = useNavigate();
  if (paths.length === 0) {
    return <></>;
  }

  const handleNavigate = (route: string) => {
    navigate(route);
  };

  return (
    <nav className="breadcrumbs" data-testid={dataTestID || breadcrumbsTestID}>
      <ol className="breadcrumbs__content">
        <li
          className="breadcrumbs__item--first"
          data-testid={breadcrumbsFirstRouteTestID}
        >
          <span
            className="breadcrumbs__item-label--first"
            onClick={() => handleNavigate(paths[0].route)}
          >
            {paths[0].title}
          </span>
        </li>
        {paths.slice(1).map((path, idx) => (
          <li className="inline-block" key={idx}>
            <div
              className="breadcrumbs__item"
              onClick={() => handleNavigate(path.route)}
            >
              <span className="breadcrumbs__item-label">
                &nbsp;/&nbsp;{path.title}
              </span>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};
