import { createContext, useContext, useState } from "react";

interface IContextProps {
  dealershipID: string | null;
  showAllDealerships: boolean;
  setDealershipID: React.Dispatch<React.SetStateAction<string | null>>;
  isDealershipSelectorVisible?: boolean;
  setIsDealershipSelectorVisible?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

const DealershipContext = createContext<IContextProps>({
  dealershipID: null,
  showAllDealerships: true,
  setDealershipID: () => {},
  isDealershipSelectorVisible: false,
  setIsDealershipSelectorVisible: () => {},
});

export const useDealership = () => useContext(DealershipContext);

const DealershipProvider = ({ children }: any) => {
  const [dealershipID, setDealershipID] = useState<string | null>(null);
  const [isDealershipSelectorVisible, setIsDealershipSelectorVisible] =
    useState<boolean>(true);

  return (
    <DealershipContext.Provider
      value={{
        dealershipID,
        showAllDealerships: !dealershipID || dealershipID.includes(","),
        setDealershipID,
        isDealershipSelectorVisible,
        setIsDealershipSelectorVisible,
      }}
    >
      {children}
    </DealershipContext.Provider>
  );
};

export default DealershipProvider;
