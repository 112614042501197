import { ALL, SOLD } from "./statuses";

export const INCOMPLETE = { key: "draft", label: "Incomplete" };
export const SCHEDULED = { key: "scheduled", label: "Scheduled" };
export const ACTIVE = { key: "active", label: "Active" };
export const CLOSED = { key: "closed", label: "Closed" };

export const SELLING = { name: "selling", label: "Selling" };
export const BUYING = { name: "buying", label: "Buying" };
export enum TENDER_FLOW_TYPE {
  SELLING,
  BUYING,
}

export const TENDER_STATUS_LIST = [INCOMPLETE, SCHEDULED, ACTIVE, CLOSED];
export const TENDER_STATUS_LIST_BUYING = [SCHEDULED, ACTIVE, CLOSED];
export const TENDER_DELETABLE_STATUSES = [INCOMPLETE.key, SCHEDULED.key];

export const SAVED = { key: "saved", label: "Saved" };
export const TENDER_VEHICLE_FILTER = [ALL, SAVED];
export const SOLD_VEHICLE_FILTER = [ALL, SOLD];
