import { useQuery } from "@tanstack/react-query";
import { useContext, useState } from "react";

import { LoadItem } from "components/app/LoadVehicle";
import { Modal } from "components/shared";

import { AuthContext } from "context/AuthContext";

import { formatNumber } from "utils";

import { ReactComponent as EyeIcon } from "assets/images/eye-icon.svg";

import { TenderService } from "api/client";

import { ModalTenderBidsHistory } from "../Modal";

export const TenderBidSection = ({
  tender_vehicle_id,
}: {
  tender_vehicle_id: string;
}) => {
  const { userID } = useContext(AuthContext);

  const [selectedBidder, setSelectedBidder] = useState("");

  const { data: bids, isLoading } = useQuery(
    [`fetchTenderVehicleBids_${userID}`, tender_vehicle_id],
    TenderService.getTenderVehicleBids
  );

  const onOpenBidsLog = (id: string) => {
    setSelectedBidder(id);
  };

  const onCloseBidsModal = () => {
    setSelectedBidder("");
  };

  return (
    <div className="disclosure__pannel--wide">
      <div className="flex flex-col w-full -mt-4">
        {isLoading ? (
          <LoadItem />
        ) : (
          bids?.data?.map((bid) => (
            <div
              key={bid.id}
              className="flex flex-col w-full py-2 border-b border-softPeach gap-2"
            >
              <div className="flex justify-between">
                <div className="flex items-start text-sm">
                  {bid.dealership.name}
                  <div
                    className="cursor-pointer ml-2 mt-1 inline-block"
                    onClick={() => onOpenBidsLog(bid.id)}
                  >
                    <EyeIcon />
                  </div>
                </div>
                <div className="text-sm">${formatNumber(bid.amount)}</div>
              </div>
              <div className="flex justify-between -mt-1 text-sm">
                <div className="text-lightGrey">{bid.bidder.name}</div>
                <div className="text-lightGrey">{bid.created_at}</div>
              </div>
            </div>
          ))
        )}
      </div>
      <Modal
        open={!!selectedBidder}
        closeModal={onCloseBidsModal}
        title="Bids Log"
        size="auto"
      >
        <ModalTenderBidsHistory bidderID={selectedBidder} isSeller />
      </Modal>
    </div>
  );
};
