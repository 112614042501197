import { ChangeEventHandler, forwardRef, LegacyRef } from "react";

import "./styles.scss";

export interface FieldRadioProps {
  checked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  label?: string;
  name: string;
  dataTestID?: string;
}

export const FieldRadio = forwardRef(
  (
    {
      checked,
      onChange,
      label,
      name,
      dataTestID = "field-radio",
    }: FieldRadioProps,
    ref: LegacyRef<HTMLInputElement>
  ) => {
    return (
      <div className="radio" ref={ref}>
        <label className="radio__label">
          <input
            className="radio__input"
            type="radio"
            name={name}
            checked={checked}
            onChange={onChange}
            data-testid={dataTestID}
          />
          {label}
        </label>
      </div>
    );
  }
);
