import { useState } from "react";
import { useNavigate } from "react-router";

import { ModalTenderCreate } from "components/app/Modal";
import { Button, Modal, SvgImage } from "components/shared";

import "../styles.scss";

import { TENDER_MENU, showErrorToast } from "utils";

import { ITenderSummaryResponse } from "types";

export const ButtonTenderNew = () => {
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const [isModalClosedByRequest, setIsModalClosedByRequest] =
    useState<boolean>(false);

  const toggleCreateTenderModal = () => {
    openModal &&
      !isModalClosedByRequest &&
      showErrorToast("Tender Not Created");

    setOpenModal((open) => !open);
  };

  const onCloseModal = (data: ITenderSummaryResponse) => {
    setOpenModal(false);
    setIsModalClosedByRequest(true);
    navigate(`${TENDER_MENU.selling.route}/${data.data.tender_id}/summary`);
  };

  return (
    <div className="w-110">
      <Button type="button" onClick={toggleCreateTenderModal}>
        <div className="flex items-center justify-center gap-2">
          <SvgImage name="CreateIcon" />
          <span className="text-sm text-white font-semibold">CREATE</span>
        </div>
      </Button>
      <Modal open={openModal} size="auto" closeModal={toggleCreateTenderModal}>
        <ModalTenderCreate onClose={(data) => onCloseModal(data)} />
      </Modal>
    </div>
  );
};
