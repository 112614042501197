export const ALL = { key: "all", label: "All" };
export const LINK_SENT = { key: "link-sent", label: "Inspection Link Sent" };
export const AWAITING = { key: "awaiting", label: "Pending Offer" };
export const REVIEW = { key: "review", label: "Client Inspection" };
export const DRAFT = { key: "draft", label: "Incomplete" };
export const NEGOTIATING = { key: "negotiating", label: "Pending Deal" };
export const DECLINED_VALUATION = {
  key: "declined-valuation",
  label: "Lost Deal",
};
export const DECLINED = { key: "declined", label: "Lost Deal" };
export const LOST = { key: "all-lost", label: "Lost Deal" };
export const ACCEPTED = { key: "accepted", label: "Accepted Deal" };
export const ACQUIRED = { key: "acquired", label: "Acquired" };
export const SOLD = { key: "sold-all", label: "Sold" };
export const SOLDTODEALERSHIP = {
  key: "sold-to-dealership",
  label: "To Dealership",
};
export const SOLDTORETAIL = { key: "sold-to-retail", label: "To Retail" };
export const SOLDTOMP = { key: "sold-to-mp", label: "Through MP" };
export const SOLDTENDERED = { key: "sold-tendered", label: "Via Tender" };
export const SOLDVIADIRECTOFFER = {
  key: "sold-direct-offer",
  label: "via Direct Offer",
};
export const PENDINGSALE = { key: "pending-sale", label: "Pending Sale" };
export const WON = { key: "won", label: "Won" };

export const LOSTDEAL = { key: "lost", label: "Lost Deal" };
export const LOSTDEAL_RETAIL = { key: "lost-retail", label: "Lost Deal" };
export const LOSTSALE = { key: "lost-sale", label: "Lost Buy Now" };
export const REJECTED = { key: "rejected", label: "Rejected" };
export const REVOKED = { key: "revoked", label: "Revoked" };

export const PENDING = { key: "pending", label: "Pending Offer" };
export const BUYNOW = { key: "buy-now", label: "Buy Now" };
export const TENDERED = { key: "tendered", label: "In Tender" };
export const DEALERSHIPS = { key: "dealerships", label: "Dealerships" };
export const CUSTOMERS = { key: "customers", label: "Customers" };

export const MOTOR_MARKET_DRAFT = { key: "draft", label: "Draft" };
export const MOTOR_MARKET_LIVE = { key: "live", label: "Live" };
export const MOTOR_MARKET_ENDED = { key: "ended", label: "Ended" };
export const MOTOR_MARKET_SOLD = { key: "sold", label: "Sold" };
export const MOTOR_MARKET_AUCTION = {
  key: "in-auction",
  label: "In MotorMarket",
};
export const MOTOR_MARKET_SOLD_AUCTION = {
  key: "sold-auction",
  label: "Via MotorMarket",
};
export const MOTOR_MARKET_WITHDRAWN = {
  key: "withdrawn",
  label: "Withdrawn",
};
export const MOTOR_MARKET_WITHDRAWN_BUYER = {
  key: "withdrawn-buyer",
  label: "Withdrawn",
};
export const MOTOR_MARKET_WATCHLIST = { key: "watchlist", label: "Watchlist" };
export const MOTOR_MARKET_MY_BIDS = { key: "my-bids", label: "My Bids" };
export const MOTOR_MARKET_WON = { key: "won", label: "Won" };
export const MOTOR_MARKET_LOST = { key: "lost", label: "Lost" };
export const MOTOR_MARKET_PENDING = {
  key: "my-bids-expired",
  label: "Pending",
};
export const MOTOR_MARKET_BUYER_ENDED = {
  key: "my-bids-expired",
  label: "Ended",
};
export const MOTOR_MARKET_BUYER_BID = { key: "my-bids", label: "Pending" };

export const STATUS_LIST = {
  Valuation: [ALL, AWAITING, REVIEW, DRAFT],
  Negotiation: [ALL, NEGOTIATING, ACCEPTED, DECLINED],
  Acquisition: [
    ALL,
    ACQUIRED,
    TENDERED,
    MOTOR_MARKET_AUCTION,
    PENDINGSALE,
    SOLD,
  ],
  SoldAcquisition: [
    SOLDTORETAIL,
    SOLDTODEALERSHIP,
    SOLDTOMP,
    MOTOR_MARKET_SOLD_AUCTION,
    SOLDTENDERED,
    SOLDVIADIRECTOFFER,
  ],
  MPValuation: [ALL, PENDING],
  MPNegotiation: [ALL, NEGOTIATING, LOSTDEAL],
  MPAcquisition: [ALL, ACQUIRED, SOLD],
  WholesaleDealership: [ALL, AWAITING, REVIEW, DRAFT, BUYNOW],
  WholesaleNegotiation: [ALL, NEGOTIATING, LOST],
  WholesaleLost: [LOSTDEAL, LOSTSALE, REJECTED, REVOKED],
  WholesaleAcquisition: [ALL, ACQUIRED, SOLD, SOLDTORETAIL, SOLDTOMP],
  WholesaleClients: [DEALERSHIPS, CUSTOMERS],
  MotorMarketMyListings: [
    MOTOR_MARKET_DRAFT,
    MOTOR_MARKET_LIVE,
    MOTOR_MARKET_ENDED,
    MOTOR_MARKET_SOLD,
  ],
  MotorMarketBuying: [
    MOTOR_MARKET_LIVE,
    MOTOR_MARKET_WATCHLIST,
    MOTOR_MARKET_MY_BIDS,
    MOTOR_MARKET_BUYER_ENDED,
  ],
  MotorMarketBuyingEnded: [
    MOTOR_MARKET_PENDING,
    MOTOR_MARKET_LOST,
    MOTOR_MARKET_WON,
  ],
};

export const VALUATION_INVISIBLE_STATUSES = [
  DRAFT.key,
  TENDERED.key,
  SOLD.key,
  SOLDTOMP.key,
  SOLDTORETAIL.key,
  SOLDTODEALERSHIP.key,
  SOLDTENDERED.key,
  SOLDVIADIRECTOFFER.key,
  PENDINGSALE.key,
  REVIEW.key,
  BUYNOW.key,
];

export const VALUATIONS_TYPE = {
  OFFER: "offer",
  VALUATION: "valuation",
  MP: "mp",
};

export const ACQUIRING_STATUSES = [
  ACQUIRED.key,
  TENDERED.key,
  SOLDTOMP.key,
  SOLDTORETAIL.key,
  SOLDTODEALERSHIP.key,
  SOLDTENDERED.key,
  SOLDVIADIRECTOFFER.key,
  PENDINGSALE.key,
];

export const SOLD_COMBINED_STATUSES = [
  SOLDTENDERED.key,
  SOLDVIADIRECTOFFER.key,
  MOTOR_MARKET_SOLD_AUCTION.key,
  SOLDTOMP.key,
  SOLDTODEALERSHIP.key,
  SOLDTORETAIL.key,
  SOLD.key,
];
