import "../styles.scss";

import { buttonStatusTabTestID } from "components/app/dataTestIDs";
import { ButtonLink } from "components/shared";

import { IOption } from "types";

export interface ButtonStatusTabProps {
  data: IOption;
  selected: boolean;
  onClick: (status: string | number) => void;
  dataTestID?: string;
}

export const ButtonStatusTab = ({
  data,
  selected,
  onClick,
  dataTestID,
}: ButtonStatusTabProps) => {
  if (selected) {
    return (
      <div
        className="statustab"
        data-testid={`${dataTestID || buttonStatusTabTestID}-selected`}
      >
        <span className="statustab__label">{data.label}</span>
      </div>
    );
  }

  return (
    <ButtonLink
      onClick={() => onClick(data.key)}
      dataTestID={dataTestID || buttonStatusTabTestID}
      disabled={data.disabled}
    >
      <span className="statustab__label--link">{data.label}</span>
    </ButtonLink>
  );
};
