import { Tab as UITab } from "@headlessui/react";
import { useState } from "react";

import { TabContent } from "./TabContent";
import { TabHeader } from "./TabHeader";

interface Tab {
  id: number;
  title: string;
  content: JSX.Element;
  disabled?: boolean;
}

interface TabsProps {
  tabs: Tab[];
  hidePanel?: boolean;
  onSetActiveTab?: (tabIndex: number) => void;
}

export const Tabs = ({ tabs, hidePanel, onSetActiveTab }: TabsProps) => {
  const [activeTab, setActiveTab] = useState(0);

  const onChange = (index: number) => {
    setActiveTab(index);
    onSetActiveTab && onSetActiveTab(index);
  };

  return (
    <div className="w-full px-2 sm:px-0 flex flex-col">
      <UITab.Group defaultIndex={activeTab} onChange={onChange}>
        <UITab.List className="flex border-b-2 border-waikawaGrey w-fit">
          {tabs.map((item: Tab) => (
            <TabHeader key={item.id} {...item} />
          ))}
        </UITab.List>
        {!hidePanel && (
          <UITab.Panels className="mt-2 flex-1">
            {tabs.map((item: Tab) => (
              <TabContent key={item.id} {...item} />
            ))}
          </UITab.Panels>
        )}
      </UITab.Group>
    </div>
  );
};
