import { useEffect, useState } from "react";

import { formSelectVariantTestID } from "components/app/dataTestIDs";
import { ButtonLink, SvgImage } from "components/shared";

import { IVariant } from "types";

import { VehicleSearchService } from "api/client";

import { ISearchFormType } from "./FormManualSearch";

export interface FormSelectVariantProps {
  selectedSearchOptions: ISearchFormType | undefined;
  onNextStep: (val: IVariant) => void;
  onBack: (step: string) => void;
  dataTestID?: string;
}

export const FormSelectVariant = ({
  selectedSearchOptions,
  onNextStep,
  onBack,
  dataTestID,
}: FormSelectVariantProps) => {
  const [variants, setVariants] = useState<IVariant[]>([]);
  useEffect(() => {
    if (selectedSearchOptions) {
      VehicleSearchService.searchVariants(selectedSearchOptions).then(
        (res: any) => {
          setVariants(res.data);
        }
      );
    }
  }, [selectedSearchOptions]);

  const handleGoBack = () => {
    onBack("SELECT_VARIANT");
  };

  return (
    <div
      className="variant-wrapper"
      data-testid={dataTestID || formSelectVariantTestID}
    >
      {variants.map((variant, idx) => (
        <ButtonLink key={idx} onClick={() => onNextStep(variant)}>
          <div className="variant">
            <label className="variant__title">{variant.model_name}</label>
          </div>
        </ButtonLink>
      ))}
      <div className="variant__back">
        <ButtonLink onClick={handleGoBack}>
          <SvgImage name="LeftRoundedIcon" />
        </ButtonLink>
      </div>
    </div>
  );
};
