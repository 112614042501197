import { ChangeEventHandler } from "react";

import "./styles.scss";

export interface FieldCheckboxProps {
  variant?: string;
  rounded?: boolean;
  checked: boolean;
  label?: string;
  id?: string;
  disabled?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  dataTestID?: string;
}

export const FieldCheckbox = ({
  checked,
  onChange,
  label,
  id,
  rounded = false,
  disabled = false,
  variant,
  dataTestID,
}: FieldCheckboxProps) => {
  return (
    <label
      className={`${variant ? `custom-check--${variant}` : "custom-check"}${
        disabled ? "--disabled" : ""
      }`}
      data-testid={dataTestID}
    >
      <input
        type="checkbox"
        id={id}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
      <span
        className={`${rounded ? "checkmark--rounded" : "checkmark"}${
          disabled ? "--disabled" : ""
        }`}
      />
      <span className="check__label">{label}</span>
    </label>
  );
};
