export enum ActionResult {
  BidPlacedWithSuccess,
  PublishMotorMarketListing,
  DeleteMotorMarketDraft,
  RemoveMotorMarketVehicle,
  RemoveMotorMarketDraftVehicle,
  CancelMotorMarketSale,
  WarningCancelMotorMarketSale,
  SuccessPlaceBid,
  ConfirmMotorMarketSale,
}

export const ActionResultMessage: {
  [id: string]: { title: string; message: string };
} = {
  [ActionResult.BidPlacedWithSuccess]: {
    title: "Bid Submitted",
    message:
      "Your bid has been submitted. You will be notified of the outcome once the tender has ended and the seller has accepted the winning bid.",
  },
  [ActionResult.PublishMotorMarketListing]: {
    title: "Publish?",
    message:
      "Your listings will be made public. Anyone with access to MotorMarket will be able to bid on your vehicles.",
  },
  [ActionResult.DeleteMotorMarketDraft]: {
    title: "Delete Listings?",
    message:
      "This action will delete  this draft permanently. You will not be able to retrieve it. Any vehicle previously in this listing will be returned to your Stocklist.",
  },
  [ActionResult.RemoveMotorMarketVehicle]: {
    title: "Remove Vehicle",
    message:
      "This action will remove this vehicle permanently. Bidders will be notified the vehicle has been removed.",
  },
  [ActionResult.RemoveMotorMarketDraftVehicle]: {
    title: "Remove Vehicle",
    message:
      "This action will remove this vehicle permanently. Do you want to proceed?",
  },
  [ActionResult.ConfirmMotorMarketSale]: {
    title: "Confirm Sale?",
    message:
      "Please ensure payment has been processed before proceeding with this action.",
  },
  [ActionResult.CancelMotorMarketSale]: {
    title: "Are You Sure?",
    message:
      "This action will cancel the sale and every bid placed on the vehicle.",
  },
  [ActionResult.WarningCancelMotorMarketSale]: {
    title: "Failed To Cancel",
    message:
      "Please contact the buyer. They need to cancel their sale before you can cancel yours.",
  },
  [ActionResult.SuccessPlaceBid]: {
    title: "Bid Submitted",
    message:
      "Your bid has been submitted. You will be notified of the outcome once the listing has ended and the seller has accepted the winning bid.",
  },
};
