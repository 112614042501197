import { useMutation } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Banner, Button, ButtonLink, Modal, Tooltip } from "components/shared";

import { AuthContext } from "context/AuthContext";

import {
  INCOMPLETE,
  TENDER_DELETABLE_STATUSES,
  TENDER_STATUS_LIST,
  showErrorToast,
  showSuccessToast,
} from "utils";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import { ITender, ITenderSchedule } from "types";

import { TenderService } from "api/client";

import { ModalConfirm } from "../Modal";

interface TenderActionsBarProps {
  data: ITender;
  bidderSelected?: boolean;
  onRefresh: () => void;
}

export const TenderActionsBar = ({
  data,
  bidderSelected,
  onRefresh,
}: TenderActionsBarProps) => {
  const { hasPermission } = useContext(AuthContext);
  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const navigate = useNavigate();

  const { mutate: scheduleTender, isLoading: isScheduling } = useMutation(
    (data: ITenderSchedule) =>
      TenderService.scheduleTender(data, data.tender_id),
    {
      onSuccess: () => {
        showSuccessToast("Tender Scheduled");
        onRefresh();
      },
      onError: () => {
        showErrorToast("Failed to Save");
      },
    }
  );

  const { mutate: deleteTender, isLoading: isDeleting } = useMutation(
    (tenderId: string) => TenderService.deleteTender(tenderId),
    {
      onSuccess: () => {
        showSuccessToast("Tender Removed");
        onRefresh();
        navigate(-1);
      },
      onError: () => {
        showErrorToast("Failed to Remove");
      },
    }
  );

  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);
  const handleDeleteTender = () => {
    deleteTender(data.tender_id);
    handleCloseDeleteModal();
  };

  const handleSave = () => {
    showSuccessToast("Saved Successfully");
    navigate(-1);
  };

  const handleOpenScheduleModal = () => setOpenScheduleModal(true);
  const handleCloseScheduleModal = () => setOpenScheduleModal(false);
  const handleSchedule = () => {
    const { tender_id, name, starts_at, ends_at, location, notes } = data;
    const scheduleData = {
      tender_id,
      name,
      location,
      notes,
      starts_at,
      ends_at,
    } as ITenderSchedule;
    scheduleTender(scheduleData);
    handleCloseScheduleModal();
  };

  const isDeletable =
    TENDER_DELETABLE_STATUSES.includes(data.status) &&
    hasPermission(PermissionCategory.TENDER_SELLING, PermissionAction.DELETE);

  return (
    <div className="flex flex-col gap-2.5 w-430">
      <Banner
        name={data.status}
        size="sm"
        label={
          TENDER_STATUS_LIST.find((status) => status.key === data.status)
            ?.label || ""
        }
      />
      <div className="w-full flex flex-row-reverse items-center gap-4">
        {data.status === INCOMPLETE.key && (
          <>
            <div className="flex-1">
              {hasPermission(
                PermissionCategory.TENDER_SELLING,
                PermissionAction.CREATE
              ) && (
                <Tooltip label="No Bidders Selected" show={!bidderSelected}>
                  <Button
                    type="button"
                    className="shadow-md !w-36"
                    isLoading={isScheduling}
                    onClick={handleOpenScheduleModal}
                    disabled={!bidderSelected}
                  >
                    <span className="font-bold text-sm">SCHEDULE</span>
                  </Button>
                </Tooltip>
              )}
            </div>
            {hasPermission(
              PermissionCategory.TENDER_SELLING,
              PermissionAction.UPDATE
            ) && (
              <div className="flex-1">
                <Button
                  type="button"
                  className="shadow-md !w-36"
                  variant="secondary"
                  onClick={handleSave}
                >
                  <span className="font-bold text-sm">SAVE AS DRAFT</span>
                </Button>
              </div>
            )}
          </>
        )}
        {isDeletable && (
          <ButtonLink disabled={isDeleting} onClick={handleOpenDeleteModal}>
            <span className="text-redVibrant underline gap-3.5">
              Delete Tender
            </span>
          </ButtonLink>
        )}
      </div>
      <Modal
        open={openScheduleModal}
        closeModal={handleCloseScheduleModal}
        size="auto"
      >
        <ModalConfirm
          title="Schedule Tender?"
          description="Scheduling the tender will notify the selected dealerships of this new Tender and any changes to this Tender."
          onCancel={handleCloseScheduleModal}
          onConfirm={handleSchedule}
        />
      </Modal>
      <Modal
        open={openDeleteModal}
        closeModal={handleCloseDeleteModal}
        size="auto"
      >
        <ModalConfirm
          title="Delete Tender?"
          description="If you delete this Tender, you will loose all its information, including any bids. All vehicles will be returned to your stocklist."
          onCancel={handleCloseDeleteModal}
          onConfirm={handleDeleteTender}
        />
      </Modal>
    </div>
  );
};
