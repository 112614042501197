import React, { useRef, useState } from "react";

import { ModalTenderNotes } from "components/app/Modal";
import { Modal } from "components/shared";

import { useIsOverflow } from "hooks/useIsTextOverflow";

interface NotesProps {
  notes: string;
}

export const Notes = ({ notes }: NotesProps) => {
  const [openModal, setOpenModal] = useState(false);

  const ref = useRef<HTMLParagraphElement>(null);
  const isOverflow = useIsOverflow({ ref, text: notes });

  const toggleOpenModal = () => setOpenModal((open) => !open);

  const toggleCreateTenderModal = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    e.stopPropagation();
    toggleOpenModal();
  };

  return (
    <div className="flex items-end">
      <p className="grow" ref={ref}>
        {notes}
      </p>
      {isOverflow && (
        <>
          <span
            className="flex-none font-bold"
            onClick={toggleCreateTenderModal}
          >
            view more
          </span>
          <Modal open={openModal} size="auto" closeModal={toggleOpenModal}>
            <ModalTenderNotes onClose={toggleOpenModal} notes={notes} />
          </Modal>
        </>
      )}
    </div>
  );
};
