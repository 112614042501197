import classNames from "classnames";
import Countdown from "react-countdown";

import { ReactComponent as ClockIcon } from "assets/images/clock-hollow.svg";
import { ReactComponent as ClockBuyer } from "assets/images/clock.svg";

export const Timer = ({
  targetDate,
  isSummaryView,
  isBuyerView,
  disabled,
}: {
  targetDate: Date;
  isSummaryView?: boolean;
  isBuyerView?: boolean;
  disabled?: boolean;
}) => {
  return (
    <div
      className={classNames(
        `flex justify-center items-center bg-[#236A88] h-[30px] opacity-90 font-medium gap-1 p-2 text-sm text-white rounded-t-10`,
        {
          "h-[40px]": isSummaryView,
        },
        {
          "!bg-white !text-[#236A88] text-2xl gap-3 items-start": isBuyerView,
        },
        {
          "bg-[#9696A0]": disabled,
        }
      )}
    >
      <Countdown
        date={targetDate}
        renderer={({ days, hours, minutes, seconds, completed }) => {
          if (completed) return <span className="font-bold">ended</span>;
          return (
            <>
              {isBuyerView ? <ClockBuyer /> : <ClockIcon />}
              {!!days && `${days}d`} {!!hours && `${hours}h`}{" "}
              {!!minutes && `${minutes}m`} {seconds}s
            </>
          );
        }}
      />
    </div>
  );
};
