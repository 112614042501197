import { throttle } from "lodash";
import { useCallback, useEffect, useState } from "react";

import { Breakpoints } from "utils";

const useViewportSizing = () => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleResize = useCallback(
    throttle(
      () =>
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        }),
      200
    ),
    []
  );

  useEffect(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return {
    windowSize,
    isMobile: windowSize.width <= Breakpoints.mobile,
    isTablet: windowSize.width >= Breakpoints.tablet,
    isDesktop: windowSize.width >= Breakpoints.desktop,
  };
};

export default useViewportSizing;
