import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  Button,
  ButtonLink,
  FieldCheckbox,
  FieldDropdown,
  FieldRadio,
  FieldTextarea,
  SvgImage,
} from "components/shared";

import { AuthContext } from "context/AuthContext";

import { CUSTOMER_INSPECTION_DATA, INSPECTION_STEP } from "utils";

import { ICustomerInspection, IQuestionnaire } from "types";

import { CustomerInspectionService, InspectionService } from "api/client";

import { useLocalStorage } from "hooks";

type TCheckbox = {
  slug: string;
  costs: string | number | undefined | null;
};

export const Conditions = () => {
  const { userID } = useContext(AuthContext);
  const navigate = useNavigate();
  const params = useParams();
  const [answers, setAnswers] = useState<any>({});
  const [savedData, saveInspection] = useLocalStorage(
    CUSTOMER_INSPECTION_DATA,
    null
  );

  const { data: verifyData } = useQuery(
    [`verify_inspection_link_${userID}`, params.id],
    CustomerInspectionService.verifyInspectionLInk
  );

  const { data: questionnaire } = useQuery<{ data: IQuestionnaire[] }, Error>(
    [`fetchQuestionnaire_${userID}`],
    InspectionService.getQuestionnaire,
    {
      refetchOnWindowFocus: false,
    }
  );
  const { mutate, isLoading } = useMutation(
    (data: any) => CustomerInspectionService.saveAnswers(data, params.id!),
    {
      onSuccess: (res: any) => {
        toast.success("Saved Successfully", {
          autoClose: 2000,
          theme: "colored",
        });
        saveInspection(res.data);
        navigate("../picture");
      },
      onError: (e: any) => {
        let message = "";

        if (e.data.message !== "") {
          if (e.data.message === "The answers field is required.") {
            message = "Answers are required.";
          } else {
            message = e.data.message;
          }
        } else {
          message = "Failed to Save";
        }
        toast.error(message, {
          autoClose: 2000,
          theme: "colored",
        });
      },
    }
  );

  useEffect(() => {
    if (verifyData) {
      const { status } = verifyData.data;

      if (status.name === INSPECTION_STEP.INSPECTION_SUBMITTED) {
        return navigate(`/inspection/customer/${params.id}/complete`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyData]);

  const formData = questionnaire ? questionnaire.data[1].questionnaire : [];
  const cosmeticForm = formData.find(
    (category) => category.slug === "cosmetic-condition"
  );
  useEffect(() => {
    if (savedData) {
      const newAnswers: any = {};
      const { assessments } = savedData as ICustomerInspection;
      if (assessments && assessments.length > 1) {
        assessments[1].questionnaire.forEach((field) => {
          if (
            field.question_slug === "additional-information" &&
            field.answers?.comments &&
            field.answers?.comments.length !== 0 &&
            field?.answers?.comments[0] !== "undefined"
          ) {
            newAnswers[field.question_slug] = `${field.answers?.comments[0]}`;
          } else if (field.answers?.options) {
            if (
              field.answers.options.length === 1 &&
              field.title !== "Cosmetic Condition" &&
              field.question_slug !== "mechanical-and-roadworthy"
            ) {
              newAnswers[field.question_slug] =
                field.answers.options[0].option_slug;
            } else if (field.question_slug === "mechanical-and-roadworthy") {
              newAnswers[field.question_slug] = field.answers.options.map(
                (option) => ({ slug: option.option_slug, costs: null })
              );
            } else {
              newAnswers[field.question_slug] = field.answers.options.map(
                (option) => ({
                  slug: option.option_slug,
                  costs: option.costs || 0,
                  images:
                    option.images && Object.entries(option.images).length > 0
                      ? Object.values(option.images)
                      : [],
                })
              );
            }
          }
        });
      }

      setAnswers(newAnswers);
    } else {
      // navigate('../enter-kms')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedData]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleContinue = () => {
    const payload: any[] = [];
    Object.keys(answers).forEach((key) => {
      if (typeof answers[key] === "string") {
        if (key === "additional-information") {
          payload.push({ question_slug: key, comments: [answers[key]] });
        } else {
          payload.push({ question_slug: key, options: [answers[key]] });
        }
      } else {
        if (key === "mechanical-and-roadworthy" && answers[key].length > 0) {
          payload.push({
            question_slug: key,
            options: answers[key].map((answer: TCheckbox) => answer.slug),
          });
        } else {
          payload.push({
            question_slug: key,
            options: answers[key].map((answer: TCheckbox) => answer.slug),
            costs: answers[key]
              .filter((answer: TCheckbox) => answer.costs !== null)
              .map((answer: TCheckbox) => ({
                cost_for: answer.slug,
                amount: answer.costs,
              })),
          });
        }
      }
    });

    const data = {
      answers: payload,
      type: "conditions",
    };

    mutate(data);
  };

  const handleToggleCheck = (categorySlug: string, optionSlug: string) => {
    if (!answers[categorySlug]) {
      setAnswers({
        ...answers,
        [categorySlug]: [{ slug: optionSlug, costs: null, images: [] }],
      });
    } else if (
      answers[categorySlug].findIndex(
        (val: TCheckbox) => val.slug === optionSlug
      ) > -1
    ) {
      setAnswers({
        ...answers,
        [categorySlug]: answers[categorySlug].filter(
          (val: TCheckbox) => val.slug !== optionSlug
        ),
      });
    } else {
      setAnswers({
        ...answers,
        [categorySlug]: [
          ...answers[categorySlug],
          { slug: optionSlug, costs: null, images: [] },
        ],
      });
    }
  };

  return (
    <div className="flex flex-col justify-between text-primary w-full h-[calc(100%-50px)]">
      <div className="absolute h-0 md:h-10 w-full left-0 border-b-[7px] border-softPeach">
        <div className="bg-primary h-[7px] mt-0 md:mt-33 w-3/4" />
      </div>
      <div className="flex-1 w-full flex flex-col overflow-auto px-5 mt-0 md:mt-[40px]">
        <div className="text-black text-sm pt-7">
          <ButtonLink onClick={handleGoBack}>
            {"< Back to Specifications"}
          </ButtonLink>
        </div>
        <h5 className="text-primary font-bold text-23 mt-4 mb-0">
          Vehicle Inspection
        </h5>
        <div>
          <label className="text-primary text-sm font-bold leading-10">
            STEP 2/3 - CONDITIONS
          </label>
          <p className="text-label text-sm font-normal">
            Please make sure all the information is correct. The more
            information you enter, the more accurate valuation you will receive.
          </p>
        </div>
        <div className="view-specification__content mt-3">
          <div>
            {formData
              .filter((category) => category.slug !== "cosmetic-condition")
              .map((category) => (
                <div className="mb-10" key={category.slug}>
                  {category.field_type === "switch" && (
                    <>
                      <p className="text-lg	text-lightGrey font-bold">
                        {category.title}:
                      </p>
                      <div className="grid grid-cols-2">
                        {category.options.map((option) => (
                          <FieldRadio
                            key={option.slug}
                            name={category.slug}
                            label={option.title}
                            checked={answers[category.slug] === option.slug}
                            onChange={() => {
                              setAnswers({
                                ...answers,
                                [category.slug]: option.slug,
                              });
                            }}
                          />
                        ))}
                      </div>
                    </>
                  )}
                  {category.field_type === "dropdown" && (
                    <>
                      <p className="ctext-lg	text-lightGrey font-bold">
                        {category.title}:
                      </p>
                      <FieldDropdown
                        placeholder="Select"
                        options={category.options.map((option) => ({
                          key: option.slug,
                          label: option.title,
                        }))}
                        value={answers[category.slug]}
                        onChange={(val) => {
                          setAnswers({
                            ...answers,
                            [category.slug]: val,
                          });
                        }}
                      />
                    </>
                  )}
                  {category.field_type === "checkbox" && (
                    <>
                      <p className="text-lg	text-lightGrey font-bold">
                        {category.title}
                      </p>
                      {category.options.map((option) => (
                        <div className="mt-6" key={option.slug}>
                          <FieldCheckbox
                            checked={
                              answers[category.slug] &&
                              answers[category.slug].findIndex(
                                (val: TCheckbox) => val.slug === option.slug
                              ) > -1
                            }
                            onChange={() =>
                              handleToggleCheck(category.slug, option.slug)
                            }
                            label={option.title}
                          />
                        </div>
                      ))}
                    </>
                  )}
                  {category.field_type === "textbox" && (
                    <FieldTextarea
                      title={category.title}
                      placeholder={
                        "Enter any comments regarding the condition of your vehicle"
                      }
                      value={answers[category.slug]}
                      onChange={(evt) => {
                        setAnswers({
                          ...answers,
                          [category.slug]: evt.target.value,
                        });
                      }}
                    />
                  )}
                </div>
              ))}
          </div>

          <div className="mb-10">
            {cosmeticForm && (
              <>
                <p className="text-lg	text-lightGrey font-bold">
                  Does your vehicle have any damage?
                </p>
                <label className="text-label text-sm font-normal">
                  Add Photos In the next step
                </label>
                {cosmeticForm.options.map((option) => (
                  <div className="mt-6" key={option.slug}>
                    <FieldCheckbox
                      checked={
                        answers[cosmeticForm.slug] &&
                        answers[cosmeticForm.slug].findIndex(
                          (val: TCheckbox) => val.slug === option.slug
                        ) > -1
                      }
                      onChange={() =>
                        handleToggleCheck(cosmeticForm.slug, option.slug)
                      }
                      label={option.title}
                    />
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
        <div className="w-full flex justify-center flex-col items-center mb-2">
          <div className="w-[310px] pb-2">
            <Button
              type="submit"
              isLoading={isLoading}
              onClick={handleContinue}
            >
              <span className="font-bold">Continue</span>
            </Button>
          </div>
          <div className="flex flex-row items-center space-x-1 justify-center">
            <p className="text-label font-norma text-xs">powered by</p>
            <SvgImage name="MPLogo" className="mt-1" width={116} height={39} />
          </div>
        </div>
      </div>
    </div>
  );
};
