import { useMutation, useQuery } from "@tanstack/react-query";
import imageCompression from "browser-image-compression";
import FileSaver from "file-saver";
import { ChangeEvent, useContext, useRef, useState } from "react";

import { ModalConfirm } from "components/app";
import { LoadingTable } from "components/Loading/LoadingTable";
import { Button, Modal } from "components/shared";

import { AuthContext } from "context/AuthContext";

import {
  ERROR_MESSAGE,
  showErrorToast,
  showSuccessToast,
  StorageKeys,
} from "utils";

import { IDocument } from "types";

import { CustomerService } from "api/client";

import { Table } from "./Table";

interface DocumentsProps {
  customerID?: string;
}

export const Documents = ({ customerID }: DocumentsProps) => {
  const { userID } = useContext(AuthContext);
  const fileInputField = useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState<
    IDocument | undefined
  >(undefined);

  const { data, refetch, isInitialLoading, isError, isSuccess } = useQuery<
    { data: IDocument[] },
    Error
  >(
    [
      `${StorageKeys.WholesaleDealershipCustomerDocumentsList}_${userID}`,
      customerID,
    ],
    CustomerService.getCustomerDocuments,
    {
      enabled: !!customerID,
    }
  );

  const { mutate: deleteDocument, isLoading: deletingDocument } = useMutation(
    (documentID: string) =>
      CustomerService.deleteCustomerDocument(customerID || "", documentID),
    {
      onSuccess: () => {
        showSuccessToast("Successfully Removed");
        refetch();
      },
      onError: () => {
        showErrorToast("Failed to Remove");
      },
    }
  );

  const onUploadClick = () => {
    if (fileInputField.current) {
      fileInputField.current.click();
    }
  };

  const onUploadDocument = async (formData: FormData) => {
    if (customerID) {
      await CustomerService.uploadDocument(formData, customerID);
    }
  };

  const onFileDownload = async (documentID: string, documentName: string) => {
    try {
      const response = await CustomerService.downloadCustomerDocument(
        customerID || "",
        documentID
      );

      const url = URL.createObjectURL(new File([response], documentName));

      FileSaver.saveAs(url, documentName);

      URL.revokeObjectURL(url);
    } catch (error) {
      showErrorToast("Download Failed");
      console.error("Error downloading File:", error);
    }
  };

  const onFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (e.target.files?.length) {
        setIsUploading(true);

        const file = e.target.files[0];
        const formData = new FormData();

        if (file.size > 4000 * 1000) {
          showErrorToast("Maximum File Size Exceeded");
          return;
        }

        if (file.type.split("/")[0] === "image") {
          const options = {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          };
          const compressedFile = await imageCompression(file, options);

          formData.append("files[]", compressedFile, file.name);
        } else {
          formData.append("files[]", file);
        }

        await onUploadDocument(formData);

        showSuccessToast("Upload Successful");
        refetch();
      }
    } catch (error) {
      showErrorToast("Upload Failed");
    } finally {
      setIsUploading(false);
    }
  };

  const getContent = () => {
    if (isInitialLoading) return <LoadingTable />;

    if (isError)
      return <div className="text-sm text-center">{ERROR_MESSAGE}</div>;

    if (isSuccess)
      if (!data?.data?.length)
        return (
          <div className="py-16 text-label text-lg">
            No Documents Uploaded Yet.
          </div>
        );

    return (
      <Table
        data={data?.data}
        onFileDownload={onFileDownload}
        setDocumentToDelete={setDocumentToDelete}
        setOpenDeleteConfirmModal={setOpenDeleteConfirmModal}
      />
    );
  };

  return (
    <div className="mt-10">
      <span className="text-lg text-txt font-semibold mb-4">Documents</span>
      <div className="mt-5 flex gap-7 items-center">
        <Button
          type="button"
          className="shadow-md !w-100"
          onClick={onUploadClick}
          isLoading={isUploading}
        >
          <span className="uppercase text-sm font-bold">Upload</span>
        </Button>
        <input
          type="file"
          ref={fileInputField}
          onChange={onFileUpload}
          className="hidden"
          accept="image/*, application/pdf"
        />
        <div>
          <div className="text-xs text-label">Maximum Upload File Size 4MB</div>
          <div className="text-xs text-label">
            Allowed File Type: .pdf, .jpg, .jpeg, .png
          </div>
        </div>
      </div>
      <div className="mt-10">
        {getContent()}
        <Modal
          open={openDeleteConfirmModal}
          size="auto"
          closeModal={() => {
            setOpenDeleteConfirmModal(false);
            setDocumentToDelete(undefined);
          }}
        >
          <ModalConfirm
            title="Remove Document?"
            description="This action will remove this file permanently."
            onCancel={() => {
              setOpenDeleteConfirmModal(false);
              setDocumentToDelete(undefined);
            }}
            onConfirm={() => {
              documentToDelete && deleteDocument(documentToDelete.id);
              setOpenDeleteConfirmModal(false);
              setDocumentToDelete(undefined);
            }}
            isLoading={deletingDocument}
          />
        </Modal>
      </div>
    </div>
  );
};
