import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  Breadcrumbs,
  Button,
  ButtonLink,
  FieldText,
  FieldTextarea,
  Prompt,
  SvgImage,
  TextError,
} from "components/shared";

import { AuthContext } from "context/AuthContext";
import { SearchContext } from "context/SearchContext";

import {
  FLOW_TYPE,
  VEHICLE_ROUTES,
  VEHICLE_SUMMARY_TITLE,
  getVehicleInformationRoute,
} from "utils";

import { ISpecification, IValue, IVehicleSummary } from "types";

import { DealershipInspectionService, VehicleService } from "api/client";

interface SpecificationProps {
  flow: FLOW_TYPE;
  route: VEHICLE_ROUTES;
}

export const Specification = ({ flow, route }: SpecificationProps) => {
  const queryClient = useQueryClient();
  const { userID } = useContext(AuthContext);
  const navigate = useNavigate();
  const params = useParams();

  const { title, path } = getVehicleInformationRoute(flow);
  const isValuedRoute = route === VEHICLE_ROUTES.Unvalued;

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isDirty },
  } = useForm<ISpecification>();
  const { setIsUpdated } = useContext(SearchContext);

  const { data: dealershipInspectionData } = useQuery<
    { data: IVehicleSummary },
    Error
  >(
    [`fetchDealershipInspection_${userID}`, params.id],
    DealershipInspectionService.getDealershipSummary
  );
  const { mutate, isLoading } = useMutation(
    (data: { specification: ISpecification; type: string }) =>
      VehicleService.updateVehicle(data, params.id || ""),
    {
      onSuccess: () => {
        toast.success("Saved Successfully", {
          autoClose: 2000,
          theme: "colored",
        });
        queryClient.invalidateQueries([`retail-summary-${params.id}`]);
        setIsUpdated(params.id || "", true);
        navigate(-1);
        reset({}, { keepDirty: false, keepValues: true });
      },
      onError: () => {
        toast.error("Failed to Save", {
          autoClose: 2000,
          theme: "colored",
        });
      },
    }
  );

  const specification = useMemo(() => {
    if (dealershipInspectionData) {
      return dealershipInspectionData.data.vehicle.specification;
    }

    return undefined;
  }, [dealershipInspectionData]);

  useEffect(() => {
    if (specification) {
      Object.keys(specification).forEach((key) => {
        setValue(
          key as keyof ISpecification,
          (
            specification?.[key as keyof ISpecification] as unknown as Record<
              keyof IValue,
              IValue
            >
          ).value
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specification]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const onSave = (data: ISpecification) => {
    const uploadData = {
      specification: data,
      type: "specification",
    };

    mutate(uploadData);
  };

  return (
    <div className="dashboard__container">
      <div className="dashboard__header">
        <Breadcrumbs
          paths={[
            {
              title: title,
              route: path,
            },
            {
              title: route,
              route: isValuedRoute ? path : `${path}/${route?.toLowerCase()}`,
            },
            {
              title: VEHICLE_SUMMARY_TITLE,
              route: isValuedRoute
                ? `${path}/valuation/${params.id}/summary`
                : `${path}/${route?.toLowerCase()}/${params.id}/summary`,
            },
            {
              title: "Vehicle Information",
              route: isValuedRoute
                ? `${path}/valuation/${params.id}/summary`
                : `${path}/${route?.toLowerCase()}/${params.id}/summary`,
            },
            {
              title: "Specification",
              route: isValuedRoute
                ? `${path}/${params.id}/specification`
                : `${path}/${route?.toLowerCase()}/${params.id}/specification`,
            },
          ]}
        />
        <ButtonLink onClick={handleGoBack}>
          <SvgImage name="LeftRoundedIcon" />
        </ButtonLink>
      </div>
      <div className="dashboard__content">
        <div className="specification">
          <div className="specification__content">
            <div>
              <FieldText
                title="Vehicle Year*"
                type="text"
                {...register("year", {
                  required: specification?.year.requirement === "required",
                })}
                placeholder="Enter year"
              />
              {errors.year && <TextError error="Vehicle Year is required" />}
            </div>

            <div>
              <FieldText
                title="Body Name*"
                type="text"
                {...register("body_name", {
                  required: specification?.body_name.requirement === "required",
                })}
                placeholder="Enter body name"
              />
              {errors.body_name && <TextError error="Body Name is required" />}
            </div>

            <div>
              <FieldText
                title="Seats*"
                type="text"
                {...register("seats", {
                  required: specification?.seats.requirement === "required",
                })}
                placeholder="Enter seats"
              />
              {errors.seats && <TextError error="Seats is required" />}
            </div>

            <div>
              <FieldText
                title="Vehicle Make*"
                type="text"
                {...register("make", {
                  required: specification?.make.requirement === "required",
                })}
                placeholder="Enter make"
              />
              {errors.make && <TextError error="Make is required" />}
            </div>

            <div>
              <FieldText
                title="Fuel*"
                type="text"
                {...register("fuel", {
                  required: specification?.fuel.requirement === "required",
                })}
                placeholder="Enter Fuel"
              />
              {errors.fuel && <TextError error="Fuel is required" />}
            </div>

            <div>
              <FieldText
                title="Doors*"
                type="text"
                {...register("doors", {
                  required: specification?.doors.requirement === "required",
                })}
                placeholder="Enter Doors"
              />
              {errors.doors && <TextError error="Doors is required" />}
            </div>

            <div>
              <FieldText
                title="Vehicle Model*"
                type="text"
                {...register("model", {
                  required: specification?.model.requirement === "required",
                })}
                placeholder="Enter model"
              />
              {errors.model && <TextError error="Model is required" />}
            </div>

            <div>
              <FieldText
                title="Drive*"
                type="text"
                {...register("drive", {
                  required: specification?.drive.requirement === "required",
                })}
                placeholder="Enter drive"
              />
              {errors.drive && <TextError error="Drive is required" />}
            </div>

            <div>
              <FieldText
                title="Vehicle Type*"
                type="text"
                {...register("vehicle_type", {
                  required:
                    specification?.vehicle_type.requirement === "required",
                })}
                placeholder="Enter vehicle type"
              />
              {errors.vehicle_type && (
                <TextError error="Vehicle type is required" />
              )}
            </div>

            <div>
              <FieldText
                title="Series*"
                type="text"
                {...register("series", {
                  required: specification?.series.requirement === "required",
                })}
                placeholder="Enter Series"
              />
              {errors.series && <TextError error="Series is required" />}
            </div>

            <div>
              <FieldText
                title="Cylinders*"
                type="text"
                {...register("cylinders", {
                  required: specification?.cylinders.requirement === "required",
                })}
                placeholder="Enter cylinders"
              />
              {errors.cylinders && <TextError error="Cylinders is required" />}
            </div>

            <div>
              <FieldText
                title="Engine Type*"
                type="text"
                {...register("engine", {
                  required: specification?.engine.requirement === "required",
                })}
                placeholder="Enter fuel type"
              />
              {errors.engine && <TextError error="Engine type is required" />}
            </div>

            <div>
              <FieldText
                title="Badge*"
                type="text"
                {...register("variant", {
                  required: specification?.variant.requirement === "required",
                })}
                placeholder="Enter Badge"
              />
              {errors.variant && <TextError error="Badge is required" />}
            </div>

            <div>
              <FieldText
                title="Induction*"
                type="text"
                {...register("induction", {
                  required:
                    specification?.induction?.requirement === "required",
                })}
                placeholder="Enter induction"
              />
              {errors.induction && <TextError error="Induction is required" />}
            </div>
            <div>
              <FieldText
                title="Engine Size*"
                type="text"
                {...register("engine_size", {
                  required:
                    specification?.engine_size?.requirement === "required",
                })}
                placeholder="Enter Engine Size"
              />
              {errors?.engine_size && (
                <TextError error="Engine Size is required" />
              )}
            </div>

            <div>
              <FieldText
                title="Body Type*"
                type="text"
                {...register("body_type", {
                  required: specification?.body_type.requirement === "required",
                })}
                placeholder="Enter body type"
              />
              {errors.body_type && <TextError error="Body type is required" />}
            </div>

            <div>
              <FieldText
                title="Transmission*"
                type="text"
                {...register("transmission", {
                  required:
                    specification?.transmission.requirement === "required",
                })}
                placeholder="Enter transmission"
              />
              {errors.transmission && (
                <TextError error="Transmission is required" />
              )}
            </div>

            <div>
              <FieldText
                title="Colour*"
                type="text"
                {...register("colour", {
                  required: specification?.colour.requirement === "required",
                })}
                placeholder="Enter colour"
              />
              {errors.colour && <TextError error="Colour is required" />}
            </div>

            <div className="col-span-3">
              <FieldText
                title="Model Description"
                type="text"
                {...register("model_name")}
                placeholder="Enter model information"
              />
            </div>

            <div className="col-span-3">
              <FieldTextarea
                title="Specification Info"
                type="text"
                {...register("comment", { required: false })}
                placeholder="Enter specification info"
              />
            </div>
          </div>

          <div className="specification__tool">
            <Button
              type="submit"
              isLoading={isLoading}
              onClick={handleSubmit(onSave)}
            >
              <span className="specification__tool__txt">Save</span>
            </Button>
          </div>
        </div>
      </div>
      <Prompt isDirty={isDirty} />
    </div>
  );
};
