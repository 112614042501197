import ClipLoader from "react-spinners/ClipLoader";

export const LoadingSpinner = ({
  color = "#0c2146",
  size = 20,
}: {
  color?: string;
  size?: number;
}) => {
  return <ClipLoader color={color} size={size} />;
};
