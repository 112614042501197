import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ModalConfirm } from "components/app";
import { Button, ButtonLink, Modal } from "components/shared";

import { showErrorToast, showSuccessToast } from "utils";

import { DealershipInspectionService, VehicleService } from "api/client";

interface MoreActionsProps {
  contractID: string;
  canDeleteVehicle?: boolean;
}

export const MoreActions = ({
  contractID,
  canDeleteVehicle = true,
}: MoreActionsProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [showAddStockListModal, setShowAddStockListModal] = useState(false);

  const { mutate: handleRemoveDraft, isLoading: removeDraftIsLoading } =
    useMutation(() => VehicleService.removeVehicle(contractID), {
      onSuccess: () => {
        showSuccessToast("Vehicle Removed");
        navigate(-1);
      },
      onError: () => {
        showErrorToast("Failed to Remove");
      },
    });

  const { mutate: addToStocklist, isLoading: addToStocklistIsLoading } =
    useMutation(
      () =>
        DealershipInspectionService.addVehicleToStocklist(contractID, {
          inventory_type: "stocklist",
        }),
      {
        onSuccess: () => {
          showSuccessToast("Successfully Moved");
          handleAddToStocklistSuccess();
        },
        onError: () => {
          showErrorToast("Failed to Move");
        },
      }
    );

  const handleSaveDraft = () => {
    navigate(-1);
  };

  const handleAddToStocklistSuccess = () => {
    pathname.includes("retail-dealership")
      ? navigate(`/retail-dealership/stocklist/${contractID}/summary`)
      : navigate(`/wholesale-dealership/stocklist/${contractID}/summary`);
    setShowAddStockListModal(false);
  };

  return (
    <div className="dashboard__content__footer section">
      <p className="dashboard__content__footer__title">More Actions</p>
      <div className="my-2">
        <div className="flex mb-6 gap-5">
          <div className="btn__draft !w-1/2">
            <Button onClick={() => setShowAddStockListModal(true)}>
              <span>ADD TO STOCKLIST</span>
            </Button>
            <Modal
              open={showAddStockListModal}
              size="auto"
              closeModal={() => setShowAddStockListModal(false)}
              isCenter
            >
              <ModalConfirm
                title="Add to Stocklist?"
                description="This action will move this vehicle to your Stocklist as Acquired."
                cancelButtonLabel="NO"
                confirmButtonLabel="YES"
                onCancel={() => setShowAddStockListModal(false)}
                onConfirm={addToStocklist}
                isLoading={addToStocklistIsLoading}
              />
            </Modal>
          </div>
          <div className="btn__draft !w-1/2">
            <Button type="button" onClick={handleSaveDraft}>
              <span>SAVE AS DRAFT</span>
            </Button>
          </div>
        </div>
        {canDeleteVehicle && (
          <div className="flex">
            <ButtonLink onClick={() => setShowDeleteConfirmModal(true)}>
              <span className="text-darkRed underline font-semibold">
                Delete This Vehicle
              </span>
            </ButtonLink>
            <Modal
              open={showDeleteConfirmModal}
              size="auto"
              canClose
              closeModal={() => setShowDeleteConfirmModal(false)}
            >
              <ModalConfirm
                title="Delete Vehicle?"
                description="If you delete this vehicle, you will loose all its information."
                onCancel={() => setShowDeleteConfirmModal(false)}
                onConfirm={handleRemoveDraft}
                isLoading={removeDraftIsLoading}
              />
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
};
