import { useEffect, useState } from "react";

import { useCallbackPrompt } from "hooks";

import { ModalRouteChange } from "../../app/Modal/ModalRouteChange";
import { Modal } from "../Modal/Modal";

interface PromptProps {
  isDirty: boolean;
}

export const Prompt = ({ isDirty }: PromptProps) => {
  const [showRouteChangeModal, setShowRouteChangeModal] = useState(false);
  const { showPrompt, confirmNavigation, cancelNavigation } =
    useCallbackPrompt(isDirty);

  useEffect(() => {
    if (showPrompt && isDirty) {
      setShowRouteChangeModal(true);
    }
  }, [showPrompt, isDirty, showRouteChangeModal]);

  return (
    <Modal
      size="auto"
      open={!!showPrompt && showRouteChangeModal}
      closeModal={() => {
        setShowRouteChangeModal(false);
        cancelNavigation();
      }}
    >
      <ModalRouteChange
        onConfirm={confirmNavigation}
        onCancel={cancelNavigation}
      />
    </Modal>
  );
};
