import { Tab } from "@headlessui/react";
import classNames from "classnames";

interface TabHeaderProps {
  id: number;
  title: string;
  disabled?: boolean;
}

export const TabHeader = ({ id, title, disabled }: TabHeaderProps) => (
  <Tab
    key={id}
    className={({ selected }) =>
      classNames(
        "rounded-t-lg py-2.5 px-6 text-xs font-bold whitespace-nowrap text-label ",
        {
          " hover:bg-waikawaGrey hover:text-white": !disabled,
        },
        {
          "bg-waikawaGrey text-white": selected,
        },
        {
          " text-label cursor-not-allowed hover:": disabled,
        }
      )
    }
    disabled={disabled}
  >
    {title}
  </Tab>
);
