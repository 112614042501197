import { useMutation, useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { useContext, useEffect, useMemo, useState } from "react";

import { LoadItem } from "components/app";
import { ConfirmationModal } from "components/Modal/ConfirmationModal";
import { ModalMotorMarketBidsHistory } from "components/Modal/MotorMarketBids";
import { Button, FieldCheckbox } from "components/shared";

import { AuthContext } from "context/AuthContext";

import {
  ERROR_MESSAGE,
  formatNumber,
  MOTOR_MARKET_ENDED,
  showErrorToast,
  showSuccessToast,
} from "utils";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import { IVehicleSummary } from "types";
import { IMotorMarket, IMotorMarketBidDetails } from "types/IMotorMarket";

import { ReactComponent as EyeIcon } from "assets/images/eye-icon.svg";

import { MotorMarketService } from "api/client";

interface BidHistoryProps {
  vehicleAuctionID?: string;
  selectedVehicle: IMotorMarket | IVehicleSummary;
  panelView?: boolean;
  refetchSummary?: () => void;
}

export const BidsHistory = ({
  vehicleAuctionID,
  selectedVehicle,
  panelView,
  refetchSummary,
}: BidHistoryProps) => {
  const { userID, hasPermission } = useContext(AuthContext);

  const [selectedBid, setSelectedBid] = useState<
    IMotorMarketBidDetails | undefined
  >(undefined);
  const [currentViewingBid, setCurrentViewingBid] = useState<
    IMotorMarketBidDetails | undefined
  >(undefined);
  const [isConfirmSaleModalOpen, setIsConfirmSaleModalOpen] = useState(false);
  const [isBidsLogOpen, setIsBidsLogOpen] = useState(false);

  const { status } = selectedVehicle;

  const { data, isLoading, isError, isSuccess, refetch } = useQuery<
    { data: IMotorMarketBidDetails[] },
    Error
  >(
    [`motormarket-seller-summary-bids-${userID}`, vehicleAuctionID],
    MotorMarketService.getBids
  );

  const { mutate: sellVehicle, isLoading: isSellingVehicle } = useMutation(
    (auctionBidID: string) =>
      MotorMarketService.selectAuctionWinner(auctionBidID),
    {
      onSuccess: () => {
        setIsConfirmSaleModalOpen(false);
        showSuccessToast("Sold Successfully");
        refetch();
        refetchSummary && refetchSummary();
      },
      onError: () => {
        showErrorToast("Failed To Sell");
      },
    }
  );

  const hasWinner = useMemo(() => {
    return !!data?.data?.find(({ is_winner }) => is_winner);
  }, [data?.data]);

  useEffect(() => {
    data?.data.length === 1 && setSelectedBid(data?.data[0]);
  }, [data?.data]);

  const canSell = hasPermission(
    PermissionCategory.MOTOR_MARKET_SELLING,
    PermissionAction.SELL
  );

  const renderBids = () => {
    if (isLoading) {
      return <LoadItem />;
    }

    if (isError) return <div>{ERROR_MESSAGE}</div>;

    if (isSuccess && data?.data.length) {
      return data?.data?.map((bid: IMotorMarketBidDetails) => {
        return (
          <div key={bid.id} className="py-3 border-b border-softPeach">
            <div className="flex justify-between">
              <div className="flex flex-row">
                <FieldCheckbox
                  checked={bid.id === selectedBid?.id || bid.is_winner}
                  label={bid.dealership.name}
                  disabled={status.name !== MOTOR_MARKET_ENDED.key || !canSell}
                  onChange={() => {
                    bid.id === selectedBid?.id
                      ? setSelectedBid(undefined)
                      : setSelectedBid(bid);
                  }}
                />
                <div className="flex items-start ml-2 mt-[-2px] text-base">
                  <div
                    className="cursor-pointer mt-1 inline-block"
                    onClick={() => {
                      setCurrentViewingBid(bid);
                      setIsBidsLogOpen(true);
                    }}
                  >
                    <EyeIcon />
                  </div>
                </div>
              </div>
              <div className="text-base">${formatNumber(bid.bid_amount)}</div>
            </div>
            <div className="flex justify-between text-sm">
              <div className="text-lightGrey">{bid.latest_bidder.name}</div>
              <div className="text-lightGrey">{bid.last_bid_date}</div>
            </div>
            <div className="flex justify-between text-sm">
              <div className="text-lightGrey">Contact Number</div>
              <div className="text-lightGrey">{bid.latest_bidder.phone}</div>
            </div>
          </div>
        );
      });
    }

    if (isSuccess && !data?.data.length) {
      return (
        <div className="text-left mt-8 mb-4">
          There are No Bids On This Vehicle
        </div>
      );
    }
  };

  return (
    <div>
      <div className="py-5 px-4 bg-sidebarbg rounded-b-10">
        {panelView && (
          <div className="mb-3">
            <div className="font-medium text-lg">Bids History</div>
            <p className="text-label text-sm">
              Make sure to contact the buyer to discuss terms of payment and
              other important information before selling the car.
            </p>
          </div>
        )}
        <div
          className={classNames({ "flex flex-col max-w-[50%]": !panelView })}
        >
          {renderBids()}
        </div>
        {panelView && canSell && (
          <div>
            <Button
              type="button"
              className="shadow-md mt-5 mb-1 !w-36"
              disabled={
                (status.name !== MOTOR_MARKET_ENDED.key && !hasWinner) ||
                !data?.data?.length ||
                !selectedBid
              }
              onClick={() => setIsConfirmSaleModalOpen(true)}
            >
              <span className="font-bold text-sm">SELL</span>
            </Button>
          </div>
        )}
      </div>
      <ModalMotorMarketBidsHistory
        bidderID={(canSell ? currentViewingBid?.id : vehicleAuctionID) || ""}
        isSeller={canSell}
        isOpen={isBidsLogOpen}
        onClose={() => {
          setIsBidsLogOpen(false);
          setCurrentViewingBid(undefined);
        }}
      />
      <ConfirmationModal
        title={"Confirm Sale?"}
        description={
          "Please ensure payment has been processed before proceeding with this action."
        }
        isOpen={isConfirmSaleModalOpen}
        onClose={() => setIsConfirmSaleModalOpen(false)}
        content={
          <div className="flex flex-col space-y-4 my-8">
            <div className="flex flex-col">
              <label className="text-sm">Selling To:</label>
              <span className="font-semibold">
                {selectedBid?.dealership.name}
              </span>
            </div>
            <div className="flex flex-col">
              <label className="text-sm">Selling Price:</label>
              <span className="font-medium">
                {`$${formatNumber(selectedBid?.bid_amount)}`}
              </span>
            </div>
          </div>
        }
        onConfirm={() => sellVehicle(selectedBid?.id || "")}
        isLoading={isSellingVehicle}
        confirmButtonLabel="YES"
        cancelButtonLabel="NO"
      />
    </div>
  );
};
