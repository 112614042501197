import { useState, KeyboardEvent } from "react";

import { ModalContainer } from "components/ModalContainer";
import { Button } from "components/shared";

import {
  showErrorToast,
  ERROR_NOTES_255_CHARACTERS_LIMIT,
  MAX_CHARACTERS_NOTES,
} from "utils";

import { VehicleComment } from "types";

import { Note } from "../../VehicleNotes/Note/Note";

interface NotesModalProps {
  data: Array<VehicleComment>;
  isOpen: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onSave: (note: string) => void;
  canComment?: boolean;
}

export const NotesModal = ({
  data,
  isOpen,
  isLoading,
  onClose,
  onSave,
  canComment = true,
}: NotesModalProps) => {
  const [note, setNote] = useState("");
  const [isEditingNote, setIsEditingNote] = useState(false);
  const [hasCharacterLimit, setHasCharacterLimit] = useState(false);

  const onSaveComment = () => {
    if (note.length > MAX_CHARACTERS_NOTES)
      return showErrorToast(ERROR_NOTES_255_CHARACTERS_LIMIT);

    onSave(note);
    setNote("");
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (note === "" || hasCharacterLimit) return;

      onSave(note);
      setNote("");
    }
  };

  return (
    <ModalContainer isOpen={isOpen} onClose={onClose} title="Notes">
      <div className="w-[430px] max-h-[85vh]">
        <input
          className="text-left h-9 w-full rounded-4 border-solid border-grey border pl-4 focus:outline-none"
          placeholder="Enter your notes here"
          value={note}
          onChange={(e) => {
            if (e.target.value.length > MAX_CHARACTERS_NOTES)
              setHasCharacterLimit(true);
            else {
              setHasCharacterLimit(false);
              setNote(e.target.value);
            }
          }}
          onClick={() => setIsEditingNote(true)}
          onKeyDown={onKeyDown}
          disabled={!canComment}
        />
        {hasCharacterLimit && (
          <span className="text-darkRed font-normal text-sm">
            Character limit reached.
          </span>
        )}
        {isEditingNote && (
          <div className="flex items-center gap-5 mt-4 mb-2">
            <Button
              type="button"
              className="!w-24 !bg-white shadow-md"
              onClick={() => {
                setNote("");
              }}
              disabled={!canComment}
            >
              <span className="text-primary font-bold">CANCEL</span>
            </Button>
            <Button
              type="button"
              className="!w-24"
              disabled={!note || hasCharacterLimit || !canComment}
              onClick={onSaveComment}
              isLoading={isLoading}
            >
              <span className="font-bold">SAVE</span>
            </Button>
          </div>
        )}
        <div className="max-h-[calc(85vh-200px)] overflow-y-auto">
          {data?.map((note, index) => (
            <Note note={note} key={`${note.commented_at}-${index}`} />
          ))}
        </div>
      </div>
    </ModalContainer>
  );
};
