import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";

import {
  DealershipInformation,
  Footer,
  LoadVehicleSummary,
  SellerInformation,
  SummaryHeader,
  ValuationsSection,
  VehicleInformation,
} from "components/app";
import { ErrorMessage } from "components/ErrorMessage";
import { DeclineRequestModal } from "components/Modal/DeclineRequestModal";
import { NoPermissionMessage } from "components/NoPermissionMessage";
import { Banner, Breadcrumbs, Button } from "components/shared";
import { VehicleComments } from "components/VehicleNotes";
import { VehiclePhotoContainer } from "components/VehiclePhotoContainer";

import { AuthContext } from "context/AuthContext";

import {
  FLOW_TYPE,
  PENDING,
  REJECTED,
  showErrorToast,
  showSuccessToast,
  VEHICLE_SUMMARY_TITLE,
  WHOLESALE_DEALERSHIP_MENU,
} from "utils";
import { isValuationContractType } from "utils/inspections";
import { getPageTitle, SECTIONS } from "utils/pageSections";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import { IVehicleSummaryResponse, NavigationMenuLabel } from "types";

import { ValuationService } from "api/client";

import "./styles.scss";

export const WholesaleDealershipSummary = () => {
  const { userID, hasPermission } = useContext(AuthContext);
  const params = useParams();
  const navigate = useNavigate();
  const { id: contractId, requestId } = params;
  const [openDeclineModal, setOpenDeclineModal] = useState(false);

  const canView = hasPermission(PermissionCategory.WHOLESALE_INSPECTIONS, [
    PermissionAction.VIEW,
    PermissionAction.VIEW_ANY,
  ]);

  const { data, isLoading, isError, refetch } =
    useQuery<IVehicleSummaryResponse>(
      [
        `wholesale-summary-${contractId}`,
        contractId,
        { with: "dealership,owner,assessments,valuations,expenses,images" },
        requestId,
        "valuation",
      ],
      ValuationService.getValuationRequestSummary,
      {
        refetchOnWindowFocus: false,
        enabled: canView,
      }
    );

  const { mutate: declineValuationRequest } = useMutation(
    () =>
      ValuationService.declineValuationRequest(
        data?.data.contract_id ?? "",
        data?.data.request_id ?? ""
      ),
    {
      onSuccess: () => {
        showSuccessToast("Request Declined");
        setOpenDeclineModal(false);
        navigate(`../valued/${contractId}/${requestId}/summary`);
      },
      onError: () => {
        showErrorToast("Failed to Decline");
      },
    }
  );

  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem("SCROLL_POSITION");
    if (savedScrollPosition) {
      document
        .getElementsByClassName("dashboard")[0]
        .scrollTo({ behavior: "smooth", top: parseInt(savedScrollPosition) });
      sessionStorage.removeItem("SCROLL_POSITION");
    }

    return () => {
      document.getElementsByClassName("dashboard")[0].scrollTo({ top: 0 });
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{getPageTitle("Unvalued", SECTIONS.WHOLESALE_DEALERSHIP)}</title>
      </Helmet>
      <div className="dashboard__container">
        <div className="dashboard__header">
          <Breadcrumbs
            paths={[
              {
                title: NavigationMenuLabel.WHOLESALE_DEALERSHIP,
                route: WHOLESALE_DEALERSHIP_MENU[0].route,
              },
              { title: "Unvalued", route: WHOLESALE_DEALERSHIP_MENU[0].route },
              {
                title: VEHICLE_SUMMARY_TITLE,
                route: `${WHOLESALE_DEALERSHIP_MENU[0].route}/valuation/${contractId}/${requestId}/summary`,
              },
            ]}
          />
        </div>
        <div className="dashboard__content">
          {!canView ? (
            <NoPermissionMessage
              viewPermissionCategory={PermissionCategory.WHOLESALE_INSPECTIONS}
            />
          ) : (
            <>
              {isLoading && <LoadVehicleSummary />}
              {isError && <ErrorMessage />}
              {data && !isError && (
                <>
                  <div className="dashboard__content--left">
                    <SummaryHeader
                      data={data.data}
                      onRefresh={refetch}
                      flow={FLOW_TYPE.WHOLESALE_DEALERSHIP}
                    />
                    <VehicleInformation
                      data={data.data}
                      defaultOpen
                      flow={FLOW_TYPE.WHOLESALE_DEALERSHIP}
                      refetch={refetch}
                      isLocked={
                        !hasPermission(
                          PermissionCategory.WHOLESALE_INSPECTIONS,
                          PermissionAction.UPDATE
                        )
                      }
                    />
                    <DealershipInformation data={data.data} />
                    <SellerInformation
                      data={data.data}
                      flow={
                        data?.data?.owner?.id
                          ? FLOW_TYPE.RETAIL_DEALERSHIP
                          : FLOW_TYPE.WHOLESALE_DEALERSHIP
                      }
                    />
                    <VehiclePhotoContainer data={data.data} />
                  </div>
                  <div className="dashboard__content--right">
                    <Banner
                      name={data.data.status.name}
                      label={data.data.status.label}
                    />
                    {data.data.status.name !== REJECTED.key && (
                      <ValuationsSection data={data.data} onRefresh={refetch} />
                    )}
                    {data.data.status.label === PENDING.label &&
                      isValuationContractType(data.data) &&
                      hasPermission(
                        PermissionCategory.WHOLESALE_VALUATIONS,
                        PermissionAction.CREATE
                      ) && (
                        <div className="w-full">
                          <Button
                            variant="secondary"
                            onClick={() => setOpenDeclineModal(true)}
                          >
                            <b>DECLINE REQUEST</b>
                          </Button>
                        </div>
                      )}
                    <Footer
                      data={data.data}
                      flow={FLOW_TYPE.WHOLESALE_DEALERSHIP}
                      canSellVehicle={hasPermission(
                        isValuationContractType(data.data)
                          ? PermissionCategory.WHOLESALE_VALUATIONS
                          : PermissionCategory.WHOLESALE_INSPECTIONS,
                        PermissionAction.SELL
                      )}
                      onRefresh={refetch}
                    />
                    <VehicleComments contractID={data.data?.contract_id} />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <DeclineRequestModal
        open={openDeclineModal}
        onConfirm={declineValuationRequest}
        onCancel={() => setOpenDeclineModal(false)}
      />
    </>
  );
};
