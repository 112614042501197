import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import { LoadItem } from "components/app";

import { IDirectOfferBuyer } from "types";

import { DirectOfferService } from "api/client/DirectOfferService";

import { DirectOfferDealershipSelectionItem } from "./DirectOfferDealershipSelectionItem";

type DirectOfferDetailsProps = {
  contractID: string;
};

export const DirectOfferDetails = ({ contractID }: DirectOfferDetailsProps) => {
  const { data: { data } = {}, isLoading } = useQuery<
    { data: IDirectOfferBuyer[] },
    Error
  >(["direct-offer-dealeships", contractID], DirectOfferService.getBuyers, {
    staleTime: 0,
  });

  const directOfferWithBuyers = useMemo(() => {
    return data?.filter(
      (buyer: IDirectOfferBuyer) => buyer?.offer?.offer_amount
    );
  }, [data]);

  return (
    <div className="mt-5">
      {isLoading ? (
        <LoadItem />
      ) : (
        directOfferWithBuyers?.map((dealership) => (
          <DirectOfferDealershipSelectionItem
            key={dealership.id}
            buyer={dealership}
          />
        ))
      )}
    </div>
  );
};
