import { Button } from "components/shared";

interface DownloadContainerProps {
  isLoading: boolean;
  onDownload: () => void;
}

export const DownloadContainer = ({
  isLoading,
  onDownload,
}: DownloadContainerProps) => (
  <div key="initial-content" className="flex flex-col gap-10 m-4">
    <p className="text-sm text-label">
      Download VIN specific factory options for this vehicle.
    </p>
    <Button
      className="!w-240 font-bold"
      isLoading={isLoading}
      onClick={onDownload}
    >
      DOWNLOAD FACTORY OPTIONS
    </Button>
  </div>
);
