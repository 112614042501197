import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext, useState } from "react";

import { InformativeModal } from "components/Modal/InformativeModal";
import { ModalMotorMarketBidsHistory } from "components/Modal/MotorMarketBids";
import { Button, Modal } from "components/shared";

import { AuthContext } from "context/AuthContext";
import { useDealership } from "context/DealershipContext";

import {
  ActionResult,
  ActionResultMessage,
  checkIsValidNumber,
  convertToDate,
  convertToNumber,
  formatNumber,
  showErrorToast,
} from "utils";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import { IMotorMarketBidDetails } from "types/IMotorMarket";

import { ReactComponent as EyeIcon } from "assets/images/eye-icon.svg";
import { ReactComponent as Verified } from "assets/images/verified.svg";

import { MotorMarketService } from "api/client";

interface BuyerBidsProps {
  vehicleAuctionID?: string;
  hasAuctionEnded: boolean;
}

export const BuyerBids = ({
  vehicleAuctionID,
  hasAuctionEnded,
}: BuyerBidsProps) => {
  const { user, userID, hasPermission } = useContext(AuthContext);
  const { dealershipID, showAllDealerships } = useDealership();

  const [bid, setBid] = useState("");
  const [isBidsModalOpen, setIsBidsModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const { data, refetch } = useQuery<{ data: IMotorMarketBidDetails[] }, Error>(
    [`fetch_bids_${vehicleAuctionID}_${userID}`, vehicleAuctionID],
    MotorMarketService.getBids
  );

  const { mutate: addBid, isLoading: isLoadingAddBid } = useMutation(
    () =>
      MotorMarketService.addBid(
        {
          amount: +convertToNumber(bid),
          dealership_id: showAllDealerships
            ? user?.data.dealership?.id
            : dealershipID,
        },
        vehicleAuctionID || ""
      ),
    {
      onSuccess: () => {
        setBid("");
        setIsSuccessModalOpen(true);
        refetch();
      },
      onError: () => {
        showErrorToast("Failed to Submit Bid");
      },
    }
  );

  const latestBid = data?.data?.[0];

  const onChangeBid = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replaceAll(",", "");
    if (checkIsValidNumber(value)) {
      setBid(value);
    }
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      addBid();
    }
  };

  const canBid = hasPermission(
    PermissionCategory.MOTOR_MARKET_BUYING,
    PermissionAction.BID
  );

  return (
    <>
      <div className="m-5 pt-4 border-t border-[#D6D6D6] flex gap-3 flex-col">
        <div className="font-medium text-xl">Bid History</div>
        {data?.data?.length ? (
          <>
            {!hasAuctionEnded && (
              <div className="flex text-[#397994] gap-2">
                <span>
                  <Verified />
                </span>
                You have placed a bid on this listing
              </div>
            )}
            <div className="flex justify-between items-center">
              <div>
                <div className="flex gap-2 items-center">
                  <span>My Bids</span>
                  <div
                    className="cursor-pointer ml-2 inline-block"
                    onClick={() => setIsBidsModalOpen(true)}
                  >
                    <EyeIcon />
                  </div>
                </div>
                {!hasAuctionEnded && (
                  <div className="text-label text-sm">
                    as of &nbsp;
                    {convertToDate(
                      latestBid?.created_at?.replaceAll(" - ", " "),
                      false,
                      "MMM dd, yyyy"
                    )}
                  </div>
                )}
              </div>
              <div className="text-lg font-medium">
                ${formatNumber(latestBid?.bid_amount)}
              </div>
            </div>
          </>
        ) : (
          <div className="flex justify-between py-2">
            <div className="text-base">My Bids</div>
            <div>-</div>
          </div>
        )}
      </div>
      {!hasAuctionEnded && canBid && (
        <div className="p-5 bg-[#A4C0CF] rounded-b-10">
          <div className="bg-white p-1 mb-2 rounded-4 border border-white">
            <input
              className="w-full rounded-4 border-solid border-[#2979FF] border focus:outline-none h-12 text-center"
              placeholder="Enter Your Bid Here"
              value={formatNumber(bid)}
              onChange={onChangeBid}
              onKeyPress={onKeyPress}
            />
          </div>
          <Button
            type="button"
            variant="primary"
            className="w-full h-10"
            isLoading={isLoadingAddBid}
            onClick={() => addBid()}
          >
            <span className="font-bold uppercase">Place Bid</span>
          </Button>
        </div>
      )}
      <ModalMotorMarketBidsHistory
        bidderID={
          (!hasAuctionEnded && canBid ? vehicleAuctionID : latestBid?.id) || ""
        }
        isSeller={hasAuctionEnded || !canBid}
        isOpen={isBidsModalOpen}
        onClose={() => {
          setIsBidsModalOpen(false);
        }}
        latestBid={latestBid?.bid_amount}
        latestUpdated={latestBid?.created_at}
      />
      <Modal
        open={isSuccessModalOpen}
        closeModal={() => setIsSuccessModalOpen(false)}
        size="auto"
      >
        <InformativeModal
          content={{
            title: ActionResultMessage[ActionResult.SuccessPlaceBid].title,
            message: ActionResultMessage[ActionResult.SuccessPlaceBid].message,
            icon: "SuccessIcon",
          }}
        />
      </Modal>
    </>
  );
};
