import { useMutation } from "@tanstack/react-query";
import { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import "../styles.scss";
import { modalValuationAddTestID } from "components/app/dataTestIDs";
import { Button, FieldText } from "components/shared";

import { VALUATIONS_TYPE, formatNumber } from "utils";

import { ValuationService } from "api/client";

export interface ModalValuationAddProps {
  onCloseModal: () => void;
  contract_id: string;
  requester_dealership: string;
  dataTestID?: string;
  requestID?: string;
}

export const ModalValuationAdd = ({
  onCloseModal,
  contract_id,
  requester_dealership,
  dataTestID,
  requestID,
}: ModalValuationAddProps) => {
  const [amount, setAmount] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  const { mutate, isLoading } = useMutation(
    () =>
      ValuationService.addValuationAmount(
        contract_id,
        requester_dealership
          ? {
              type: VALUATIONS_TYPE.VALUATION,
              amount,
              valuation_request_id: requestID,
            }
          : {
              type: VALUATIONS_TYPE.VALUATION,
              amount,
            }
      ),
    {
      onSuccess: () => {
        toast.success("Saved Successfully", {
          autoClose: 2000,
          theme: "colored",
        });
        setAmount("");
        onCloseModal();
      },
      onError: () => {
        onCloseModal();
        toast.error("Failed to Save", {
          autoClose: 2000,
          theme: "colored",
        });
      },
    }
  );

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleAmountConfirm = useCallback(() => {
    if (amount !== "") {
      mutate();
    }
  }, [amount, mutate]);

  const handleAmount = (value: string) => {
    setAmount(value.replaceAll(",", ""));
  };

  return (
    <div
      className="valuation-add !w-310"
      data-testid={dataTestID || modalValuationAddTestID}
    >
      <div className="valuation-add__txt">
        <FieldText
          type="text"
          value={formatNumber(amount)}
          onChange={(e) => handleAmount(e.target.value)}
          placeholder="Enter internal valuation (real) amount"
          ref={inputRef}
        />
        <span className="valuation-add__txt__unit">$</span>
      </div>
      <div className="valuation-add__tool">
        <Button
          type="button"
          isLoading={isLoading}
          onClick={handleAmountConfirm}
        >
          <span className="valuation-add__tool__title">CONFIRM AMOUNT</span>
        </Button>
      </div>
    </div>
  );
};
