import {
  IQueryKeyProps,
  IQuestionnaire,
  ISection,
  IVehicleResponse,
  IVehicleSummaryResponse,
} from "types";

import { api, url } from "api";

export const InspectionService = {
  getVehicleList: ({ queryKey }: IQueryKeyProps) =>
    api.get(url.inspections, queryKey[1]) as Promise<IVehicleResponse>,

  getVehicleSummary: ({ queryKey }: IQueryKeyProps) =>
    api.get(
      `${url.inspections}/${queryKey[1]}`,
      queryKey[2]
    ) as Promise<IVehicleSummaryResponse>,

  getSections: () =>
    api.get(url.assessmentSections) as Promise<{ data: ISection[] }>,

  getQuestionnaire: () =>
    api.get(url.assessment) as Promise<{ data: IQuestionnaire[] }>,

  sendLink: (customer_id: string, payload: { inspection_type: string }) =>
    api.post(`${url.customerInspections}/${customer_id}/link`, payload),

  resendLink: (inspection_id: string) =>
    api.post(`${url.customerInspections}/${inspection_id}/resend`, {}),
};
