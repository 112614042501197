import debounce from "lodash.debounce";
import { KeyboardEvent, useEffect, useMemo, useState } from "react";

import { SvgImage } from "components/shared";

interface SearchFieldProps {
  onSearch: (term: string) => void;
}

export const SearchField = ({ onSearch }: SearchFieldProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedFetchData = useMemo(() => {
    return debounce(onSearch, 1000);
  }, [onSearch]);

  useEffect(() => {
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSearch(searchTerm);
    }
  };

  return (
    <div className="w-430 relative">
      <input
        className="h-9 w-full rounded-4 border-solid border-grey border pl-10 focus:outline-none bg-white"
        placeholder="Search"
        onKeyPress={handleKeyPress}
        onChange={(event) => {
          setSearchTerm(event.target.value);
          debouncedFetchData(event.target.value);
        }}
      />
      <span
        className="absolute top-2 left-3"
        onClick={() => onSearch(searchTerm)}
      >
        <SvgImage name="SearchIcon" width={20} height={20} />
      </span>
    </div>
  );
};
