import { NavHeader } from "components/layouts/components/TheSidebar/NavHeader";

import { ACCESS_DENIED_ROUTE } from "utils";

import { NavigationMenuLabel } from "types";

interface NoAccessNavigationProps {
  title: NavigationMenuLabel;
}

export const NoAccessNavigation = ({ title }: NoAccessNavigationProps) => (
  <NavHeader
    key={`no-access-menu-${title}`}
    open={false}
    label={title}
    isActive={false}
    route={ACCESS_DENIED_ROUTE}
  />
);
