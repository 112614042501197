import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, SvgImage } from "components/shared";

import { AuthContext } from "context/AuthContext";

import { INSPECTION_STEP } from "utils";

import { CustomerInspectionService } from "api/client";

export const Welcome = () => {
  const { userID } = useContext(AuthContext);
  const params = useParams();
  const navigate = useNavigate();
  const inspectionId = params.id;
  const { data } = useQuery(
    [`verify_inspection_link_${userID}`, inspectionId],
    CustomerInspectionService.verifyInspectionLInk
  );

  useEffect(() => {
    if (data) {
      const { status } = data.data;

      switch (status.name) {
        case INSPECTION_STEP.VEHICLE_INFO:
          return navigate(`/inspection/customer/${inspectionId}/enter-kms`);
        case INSPECTION_STEP.SPEC_OPTIONS:
          return navigate(`/inspection/customer/${inspectionId}/conditions`);
        case INSPECTION_STEP.INSPECTION_SUBMITTED:
          return navigate(`/inspection/customer/${inspectionId}/complete`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleInspection = () => {
    navigate(`../find-vehicle`);
  };

  return (
    <div className="flex flex-col justify-between text-primary w-full h-[calc(100%-60px)] mb-5">
      <div className="flex-1 w-full flex flex-col overflow-auto px-5 h-full">
        <div className="flex flex-col">
          <h5 className="font-bold text-2xl mb-9 mt-[110px] md:mt-158">
            Your Vehicle Inspection
          </h5>
          <span className="text-primary font-normal text-base">for</span>
          <span className="font-medium text-lg">
            {data?.data.salesperson.name}
          </span>
          <span className="text-primary font-normal text-base">
            {data?.data.dealership.name}
          </span>
        </div>
        <label className="mt-10">Ready to start?</label>
        <div className="font-medium text-sm mt-7">
          <span className="flex items-center text-academicBlue mb-1">
            <SvgImage name="ClockIcon" className="mr-3" />3 - 5 minutes
          </span>
          <p className="text-label font-normal">
            Please allow 3 to 5 minutes
            <br /> to complete this process.
          </p>
        </div>
        <div className="font-medium text-sm mt-7">
          <span className="flex items-center text-academicBlue mb-1">
            <SvgImage name="DealershipIcon" className="mr-3" />
            Proceed to your vehicle
          </span>
          <p className="text-label font-normal">
            Make sure you are near your vehicle to
            <br /> collect information and take pictures.
          </p>
        </div>
        <div className="mt-auto">
          <Button type="submit" onClick={handleInspection}>
            <span className="uppercase text-white text-sm font-bold">
              START INSPECTION
            </span>
          </Button>
        </div>
        <div className="flex flex-row items-center mt-5 space-x-1 justify-center">
          <p className="text-label font-norma text-sm">powered by</p>
          <SvgImage name="MPLogo" className="mt-1" width={116} height={39} />
        </div>
      </div>
    </div>
  );
};
