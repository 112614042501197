import { NavigationTestID } from "components/layouts/dataTestIDs";

import AcquisitionIcon from "assets/images/menu-icons/acquisition.svg";
import BuyingIcon from "assets/images/menu-icons/buying.svg";
import CustomersIcon from "assets/images/menu-icons/customers.svg";
import DashboardIcon from "assets/images/menu-icons/dashboard.svg";
import MotorMarketBuyIcon from "assets/images/menu-icons/motormarket-buy.svg";
import MotorMarketSellIcon from "assets/images/menu-icons/motormarket-sell.svg";
import NegotiationIcon from "assets/images/menu-icons/negotiation.svg";
import PeopleIcon from "assets/images/menu-icons/people-icon.svg";
import SellingIcon from "assets/images/menu-icons/selling.svg";
import ValuationIcon from "assets/images/menu-icons/valuation.svg";

import { PermissionCategory } from "./userPermissions";

export const DASHBOARD_MENU = {
  label: "Dashboard",
  icon: DashboardIcon,
  route: "/dashboard",
};

export const RETAIL_DEALERSHIP_MENU = [
  {
    label: "Unvalued",
    icon: ValuationIcon,
    route: "/retail-dealership",
  },
  {
    label: "Valued",
    icon: NegotiationIcon,
    route: "/retail-dealership/valued",
  },
  {
    label: "Stocklist",
    icon: AcquisitionIcon,
    route: "/retail-dealership/stocklist",
  },
  {
    label: "Customers",
    icon: CustomersIcon,
    route: "/retail-dealership/customers",
  },
];

export const WHOLESALE_DEALERSHIP_MENU = [
  {
    label: "Unvalued",
    icon: ValuationIcon,
    route: "/wholesale-dealership",
  },
  {
    label: "Valued",
    icon: NegotiationIcon,
    route: "/wholesale-dealership/valued",
  },
  {
    label: "Stocklist",
    icon: AcquisitionIcon,
    route: "/wholesale-dealership/stocklist",
  },
  {
    label: "Clients",
    icon: CustomersIcon,
    route: "/wholesale-dealership/clients",
  },
];

export const TENDER_MENU = {
  selling: {
    label: "Selling",
    icon: SellingIcon,
    route: "/tender/selling",
    dataTestID: NavigationTestID.TenderSelling,
    permission: PermissionCategory.TENDER_SELLING,
  },
  buying: {
    label: "Buying",
    icon: BuyingIcon,
    route: "/tender/buying",
    dataTestID: NavigationTestID.TenderBuying,
    permission: PermissionCategory.TENDER_BUYING,
  },
};

export const MOTOR_MARKET_MENU = {
  myListings: {
    label: "My Listings",
    icon: MotorMarketSellIcon,
    route: "/motor-market/my-listings",
    dataTestID: NavigationTestID.MotorMarketMyListings,
    permission: PermissionCategory.MOTOR_MARKET_SELLING,
  },
  buying: {
    label: "Buying",
    icon: MotorMarketBuyIcon,
    route: "/motor-market/buying",
    dataTestID: NavigationTestID.MotorMarketBuying,
    permission: PermissionCategory.MOTOR_MARKET_BUYING,
  },
};

export const SETTINGS = [
  {
    label: "Dealership Pool",
    icon: PeopleIcon,
    route: "/settings/dealership-pool",
  },
];
