import { Disclosure } from "@headlessui/react";
import React, { FC } from "react";

import { SvgImage } from "components/shared";

import "./styles.scss";
import {
  accordionButtonTestID,
  accordionHeaderTestID,
  accordionPanelTestID,
  accordionTestID,
} from "../dataTestIDs";

export interface AccordionProps {
  label: string;
  defaultOpen?: boolean;
  compact?: boolean;
  disable?: boolean;
  onToggle?: () => void;
  onEdit?: () => void;
  children: React.ReactNode;
  dataTestID?: string;
}

interface IHeaderProps {
  open: boolean;
  label: string;
  compact?: boolean;
  disable?: boolean;
  onClick?: () => void;
  onEdit?: () => void;
}

const DisclosureHeader: FC<IHeaderProps> = ({
  open,
  label,
  compact,
  disable,
  onClick,
  onEdit,
}) => {
  return (
    <div className="disclosure-header">
      <div
        className="disclosure-header--left"
        onClick={onClick}
        data-testid={accordionHeaderTestID}
      >
        {compact && (
          <SvgImage
            name={disable ? "ChevronUpDisabledIcon" : "ChevronUpIcon"}
            className={`mr-3 ${
              !open && !disable ? "transform rotate-180" : ""
            }`}
          />
        )}
        <span>{label}</span>
      </div>
      {onEdit && (
        <span className="hover:cursor-pointer" onClick={onEdit}>
          <SvgImage
            name="EditPen"
            className="disclosure-header__img"
            width={14}
          />
        </span>
      )}
    </div>
  );
};

export const Accordion: FC<AccordionProps> = ({
  label,
  compact = false,
  defaultOpen = true,
  disable = false,
  onEdit,
  onToggle,
  children,
  dataTestID,
}) => {
  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <div data-testid={dataTestID || accordionTestID}>
          <Disclosure.Button
            className={disable ? "disclosure--disabled" : "outline-0"}
            data-testid={accordionButtonTestID}
          >
            <DisclosureHeader
              open={open}
              compact={compact}
              disable={disable}
              label={label}
              onClick={onToggle}
              onEdit={onEdit}
            />
          </Disclosure.Button>
          {!disable && (
            <Disclosure.Panel
              className="pl-5"
              data-testid={accordionPanelTestID}
            >
              {children}
            </Disclosure.Panel>
          )}
        </div>
      )}
    </Disclosure>
  );
};
