import {
  ICustomerInspection,
  ICustomerLink,
  IImage,
  IQueryKeyProps,
} from "types";

import { api, url } from "api";

export const CustomerInspectionService = {
  sendLink: (customerId: string, payload: any) =>
    api.post(`${url.customerInspections}/${customerId}/link`, payload),

  verifyInspectionLInk: ({ queryKey }: IQueryKeyProps) =>
    queryKey[1]
      ? (api.get(`${url.customerInspections}/${queryKey[1]}/link`) as Promise<{
          data: ICustomerLink;
        }>)
      : null,

  startInspection: (data: any, inspectionId: string) =>
    api.post(`${url.customerInspections}/${inspectionId}`, data),

  showInspection: ({ queryKey }: IQueryKeyProps) =>
    api.get(`${url.customerInspections}/${queryKey[1]}`) as Promise<{
      data: ICustomerInspection;
    }>,

  updateInspection: (data: any, inspection_id: string) =>
    api.patch(`${url.customerInspections}/${inspection_id}`, data),

  saveAnswers: (data: any, inspection_id: string) =>
    api.post(`${url.customerInspections}/${inspection_id}/assessments`, data),

  uploadImages: (data: any, inspection_id: string) =>
    api.post(
      `${url.customerInspections}/${inspection_id}/images`,
      data
    ) as Promise<{ data: IImage }>,

  removeImage: (payload: any, inspection_id: string) =>
    api.delete(`${url.customerInspections}/${inspection_id}/images`, payload),

  submitInspection: (payload: any, inspection_id: string) =>
    api.post(`${url.customerInspections}/${inspection_id}/submit`, payload),

  regenerateInspection: (payload: any, inspection_id: string) =>
    api.post(`${url.customerInspections}/${inspection_id}/resend`, payload),
};
