import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { Button } from "components/shared";

import { showErrorToast } from "utils";
import { PPSR_STATUS_NAMES } from "utils/ppsrStatuses";

import { IPPSR, IPPSRStatus } from "types";

import { VehicleService } from "api/client";

import { DataContainer } from "./DataContainer";

interface PPSRProps {
  contractID: string;
  status?: null | IPPSRStatus;
  refetch?: () => void;
}

export const PPSR = ({ contractID, status, refetch }: PPSRProps) => {
  const [isCertificateAvailable, setIsCertificateAvailable] = useState(false);
  const [isPollingTriggered, setIsPollingTriggered] = useState(false);
  const [data, setData] = useState<IPPSR>();

  const { mutate: onSearchPPSR, isLoading: isLoadingSearchPPSR } = useMutation(
    ({ refetch }: { refetch: boolean }) =>
      VehicleService.searchPPSR(contractID, refetch),
    {
      onSuccess: (data) => {
        setData(data.data);

        if (data.data.has_certificate) {
          setIsCertificateAvailable(true);
          setIsPollingTriggered(false);
        } else {
          setIsPollingTriggered(true);
        }
        refetch && refetch();
      },
      onError: () => {
        showErrorToast("Something went wrong. Try Again ");
      },
    }
  );

  useEffect(() => {
    if (
      PPSR_STATUS_NAMES.includes(status as typeof PPSR_STATUS_NAMES[number])
    ) {
      onSearchPPSR({ refetch: false });
    }
  }, [status, onSearchPPSR]);

  useEffect(() => {
    const pollSearch = window.setInterval(() => {
      if (isPollingTriggered && !isCertificateAvailable) {
        onSearchPPSR({ refetch: false });
      }
    }, 5000);

    return () => {
      clearInterval(pollSearch);
    };
  }, [isPollingTriggered, isCertificateAvailable, onSearchPPSR]);

  return (
    <div className="flex flex-col gap-10">
      {!!data?.search_id || data?.status === "changed" ? (
        <DataContainer data={data} contractID={contractID} />
      ) : (
        <div className="text-sm text-label">
          {isLoadingSearchPPSR ? (
            "Please do not leave the page to avoid stopping the process."
          ) : (
            <>
              Conduct a PPSR search for this vehicle.
              <div className="mt-3">
                PPSR results will take approximately 5-10 seconds to retrieve.
                PDF will take 10-15 seconds to download.
              </div>
            </>
          )}
        </div>
      )}
      <Button
        className="!w-36 font-bold uppercase"
        isLoading={isLoadingSearchPPSR && !isPollingTriggered}
        onClick={() => {
          onSearchPPSR({ refetch: true });
          setIsPollingTriggered(false);
        }}
        isLoadingText="Checking"
      >
        Request PPSR
      </Button>
    </div>
  );
};
