import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext, useState } from "react";

import { LoadingTable } from "components/Loading/LoadingTable";
import { Button, FieldTextarea, Prompt } from "components/shared";

import { AuthContext } from "context/AuthContext";

import { ERROR_MESSAGE, showErrorToast, showSuccessToast } from "utils";

import { ICustomerComment, ICustomerDetail } from "types";

import { CustomerService } from "api/client";

interface CommentsProps {
  id?: string;
  customerData?: ICustomerDetail;
}

export const Comments = ({ id, customerData }: CommentsProps) => {
  const { userID } = useContext(AuthContext);
  const [comment, setComment] = useState("");

  const { data, isInitialLoading, isSuccess, isError, isRefetching, refetch } =
    useQuery(
      [`fetch_customer_comments_${id}_${userID}`, id],
      CustomerService.getCustomerComments
    );

  const { mutate: updateComment, isLoading: isLoadingUpdateComment } =
    useMutation(
      (payload: ICustomerDetail & { comment: string }) =>
        CustomerService.updateDetails(payload.id, payload),
      {
        onSuccess: () => {
          refetch();
          showSuccessToast("Saved successfully!");
          setComment("");
        },
        onError: () => {
          showErrorToast(ERROR_MESSAGE);
        },
      }
    );

  const onUpdateComment = () => {
    if (comment) {
      if (customerData?.id) updateComment({ ...customerData, comment });
    }
  };

  const onClearComment = () => {
    setComment("");
  };

  const renderComments = () => {
    if (isInitialLoading || isRefetching) {
      return <LoadingTable />;
    }

    if (isError) {
      return <div className="text-sm text-center">{ERROR_MESSAGE}</div>;
    }

    if (isSuccess) {
      return data?.data.map((comment: ICustomerComment, index: number) => {
        return (
          <div
            key={`${comment?.commented_at}_${index}`}
            className="rounded-10 shadow-carousel px-4 py-3 text-lightGrey mb-4"
          >
            <div className="flex items-center">
              <span className="font-medium text-sm mr-11">
                {comment?.commentator?.name}
              </span>
              <label className="font-normal text-xs mb-0">
                {comment?.commented_at}
              </label>
            </div>
            <p className="text-txt font-light text-sm py-2">
              {comment?.comment}
            </p>
          </div>
        );
      });
    }

    return <></>;
  };

  return (
    <div className="w-full mb-5 mt-10">
      <label className="text-lg text-txt font-semibold mt-8 mb-2">
        Comments
      </label>
      <div className="my-4 flex flex-col gap-4">
        <FieldTextarea
          placeholder="Enter your comments here"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <div className="flex items-center gap-5">
          <Button
            type="button"
            className="!w-24"
            isLoading={isLoadingUpdateComment}
            onClick={onUpdateComment}
            disabled={!comment}
          >
            <span className="font-bold">SAVE</span>
          </Button>
          <Button
            type="button"
            className="!w-24 !bg-white shadow-md"
            onClick={onClearComment}
            disabled={!comment}
          >
            <span className="text-primary font-bold">CANCEL</span>
          </Button>
        </div>
      </div>
      {renderComments()}
      <Prompt isDirty={!!comment} />
    </div>
  );
};
