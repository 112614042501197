export const PPSR_SUCCESS = {
  name: "success",
  label: "Passed",
  icon: "passed",
  color: "text-[#30743D]",
  background: "bg-[#0B822C]",
} as const;

export const PPSR_FAILED = {
  name: "failed",
  label: "Failed",
  icon: "failed",
  color: "text-[#C8372D]",
  background: "bg-[#AD4D38]",
} as const;

export const PPSR_CHANGED = {
  name: "changed",
  label: "Changed",
  icon: "changed",
  color: "text-[#0C2146]",
  background: "bg-[#0C2146]",
} as const;

export const PPSR_STATUSES = {
  success: PPSR_SUCCESS,
  failed: PPSR_FAILED,
  changed: PPSR_CHANGED,
} as const;

export const PPSR_STATUS_NAMES = [
  PPSR_SUCCESS.name,
  PPSR_FAILED.name,
  PPSR_CHANGED.name,
] as const;
