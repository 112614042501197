import { useMemo } from "react";

import { CLOSED } from "utils";

import { ITender } from "types";

import { CardTender } from "../Card";

interface ListTendersProps {
  data: ITender[];
  onSelect: (id: string) => void;
  currentStatus?: string | number;
}

export const ListTenders = ({
  data,
  onSelect,
  currentStatus,
}: ListTendersProps) => {
  const sortedData = useMemo(() => {
    if (!data) return [];
    if (currentStatus === CLOSED.key) {
      return data?.sort(
        (a, b) => new Date(b.ends_at).getTime() - new Date(a.ends_at).getTime()
      );
    }
    return data?.sort(
      (a, b) =>
        new Date(a.starts_at).getTime() - new Date(b.starts_at).getTime()
    );
  }, [data, currentStatus]);

  return (
    <div className="list">
      {sortedData?.map((item) => (
        <CardTender
          key={item.tender_id}
          data={item}
          onClick={() => onSelect(item.tender_id)}
        />
      ))}
    </div>
  );
};
