import {
  ICustomer,
  ICustomerComment,
  ICustomerDetail,
  IQueryKeyProps,
  IVehicleDetail,
  IDocument,
} from "types";

import { api, url } from "api";

export const CustomerService = {
  get: ({ queryKey }: IQueryKeyProps) =>
    api.get(url.customers, queryKey[1]) as Promise<ICustomer>,

  getDetails: ({ queryKey }: IQueryKeyProps) =>
    api.get(`${url.customers}/${queryKey[1]}`) as Promise<{
      data: ICustomerDetail;
    }>,

  getCustomerContracts: ({ queryKey }: IQueryKeyProps) =>
    api.get(
      `${url.customers}/contracts?customer_id=${queryKey[1]}`,
      queryKey[2]
    ) as Promise<{ data: IVehicleDetail[] }>,

  getCustomerComments: ({ queryKey }: IQueryKeyProps) =>
    api.get(`${url.comments}/${queryKey[1]}/customer`) as Promise<{
      data: ICustomerComment[];
    }>,

  create: (payload: any) => api.post(url.customers, payload),

  updateDetails: (customerId: string, payload: any) =>
    api.put(`${url.customers}/${customerId}`, payload),

  uploadDocument: (payload: FormData, customerID: string) =>
    api.post(
      `${url.customers}/${customerID}/documents`,
      payload,
      "form-data"
    ) as Promise<{ data: IDocument }>,

  getCustomerDocuments: ({ queryKey }: IQueryKeyProps) =>
    api.get(`${url.customers}/${queryKey[1]}/documents`) as Promise<{
      data: IDocument[];
    }>,

  deleteCustomerDocument: (customerID: string, documentID: string) =>
    api.delete(`${url.customers}/${customerID}/documents/${documentID}`),

  downloadCustomerDocument: (customerID: string, documentID: string) =>
    api.get(
      `${url.customers}/${customerID}/documents/${documentID}`,
      {},
      true
    ) as Promise<Blob>,
};
