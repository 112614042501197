import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect } from "react";

import { LoadItem } from "components/app/LoadVehicle";
import { ModalContainer } from "components/ModalContainer";

import { AuthContext } from "context/AuthContext";
import { useDealership } from "context/DealershipContext";

import { ERROR_MESSAGE, formatNumber } from "utils";

import { IMotorMarketBidHistory } from "types/IMotorMarket";

import { MotorMarketService } from "api/client";

interface ModalMotorMarketBidsHistoryProps {
  bidderID: string;
  isOpen: boolean;
  onClose: () => void;
  latestBid?: number;
  latestUpdated?: string;
  isSeller?: boolean;
}

export const ModalMotorMarketBidsHistory = ({
  bidderID,
  isOpen,
  onClose,
  latestBid,
  latestUpdated,
  isSeller,
}: ModalMotorMarketBidsHistoryProps) => {
  const { userID } = useContext(AuthContext);
  const { dealershipID } = useDealership();

  const { data, isLoading, isError, isSuccess, refetch } = useQuery<
    {
      data: IMotorMarketBidHistory[];
    },
    Error
  >(
    [`bidderHistory_${bidderID}_${userID}`, bidderID, dealershipID],
    isSeller
      ? MotorMarketService.getSellerBidHistory
      : MotorMarketService.getBidHistory,
    {
      enabled: !!bidderID,
    }
  );

  useEffect(() => {
    if (
      latestBid &&
      latestUpdated &&
      (latestBid !== data?.data?.[0]?.bid_amount ||
        latestUpdated !== data?.data?.[0]?.created_at)
    ) {
      refetch();
    }
  }, [refetch, isOpen, latestBid, latestUpdated, data?.data]);

  const renderItems = () => {
    if (isLoading) {
      return <LoadItem />;
    }

    if (isError) {
      return <div className="text-sm text-center">{ERROR_MESSAGE}</div>;
    }

    if (isSuccess) {
      return (
        <>
          {data?.data?.map((item: IMotorMarketBidHistory) => {
            return (
              <div
                key={item.id}
                className="pt-4 pb-3 border-b border-softPeach last:border-none"
              >
                <div className="flex items-center justify-between text-black text-sm font-medium mb-1">
                  {item.latest_bidder?.name}
                  <div>{`$${formatNumber(item.bid_amount)}`}</div>
                </div>
                <div className="flex items-start justify-between text-lightGrey text-xs font-normal mb-1">
                  <div>{item.dealership?.name}</div>
                  <div className="min-w-[160px] text-right">
                    {item.created_at}
                  </div>
                </div>
              </div>
            );
          })}
        </>
      );
    }

    return <></>;
  };

  return (
    <ModalContainer
      isOpen={isOpen}
      onClose={onClose}
      title="Bids Log"
      size="auto"
    >
      <div className="w-[340px] mx-1 md:-mx-6 mt-2 px-3 overflow-auto max-h-[calc(100vh-300px)]">
        {renderItems()}
      </div>
    </ModalContainer>
  );
};
