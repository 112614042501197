import { FieldCheckbox } from "components/shared";

interface SelectAllProps {
  isAllSelected: boolean;
  isLoading?: boolean;
  onSelectAll: (isAllSelected: boolean) => void;
  canUpdateOrSave: boolean;
  canDeselect: boolean;
}

export const SelectAll = ({
  isAllSelected,
  isLoading = false,
  onSelectAll,
  canUpdateOrSave,
  canDeselect,
}: SelectAllProps) => (
  <div className="dashboard__content__select">
    <span className="mr-3 -mb-1.5">{` ${
      isAllSelected ? "Deselect" : "Select"
    } All`}</span>
    <FieldCheckbox
      variant="secondary"
      checked={isAllSelected}
      disabled={
        isLoading || !canUpdateOrSave || isAllSelected ? !canDeselect : false
      }
      onChange={(event) => onSelectAll(event.target.checked)}
    />
  </div>
);
