import { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { AuthContext } from "context/AuthContext";

const AuthorizedRoute = ({ children }: { children: JSX.Element }) => {
  const { authToken } = useContext(AuthContext);
  const location = useLocation();
  const searchQuery = location.search;

  if (searchQuery && searchQuery.includes("token")) {
    const email = searchQuery.split("email=")[1];
    const token = searchQuery.split("&email=")[0].split("token=")[1];
    return <Navigate to={`reset/${token}/${email}`} replace />;
  }

  if (!authToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default AuthorizedRoute;
