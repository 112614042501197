import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Breadcrumbs,
  Button,
  ButtonLink,
  FieldCheckbox,
  FieldDropdown,
  FieldRadio,
  FieldText,
  FieldTextarea,
  Prompt,
  SvgImage,
  TextError,
} from "components/shared";

import { AuthContext } from "context/AuthContext";
import { SearchContext } from "context/SearchContext";

import {
  FLOW_TYPE,
  VEHICLE_ROUTES,
  VEHICLE_SUMMARY_TITLE,
  checkIsValidNumber,
  formatNumber,
  getVehicleInformationRoute,
  showErrorToast,
  showSuccessToast,
} from "utils";

import { IFormRange, IImage, IQuestionnaire, IVehicleSummary } from "types";

import {
  DealershipInspectionService,
  InspectionService,
  VehicleService,
} from "api/client";

import { CosmeticItem } from "./CosmeticItem";

type TCheckbox = {
  slug: string;
  costs: string | number | undefined | null;
  images: IImage[];
};

interface ConditionProps {
  flow: FLOW_TYPE;
  route: VEHICLE_ROUTES;
}

type AnswerProps = {
  [key in string]?: Array<TCheckbox> | string | string[];
};

type PayloadAnswerProps = {
  question_slug: string | TCheckbox;
  comments?: string[];
  options?: string[];
  costs?: {
    cost_for: string;
    amount: string | number | null | undefined;
  }[];
}[];

export const Condition = ({ flow, route }: ConditionProps) => {
  const queryClient = useQueryClient();
  const { userID } = useContext(AuthContext);
  const [hasOnlyKMChanged, setHasOnlyKMChanged] = useState(false);

  const navigate = useNavigate();
  const params = useParams();
  const { setIsUpdated } = useContext(SearchContext);

  const { title, path } = getVehicleInformationRoute(flow);
  const isValuedRoute = route === VEHICLE_ROUTES.Unvalued;

  const [answers, setAnswers] = useState<AnswerProps>({});
  const [isNumber, setIsNumber] = useState(true);
  const [odometerVal, setOdometerVal] = useState("");
  const [contractId, setContractId] = useState("");
  const [isDirty, setIsDirty] = useState(false);
  const { mutate: updateKM } = useMutation(
    (payload: { odometer: string; type: string }) =>
      VehicleService.updateVehicle(payload, contractId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([`retail-summary-${params.id}`]);
        if (hasOnlyKMChanged) {
          successProcess();
        }
      },
    }
  );

  const { data: dealershipInspectionData } = useQuery<
    { data: IVehicleSummary },
    Error
  >(
    [`fetchDealershipInspection_${userID}`, params.id],
    DealershipInspectionService.getDealershipSummary,
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: questionnaire } = useQuery<{ data: IQuestionnaire[] }, Error>(
    [`fetchQuestionnaire_${userID}`],
    InspectionService.getQuestionnaire,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { mutate, isLoading } = useMutation(
    (data: PayloadAnswerProps) =>
      VehicleService.saveAnswers(
        { answers: data, type: "conditions" },
        params.id || ""
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([`retail-summary-${params.id}`]);
        if (!hasOnlyKMChanged) {
          successProcess();
        }
      },
      onError: () => {
        if (!hasOnlyKMChanged) {
          showErrorToast("Failed to Save");
        }
      },
    }
  );

  function successProcess() {
    showSuccessToast("Saved Successfully");

    setIsUpdated(contractId, true);
    navigate(-1);
    setIsDirty(false);
  }

  const formData = questionnaire ? questionnaire.data[1].questionnaire : [];
  const cosmeticForm = formData.find(
    (category) => category.slug === "cosmetic-condition"
  );

  useEffect(() => {
    const newAnswers: AnswerProps = {};
    if (dealershipInspectionData) {
      const {
        assessments,
        contract_id,
        vehicle: { odometer },
      } = dealershipInspectionData.data;
      setOdometerVal(odometer || "");
      setContractId(contract_id);
      assessments[1].questionnaire.forEach((field) => {
        if (
          field.question_slug === "additional-information" &&
          field.answers?.comments &&
          field.answers?.comments.length !== 0 &&
          field?.answers?.comments[0] !== "undefined"
        ) {
          newAnswers[field.question_slug] = `${field.answers?.comments[0]}`;
        } else if (field.answers?.options) {
          if (
            field.answers.options.length === 1 &&
            field.title !== "Cosmetic Condition" &&
            field.question_slug !== "mechanical-and-roadworthy"
          ) {
            newAnswers[field.question_slug] =
              field.answers.options[0].option_slug;
          } else {
            newAnswers[field.question_slug] = field.answers.options.map(
              (option) => ({
                slug: option.option_slug,
                costs: option.costs || 0,
                images:
                  option.images && Object.entries(option.images).length > 0
                    ? Object.values(option.images)
                    : [],
              })
            );
          }
        }
      });
    }
    setAnswers(newAnswers);
  }, [dealershipInspectionData]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleSave = () => {
    const payload: PayloadAnswerProps = [];
    Object.keys(answers).forEach((key: string | TCheckbox) => {
      if (typeof answers[key as keyof AnswerProps] === "string") {
        if (key === "additional-information") {
          payload.push({
            question_slug: key as string,
            comments: [answers[key]] as string[],
          });
        } else {
          payload.push({
            question_slug: key,
            options: [answers[key as keyof AnswerProps]] as string[],
          });
        }
      } else {
        payload.push({
          question_slug: key,
          options: (answers[key as keyof AnswerProps] as TCheckbox[])?.map(
            (answer: TCheckbox) => answer.slug
          ),
          costs: (answers?.[key as keyof AnswerProps] as TCheckbox[])
            ?.filter((answer: TCheckbox) => answer.costs !== null)
            .map((answer: TCheckbox) => ({
              cost_for: answer.slug,
              amount: answer.costs,
            })),
        });
      }
    });

    if (payload.length) {
      setHasOnlyKMChanged(false);
    } else {
      setHasOnlyKMChanged(true);
    }

    if (odometerVal !== dealershipInspectionData?.data.vehicle.odometer) {
      handleSubmitOdometer();
    }

    mutate(payload);
  };

  const handleToggleCheck = (categorySlug: string, optionSlug: string) => {
    if (!answers[categorySlug]) {
      setAnswers({
        ...answers,
        [categorySlug]: [{ slug: optionSlug, costs: null, images: [] }],
      });
    } else if (
      (answers[categorySlug] as TCheckbox[])?.findIndex(
        (val: TCheckbox) => val.slug === optionSlug
      ) > -1
    ) {
      setAnswers({
        ...answers,
        [categorySlug]: (answers[categorySlug] as TCheckbox[])?.filter(
          (val: TCheckbox) => val.slug !== optionSlug
        ),
      });
    } else {
      setAnswers({
        ...answers,
        [categorySlug]: [
          ...((answers?.[categorySlug] ?? []) as TCheckbox[]),
          { slug: optionSlug, costs: null, images: [] },
        ],
      });
    }
    setIsDirty(true);
  };

  const handleChangeCost = (
    categorySlug: string,
    optionSlug: string,
    val: string | number
  ) => {
    const selectedOption = (answers[categorySlug] as TCheckbox[])?.find(
      ({ slug }: TCheckbox) => slug === optionSlug
    );

    setAnswers({
      ...answers,
      [categorySlug]: [
        ...((answers[categorySlug] as TCheckbox[])?.filter(
          (c: TCheckbox) => c.slug !== optionSlug
        ) ?? []),
        { ...selectedOption, costs: val },
      ] as TCheckbox[],
    });
    setIsDirty(true);
  };

  const handleChangeImages = (
    categorySlug: string,
    optionSlug: string,
    imageIds: IImage[]
  ) => {
    const selectedOption = (answers[categorySlug] as TCheckbox[])?.find(
      ({ slug }: TCheckbox) => slug === optionSlug
    );
    setAnswers({
      ...answers,
      [categorySlug]: [
        ...((answers[categorySlug] as TCheckbox[])?.filter(
          (c: TCheckbox) => c.slug !== optionSlug
        ) ?? []),
        { ...selectedOption, images: imageIds },
      ] as TCheckbox[],
    });
    setIsDirty(true);
  };

  const handleChangeOdometer = (e: ChangeEvent<HTMLInputElement>) => {
    setOdometerVal(e.target.value.replaceAll(",", ""));
    setIsNumber(checkIsValidNumber(e.target.value.replaceAll(",", "")));
    setIsDirty(true);
  };

  const handleSubmitOdometer = () => {
    updateKM({ odometer: odometerVal.replaceAll(",", ""), type: "odometer" });
  };

  return (
    <div className="dashboard__container">
      <div className="dashboard__header">
        <Breadcrumbs
          paths={[
            {
              title: title,
              route: path,
            },
            {
              title: route,
              route: isValuedRoute ? path : `${path}/${route?.toLowerCase()}`,
            },
            {
              title: VEHICLE_SUMMARY_TITLE,
              route: isValuedRoute
                ? `${path}/valuation/${params.id}/summary`
                : `${path}/${route?.toLowerCase()}/${params.id}/summary`,
            },
            {
              title: "Vehicle Information",
              route: isValuedRoute
                ? `${path}/valuation/${params.id}/summary`
                : `${path}/${route?.toLowerCase()}/${params.id}/summary`,
            },
            {
              title: "Condition",
              route: isValuedRoute
                ? `${path}/${params.id}/features`
                : `${path}/${route?.toLowerCase()}/${params.id}/condition`,
            },
          ]}
        />
        <ButtonLink onClick={handleGoBack}>
          <SvgImage name="LeftRoundedIcon" />
        </ButtonLink>
      </div>
      <div className="dashboard__content">
        <div className="condition">
          <div className="condition__content">
            <div>
              <div className="mb-6">
                <FieldText
                  title="Kilometres*"
                  type="text"
                  placeholder="Enter KM"
                  value={formatNumber(odometerVal)}
                  onChange={handleChangeOdometer}
                  onBlur={handleSubmitOdometer}
                />
                {isNumber === false && (
                  <TextError error="Please enter numbers only" />
                )}
              </div>
              {formData
                .filter((category) => category.slug !== "cosmetic-condition")
                .map((category) => (
                  <div className="mb-6" key={category.slug}>
                    {category.field_type === "switch" && (
                      <>
                        <label className="condition-title--small">
                          {category.title}:
                        </label>
                        <div className="grid grid-cols-2">
                          {category.options.map((option) => (
                            <FieldRadio
                              key={option.slug}
                              name={category.slug}
                              label={option.title}
                              checked={answers[category.slug] === option.slug}
                              onChange={() => {
                                setAnswers({
                                  ...answers,
                                  [category.slug]: option.slug,
                                });
                                setIsDirty(true);
                              }}
                            />
                          ))}
                        </div>
                      </>
                    )}
                    {category.field_type === "dropdown" && (
                      <>
                        <label className="condition-title--small">
                          {category.title}:
                        </label>
                        <FieldDropdown
                          placeholder="Select"
                          options={category.options.map((option) => ({
                            key: option.slug,
                            label: option.title,
                          }))}
                          value={answers[category.slug] as string}
                          onChange={(val) => {
                            setAnswers({
                              ...answers,
                              [category.slug]: val,
                            });
                            setIsDirty(true);
                          }}
                        />
                      </>
                    )}
                    {category.field_type === "checkbox" && (
                      <>
                        <label className="condition-title">
                          {category.title}
                        </label>
                        {category.options.map((option) => (
                          <div className="mt-6" key={option.slug}>
                            <FieldCheckbox
                              checked={
                                (
                                  answers[category.slug] as TCheckbox[]
                                )?.findIndex(
                                  (val: TCheckbox) => val.slug === option.slug
                                ) > -1
                              }
                              onChange={() =>
                                handleToggleCheck(category.slug, option.slug)
                              }
                              label={option.title}
                            />
                            {answers[category.slug] &&
                              (answers[category.slug] as TCheckbox[]).findIndex(
                                (val: TCheckbox) => val.slug === option.slug
                              ) > -1 && (
                                <CosmeticItem
                                  limit={Number(
                                    (option.children[0] as IFormRange).data.max
                                  )}
                                  cost={
                                    (
                                      answers[
                                        category.slug as keyof AnswerProps
                                      ] as TCheckbox[]
                                    )?.find(
                                      (val: TCheckbox) =>
                                        val.slug === option.slug
                                    )?.costs
                                  }
                                  label={option.slug}
                                  onChangeImage={(imageIds: IImage[]) =>
                                    handleChangeImages(
                                      category.slug,
                                      option.slug,
                                      imageIds
                                    )
                                  }
                                  contractId={params.id as string}
                                  photos={
                                    (
                                      answers[
                                        category.slug as keyof AnswerProps
                                      ] as TCheckbox[]
                                    )?.find(
                                      (val: TCheckbox) =>
                                        val.slug === option.slug
                                    )?.images ?? []
                                  }
                                  onChangeCost={(val) =>
                                    handleChangeCost(
                                      category.slug,
                                      option.slug,
                                      val
                                    )
                                  }
                                />
                              )}
                          </div>
                        ))}
                      </>
                    )}
                    {category.field_type === "textbox" && (
                      <>
                        <label className="condition-title--small">
                          {category.title}
                        </label>
                        <FieldTextarea
                          value={answers[category.slug] as string}
                          onChange={(evt) => {
                            setAnswers({
                              ...answers,
                              [category.slug]: evt.target.value,
                            });
                          }}
                          placeholder="Enter any additional information"
                        />
                      </>
                    )}
                  </div>
                ))}
            </div>

            <div>
              {cosmeticForm && (
                <>
                  <label className="condition-title">
                    {cosmeticForm.title}
                  </label>
                  {cosmeticForm.options.map((option) => (
                    <div className="mt-6" key={option.slug}>
                      <FieldCheckbox
                        checked={
                          !!(
                            answers[cosmeticForm.slug] &&
                            (
                              answers[cosmeticForm.slug] as TCheckbox[]
                            ).findIndex(
                              (val: TCheckbox) => val.slug === option.slug
                            ) > -1
                          )
                        }
                        onChange={() =>
                          handleToggleCheck(cosmeticForm.slug, option.slug)
                        }
                        label={option.title}
                      />
                      {answers[cosmeticForm.slug] &&
                        (answers[cosmeticForm.slug] as TCheckbox[]).findIndex(
                          (val: TCheckbox) => val.slug === option.slug
                        ) > -1 && (
                          <CosmeticItem
                            limit={Number(
                              (option.children[0] as IFormRange).data.max
                            )}
                            cost={
                              (answers[cosmeticForm.slug] as TCheckbox[])?.find(
                                (val: TCheckbox) => val.slug === option.slug
                              )?.costs
                            }
                            label={option.slug}
                            onChangeImage={(imageIds: IImage[]) =>
                              handleChangeImages(
                                cosmeticForm.slug,
                                option.slug,
                                imageIds
                              )
                            }
                            contractId={params.id ?? ""}
                            photos={
                              (answers[cosmeticForm.slug] as TCheckbox[]).find(
                                (val: TCheckbox) => val.slug === option.slug
                              )?.images ?? []
                            }
                            onChangeCost={(val) =>
                              handleChangeCost(
                                cosmeticForm.slug,
                                option.slug,
                                val
                              )
                            }
                          />
                        )}
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
          <div className="condition__tool">
            <Button type="submit" isLoading={isLoading} onClick={handleSave}>
              <span className="condition__tool__txt">Save</span>
            </Button>
          </div>
        </div>
      </div>
      <Prompt isDirty={isDirty} />
    </div>
  );
};
