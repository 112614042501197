import { FC } from "react";

import { cardContainerTestID } from "../dataTestIDs";

import "./styles.scss";

export interface CardProps {
  onClick?: () => void;
  className?: string;
  dataTestID?: string;
}

export const Card: FC<CardProps> = ({
  children,
  className,
  onClick,
  dataTestID,
}) => {
  return (
    <div
      className={`card${className ? ` ${className}` : ""}`}
      onClick={onClick}
      data-testid={dataTestID || cardContainerTestID}
    >
      {children}
    </div>
  );
};
