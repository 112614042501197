import classNames from "classnames";
import { Link } from "react-router-dom";

import { navItemTestID } from "components/layouts/dataTestIDs";

import { PermissionCategory } from "utils/userPermissions";
import "../../styles.scss";

export interface NavItemProps {
  route: string;
  label: string;
  icon: string | string[];
  isActive?: boolean;
  hasAccess?: boolean;
  permission?: PermissionCategory;
  dataTestID?: string;
}

export const NavItem = ({
  route,
  label,
  icon,
  isActive,
  hasAccess = true,
  dataTestID,
}: NavItemProps) => {
  return (
    <Link to={route}>
      <div
        className={classNames("sidebar__menu-item", {
          active: isActive,
          "opacity-30": !hasAccess,
        })}
        data-testid={dataTestID || navItemTestID}
      >
        <div className="w-6 flex items-center justify-center mr-2">
          {Array.isArray(icon) ? (
            <div className="w-6 flex items-center justify-center mr-2">
              {icon.map((item) => (
                <img key={item.toString()} src={item.toString()} alt="icon" />
              ))}
            </div>
          ) : (
            <img src={icon.toString()} alt="icon" />
          )}
        </div>
        <span>{label}</span>
      </div>
    </Link>
  );
};
