import { Button } from "components/shared";

import "./styles.scss";
import { modalDeleteCarTestID } from "../dataTestIDs";

export interface ModalDeleteCarProps {
  onDelete: () => void;
  onCancel: () => void;
  dataTestID?: string;
}

export const ModalDeleteCar = ({
  onDelete,
  onCancel,
  dataTestID,
}: ModalDeleteCarProps) => {
  const handleDelete = () => {
    onDelete();
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <div
      className="delete-car"
      data-testid={dataTestID || modalDeleteCarTestID}
    >
      <div className="delete-car__title">
        Are you sure you want to delete this photo?
      </div>
      <div className="delete-car__footer">
        <div className="btn__cancel">
          <Button
            type="button"
            onClick={handleCancel}
            dataTestID={`${dataTestID || modalDeleteCarTestID}-cancel`}
          >
            <span>No</span>
          </Button>
        </div>
        <div className="btn__confirm">
          <Button
            type="button"
            onClick={handleDelete}
            dataTestID={`${dataTestID || modalDeleteCarTestID}-confirm`}
          >
            <span>Yes, Delete</span>
          </Button>
        </div>
      </div>
    </div>
  );
};
