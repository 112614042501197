import { ReactNode } from "react";

import { ReactComponent as ArrowDownIcon } from "assets/images/chevron-down.svg";
import { ReactComponent as ArrowUpIcon } from "assets/images/chevron-up-big.svg";

export const CollapsiblePanel = ({
  title,
  isCollapsed,
  onChange,
  children,
}: {
  title: string;
  isCollapsed: boolean;
  onChange: () => void;
  children: ReactNode;
}) => {
  return (
    <div className="mt-2 mb-2">
      <div
        className="flex flex-row justify-between cursor-pointer"
        onClick={onChange}
      >
        <p className="font-semibold text-base">{title}</p>
        <div>{isCollapsed ? <ArrowDownIcon /> : <ArrowUpIcon />}</div>
      </div>
      {!isCollapsed && children}
    </div>
  );
};
