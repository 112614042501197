import { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "context/AuthContext";

import {
  NavigatePermissionCategory,
  PermissionCategory,
} from "utils/userPermissions";

export const useNavigationByPermission = () => {
  const navigate = useNavigate();
  const { afterSigninPermissionCheck } = useContext(AuthContext);

  const permissionRoutes: Record<NavigatePermissionCategory, string> = {
    [PermissionCategory.REPORTS]: "/dashboard",
    [PermissionCategory.RETAIL_INSPECTIONS]: "/retail-dealership",
    [PermissionCategory.WHOLESALE_INSPECTIONS]: "/wholesale-dealership",
    [PermissionCategory.TENDER_SELLING]: "/tender/selling",
    [PermissionCategory.TENDER_BUYING]: "/tender/buying",
    [PermissionCategory.MOTOR_MARKET_SELLING]: "/motor-market/selling",
    [PermissionCategory.MOTOR_MARKET_BUYING]: "/motor-market/buying",
  };

  const navigateByPermission = useCallback(
    (permissions: string[]) => {
      let permissionMatched = false;
      for (const [category, route] of Object.entries(permissionRoutes)) {
        if (
          afterSigninPermissionCheck(
            permissions,
            category as PermissionCategory
          )
        ) {
          permissionMatched = true;
          navigate(route);
          break;
        }
      }

      if (!permissionMatched) {
        navigate("/retail-dealership");
      }
    },
    [navigate]
  );

  return { navigateByPermission };
};
