import { Button, SvgImage } from "components/shared";

interface InformativeModalProps {
  content: {
    message: string;
    title?: string;
    icon?: string;
  };
  onClose?: () => void;
}

export const InformativeModal = ({
  content,
  onClose,
}: InformativeModalProps) => {
  const defaultIcon = "InfoIcon";

  return (
    <div className="flex flex-col items-center w-250 gap-5">
      <SvgImage name={content.icon || defaultIcon} width={32} />
      {content.title && (
        <p className="font-semibold text-lg">{content.title}</p>
      )}
      <p className="font-medium text-lightBlue text-center">
        {content.message}
      </p>
      {onClose && (
        <Button type="button" onClick={onClose} className="!w-24 mt-7">
          <span className="font-bold">OK</span>
        </Button>
      )}
    </div>
  );
};
