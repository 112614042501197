import {
  IDirectOfferAcceptDeclinePayload,
  IDirectOfferBuyer,
  IDirectOfferLog,
  IDirectOfferPayload,
  IDirectOfferRevokePayload,
  IDirectOfferUpdatePayload,
  IOffer,
  IQueryKeyProps,
} from "types";

import { api, url } from "api";

export const DirectOfferService = {
  getBuyers: ({ queryKey }: IQueryKeyProps) =>
    api.get(`${url.offers}/${queryKey[1]}/buyers`) as Promise<{
      data: IDirectOfferBuyer[];
    }>,

  offerHistory: ({ queryKey }: IQueryKeyProps) =>
    api.get(`${url.offers}/${queryKey[1]}/histories`) as Promise<{
      data: IDirectOfferLog[];
    }>,

  sendOffer: (payload: IDirectOfferPayload, contractID: string) =>
    api.post(`${url.offers}/${contractID}`, payload) as Promise<{
      data: IOffer[];
    }>,

  updateOffer: (payload: IDirectOfferUpdatePayload, contractID: string) =>
    api.put(`${url.offers}/${contractID}`, payload) as Promise<{
      data: IOffer[];
    }>,

  acceptOffer: (payload?: IDirectOfferAcceptDeclinePayload, offerID?: string) =>
    api.post(`${url.offers}/${offerID}/accept`, payload),

  revokeOffer: (payload: IDirectOfferRevokePayload, contractID: string) =>
    api.post(`${url.offers}/${contractID}/revoke`, payload),

  declineOffer: (
    payload?: IDirectOfferAcceptDeclinePayload,
    offerID?: string
  ) => api.post(`${url.offers}/${offerID}/decline`, payload),
};
