import { useMutation, useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { useContext, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import {
  FieldState,
  LoadVehicleSummary,
  ModalCarouselCar,
} from "components/app";
import { FactoryOptions } from "components/FactoryOptions";
import { PPSR } from "components/PPSR";
import {
  Button,
  FieldTextarea,
  Prompt,
  SvgImage,
  Tab,
  TextError,
} from "components/shared";

import { AuthContext } from "context/AuthContext";

import {
  COMPLIANCE_STATES,
  COMPLIANCE_STATUS,
  ERROR_MESSAGE,
  FINANCE_STATES,
  FLOW_TYPE,
  formatNumber,
  showErrorToast,
  showSuccessToast,
  SPECIFICATION_STATES,
  VEHICLE_ROUTES,
} from "utils";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import {
  ICompliance,
  IFinance,
  IImage,
  ISpecification,
  IVehicleOptions,
  IVehicleSummary,
} from "types";

import { ReactComponent as ArrowDownIcon } from "assets/images/chevron-down.svg";
import { ReactComponent as ArrowUpIcon } from "assets/images/chevron-up-big.svg";

import { VehicleService } from "api/client";

import { useSessionStorage } from "hooks";

import "./styles.scss";

interface VehicleInformationProps {
  data: IVehicleSummary;
  defaultOpen?: boolean;
  flow?: FLOW_TYPE;
  vehicleRoute?: VEHICLE_ROUTES;
  refetch: () => void;
  isLocked?: boolean;
}

const Comments = ({
  comment,
  isExternal,
  contractID,
  canEdit = true,
  refetch,
}: {
  comment: string;
  isExternal: boolean;
  contractID: string;
  canEdit?: boolean;
  refetch: () => void;
}) => {
  const [textFieldEnabled, setTextFieldEnabled] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<{ notes: string }>({
    defaultValues: {
      notes: comment,
    },
  });

  const { mutate: saveRemarks, isLoading: savingRemarks } = useMutation(
    (payload: { remarks: string; type: string }) =>
      VehicleService.updateVehicle(payload, contractID),
    {
      onSuccess: () => {
        showSuccessToast("Saved successfully!");
        setTextFieldEnabled(false);
        refetch();
      },
      onError: () => {
        showErrorToast("Failed to Save");
      },
    }
  );

  return (
    <>
      <div className="font-semibold text-sm mt-5 mb-5 text-[#383B3F]">
        Public Vehicle Comments
      </div>
      <div className="text-sm font-normal text-[#383B3F] whitespace-pre-line overflow-auto max-h-[500px]">
        {!textFieldEnabled && (comment ? comment : !isExternal && "-")}
      </div>
      {textFieldEnabled && (
        <div className="w-full mb-9">
          <FieldTextarea
            {...register("notes")}
            className="max-h-[450px] min-h-[102px] !bg-white"
            maxLength={2500}
            placeholder="Enter your comments here"
            onChange={(e) => {
              e.currentTarget.value.length === 2500
                ? setError("notes", { type: "onChange" })
                : clearErrors("notes");
            }}
          />
          {errors.notes && <TextError error="2500 character limit reached" />}
        </div>
      )}
      {isExternal && !comment && (
        <p className="text-sm text-label">
          There are no comments from the dealership about this vehicle.
        </p>
      )}
      {!isExternal && !textFieldEnabled && canEdit && (
        <div className="w-full flex justify-between items-end mt-auto">
          <p className="text-sm text-label h-9">
            * Access editing page to add information
          </p>
          <div className="w-86">
            <Button type="button" onClick={() => setTextFieldEnabled(true)}>
              <span className="uppercase font-bold text-sm text-white">
                Edit
              </span>
            </Button>
          </div>
        </div>
      )}
      {textFieldEnabled && (
        <div className="flex w-full mt-3 justify-end">
          <div className="w-86">
            <Button
              type="submit"
              isLoading={savingRemarks}
              onClick={handleSubmit(({ notes }: { notes: string }) => {
                saveRemarks({ remarks: notes, type: "remarks" });
              })}
            >
              <span className="uppercase text-lighttxt text-sm font-bold">
                Save
              </span>
            </Button>
          </div>
        </div>
      )}
      <Prompt isDirty={textFieldEnabled} />
    </>
  );
};

const Specification = ({ data }: { data: ISpecification }) => (
  <>
    <div className="disclosure__pannel--wide">
      {Object.keys(SPECIFICATION_STATES).map((key) => (
        <FieldState
          key={key}
          name={SPECIFICATION_STATES[key as keyof typeof SPECIFICATION_STATES]}
          value={data[key as keyof typeof SPECIFICATION_STATES]}
        />
      ))}
      <div className="col-span-2">
        <div className="mb-4">
          <FieldState
            name="Model Description"
            variant="comment"
            value={data.model_name}
          />
        </div>
        <FieldState
          name="Specification Info"
          variant="comment"
          value={data.comment}
        />
      </div>
    </div>
  </>
);

const FeaturesAndOptions = ({ data }: { data: IVehicleSummary }) => {
  const { userID } = useContext(AuthContext);

  const [searchTerm, setSearchTerm] = useState("");

  const stringComments =
    data?.assessments?.[0]?.questionnaire?.[0]?.answers?.comments;
  const parsedComments =
    stringComments &&
    typeof stringComments === "string" &&
    JSON.parse(stringComments as unknown as string);

  const {
    data: vehicleOptions,
    isLoading: isLoadingVehicleOptions,
    isError: isErrorVehicleOptions,
    isSuccess: isSuccessVehicleOptions,
  } = useQuery<{ data: IVehicleOptions }, Error>(
    [`fetchVehicleOptions_${userID}`, data.contract_id],
    VehicleService.getVehicleOptions
  );

  const wheels = vehicleOptions?.data?.equipments?.wheels;

  const filteredData = useMemo(() => {
    const standardFeatures = vehicleOptions?.data?.equipments?.standard;
    const options = parsedComments?.options as string[];

    if (searchTerm === "") {
      return {
        standardFeatures,
        options,
        tyre_front: true,
        tyre_rear: true,
      };
    } else {
      return {
        standardFeatures: standardFeatures?.filter((feature) =>
          feature?.toLowerCase().includes(searchTerm.toLowerCase())
        ),
        options: options?.filter((item: string) =>
          item?.toLowerCase().includes(searchTerm.toLowerCase())
        ),
        tyre_front: "front wheel".includes(searchTerm.toLowerCase()),
        tyre_rear: "rear wheel".includes(searchTerm.toLowerCase()),
      };
    }
  }, [
    vehicleOptions?.data?.equipments?.standard,
    parsedComments?.options,
    searchTerm,
  ]);

  const renderItems = () => {
    if (isLoadingVehicleOptions) {
      return <LoadVehicleSummary />;
    }

    if (isErrorVehicleOptions) {
      return <div className="text-sm text-center">{ERROR_MESSAGE}</div>;
    }

    if (isSuccessVehicleOptions) {
      return (
        <div className="grid grid-cols-2">
          <div>
            <div className="relative">
              <input
                className="h-9 w-full rounded-4 border-solid border-grey border pl-10 focus:outline-none mb-7"
                placeholder="Search"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <span className="absolute top-2 left-3">
                <SvgImage name="SearchIcon" width={20} height={20} />
              </span>
            </div>
            {/* Hide - https://motorarbitrage.atlassian.net/jira/software/projects/MWA/boards/24?selectedIssue=MWA-1806
            <div className="mb-7">
              <div className="font-semibold text-sm mb-3">Wheels</div>
              {filteredData.tyre_front && (
                <div className="pl-4 flex">
                  <div className="mr-6 font-medium text-sm pb-2 min-w-[100px]">
                    Front Wheel
                  </div>
                  <div className="text-sm font-normal">
                    {parsedComments?.["tyre_front"] ||
                      wheels?.tyre_front ||
                      "-"}
                  </div>
                </div>
              )}
              {filteredData.tyre_rear && (
                <div className="pl-4 flex">
                  <div className="mr-6 font-medium text-sm pb-2 min-w-[100px] font">
                    Rear Wheel
                  </div>
                  <div className="text-sm font-normal">
                    {parsedComments?.["tyre_rear"] || wheels?.tyre_rear || "-"}
                  </div>
                </div>
              )}
            </div> 
            */}
            <div className="overflow-auto max-h-[400px]">
              <div className="font-semibold text-sm mb-3">Options</div>
              {filteredData?.options?.map((option: string) => (
                <div className="pb-2 text-sm font-normal" key={option}>
                  {option}
                </div>
              ))}
              {!filteredData?.options?.length && (
                <div className="text-label text-sm font-normal">
                  No Options entered.
                </div>
              )}
            </div>
          </div>
          <div className="mt-16">
            <div className="font-semibold text-sm mb-3">Standard Features</div>
            <div className="overflow-y-auto max-h-450">
              {filteredData?.standardFeatures?.map((feature) => (
                <div className="pb-2 text-sm font-normal" key={feature}>
                  {feature}
                </div>
              ))}
              {!filteredData?.standardFeatures && (
                <div className="text-label text-sm font-normal">
                  No standard features.
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="disclosure__pannel--wide--feature">{renderItems()}</div>
  );
};

const Condition = ({ data }: { data: IVehicleSummary }) => {
  const { vehicle } = data;

  const { odometer } = vehicle || {};

  const [isCarousel, setIsCarousel] = useState<boolean>(false);
  const [carouselImgs, setCarouselImgs] = useState<IImage[]>([]);
  const [activeCarouselItem, setActiveCarouselItem] = useState<number>(0);
  const conditions = data?.assessments?.[1]?.questionnaire;

  if (!conditions) {
    return (
      <div className="disclosure__pannel__placeholder">
        Information not entered
      </div>
    );
  }

  const handleCarousel = (activeIndex: number, imgs: IImage[]) => {
    setIsCarousel(true);
    setActiveCarouselItem(activeIndex);
    if (Array.isArray(imgs)) {
      setCarouselImgs(imgs);
    } else {
      const newImgs = Object.values(imgs).map((val) => val) as IImage[];
      setCarouselImgs(newImgs);
    }
  };

  return (
    <div className="condition-content disclosure__pannel--wide">
      <FieldState name="Kilometres" value={formatNumber(odometer)} />

      <FieldState
        name={conditions[0].title}
        value={
          conditions[0].answers?.options
            ? conditions[0].answers.options[0]?.title
            : ""
        }
      />
      <FieldState
        name={conditions[1].title}
        value={
          conditions[1].answers?.options
            ? conditions[1].answers.options[0]?.title
            : ""
        }
      />

      {conditions.slice(2, 4).map((condition) => (
        <>
          <FieldState name={condition.title} value=" " />
          {condition.answers?.options && (
            <div className="pl-5 flex flex-col gap-3">
              {condition.answers.options.map((option, index) => (
                <div key={index}>
                  <div className="condition-content__info">
                    <FieldState name={option.title} value=" " />
                    <FieldState
                      name="Repair Cost"
                      value={`$${option.costs || 0}`}
                    />
                  </div>
                  <div className="condition-content__images">
                    {Object.entries(option.images).length > 0 &&
                      Object.values(option.images).map((image, idx) => (
                        <img
                          src={image?.url}
                          key={image?.id}
                          alt={image.label}
                          onClick={() => handleCarousel(idx, option.images)}
                        />
                      ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      ))}
      <ModalCarouselCar
        open={isCarousel}
        images={carouselImgs || []}
        activeIndex={activeCarouselItem}
        closeModal={() => setIsCarousel(false)}
      />

      <FieldState
        name={conditions[4]?.title}
        value={
          conditions[4]?.answers?.comments[0] &&
          conditions[4]?.answers?.comments[0] !== "undefined"
            ? conditions[4]?.answers.comments[0]
            : "-"
        }
      />
    </div>
  );
};

const Compliance = ({
  data,
  finance,
}: {
  data: ICompliance;
  finance: IFinance;
}) => {
  return (
    <div className="disclosure__pannel--wide">
      <div className="disclosure__pannel__section">
        {Object.keys(COMPLIANCE_STATES).map((key) => {
          if (key === "registration_status") {
            return (
              <FieldState
                key={key}
                name={COMPLIANCE_STATES[key as keyof typeof COMPLIANCE_STATES]}
                value={
                  data[key as keyof typeof COMPLIANCE_STATES] ===
                  COMPLIANCE_STATUS.REGISTERED
                    ? "Yes"
                    : "No"
                }
                allowNameWrapping
              />
            );
          }
          return (
            <FieldState
              key={key}
              name={COMPLIANCE_STATES[key as keyof typeof COMPLIANCE_STATES]}
              value={data[key as keyof typeof COMPLIANCE_STATES]}
              allowNameWrapping
            />
          );
        })}
      </div>
      {finance && (
        <div className="disclosure__pannel__section w">
          <span className="state__name">Finance Information</span>
          {Object.keys(FINANCE_STATES).map((key) => (
            <FieldState
              key={key}
              name={FINANCE_STATES[key]}
              value={
                key === "owing"
                  ? `$${formatNumber((finance as any)[key])}`
                  : (finance as any)[key]
              }
            />
          ))}
        </div>
      )}
      <FieldState
        variant="comment"
        name="Compliance Info"
        style={{ gridRow: 2 }}
        value={data.comment}
      />
    </div>
  );
};

export const VehicleInformation = ({
  data,
  defaultOpen,
  flow,
  vehicleRoute,
  refetch,
  isLocked,
}: VehicleInformationProps) => {
  const params = useParams();
  const navigate = useNavigate();
  const { hasPermission } = useContext(AuthContext);

  const [currentTab, setCurrentTab] = useSessionStorage(
    "ACTIVE_VEHICLE_INFORMATION_TAB",
    0
  );

  const handleGoChecklist = (path: string) => {
    sessionStorage.setItem(
      "SCROLL_POSITION",
      `${document.getElementsByClassName("dashboard")[0].scrollTop}`
    );

    if (vehicleRoute === VEHICLE_ROUTES.Stocklist) {
      navigate(
        `/${
          flow === FLOW_TYPE.WHOLESALE_DEALERSHIP ? "wholesale" : "retail"
        }-dealership/stocklist/${params.id}/${path}`
      );
    } else if (vehicleRoute === VEHICLE_ROUTES.Valued) {
      navigate(
        `/${
          flow === FLOW_TYPE.WHOLESALE_DEALERSHIP ? "wholesale" : "retail"
        }-dealership/valued/${params.id}/${path}`
      );
    } else {
      navigate(
        `/${
          flow === FLOW_TYPE.WHOLESALE_DEALERSHIP ? "wholesale" : "retail"
        }-dealership/${params.id}/${path}`
      );
    }
  };

  const { vehicle, expenses, is_locked } = data;
  const { compliance, specification } = vehicle || {};

  const [activeTab, setActiveTab] = useState<number>(currentTab);
  const [showPanel, setShowPanel] = useState(defaultOpen);

  const { user } = useContext(AuthContext);
  const isMPUser = user?.data.user.is_mp_user;

  const handleToggleShowPanel = () => {
    setShowPanel((showPanel) => !showPanel);
  };

  const isEditingDisabled = isLocked || is_locked;

  const vehicleTabs = [
    {
      label: "COMMENTS",
      key: "COMMENTS",
      tabPanel: (
        <Comments
          comment={data?.vehicle?.remarks || ""}
          isExternal={isEditingDisabled}
          contractID={data.contract_id}
          canEdit={!isEditingDisabled}
          refetch={refetch}
        />
      ),
    },
    {
      label: "SPECIFICATION",
      key: "SPECIFICATION",
      tabPanel: <Specification data={specification} />,
      onEdit: isEditingDisabled
        ? undefined
        : () => handleGoChecklist("specification"),
    },
    {
      label: "FEATURES & OPTIONS",
      key: "FEATURES_OPTIONS",
      tabPanel: <FeaturesAndOptions data={data} />,
      onEdit: isEditingDisabled
        ? undefined
        : () => handleGoChecklist("features"),
    },
    {
      label: "FACTORY OPTIONS",
      key: "FACTORY_OPTIONS",
      tabPanel: (
        <FactoryOptions
          contractID={data.contract_id}
          hasFactoryOptions={!!data.has_factory_options}
          vin={
            data?.vehicle?.compliance?.vin.value ||
            (data?.vehicle?.compliance.vin as unknown as string)
          }
        />
      ),
      hidden:
        !hasPermission(
          PermissionCategory.FACTORY_OPTIONS,
          PermissionAction.VIEW_ANY
        ) ||
        !data?.vehicle?.allow_factory_options ||
        (!data?.is_contract_owner &&
          (isMPUser ? !isMPUser : !data.has_factory_options)),
    },
    {
      label: "CONDITION",
      key: "CONDITION",
      tabPanel: <Condition data={data} />,
      onEdit: isEditingDisabled
        ? undefined
        : () => handleGoChecklist("condition"),
    },
    {
      label: "COMPLIANCE",
      key: "COMPLIANCE",
      tabPanel: <Compliance data={compliance} finance={expenses} />,
      onEdit: isEditingDisabled
        ? undefined
        : () => handleGoChecklist("compliance"),
    },
    {
      label: "PPSR",
      key: "PPSR",
      tabPanel: (
        <PPSR
          contractID={data.contract_id}
          status={data.ppsr?.status}
          refetch={refetch}
        />
      ),
      onEdit: undefined,
      hidden:
        !hasPermission(
          PermissionCategory.PPSR_SEARCH,
          PermissionAction.VIEW_ANY
        ) ||
        (!data.is_contract_owner && !data.seller),
    },
  ];

  const countOfTabsShown = vehicleTabs.filter((tab) => !tab.hidden).length;

  return (
    <div
      className={classNames("section", {
        "!min-h-[780px]": showPanel,
      })}
    >
      <div
        className="section__header cursor-pointer"
        onClick={handleToggleShowPanel}
      >
        <span>Vehicle Information</span>
        {showPanel ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </div>
      <div className="section__body--info">
        <Tab
          items={vehicleTabs}
          defaultActiveTab={vehicleTabs[activeTab].hidden ? 0 : activeTab}
          showPanel={!!showPanel}
          onSetActiveTab={(index: number) => {
            setActiveTab(index);
            setShowPanel(true);
            setCurrentTab(index);
          }}
          fitContent={countOfTabsShown === vehicleTabs.length}
        />
      </div>
    </div>
  );
};
