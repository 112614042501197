import {
  IDealershipDetail,
  IDealershipDetailResponse,
  IQueryKeyProps,
  IVehicle,
  IVehicleSummary,
} from "types";

import { api, url } from "api";

export const DealershipInspectionService = {
  getDealershipSummary: ({ queryKey }: IQueryKeyProps) =>
    api.get(`${url.dealershipInspection}/${queryKey[1]}`) as Promise<{
      data: IVehicleSummary;
    }>,

  startInspection: (payload: IVehicle) =>
    api.post(`${url.dealershipInspection}`, payload),

  sendForValuation: (contract_id: string) =>
    api.post(`${url.dealershipInspection}/${contract_id}/submit`, {}),

  addVehicleToStocklist: (
    contract_id: string,
    payload: Record<string, unknown>
  ) =>
    api.post(
      `${url.dealershipInspection}/${contract_id}/submit`,
      payload || {}
    ),

  getDealershipPool: ({ queryKey }: IQueryKeyProps) =>
    api.get(
      `${url.dealerships}/${queryKey[1]}/pools?query=${queryKey[2]}`
    ) as Promise<{ data: IDealershipDetail[] }>,

  getDealershipPools: (
    { queryKey }: IQueryKeyProps // params: queryKey[0]: dealership_id
  ) =>
    api.get(
      `${url.dealerships}/${queryKey[1]}/pools`,
      queryKey[2]
    ) as Promise<IDealershipDetailResponse>,

  updateDealershipPools: (data: any, dealershipId: string | undefined) => {
    return api.patch(`${url.dealerships}/${dealershipId}/pools`, {
      member_id: data,
    }) as Promise<{ data: IDealershipDetail[] }>;
  },

  createDealershipPool: (data: any, dealershipId: string) =>
    api.post(`${url.dealerships}/${dealershipId}/pools`, data),

  setAllToDefault: (
    dealershipId: string | undefined,
    payload: { all_selected: boolean }
  ) => api.patch(`${url.dealerships}/${dealershipId}/all-pools`, payload),
};
