import { textErrorTestID } from "../dataTestIDs";

export interface TextErrorProps {
  error: string;
  dataTestID?: string;
}

export const TextError = ({ error, dataTestID }: TextErrorProps) => {
  return (
    <span
      className="text-darkRed text-sm"
      data-testid={dataTestID || textErrorTestID}
    >
      {error}
    </span>
  );
};
