import { useMutation } from "@tanstack/react-query";
import classNames from "classnames";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";

import { ModalContainer } from "components/ModalContainer";
import { AddVehiclesForm } from "components/MotorMarket/AddVehiclesForm";
import { AuctionDetailsForm } from "components/MotorMarket/AuctionDetailsForm/AuctionDetailsForm";
import { Button } from "components/shared";

import { AuthContext } from "context/AuthContext";
import { useDealership } from "context/DealershipContext";

import {
  convertDayAndTimeToISO,
  showErrorToast,
  showSuccessToast,
} from "utils";

import {
  IMotorMarketDraftResponse,
  IMotorMarketForm,
} from "types/IMotorMarket";

import { MotorMarketService } from "api/client";

export interface CreateListingModalProps {
  isOpen: boolean;
  onClose: () => void;
  step?: "Details" | "Vehicles";
  currentAuctionID?: string;
  refetch?: () => void;
  setIsCreateListingModalOpen?: (open: boolean) => void;
}

export const CreateListingModal = ({
  isOpen,
  onClose,
  step,
  currentAuctionID,
  refetch,
  setIsCreateListingModalOpen,
}: CreateListingModalProps) => {
  const [currentStep, setCurrentStep] = useState<"Details" | "Vehicles">(
    step ?? "Details"
  );
  const [closeForm, setCloseForm] = useState(false);

  const [auctionID, setAuctionID] = useState(currentAuctionID ?? "");

  const { user } = useContext(AuthContext);
  const { dealershipID, showAllDealerships } = useDealership();

  const { mutate, isLoading } = useMutation(
    (data: IMotorMarketForm) => MotorMarketService.createGroup(data),
    {
      onSuccess: (data: { data: IMotorMarketDraftResponse }) => {
        if (closeForm) {
          showSuccessToast("Draft Saved");
          onCloseForm();
          refetch && refetch();
        } else {
          setAuctionID(data?.data?.auction_group_id);
          setCurrentStep("Vehicles");
        }
      },
      onError: () => {
        showErrorToast("Failed to Save");
      },
    }
  );

  const { mutate: updateGroup, isLoading: updatingGroup } = useMutation(
    (payload: IMotorMarketForm) =>
      MotorMarketService.updateGroup(auctionID || "", "draft", payload),
    {
      onSuccess: () => {},
      onError: () => {
        showErrorToast("Failed to Publish");
      },
    }
  );

  const onNext = (data: IMotorMarketForm) => {
    if (!validateEndDate(data)) {
      return;
    }
    setCloseForm(false);
    saveForm(data);
  };

  const onSaveDraft = (data: IMotorMarketForm) => {
    setCloseForm(true);
    if (auctionID) {
      updateForm(data);
    } else {
      saveForm(data);
    }
  };

  const saveForm = (data: IMotorMarketForm) => {
    const isoDateTime = convertDayAndTimeToISO(
      data.ends_at,
      data.ends_at_time || ""
    );

    mutate({
      contact_name: data.contact_name,
      contact_phone: data.contact_phone,
      location: data.location,
      ends_at: isoDateTime,
      dealership_id: !showAllDealerships ? dealershipID : undefined,
    });
  };

  const updateForm = (data: IMotorMarketForm) => {
    const isoDateTime = convertDayAndTimeToISO(
      data.ends_at,
      data.ends_at_time || ""
    );

    updateGroup({
      contact_name: data.contact_name,
      contact_phone: data.contact_phone,
      location: data.location,
      ends_at: isoDateTime,
    });
  };

  const defaultValues = {
    contact_name: user?.data.dealership?.sales?.agent || "",
    contact_phone: user?.data.dealership?.sales?.phone || "",
    ends_at_time: "",
    ends_at: "",
    location: "",
  };

  const {
    register,
    setValue,
    handleSubmit,
    setFocus,
    control,
    formState,
    reset,
    getValues,
    setError,
  } = useForm<IMotorMarketForm>({
    defaultValues,
  });

  const validateEndDate = (formData: IMotorMarketForm) => {
    const { ends_at, ends_at_time } = formData;
    if (new Date(`${ends_at} ${ends_at_time}`) < new Date()) {
      setError("ends_at", { type: "validate" });
      return false;
    }

    return true;
  };

  const onCloseForm = (showWarning?: boolean) => {
    if (showWarning && currentStep === "Details") {
      showErrorToast("Listings Not Saved");
    }
    step ? setCurrentStep(step) : setCurrentStep("Details");
    reset(defaultValues, { keepValues: false, keepDirty: false });
    onClose();
  };

  const renderStep = (step: "Details" | "Vehicles") => {
    if (step === "Details") {
      return (
        <AuctionDetailsForm
          register={register}
          setValue={setValue}
          formState={formState}
          setFocus={setFocus}
          control={control}
          isLoading={closeForm ? false : isLoading}
          data={defaultValues}
          getValues={getValues}
        />
      );
    } else {
      return (
        <AddVehiclesForm
          auctionID={currentAuctionID ?? auctionID}
          onAdded={(status) => {
            if (status && refetch) refetch();
            setIsCreateListingModalOpen && setIsCreateListingModalOpen(false);
            onCloseForm();
          }}
        />
      );
    }
  };

  return (
    <ModalContainer
      title={currentStep === "Details" ? "My Listings" : "Add Vehicles"}
      isOpen={isOpen}
      canClose={true}
      size="responsive"
      onClose={() => onCloseForm(true)}
    >
      <div
        className={classNames("h-[600px] w-[1000px] space-y-[80px]", {
          "w-[1400px] h-[650px]": currentStep === "Vehicles",
        })}
      >
        <div className="flex justify-center items-center space-x-4">
          <div className="flex items-center">
            <p
              className={classNames(
                "flex justify-center m-2 h-[22px] w-[22px]",
                {
                  "bg-[#3A3A44] text-white rounded-full ":
                    currentStep === "Details",
                  "bg-[#F6F7F9] text-black rounded-full": !(
                    currentStep === "Details"
                  ),
                }
              )}
            >
              1
            </p>
            <span
              className={classNames("text-sm", {
                "font-semibold": currentStep === "Details",
              })}
            >
              Auction Details
            </span>
          </div>
          <div className="w-32 border-t border-gray-300"></div>
          <div className="flex items-center">
            <p
              className={classNames(
                "flex justify-center m-2 h-[22px] w-[22px]",
                {
                  "bg-[#3A3A44] text-white rounded-full ":
                    currentStep === "Vehicles",
                  "bg-[#F6F7F9] text-black rounded-full": !(
                    currentStep === "Vehicles"
                  ),
                }
              )}
            >
              2
            </p>
            <span
              className={classNames("text-sm", {
                "font-semibold": currentStep === "Vehicles",
              })}
            >
              Vehicles
            </span>
          </div>
        </div>
        {renderStep(currentStep)}
        {currentStep === "Details" && (
          <div className="flex justify-center items-center gap-4 w-[320px] m-auto">
            <Button
              type="button"
              className="!bg-white shadow-md font-semibold uppercase"
              onClick={handleSubmit(onSaveDraft)}
              isLoading={updatingGroup || isLoading}
            >
              <span className="text-primary">Save Draft</span>
            </Button>
            <Button
              type="button"
              className="font-semibold uppercase"
              isLoading={isLoading}
              onClick={handleSubmit(onNext)}
            >
              <span>Next</span>
            </Button>
          </div>
        )}
      </div>
    </ModalContainer>
  );
};
