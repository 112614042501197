import { IDirectOfferBuyer, IDirectOfferPayload } from "types/IDirectOffer";

export const directOffer = {
  offers(buyers: IDirectOfferBuyer[], payload: IDirectOfferPayload) {
    const selectedDealerships = buyers.filter((buyer: IDirectOfferBuyer) =>
      payload.dealership_ids.includes(buyer.id)
    );
    const existingOffers = selectedDealerships.filter(
      (buyer: IDirectOfferBuyer) => buyer?.offer?.offer_amount
    );
    const newOffers = selectedDealerships.filter(
      (buyer: IDirectOfferBuyer) =>
        !buyer?.offer == null || !buyer?.offer?.offer_amount
    );

    return { existingOffers, newOffers };
  },
};
