import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { CardTender, TenderVehicleSection } from "components/app";
import { ErrorMessage } from "components/ErrorMessage";
import { NoPermissionMessage } from "components/NoPermissionMessage";
import { Banner, Breadcrumbs } from "components/shared";

import { AuthContext } from "context/AuthContext";
import { useDealership } from "context/DealershipContext";

import { TENDER_FLOW_TYPE, TENDER_MENU, TENDER_STATUS_LIST } from "utils";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import { NavigationMenuLabel } from "types";

import { TenderService } from "api/client";

export const TenderBuyingSummary = () => {
  const { dealershipID } = useDealership();
  const { userID, hasPermission } = useContext(AuthContext);
  const params = useParams();

  const canView = hasPermission(
    PermissionCategory.TENDER_BUYING,
    PermissionAction.VIEW
  );

  const { data, isLoading, isError } = useQuery(
    [`tender-buyer-summary-${userID}`, params.id, dealershipID],
    TenderService.getTenderBuyingSummary,
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: canView,
    }
  );

  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem("SCROLL_POSITION");
    if (savedScrollPosition) {
      document
        .getElementsByClassName("dashboard")[0]
        .scrollTo({ behavior: "smooth", top: parseInt(savedScrollPosition) });
      sessionStorage.removeItem("SCROLL_POSITION");
    }

    return () => {
      document.getElementsByClassName("dashboard")[0].scrollTo({ top: 0 });
    };
  }, []);

  return (
    <div className="dashboard__container">
      <div className="flex justify-between w-full mb-15">
        <div className="flex flex-col flex-1 gap-2.5">
          <Breadcrumbs
            paths={[
              {
                title: NavigationMenuLabel.TENDER,
                route: TENDER_MENU.buying.route,
              },
              { title: "Buying", route: TENDER_MENU.buying.route },
              {
                title: "Tender Summary",
                route: `${TENDER_MENU.buying.route}/${params.id}/summary`,
              },
            ]}
          />
          <div className="title">{data?.data.name || ""}</div>
        </div>
        {data && (
          <div className="w-430">
            <Banner
              name={data.data.status}
              size="sm"
              label={
                TENDER_STATUS_LIST.find(
                  (status) => status.key === data.data.status
                )?.label || ""
              }
            />
          </div>
        )}
      </div>
      {!canView && (
        <NoPermissionMessage
          viewPermissionCategory={PermissionCategory.TENDER_BUYING}
        />
      )}
      {isLoading && canView && <span>Loading...</span>}
      {isError && <ErrorMessage />}
      {data && !isError && (
        <>
          <CardTender data={data.data} onClick={() => {}} />
          <TenderVehicleSection
            data={data.data}
            from={TENDER_FLOW_TYPE.BUYING}
            canView={canView}
            canBid={hasPermission(
              PermissionCategory.TENDER_BUYING,
              PermissionAction.BID
            )}
          />
        </>
      )}
    </div>
  );
};
