import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { VinRegoFormType } from "components/app/Form/Search/FormVinRegoSearch";
import {
  Button,
  FieldText,
  FieldDropdown,
  TextError,
  ButtonLink,
  SvgImage,
} from "components/shared";

import { AuthContext } from "context/AuthContext";

import {
  CUSTOMER_SEARCHED_VEHICLE,
  INSPECTION_STEP,
  STATE_OPTIONS,
} from "utils";

import { CustomerInspectionService, VehicleSearchService } from "api/client";

import { useLocalStorage } from "hooks";

export const FindVehicle = () => {
  const { userID } = useContext(AuthContext);

  const navigate = useNavigate();
  const params = useParams();
  const [isSearching, setIsSearching] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, saveVehicle] = useLocalStorage(CUSTOMER_SEARCHED_VEHICLE, null);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      plate: "",
      state: "",
    },
  });

  const { data: verifyData } = useQuery(
    [`verify_inspection_link_${userID}`, params.id],
    CustomerInspectionService.verifyInspectionLInk
  );

  useEffect(() => {
    if (verifyData) {
      const { status } = verifyData.data;

      if (status.name === INSPECTION_STEP.INSPECTION_SUBMITTED) {
        return navigate(`/inspection/customer/${params.id}/complete`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyData]);

  const handleFindVehicle = async (data: VinRegoFormType) => {
    try {
      setIsSearching(true);
      const searchResult = await VehicleSearchService.searchPlate(
        data,
        params.id || ""
      );
      if (searchResult) {
        setIsSearching(false);
        saveVehicle(searchResult.data);
        navigate("../vehicle-confirmation");
      }
    } catch (err) {
      setIsSearching(false);
      toast.error("Failed to Find Vehicle", {
        theme: "colored",
        autoClose: 2000,
      });
    }
  };

  const handleVin = () => {
    navigate(`../find-vehicle-vin`);
  };

  const handlePlate = (value: string) => {
    setValue("plate", value.replace(/\s/g, "").toUpperCase());
  };

  return (
    <div className="flex flex-col justify-between text-primary w-full mb-5 h-full">
      <div className="flex-1 w-full flex flex-col overflow-auto px-5">
        <div className="font-medium text-sm mt-7">
          <h5 className="font-bold text-2xl mb-5 mt-95">Find your vehicle</h5>
          <p className="text-label font-normal mb-4">
            Please enter the following details:
          </p>
          <div className="mb-[18px]">
            <FieldText
              title="Registration Plate"
              type="text"
              {...register("plate", { required: true })}
              placeholder="ABC123"
              onChange={(e) => handlePlate(e.target.value)}
            />
            {errors.plate && <TextError error="Please check your entry." />}
          </div>

          <div className="mb-[18px]">
            <Controller
              render={({ field: { value, onChange } }) => (
                <FieldDropdown
                  title="Registration State"
                  value={value}
                  onChange={onChange}
                  placeholder="Select State"
                  options={STATE_OPTIONS}
                />
              )}
              control={control}
              name="state"
              rules={{ required: true }}
            />
            {errors.state && <TextError error="Please check your entry." />}
          </div>
        </div>

        <div className="font-medium text-sm mt-7">
          <div className="text-darkKnight font-semibold text-sm block mb-2">
            Or&nbsp;
            <ButtonLink onClick={handleVin}>
              <p className="underline">Enter VIN number</p>
            </ButtonLink>
          </div>
        </div>
        <div className="mt-auto">
          <Button
            type="submit"
            isLoading={isSearching}
            onClick={handleSubmit(handleFindVehicle)}
          >
            <span className="uppercase text-white text-sm font-bold">
              Search
            </span>
          </Button>
        </div>
        <div className="flex flex-row items-center mt-5 space-x-1 justify-center">
          <p className="text-label font-norma text-sm">powered by</p>
          <SvgImage name="MPLogo" className="mt-1" width={116} height={39} />
        </div>
      </div>
    </div>
  );
};
