import { MouseEventHandler, useCallback, useLayoutEffect, useRef } from "react";

export const useManageDisclosure = <T extends HTMLElement = HTMLButtonElement>(
  sideEffectDeps: any[]
) => {
  const collectedElementRef = useRef(new Set<T>([]));

  // clear old disclosure button element after rerender to prevent stockpiling (e.g. change of url pathname)
  useLayoutEffect(() => {
    return () => collectedElementRef.current.clear();
  }, sideEffectDeps);

  const onMouseUp: MouseEventHandler<T> = useCallback((event) => {
    const targetEl = event.target as HTMLElement;
    const targetDisclosureButton = targetEl.closest(
      '[id^="headlessui-disclosure-button-"]'
    );
    const targetDisclosureButtonIsOpen = targetDisclosureButton?.matches(
      '[aria-expanded="true"]'
    );

    if (!targetDisclosureButton || targetDisclosureButtonIsOpen) return;

    collectedElementRef.current.forEach((disclosureButton) => {
      if (disclosureButton?.matches('[aria-expanded="true"]')) {
        disclosureButton.click();
      }
    });
  }, []);

  const collectElement = useCallback((node: T) => {
    collectedElementRef.current.add(node);
  }, []);

  return {
    ref: collectElement,
    onMouseUp,
  };
};
