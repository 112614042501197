import { useState, useMemo } from "react";

import { FormConfirmVariant, FormVinRegoSearch } from "components/app";
import { modalSearchVinRegoTestID } from "components/app/dataTestIDs";

import { IVehicle } from "types";

import "../styles.scss";

enum STEPS {
  VEHICLE_SEARCH = "SEARCH",
  CONFIRM_VARIANT = "CONFIRM_VARIANT",
}

export interface ModalSearchVinRegoProps {
  inspectionType: string;
  dataTestID?: string;
  onCreatedNew: (contract_id: string) => void;
}

export const ModalSearchVinRego = ({
  inspectionType,
  dataTestID,
  onCreatedNew,
}: ModalSearchVinRegoProps) => {
  const [selectedVehicle, setSelecteVehicle] = useState<IVehicle>();
  const [step, setStep] = useState<STEPS>(STEPS.VEHICLE_SEARCH);

  const modalSearchTitle = useMemo(() => {
    switch (step) {
      case STEPS.VEHICLE_SEARCH:
        return "Search by VIN/REGO";
      case STEPS.CONFIRM_VARIANT:
        return "Is this your vehicle?";
      default:
        return "";
    }
  }, [step]);

  const handleStep = (curStep: string) => {
    if (curStep === STEPS.CONFIRM_VARIANT) {
      setStep(STEPS.VEHICLE_SEARCH);
    }
  };

  return (
    <div
      className="vin-rego"
      data-testid={dataTestID || modalSearchVinRegoTestID}
    >
      <div className="vin-rego__form">
        <h3 className="vin-rego__form__title">{modalSearchTitle}</h3>
        {step === STEPS.VEHICLE_SEARCH && (
          <FormVinRegoSearch
            onNextStep={(vehicle) => {
              setSelecteVehicle(vehicle);
              setStep(STEPS.CONFIRM_VARIANT);
            }}
          />
        )}
        {step === STEPS.CONFIRM_VARIANT && (
          <FormConfirmVariant
            searchedVehicle={selectedVehicle}
            inspectionType={inspectionType}
            onNextStep={onCreatedNew}
            onBack={handleStep}
          />
        )}
      </div>
    </div>
  );
};
