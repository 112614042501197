import ContentLoader from "react-content-loader";

import { loadVehicleTestID } from "../dataTestIDs";

import "./styles.scss";

export const LoadVehicle = () => {
  return (
    <div className="list" data-testid={loadVehicleTestID}>
      {[0, 0, 0, 0].map((_item, idx) => (
        <ContentLoader key={idx} speed={2} viewBox="0 0 946 141">
          <rect width="311" height="141" rx="20" fill="#F3F3F3" />
          <rect x="342" y="13" width="278" height="40" rx="20" fill="#F3F3F3" />
          <rect x="342" y="63" width="278" height="20" rx="10" fill="#F3F3F3" />
          <rect
            x="342"
            y="93"
            width="278"
            height="33"
            rx="16.5"
            fill="#F3F3F3"
          />
          <rect x="668" y="13" width="278" height="20" rx="10" fill="#F3F3F3" />
          <rect x="668" y="44" width="278" height="20" rx="10" fill="#F3F3F3" />
          <rect x="668" y="75" width="278" height="20" rx="10" fill="#F3F3F3" />
          <rect
            x="668"
            y="106"
            width="278"
            height="20"
            rx="10"
            fill="#F3F3F3"
          />
        </ContentLoader>
      ))}
    </div>
  );
};
