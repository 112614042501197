import {
  IQueryKeyProps,
  IVehicleResponse,
  IVehicleSummaryResponse,
} from "types";

import { api, url } from "api";

export const NegotiationService = {
  getVehicleList: ({ queryKey }: IQueryKeyProps) =>
    api.get(url.negotiations, queryKey[1]) as Promise<IVehicleResponse>,

  getNegotiationSummary: ({ queryKey }: IQueryKeyProps) =>
    api.get(
      `${url.negotiations}/${queryKey[1]}`,
      queryKey[2]
    ) as Promise<IVehicleSummaryResponse>,

  negotiationOffer: (
    contractID: string,
    payload: { offer_type: string; offer_amount: string; comment: string }
  ) => api.post(`${url.acquisitions}/${contractID}`, payload),

  negotiationRevert: (contractId: string, payload: { reason: string }) =>
    api.delete(`${url.acquisitions}/${contractId}/revert`, payload),
};
