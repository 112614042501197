export enum SECTIONS {
  RETAIL_DEALERSHIP = "Retail Dealership",
  WHOLESALE_DEALERSHIP = "Wholesale Dealership",
  TENDER = "Tender",
  PROFILE = "Profile",
  SETTINGS = "Settings",
  MOTORMARKET = "MotorMarket",
}

export const getPageTitle = (title: string, section?: SECTIONS) =>
  `${section ? section + " - " : ""} ${title} - MotorPlatform`;
