import { DateTime } from "luxon";

import { ModalContainer } from "components/ModalContainer";

import { formatNumberWithDecimal } from "utils";

import { ITrade } from "types";

interface PricingInsightsModalProps {
  data?: ITrade;
  isOpen: boolean;
  onClose: () => void;
  dateCreated?: string;
}

export const PricingInsightsModal = ({
  data,
  isOpen,
  onClose,
  dateCreated,
}: PricingInsightsModalProps) => {
  const pricingInsightDate = new Date(
    dateCreated ? dateCreated?.replace("- ", "") : Date.now()
  );
  return (
    <ModalContainer
      isOpen={isOpen}
      title="Pricing Insights"
      size="auto"
      onClose={onClose}
    >
      {
        <div className="w-[300px] flex flex-col text-primary text-sm font-medium gap-6 mt-20 text-cornflowerBlue">
          {[
            { label: "Low Trade", value: data?.low },
            { label: "High Trade", value: data?.trade },
            { label: "Recommended Retail Used", value: data?.retail },
            { label: "Recommended Retail New", value: data?.rrp },
          ].map((item) => {
            return (
              <div
                key={item.label}
                className="flex items-center justify-between pb-2 border-b border-[#EFEFEF] font-medium text-sm"
              >
                {item.label}
                <span className="font-medium">{`${
                  item.value ? `$${formatNumberWithDecimal(item.value)}` : "-"
                }`}</span>
              </div>
            );
          })}
          <div className="flex flex-col text-primary text-sm font-normal">
            <div className="text-[#9696A0] text-right">
              {`*Pricing as at ${DateTime.fromJSDate(
                pricingInsightDate
              ).toFormat("d LLL yyyy")}`}
            </div>
          </div>
        </div>
      }
    </ModalContainer>
  );
};
