import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import {
  Footer,
  LoadVehicleSummary,
  NotifyUpdate,
  SellerInformation,
  SummaryHeader,
  ValuationsSection,
  VehicleInformation,
} from "components/app";
import { ErrorMessage } from "components/ErrorMessage";
import { NoPermissionMessage } from "components/NoPermissionMessage";
import { Banner, Breadcrumbs } from "components/shared";
import { VehicleComments } from "components/VehicleNotes";
import { VehiclePhotoContainer } from "components/VehiclePhotoContainer";

import { AuthContext } from "context/AuthContext";

import {
  AWAITING,
  FLOW_TYPE,
  RETAIL_DEALERSHIP_MENU,
  VEHICLE_SUMMARY_TITLE,
  WHOLESALE_DEALERSHIP_MENU,
} from "utils";
import { getPageTitle, SECTIONS } from "utils/pageSections";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import { IVehicleSummaryResponse, NavigationMenuLabel } from "types";

import { InspectionService } from "api/client";

import "./styles.scss";

export const ValuationSummary = ({ flow }: { flow: FLOW_TYPE }) => {
  const { userID, hasPermission } = useContext(AuthContext);
  const params = useParams();

  const isRetailDealershipFlow = flow === FLOW_TYPE.RETAIL_DEALERSHIP;

  const canRevertVehicle = hasPermission(
    isRetailDealershipFlow
      ? PermissionCategory.RETAIL_INSPECTIONS
      : PermissionCategory.WHOLESALE_INSPECTIONS,
    PermissionAction.REVERT
  );

  const canView = hasPermission(
    isRetailDealershipFlow
      ? PermissionCategory.RETAIL_INSPECTIONS
      : PermissionCategory.WHOLESALE_INSPECTIONS,
    [PermissionAction.VIEW, PermissionAction.VIEW_ANY]
  );

  const { data, isLoading, isError, refetch } =
    useQuery<IVehicleSummaryResponse>(
      [
        `retail-summary-${params.id}`,
        params.id,
        { with: "dealership,owner,assessments,valuations,expenses,images" },
      ],
      InspectionService.getVehicleSummary,
      {
        refetchOnWindowFocus: false,
        enabled: canView,
      }
    );

  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem("SCROLL_POSITION");
    if (savedScrollPosition) {
      document
        .getElementsByClassName("dashboard")[0]
        .scrollTo({ behavior: "smooth", top: parseInt(savedScrollPosition) });
      sessionStorage.removeItem("SCROLL_POSITION");
    }

    return () => {
      document.getElementsByClassName("dashboard")[0]?.scrollTo({ top: 0 });
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{getPageTitle("Unvalued", SECTIONS.RETAIL_DEALERSHIP)}</title>
      </Helmet>
      <div className="dashboard__container">
        <div className="dashboard__header">
          <Breadcrumbs
            paths={[
              {
                title: isRetailDealershipFlow
                  ? NavigationMenuLabel.RETAIL_DEALERSHIP
                  : NavigationMenuLabel.WHOLESALE_DEALERSHIP,
                route: isRetailDealershipFlow
                  ? RETAIL_DEALERSHIP_MENU[0].route
                  : WHOLESALE_DEALERSHIP_MENU[0].route,
              },
              {
                title: "Unvalued",
                route: isRetailDealershipFlow
                  ? RETAIL_DEALERSHIP_MENU[0].route
                  : WHOLESALE_DEALERSHIP_MENU[0].route,
              },
              {
                title: VEHICLE_SUMMARY_TITLE,
                route: `${
                  isRetailDealershipFlow
                    ? RETAIL_DEALERSHIP_MENU[0].route
                    : WHOLESALE_DEALERSHIP_MENU[0].route
                }/valuation/${params.id}/summary`,
              },
            ]}
          />
        </div>
        <div className="dashboard__content">
          {!canView ? (
            <NoPermissionMessage
              viewPermissionCategory={PermissionCategory.RETAIL_INSPECTIONS}
            />
          ) : (
            <>
              {isLoading && <LoadVehicleSummary />}
              {isError && <ErrorMessage />}
              {data && !isError && (
                <>
                  <div className="dashboard__content--left">
                    <SummaryHeader data={data.data} onRefresh={refetch} />
                    <VehicleInformation
                      data={data.data}
                      defaultOpen
                      flow={flow || FLOW_TYPE.RETAIL_DEALERSHIP}
                      refetch={refetch}
                      isLocked={
                        !hasPermission(
                          isRetailDealershipFlow
                            ? PermissionCategory.RETAIL_INSPECTIONS
                            : PermissionCategory.WHOLESALE_INSPECTIONS,
                          PermissionAction.UPDATE
                        )
                      }
                    />
                    <SellerInformation data={data.data} flow={flow} />
                    <VehiclePhotoContainer data={data.data} refetch={refetch} />
                  </div>
                  <div className="dashboard__content--right">
                    <Banner
                      name={data.data.status.name}
                      label={data.data.status.label}
                    />
                    <div className="right-container">
                      <ValuationsSection
                        data={data.data}
                        onRefresh={refetch}
                        canRevertVehicle={canRevertVehicle}
                      />
                      <Footer
                        data={data.data}
                        flow={FLOW_TYPE.RETAIL_DEALERSHIP}
                        onRefresh={refetch}
                        canRevertVehicle={canRevertVehicle}
                        canSellVehicle={hasPermission(
                          isRetailDealershipFlow
                            ? PermissionCategory.RETAIL_INSPECTIONS
                            : PermissionCategory.WHOLESALE_INSPECTIONS,
                          PermissionAction.SELL
                        )}
                      />
                      {data.data.status.name === AWAITING.key && (
                        <NotifyUpdate
                          contract_id={data.data.contract_id}
                          hidden={data?.data?.updates_notified_to_valuers}
                          refetch={refetch}
                        />
                      )}
                      <VehicleComments contractID={data.data.contract_id} />
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
