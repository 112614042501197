import { CardVehicle, LoadItem } from "components/app";

import { ERROR_MESSAGE } from "utils";

import { IVehicleDetail } from "types";

interface InspectionCompletedProps {
  data?: IVehicleDetail[];
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
}

export const InspectionCompleted = ({
  data,
  isLoading,
  isError,
  isSuccess,
}: InspectionCompletedProps) => {
  const renderInspection = () => {
    if (isLoading) {
      return <LoadItem />;
    }

    if (isError) {
      return <div className="text-sm text-center">{ERROR_MESSAGE}</div>;
    }

    if (isSuccess) {
      return (
        <>
          {data?.length ? (
            data?.map((vehicle: IVehicleDetail) => (
              <CardVehicle
                data={vehicle}
                key={vehicle.contract_id}
                onClick={() => {}}
              />
            ))
          ) : (
            <p className="text-sm text-label">
              This customer has no vehicles with transactions yet.
            </p>
          )}
        </>
      );
    }
  };

  return <div className="mt-5 flex flex-col gap-5">{renderInspection()}</div>;
};
