import { DateTime, Duration } from "luxon";

export const convertToDate = (
  value?: string,
  includeTime?: boolean,
  format?: string
) => {
  if (!value) return "-";

  if (includeTime) {
    return (
      DateTime.fromJSDate(new Date(value)).toFormat("dd MMM yyyy - hh:mm") +
      DateTime.fromJSDate(new Date(value)).toFormat(" a").toLowerCase()
    );
  }
  return DateTime.fromJSDate(new Date(value)).toFormat(format || "dd MMM yyyy");
};

export const convertDayAndTimeToISO = (day: string, time: string) => {
  const isoDateTime = DateTime.fromFormat(day, "yyyy-MM-dd")
    .plus(
      Duration.fromObject({
        hours: Number(time?.split(":")[0]),
        minutes: Number(time?.split(":")[1]),
      })
    )
    .toISO();

  return isoDateTime;
};
