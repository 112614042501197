interface VehicleInfoProps {
  label: string;
  value?: any;
}

export const CardInfoChip = ({ label, value }: VehicleInfoProps) => {
  return (
    <span className="card-vehicle__txt--info">
      {label}: <b>{value}</b>
    </span>
  );
};
