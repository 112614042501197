import ContentLoader from "react-content-loader";

const rectStyles = {
  width: "160",
  height: "120",
  rx: "20",
  y: "13",
  fill: "#F3F3F3",
};

const Container = ({ id }: { id: number }) => (
  <ContentLoader key={id} speed={2} viewBox="0 0 946 141">
    <rect {...rectStyles} />
    <rect x="180" {...rectStyles} />
    <rect x="360" {...rectStyles} />
    <rect x="540" {...rectStyles} />
    <rect x="720" {...rectStyles} />
  </ContentLoader>
);

export const PhotosSkeleton = () => (
  <div className="w-full flex flex-col gap-1">
    <Container id={1} />
    <Container id={2} />
    <Container id={3} />
  </div>
);
