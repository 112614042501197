import Ably from "ably";
import { useContext, useState, useEffect } from "react";

import { AuthContext } from "context/AuthContext";
import { NotificationContext } from "context/NotificationContext";

export const NotificationProvider = ({
  client,
  children,
}: {
  client: Ably.Realtime;
  children: React.ReactNode;
}) => {
  const { user } = useContext(AuthContext);
  const [channel, setChannel] = useState<Ably.Types.RealtimeChannelCallbacks>();

  useEffect(() => {
    const channelID = `private:user.${user?.data.user.id}`;
    setChannel(client.channels.get(channelID));

    return () => {
      if (
        ["INITIALIZED", "DETACHED", "FAILED"].includes(
          client.channels.get(channelID).state.toUpperCase()
        )
      )
        client.channels.release(channelID);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.data]);

  return (
    <NotificationContext.Provider value={{ client, channel }}>
      {children}
    </NotificationContext.Provider>
  );
};
