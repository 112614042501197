interface IFooterSectionProps {
  title?: string;
  description?: string;
  children?: React.ReactNode;
}

export const FooterSection = ({
  title,
  description,
  children,
}: IFooterSectionProps) => {
  return (
    <div className="dashboard__content__footer section">
      <p className="dashboard__content__footer__title">{title}</p>
      {description && (
        <p className="dashboard__content__footer__desc">{description}</p>
      )}
      <div className="dashboard__content__footer__tool">{children}</div>
    </div>
  );
};
