import { Button, SvgImage } from "components/shared";

import { modalRouteChangeTestID } from "../dataTestIDs";

import "./styles.scss";

export interface ModalRouteChangeProps {
  onConfirm: () => void;
  onCancel: () => void;
  dataTestID?: string;
}

export const ModalRouteChange = ({
  onConfirm,
  onCancel,
  dataTestID,
}: ModalRouteChangeProps) => {
  return (
    <div
      className="w-64 -mx-3"
      data-testid={dataTestID || modalRouteChangeTestID}
    >
      <div className="text-center flex justify-center flex-col items-center">
        <SvgImage name="ConfirmIcon" className="mt-2 mb-4" />
        <label className="font-semibold text-xl mb-5">Save Changes?</label>
      </div>
      <p className="text-label px-5 text-xs font-normal text-center mb-7">
        Any unsaved changes will be lost. Do you want to save the changes before
        leaving?
      </p>
      <div className="delete-car__footer flex">
        <div className="btn__cancel !w-1/2">
          <Button
            type="button"
            onClick={onConfirm}
            dataTestID={`${dataTestID || modalRouteChangeTestID}-confirm`}
          >
            <span>No, Leave</span>
          </Button>
        </div>
        <div className="btn__confirm !w-1/2">
          <Button
            type="button"
            onClick={onCancel}
            dataTestID={`${dataTestID || modalRouteChangeTestID}-cancel`}
          >
            <span>Yes, Stay</span>
          </Button>
        </div>
      </div>
    </div>
  );
};
