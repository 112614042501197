import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect } from "react";

import { ButtonStatus, LoadItem } from "components/app";
import { modalOfferTestID } from "components/app/dataTestIDs";

import { AuthContext } from "context/AuthContext";

import { DECLINED_VALUATION, formatNumber } from "utils";

import { IValuationLogHistory } from "types";

import { ValuationService } from "api/client";

import "../styles.scss";

export interface ValuationLogProps {
  name: string;
  role: string;
  price: string;
  date: string;
  status?: "declined" | "valued";
}

export interface ModalOfferLogProps {
  open: boolean;
  valuationID: string | undefined;
  dataTestID?: string;
  declined?: boolean;
}

export const ValuationLog = ({
  name,
  price,
  role,
  date,
  status,
}: ValuationLogProps) => {
  return (
    <div className="valuation-log">
      <div className="valuation-log__info">
        {name}
        {status === "declined" ? (
          <ButtonStatus status={DECLINED_VALUATION.key} label={"Declined"} />
        ) : (
          <span>{`$${formatNumber(price)}`}</span>
        )}
      </div>
      <div className="valuation-log__detail">
        {role}
        <span>{date}</span>
      </div>
    </div>
  );
};

export const ModalOfferLog = ({
  open,
  valuationID,
  dataTestID,
  declined,
}: ModalOfferLogProps) => {
  const { userID } = useContext(AuthContext);

  const {
    data: logs,
    refetch,
    isLoading,
  } = useQuery<any, Error>(
    [`fetchValuationLogs_${valuationID}_${userID}`, valuationID],
    ValuationService.getValuationHistory
  );

  useEffect(() => {
    if (open && valuationID) {
      refetch();
    }
  }, [open, valuationID, refetch]);

  if (isLoading)
    return (
      <div className="offer" data-testid={dataTestID || modalOfferTestID}>
        <LoadItem />
      </div>
    );

  return (
    <div className="offer" data-testid={dataTestID || modalOfferTestID}>
      {declined
        ? logs?.data
            ?.filter((log: IValuationLogHistory) => log.status === "declined")
            .map((log: IValuationLogHistory, idx: number) => (
              <ValuationLog
                key={idx}
                name={log.valuer.name}
                price={log.new_amount}
                role={log.valuer.role || ""}
                date={log.created_at}
                status={log.status}
              />
            ))
        : logs?.data.map((log: IValuationLogHistory, idx: number) => (
            <ValuationLog
              key={idx}
              name={log.valuer.name}
              price={log.new_amount}
              role={log.valuer.role || ""}
              date={log.created_at}
              status={log.status}
            />
          ))}
    </div>
  );
};
