import { useMutation } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { modalOfferAddTestID } from "components/app/dataTestIDs";
import { Button, FieldText, SvgImage } from "components/shared";

import { useDealership } from "context/DealershipContext";

import {
  FeatureRoute,
  VALUATIONS_TYPE,
  formatNumber,
  showErrorToast,
} from "utils";

import { ValuationService } from "api/client";

import "../styles.scss";

export interface ModalOfferAddProps {
  onCloseModal: (updated: boolean) => void;
  contractID: string;
  requesterDealershipID: string;
  dataTestID?: string;
  requestID?: string;
}

export const ModalOfferAdd = ({
  onCloseModal,
  contractID,
  requesterDealershipID,
  dataTestID,
  requestID,
}: ModalOfferAddProps) => {
  const [amount, setAmount] = useState("");
  const [isAmountSent, setIsAmountSent] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { mutate: sendOffer, isLoading } = useMutation(
    () =>
      ValuationService.addValuationAmount(
        contractID,
        requesterDealershipID
          ? {
              type: VALUATIONS_TYPE.OFFER,
              amount,
              valuation_request_id: requestID,
            }
          : { type: VALUATIONS_TYPE.OFFER, amount }
      ),
    {
      onSuccess: () => {
        setIsAmountSent(true);
        if (pathname.includes(FeatureRoute.RetailDealership)) {
          navigate(
            `${FeatureRoute.RetailDealershipValued}/${contractID}/summary`
          );
        } else if (pathname.includes(FeatureRoute.WholesaleDealership)) {
          navigate(
            `${FeatureRoute.WholesaleDealershipValued}/${contractID}/${
              requestID ? `${requestID}/summary` : "summary"
            }`
          );
        }
      },
      onError: () => {
        onCloseModal(false);
        showErrorToast("Failed to Save");
      },
    }
  );

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleCloseVehicle = () => {
    setIsAmountSent(false);
    onCloseModal(true);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && amount) {
      sendOffer();
    }
  };

  const handleAmount = (value: string) => {
    setAmount(value.replaceAll(",", ""));
  };

  return (
    <div className="offer-add" data-testid={dataTestID || modalOfferAddTestID}>
      {isAmountSent ? (
        <>
          <div className="offer-add__title">
            <SvgImage name="ValuationSentIcon" />
            <label className="mt-3">Offer Sent!</label>
          </div>
          <p className="offer-add__desc">
            Your offer has successfully been sent to the staff in charge of
            negotiation.
          </p>
          <div className="offer-add__footer">
            <div onClick={handleCloseVehicle}>
              <span className="offer-add__footer__title">
                Close Vehicle Summary
              </span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="offer-add__title">Add Offer Amount</div>
          <div className="offer-add__txt">
            <FieldText
              type="text"
              placeholder="Enter offer amount"
              value={formatNumber(amount)}
              onChange={(e) => handleAmount(e.target.value)}
              onKeyPress={handleKeyPress}
              ref={inputRef}
            />
            <span className="offer-add__txt__unit">$</span>
          </div>
          <div className="offer-add__tool">
            <Button type="button" isLoading={isLoading} onClick={sendOffer}>
              <span className="offer-add__tool__title">CONFIRM AMOUNT</span>
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
