import "./styles.scss";

import { Helmet } from "react-helmet";

import { getPageTitle, SECTIONS } from "utils/pageSections";

import { Container } from "./Container";

export const DealershipPool = () => {
  return (
    <>
      <Helmet>
        <title>{getPageTitle("Dealership Pool", SECTIONS.SETTINGS)}</title>
      </Helmet>
      <Container />
    </>
  );
};
