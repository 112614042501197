import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";

import {
  DealInformation,
  Footer,
  LoadVehicleSummary,
  SaleDetailSection,
  SellerInformation,
  SummaryHeader,
  ValuationsSection,
  VehicleInformation,
} from "components/app";
import { BidSection } from "components/app/Tender/BidSection";
import { ErrorMessage } from "components/ErrorMessage";
import { RemoveTenderVehicleModal } from "components/Modal/RemoveVehicleModal/RemoveVehicleModal";
import { RemoveFromMotorMarket } from "components/MotorMarket/RemoveFromMotorMarket";
import { Banner, Breadcrumbs, Button } from "components/shared";
import { VehicleComments } from "components/VehicleNotes";
import { VehiclePhotoContainer } from "components/VehiclePhotoContainer";

import { AuthContext } from "context/AuthContext";

import {
  ACQUIRED,
  FLOW_TYPE,
  MOTOR_MARKET_AUCTION,
  showSuccessToast,
  TENDERED,
  VEHICLE_ROUTES,
  VEHICLE_SUMMARY_TITLE,
  WHOLESALE_DEALERSHIP_MENU,
} from "utils";
import { getPageTitle, SECTIONS } from "utils/pageSections";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import { IVehicleSummaryResponse, NavigationMenuLabel } from "types";

import { AcquisitionService, TenderService } from "api/client";

import "./styles.scss";

export const WholesaleAcquisitionSummary = () => {
  const params = useParams();
  const { id: contractId, requestId } = params;
  const { user, userID, hasPermission } = useContext(AuthContext);
  const { permissions } = user?.data.user || {};

  const navigate = useNavigate();
  const [openRemoveModal, setOpenRemoveModal] = useState(false);

  const { data, isLoading, isError, refetch } =
    useQuery<IVehicleSummaryResponse>(
      [
        `wholesale-summary-${params.id}`,
        contractId,
        {
          with: "dealership,owner,assessments,valuations,expenses,images,comment,buyer,seller",
        },
        requestId,
        "acquisition",
      ],
      AcquisitionService.getAcquisitionSummary,
      {
        refetchOnWindowFocus: false,
      }
    );

  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem("SCROLL_POSITION");
    if (savedScrollPosition) {
      document
        .getElementsByClassName("dashboard")[0]
        .scrollTo({ behavior: "smooth", top: parseInt(savedScrollPosition) });
      sessionStorage.removeItem("SCROLL_POSITION");
    }

    return () => {
      document.getElementsByClassName("dashboard")[0]?.scrollTo({ top: 0 });
    };
  }, []);

  const { mutate } = useMutation(
    (dataparam: { contract_ids: string[] }) =>
      TenderService.deleteVehicleFromTender(
        dataparam,
        data?.data?.tender_vehicle?.tender_id ?? ""
      ),
    {
      onSuccess: () => {
        showSuccessToast("Vehicle Removed");
        navigate(-1);
      },
      onError: () => {
        showSuccessToast("Failed to Remove");
      },
    }
  );

  const isStatusSold = data?.data.status?.name.includes("sold");

  const handleOpenRemoveModal = () => setOpenRemoveModal(true);
  const handleCloseRemoveModal = () => setOpenRemoveModal(false);
  const handleRemoveFromTender = () => {
    mutate({ contract_ids: [data?.data.contract_id ?? ""] });
  };

  return (
    <>
      <Helmet>
        <title>
          {getPageTitle("Stocklist", SECTIONS.WHOLESALE_DEALERSHIP)}
        </title>
      </Helmet>
      <div className="dashboard__container">
        <div className="dashboard__header">
          <Breadcrumbs
            paths={[
              {
                title: NavigationMenuLabel.WHOLESALE_DEALERSHIP,
                route: WHOLESALE_DEALERSHIP_MENU[0].route,
              },
              { title: "Stocklist", route: WHOLESALE_DEALERSHIP_MENU[2].route },
              {
                title: VEHICLE_SUMMARY_TITLE,
                route: `${WHOLESALE_DEALERSHIP_MENU[0].route}/stocklist/${contractId}/${requestId}/summary`,
              },
            ]}
          />
        </div>
        <div className="dashboard__content">
          {isLoading && <LoadVehicleSummary />}
          {isError && <ErrorMessage />}
          {data && !isError && (
            <>
              <div className="dashboard__content--left">
                <SummaryHeader
                  data={data.data}
                  onRefresh={refetch}
                  flow={FLOW_TYPE.WHOLESALE_DEALERSHIP}
                />
                <DealInformation
                  data={data.data}
                  defaultOpen
                  onRefresh={refetch}
                  flow={
                    data?.data?.owner?.id
                      ? FLOW_TYPE.RETAIL_DEALERSHIP
                      : FLOW_TYPE.WHOLESALE_DEALERSHIP
                  }
                  auctionID={data.data?.auction_vehicle_id}
                  showInMotorMarketInfo
                />
                <VehicleInformation
                  data={data.data}
                  flow={FLOW_TYPE.WHOLESALE_DEALERSHIP}
                  vehicleRoute={VEHICLE_ROUTES.Stocklist}
                  refetch={refetch}
                  isLocked={
                    !hasPermission(
                      PermissionCategory.WHOLESALE_INSPECTIONS,
                      PermissionAction.UPDATE
                    )
                  }
                />
                <SellerInformation
                  data={data.data}
                  flow={
                    data?.data?.owner?.id
                      ? FLOW_TYPE.RETAIL_DEALERSHIP
                      : FLOW_TYPE.WHOLESALE_DEALERSHIP
                  }
                />
                <VehiclePhotoContainer data={data.data} />
              </div>
              <div className="dashboard__content--right">
                <Banner
                  name={data.data.status.name}
                  label={
                    data.data.status.name === ACQUIRED.key
                      ? ACQUIRED.label
                      : data.data.status.label
                  }
                />
                {data.data.status?.name === MOTOR_MARKET_AUCTION.key &&
                  hasPermission(
                    PermissionCategory.MOTOR_MARKET_SELLING,
                    PermissionAction.DELETE
                  ) && (
                    <RemoveFromMotorMarket
                      auctionVehicleId={data.data.auction_vehicle_id || ""}
                      onRefresh={refetch}
                    />
                  )}
                {data.data.status?.name === TENDERED.key &&
                  hasPermission(
                    PermissionCategory.TENDER_SELLING,
                    PermissionAction.DELETE
                  ) && (
                    <>
                      <BidSection
                        tenderStatus={data?.data.status.name || ""}
                        tenderVehicleId={data.data.vehicle.id || ""}
                      />
                      <div className="w-52">
                        <Button
                          variant="secondary"
                          onClick={handleOpenRemoveModal}
                        >
                          <b>REMOVE FROM TENDER</b>
                        </Button>
                      </div>
                    </>
                  )}
                <Footer
                  data={data.data}
                  flow={FLOW_TYPE.WHOLESALE_DEALERSHIP}
                  canSellVehicle={hasPermission(
                    PermissionCategory.WHOLESALE_INSPECTIONS,
                    PermissionAction.SELL
                  )}
                />
                {isStatusSold &&
                  hasPermission(
                    PermissionCategory.WHOLESALE_INSPECTIONS,
                    PermissionAction.REVERT
                  ) && <SaleDetailSection data={data.data} refetch={refetch} />}
                {data.data.status.name === ACQUIRED.key && (
                  <ValuationsSection
                    data={data.data}
                    onRefresh={refetch}
                    hideComment
                  />
                )}
                <VehicleComments contractID={data?.data?.contract_id} />
              </div>
            </>
          )}
        </div>
      </div>
      <RemoveTenderVehicleModal
        open={openRemoveModal}
        onConfirm={handleRemoveFromTender}
        onCancel={handleCloseRemoveModal}
      />
    </>
  );
};
