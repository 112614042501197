import { IOption } from "types";

export const STATE_OPTIONS: IOption[] = [
  { key: "ACT", label: "Australian Capital Territory" },
  { key: "NSW", label: "New South Wales" },
  { key: "NT", label: "Northern Territory" },
  { key: "QLD", label: "Queensland" },
  { key: "SA", label: "South Australia" },
  { key: "VIC", label: "Victoria" },
  { key: "TAS", label: "Tasmania" },
  { key: "WA", label: "Western Australia" },
];

export const MONTH_OPTIONS: IOption[] = [
  { key: "1", label: "January" },
  { key: "2", label: "February" },
  { key: "3", label: "March" },
  { key: "4", label: "April" },
  { key: "5", label: "May" },
  { key: "6", label: "June" },
  { key: "7", label: "July" },
  { key: "8", label: "August" },
  { key: "9", label: "September" },
  { key: "10", label: "October" },
  { key: "11", label: "November" },
  { key: "12", label: "December" },
];

export const YEARS_OPTIONS: IOption[] = [];
for (let i = 0; i < 30; i += 1) {
  const year = new Date().getFullYear() - i;
  YEARS_OPTIONS.push({ key: `${year}`, label: `${year}` });
}

export const CUSTOMER_MONTH_OPTIONS: IOption[] = [
  { key: "Jan", label: "January" },
  { key: "Feb", label: "February" },
  { key: "Mar", label: "March" },
  { key: "Apr", label: "April" },
  { key: "May", label: "May" },
  { key: "Jun", label: "June" },
  { key: "Jul", label: "July" },
  { key: "Aug", label: "August" },
  { key: "Sep", label: "September" },
  { key: "Oct", label: "October" },
  { key: "Nov", label: "November" },
  { key: "Dec", label: "December" },
];
