import { DirectOfferForm } from "components/DirectOffer/DirectOfferForm";
import { Modal } from "components/shared";

export interface IDirectOfferModalProps {
  isOpen: boolean;
  onClose: () => void;
  onRefresh?: () => void;
}

export const DirectOfferModal = ({
  isOpen,
  onClose,
  onRefresh,
}: IDirectOfferModalProps) => {
  return (
    <Modal open={isOpen} title="Direct Offer" closeModal={onClose}>
      <DirectOfferForm onRefresh={onRefresh} />
    </Modal>
  );
};
