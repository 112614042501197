import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

import { LoadItem } from "components/app/LoadVehicle";

import { AuthContext } from "context/AuthContext";
import { useDealership } from "context/DealershipContext";

import { ERROR_MESSAGE, formatNumber } from "utils";

import { ITenderBidHistoryResponse } from "types";

import { TenderService } from "api/client";

import { modalTenderBidsHistoryID } from "../dataTestIDs";

export interface ModalTenderBidsHistoryProps {
  bidderID: string;
  dataTestID?: string;
  isSeller?: boolean;
}

export const ModalTenderBidsHistory = ({
  bidderID,
  dataTestID,
  isSeller,
}: ModalTenderBidsHistoryProps) => {
  const { userID } = useContext(AuthContext);
  const { dealershipID } = useDealership();

  const { data, isLoading, isError, isSuccess } = useQuery<
    ITenderBidHistoryResponse,
    Error
  >(
    [`bidderHistory_${bidderID}_${userID}`, bidderID, dealershipID],
    isSeller ? TenderService.getSellerBidHistory : TenderService.getBidHistory,
    {
      enabled: !!bidderID,
    }
  );

  const renderItems = () => {
    if (isLoading) {
      return <LoadItem />;
    }

    if (isError) {
      return <div className="text-sm text-center">{ERROR_MESSAGE}</div>;
    }

    if (isSuccess) {
      return (
        <>
          {data?.data?.map((item) => {
            return (
              <div
                key={item.id}
                className="pt-4 pb-3 border-b border-softPeach last:border-none"
              >
                <div className="flex items-center justify-between text-black text-sm font-medium mb-1">
                  {item.bidder?.name}
                  <div>{`$${formatNumber(item.bid_amount)}`}</div>
                </div>
                <div className="flex items-start justify-between text-lightGrey text-xs font-normal mb-1">
                  <div>{item.dealership?.name}</div>
                  <div className="min-w-[160px] text-right">
                    {item.created_at}
                  </div>
                </div>
              </div>
            );
          })}
        </>
      );
    }

    return <></>;
  };

  return (
    <div
      className="w-[310px] -mx-1 md:-mx-6 mt-2"
      data-testid={dataTestID || modalTenderBidsHistoryID}
    >
      {renderItems()}
    </div>
  );
};
