import { ButtonLink } from "components/shared";

import { formatDateFromISO } from "utils";

import { IDocument } from "types";

interface TableProps {
  data?: IDocument[];
  onFileDownload: (documentID: string, documentName: string) => void;
  setDocumentToDelete: (document: IDocument) => void;
  setOpenDeleteConfirmModal: (confirm: boolean) => void;
}

export const Table = ({
  data,
  onFileDownload,
  setDocumentToDelete,
  setOpenDeleteConfirmModal,
}: TableProps) => {
  return (
    <table className="w-full border-collapse text-sm font-normal px-5 text-cornflowerBlue">
      <tbody>
        {data?.map((document: IDocument) => (
          <tr key={document.id} className="border-b border-[#EFEFEF]">
            <td
              className="py-2 px-4 w-1/2 cursor-pointer"
              onClick={() => onFileDownload(document.id, document.name)}
            >
              {document.name}
            </td>
            <td className="py-2 px-4 font-medium">
              {document.uploaded_by.name}
            </td>
            <td className="py-2 px-4 text-lightGrey font-light text-xs">
              {formatDateFromISO(document.created_at)}
            </td>
            <td className="py-2 px-4">
              <ButtonLink
                onClick={() => {
                  setDocumentToDelete(document);
                  setOpenDeleteConfirmModal(true);
                }}
              >
                <span className="text-darkRed underline font-semibold">
                  Delete
                </span>
              </ButtonLink>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
