import classNames from "classnames";

import { ButtonStatus } from "components/app";

import {
  cardContainerTestID,
  cardImagePlaceholderTestID,
} from "../app/dataTestIDs";
import { SvgImage } from "../shared";

export interface CardProps {
  content?: React.ReactNode[];
  status?: { status: string; amount?: number; label: string };
  liveTimer?: React.ReactNode;
  coverSource?: string;
  actions?: React.ReactNode[];
  dataTestID?: string;
  onClick?: () => void;
  hasWithdrawn?: boolean;
  showAllImage?: boolean;
}

export const Card = ({
  content,
  coverSource,
  status,
  liveTimer,
  actions,
  dataTestID,
  onClick,
  hasWithdrawn,
  showAllImage,
}: CardProps) => {
  return (
    <div
      className="flex flex-col w-full shadow-md rounded-12 overflow-hidden bg-white"
      data-testid={dataTestID || cardContainerTestID}
      onClick={onClick}
    >
      <div className="w-full flex flex-col sm:gap-0 sm:flex-row lg:h-44 hover:cursor-pointer">
        <div className="relative w-full sm:max-w-[433px] sm:w-[40%] h-[180px] sm:h-full overflow-hidden">
          {!coverSource ? (
            <SvgImage
              name="NoImageSvg"
              className={classNames("w-full h-full object-cover", {
                "opacity-50": hasWithdrawn,
              })}
              data-testid={dataTestID || cardImagePlaceholderTestID}
            />
          ) : (
            <img
              src={coverSource}
              alt={"Img cover"}
              className={classNames("w-full h-full object-cover", {
                "opacity-50": hasWithdrawn,
                "!object-contain": showAllImage,
              })}
            />
          )}
          {status && (
            <div className="absolute top-4 right-4">
              <ButtonStatus
                status={status.status}
                amount={status.amount}
                label={status.label}
              />
            </div>
          )}
          <div className="absolute bottom-0 left-0 fixed">{liveTimer}</div>
        </div>
        <div className="flex items-center flex-1 sm:ml-[15px] sm:max-w-[50%] max-w-full">
          <div className="max-w-full p-[15px] sm:p-0">{content}</div>
        </div>
        <div className="p-6 flex items-end flex-col gap-2 ml-auto">
          {actions}
        </div>
      </div>
    </div>
  );
};
