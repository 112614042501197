import { SELLING } from "utils";

import {
  IBidDetails,
  IBuyerDealership,
  INote,
  IQueryKeyProps,
  ITenderBidHistoryResponse,
  ITenderForm,
  ITenderResponse,
  ITenderSummaryResponse,
  ITenderVehicle,
  ITenderVehicleListResponse,
  ITenderVehicleResponse,
} from "types";

import { api, url } from "api";

export const TenderService = {
  getTendersSelling: ({ queryKey }: IQueryKeyProps) =>
    api.get(url.tenderSelling, queryKey[1]) as Promise<ITenderResponse>,

  getTenderSummary: ({ queryKey }: IQueryKeyProps) =>
    queryKey[1]
      ? (api.get(
          `${url.tenderSelling}/${queryKey[1]}`
        ) as Promise<ITenderSummaryResponse>)
      : null,

  createTender: (payload: ITenderForm) =>
    api.post(`${url.tenderSelling}`, payload),

  updateTender: (payload: ITenderForm, tenderId: string) =>
    api.put(`${url.tenderSelling}/${tenderId}`, payload),

  deleteTender: (tender_id: string) =>
    api.delete(`${url.tenderSelling}/${tender_id}`),

  scheduleTender: (payload: any, tenderId: string) =>
    api.patch(`${url.tenderSelling}/${tenderId}/schedule`, payload),

  getAcquiredVehicles: ({ queryKey }: IQueryKeyProps) =>
    api.get(`${url.tenderVehiclesSelling}/acquired`, queryKey[1]) as Promise<{
      data: ITenderVehicle[];
    }>,

  getVehicleTender: ({ queryKey }: IQueryKeyProps) =>
    api.get(
      queryKey[2] === SELLING.name
        ? `${url.tenderVehiclesSelling}/${queryKey[1]}`
        : `${url.tenderVehiclesBuying}/${queryKey[1]}`,
      queryKey[3]
    ) as Promise<ITenderVehicleListResponse>,

  addVehicleToTender: (tender_id: string, payload: any) =>
    api.post(`${url.tenderVehiclesSelling}/${tender_id}`, payload),

  deleteVehicleFromTender: (payload: any, tender_id: string) =>
    api.delete(`${url.tenderVehiclesSelling}/${tender_id}`, payload),

  getTendersBuying: ({ queryKey }: IQueryKeyProps) =>
    api.get(url.tenderBuying, queryKey[1]) as Promise<ITenderResponse>,

  getTenderBuyingSummary: ({ queryKey }: IQueryKeyProps) =>
    api.get(
      `${url.tenderBuying}/${queryKey[1]}?dealership_id=${queryKey[2]}`
    ) as Promise<ITenderSummaryResponse>,

  getTenderVehicleSummary: ({ queryKey }: IQueryKeyProps) =>
    api.get(
      queryKey[2] === SELLING.name
        ? `${url.tenderVehiclesSelling}/${queryKey[1]}/summary`
        : `${url.tenderVehiclesBuying}/${queryKey[1]}/summary`,
      {
        with: "comment",
        dealership_id: queryKey[3],
      }
    ) as Promise<ITenderVehicleResponse>,

  saveTenderVehicle: (tenderVehicleId: string) =>
    api.post(`${url.tenderVehiclesBuying}/${tenderVehicleId}/save`, {}),

  toggleVehicleSave: (
    payload: { dealership_ids: string[] },
    tenderVehicleId: string,
    is_saved: boolean
  ) =>
    api.post(
      `${url.tenderVehiclesBuying}/${tenderVehicleId}/${
        !is_saved ? "save" : "unsave"
      }`,
      payload
    ),

  addNotes: (payload: { comment: string }, tenderVehicleID: string) =>
    api.post(`${url.tenderBuying}/comment/${tenderVehicleID}`, payload),

  getNotes: ({ queryKey }: IQueryKeyProps) =>
    api.get(`${url.tenderBuying}/comment/${queryKey[1]}`) as Promise<{
      data: INote[];
    }>,

  getBuyers: ({ queryKey }: IQueryKeyProps) =>
    api.get(`${url.tenderBuyers}/${queryKey[1]}`) as Promise<{
      data: IBuyerDealership[];
    }>,

  addBuyers: (tenderId: string, payload: any) =>
    api.post(`${url.tenderBuyers}/${tenderId}`, payload),

  deleteBuyers: (tenderId: string, payload: any) =>
    api.delete(`${url.tenderBuyers}/${tenderId}`, payload),

  placeBid: (
    payload: { amount: number | string },
    tenderVehicleID: string,
    dealershipID?: string | null
  ) =>
    api.post(`${url.tenderBids}/${tenderVehicleID}`, {
      ...payload,
      dealership_id: dealershipID,
    }),

  getSellerBidHistory: ({ queryKey }: IQueryKeyProps) =>
    api.get(
      `${url.tenderBids}/${queryKey[1]}/history`
    ) as Promise<ITenderBidHistoryResponse>,

  getBidHistory: ({ queryKey }: IQueryKeyProps) =>
    api.get(`${url.tenderBids}/${queryKey[1]}/all-bids`, {
      dealership_id: queryKey[2],
    }) as Promise<ITenderBidHistoryResponse>,

  getTenderVehicleBids: ({ queryKey }: IQueryKeyProps) =>
    queryKey[1]
      ? (api.get(`${url.tenderBids}/${queryKey[1]}`) as Promise<{
          data: IBidDetails[];
        }>)
      : undefined,

  bidWinner: (bidID: string, amount: number) =>
    api.post(`${url.tenderBids}/${bidID}/winner`, {
      amount,
    }),
};
