import { useMemo, useState } from "react";

import { FieldState } from "components/app";
import { CollapsiblePanel } from "components/CollapsiblePanel";
import { SearchField } from "components/SearchField";

import {
  IVinSpecifications,
  IVinSpecificationsColor,
} from "types/IVinSpecifications";

interface DataContainerProps {
  data: IVinSpecifications;
}

const VehicleInformation = ({ data }: { data: IVinSpecifications }) => {
  const [showPanel, setShowOpen] = useState(true);

  return (
    <CollapsiblePanel
      title="Vehicle Information"
      isCollapsed={!showPanel}
      onChange={() => setShowOpen((status) => !status)}
    >
      <div className="disclosure__pannel--wide !gap-3">
        <FieldState key="vin" name="VIN" value={data.vin} />
        <FieldState
          key="engine-code"
          name="Engine Code"
          value={data.attributes.engine_code}
        />
        <FieldState key="make" name="Make" value={data.make} />
        <FieldState
          key="system-power"
          name="System Power"
          value={data.attributes.system_power}
        />
        <FieldState key="model" name="Model" value={data.model} />
        <FieldState
          key="fuel-type"
          name="Fuel Type"
          value={data.attributes.fuel_type}
        />
        <FieldState key="series" name="Series" value={data.series} />
        <FieldState
          key="displacement"
          name="Displacement"
          value={data.attributes.displacement}
        />
        <FieldState
          key="production-date"
          name="Production Date"
          value={data.production_date}
        />
        <FieldState
          key="steering-side"
          name="Steering Side"
          value={data.attributes.steering_side}
        />
        <FieldState key="body-type" name="Body Type" value={data.body_type} />
        <FieldState
          key="transmission-type"
          name="Transmission Type"
          value={data.attributes.transmission_type}
        />
      </div>
    </CollapsiblePanel>
  );
};

const Colours = ({ data }: { data: IVinSpecificationsColor }) => {
  const [showPanel, setShowOpen] = useState(true);

  return (
    <CollapsiblePanel
      title="Colours"
      isCollapsed={!showPanel}
      onChange={() => setShowOpen((status) => !status)}
    >
      <div className="disclosure__pannel--wide !gap-3">
        <div className="col-span-2">
          <FieldState key="exterior" name="Exterior" value={data.exterior} />
        </div>
        <div className="col-span-2">
          <FieldState key="interior" name="Interior" value={data.interior} />
        </div>
      </div>
    </CollapsiblePanel>
  );
};

const FactoryOptions = ({ data }: { data: string[] }) => {
  const [showPanel, setShowOpen] = useState(true);
  const [term, setTerm] = useState("");

  const options = useMemo(
    () =>
      data.filter((option: string) =>
        option.toLocaleLowerCase().includes(term.toLowerCase())
      ),
    [data, term]
  );

  return (
    <CollapsiblePanel
      title="Factory Options"
      isCollapsed={!showPanel}
      onChange={() => setShowOpen((status) => !status)}
    >
      <div className="mt-4">
        <SearchField onSearch={(term) => setTerm(term)} />
        <div className="flex flex-row gap-1 mt-4">
          <div className="flex flex-1 flex-col gap-2" key="vehicle-information">
            {options.length ? (
              <ul className="columns-2 auto">
                {options.map((option: string) => (
                  <li className="pb-1 pt-1" key={option}>
                    <p className="text-sm font-normal list-item">
                      &#8226; {option}
                    </p>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-label text-sm font-normal">No options.</p>
            )}
          </div>
        </div>
      </div>
    </CollapsiblePanel>
  );
};

export const DataContainer = ({ data }: DataContainerProps) => {
  return (
    <div className="flex flex-col mt-4 max-h-[570px] overflow-y-auto gap-2">
      <VehicleInformation data={data} />
      <Colours data={data.colors} />
      <FactoryOptions data={data.options} />
    </div>
  );
};
