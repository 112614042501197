import { useMutation } from "@tanstack/react-query";
import { useContext } from "react";
import { toast } from "react-toastify";

import { Button } from "components/shared";

import { SearchContext } from "context/SearchContext";

import { VehicleService } from "api/client";

type NotifyUpdateProps = {
  contract_id: string | undefined;
  hidden: boolean;
  refetch: () => void;
};

export const NotifyUpdate = ({
  contract_id,
  hidden,
  refetch,
}: NotifyUpdateProps) => {
  const { setIsUpdated } = useContext(SearchContext);

  const { mutate: handleNotifyValuers, isLoading } = useMutation(
    () => VehicleService.notifyValuers(contract_id || ""),
    {
      onSuccess: () => {
        setIsUpdated(contract_id || "", false);
        refetch();
        toast.success("Notification Successfully Sent", {
          autoClose: 2000,
          theme: "colored",
        });
      },
      onError: () => {
        toast.error("Failed to Send Notification", {
          autoClose: 2000,
          theme: "colored",
        });
      },
    }
  );

  if (hidden) {
    return <></>;
  }

  return (
    <div className="dashboard__content__footer section">
      <p className="dashboard__content__footer__title">Updates to Vehicle</p>
      <p className="dashboard__content__footer__desc">
        Vehicle Information has been changed on this vehicle. Valuations may be
        affected. Consider notifying valuers of updates.
      </p>
      <Button
        type="button"
        variant="secondary"
        className="!w-240 !border-softPeach"
        isLoading={isLoading}
        onClick={handleNotifyValuers}
      >
        <span className="font-bold">NOTIFY VALUERS OF UPDATES</span>
      </Button>
    </div>
  );
};
