import { CardCustomer } from "components/app";

import { ICustomerDetail } from "types";

import "./styles.scss";
import { listCustomersTestID } from "../dataTestIDs";

export interface ListCustomersProps {
  data: ICustomerDetail[];
  onSelect: (id: string) => void;
  dataTestID?: string;
}

export const ListCustomers = ({
  data,
  onSelect,
  dataTestID,
}: ListCustomersProps) => {
  return (
    <div className="list" data-testid={dataTestID || listCustomersTestID}>
      {data.map((customer) => (
        <CardCustomer
          key={customer.id}
          data={customer}
          onClick={() => onSelect(customer.id)}
          dataTestID={`${dataTestID || listCustomersTestID}-card-${
            customer.id
          }`}
        />
      ))}
    </div>
  );
};
