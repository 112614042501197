import { useMutation } from "@tanstack/react-query";
import classNames from "classnames";

import { Card } from "components/Card";
import { ButtonLink } from "components/shared";
import { Timer } from "components/Timer";
import { Tooltip } from "components/Tooltip";

import {
  MOTOR_MARKET_BUYER_BID,
  MOTOR_MARKET_ENDED,
  MOTOR_MARKET_LIVE,
  MOTOR_MARKET_LOST,
  MOTOR_MARKET_PENDING,
  MOTOR_MARKET_WATCHLIST,
  MOTOR_MARKET_WITHDRAWN_BUYER,
  MOTOR_MARKET_WON,
  createEllipsisText,
  formatDateToJsDate,
  formatNumber,
  showErrorToast,
} from "utils";

import { IMotorMarketBuyer } from "types/IMotorMarket";

import { ReactComponent as MarketStarIconFilled } from "assets/images/market-star-filled.svg";
import { ReactComponent as MarketStarIconOutlined } from "assets/images/market-star-outline.svg";
import { ReactComponent as PinIcon } from "assets/images/pin.svg";

import { MotorMarketService } from "api/client";

export const ListVehicles = ({
  data,
  status,
  onClick,
  refetch,
  canView = true,
}: {
  data: IMotorMarketBuyer[];
  status: string;
  onClick: (auction: IMotorMarketBuyer) => void;
  onClickWatchlist?: (
    auctionVehicleID: string,
    action: "add" | "remove"
  ) => void;
  refetch: () => void;
  canView?: boolean;
}) => {
  const showCardStatusLabels = [
    MOTOR_MARKET_PENDING.key,
    MOTOR_MARKET_LOST.key,
    MOTOR_MARKET_WON.key,
    MOTOR_MARKET_BUYER_BID.key,
  ].includes(status);

  const { mutate: addToWatchList, isLoading: addingToWatchlist } = useMutation(
    (auctionVehicleID: string) =>
      MotorMarketService.addToWatchlist(auctionVehicleID),
    {
      onSuccess: () => {
        refetch();
      },
      onError: () => {
        showErrorToast("Failed To Add");
      },
    }
  );

  const { mutate: removeFromWatchlist, isLoading: removingFromWatchlist } =
    useMutation(
      (auctionWatchlistID: string) =>
        MotorMarketService.removeFromWatchlist(auctionWatchlistID),
      {
        onSuccess: () => {
          refetch();
        },
        onError: () => {
          showErrorToast("Failed To Remove");
        },
      }
    );

  return (
    <>
      {data.map((auction) => {
        const hasWidthdrawn =
          auction?.has_withdrawn &&
          auction.status.name !== MOTOR_MARKET_ENDED.key;

        return (
          <Card
            key={auction.auction_vehicle_id}
            coverSource={auction?.images?.[0]?.url}
            hasWithdrawn={hasWidthdrawn}
            status={
              showCardStatusLabels
                ? {
                    status: hasWidthdrawn
                      ? MOTOR_MARKET_WITHDRAWN_BUYER.key
                      : status,
                    amount: auction?.bid_summary?.latest_bid,
                    label: hasWidthdrawn
                      ? MOTOR_MARKET_WITHDRAWN_BUYER.label
                      : [
                          MOTOR_MARKET_PENDING.key,
                          MOTOR_MARKET_BUYER_BID.key,
                        ].includes(status)
                      ? MOTOR_MARKET_PENDING.label
                      : auction.status.name === MOTOR_MARKET_ENDED.key &&
                        auction.has_withdrawn
                      ? MOTOR_MARKET_LOST.label
                      : auction.status.label,
                  }
                : undefined
            }
            content={[
              <div
                className="flex flex-col gap-2"
                key={auction.auction_vehicle_id}
              >
                <p className="text-base">
                  <span className="font-semibold">
                    <Tooltip
                      className="line-clamp-2 truncate whitespace-normal"
                      tooltip={auction.vehicle.name}
                    >
                      {auction.vehicle.name}
                    </Tooltip>
                  </span>
                </p>
                <div className="flex flex-row items-start space-x-8">
                  <div className="flex flex-col gap-2.5 max-w-sm">
                    <div className="flex items-center">
                      <p className="text-sm text-label ">
                        {createEllipsisText(
                          Object.values(auction.vehicle.specification)
                            .filter(
                              (value) =>
                                !!value &&
                                !auction.vehicle.name.includes(
                                  value as unknown as string
                                )
                            )
                            .join(" | "),
                          80
                        )}
                      </p>
                    </div>
                    <p className="text-sm">
                      {"KM: "}
                      <span className="font-semibold">
                        {auction.vehicle.odometer
                          ? formatNumber(auction.vehicle.odometer)
                          : "-"}
                      </span>
                    </p>
                  </div>
                  <div className="flex flex-col gap-2.5">
                    <div className="flex items-center">
                      <PinIcon />
                      <p className="text-sm text-label mx-1">
                        {auction.contact.location}
                      </p>
                    </div>
                    <p className="text-sm">
                      {"Seller: "}
                      <span className="font-semibold">
                        {auction.contact.dealership?.name}
                      </span>
                    </p>
                    <p className="text-sm text-label ">{auction.ends_at}</p>
                  </div>
                </div>
              </div>,
            ]}
            liveTimer={
              <div className={classNames({ "opacity-50": hasWidthdrawn })}>
                <Timer targetDate={formatDateToJsDate(auction.ends_at)} />
              </div>
            }
            actions={[
              (status === MOTOR_MARKET_LIVE.key ||
                status === MOTOR_MARKET_WATCHLIST.key) &&
                canView && (
                  <ButtonLink
                    key={auction.auction_vehicle_id}
                    disabled={addingToWatchlist || removingFromWatchlist}
                    onClick={() =>
                      auction.is_watched
                        ? removeFromWatchlist(auction.auction_watchlist_id)
                        : addToWatchList(auction.auction_vehicle_id)
                    }
                  >
                    <div className="flex items-center gap-2 font-medium">
                      {auction.is_watched ? (
                        <MarketStarIconFilled />
                      ) : (
                        <MarketStarIconOutlined />
                      )}
                      {auction.is_watched
                        ? "Remove from watchlist"
                        : "Add to watchlist"}
                    </div>
                  </ButtonLink>
                ),
            ]}
            onClick={hasWidthdrawn ? undefined : () => onClick(auction)}
          />
        );
      })}
    </>
  );
};
