import { useCallback, useState, useEffect } from "react";
import { Outlet } from "react-router-dom";

import { useViewportSizing } from "hooks";

import { ScrollView } from "./components/ScrollView";
import { TheHeader } from "./components/TheHeader";
import { TheSidebar } from "./components/TheSidebar";

import "./styles.scss";

export const LayoutDashboard = () => {
  const { isDesktop } = useViewportSizing();
  const [collapsedSidebar, setCollapsedSidebar] = useState(true);

  useEffect(() => {
    setCollapsedSidebar(!!isDesktop);
  }, [isDesktop]);

  const handleToggleCollapsed = () => {
    setCollapsedSidebar((c) => !c);
  };

  const handleCloseSidebar = useCallback(() => {
    if (!isDesktop) {
      setCollapsedSidebar(false);
    }
  }, [isDesktop]);

  return (
    <div className="layout">
      <TheHeader />
      <div className="layout__content">
        <TheSidebar
          collapsed={collapsedSidebar}
          onToggleCollapsed={handleToggleCollapsed}
        />
        <ScrollView onCloseSidebar={handleCloseSidebar}>
          <Outlet />
        </ScrollView>
      </div>
    </div>
  );
};
