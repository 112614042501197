import {
  default as AxiosService,
  AxiosRequestConfig,
  AxiosInstance,
} from "axios";
import { decode } from "js-base64";

import { USER_ACCESS_TOKEN, KEEP_SIGNED_IN } from "utils";

export const Axios: AxiosInstance = AxiosService.create();
Axios.defaults.baseURL = process.env.REACT_APP_API_URL;
Axios.interceptors.request.use(
  (config: AxiosRequestConfig<unknown>) => {
    const keepSignedIn = window.localStorage.getItem(KEEP_SIGNED_IN);

    const accessToken = (
      keepSignedIn === "true" ? window.localStorage : window.sessionStorage
    ).getItem(USER_ACCESS_TOKEN);
    const parsedAccessToken = accessToken
      ? decode(JSON.parse(accessToken))
      : null;

    if (config.headers) {
      if (parsedAccessToken) {
        config.headers.Authorization = `Bearer ${parsedAccessToken}`;
      }
      config.headers.Accept = "application/json";
      if (config.headers["Content-Type"] !== "multipart/form-data") {
        config.headers["Content-Type"] = "application/json";
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      sessionStorage.clear();

      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);
