import { Dialog, Transition } from "@headlessui/react";
import { useState, Fragment, useMemo, useEffect, useRef } from "react";
import { Navigation, Pagination, Zoom, Lazy, Keyboard } from "swiper";
import { Swiper, SwiperSlide, SwiperRef } from "swiper/react";

// import required modules
import { SvgImage } from "components/shared";

import { IImage } from "types";

import { useViewportSizing } from "hooks";

import "./styles.scss";

import { modalCarouselCarTestID } from "../dataTestIDs";

export interface ModalCarouselCarProps {
  open: boolean;
  closeModal: () => void;
  images: IImage[];
  activeIndex?: number;
  dataTestID?: string;
}

export const ModalCarouselCar = ({
  images,
  open,
  activeIndex = 0,
  closeModal,
  dataTestID,
}: ModalCarouselCarProps) => {
  const ref = useRef<SwiperRef>(null);
  const { isDesktop, isTablet } = useViewportSizing();
  const [selectedIndex, setSelectedIndex] = useState<number>(activeIndex);

  useEffect(() => {
    setSelectedIndex(activeIndex);
  }, [activeIndex]);

  const carouselCloseIcon = useMemo(() => {
    if (isDesktop) {
      return "CarouselCloseIcon";
    } else if (isTablet) {
      return "CarouselCloseSmallIcon";
    }
    return "CarouselCloseBlackIcon";
  }, [isDesktop, isTablet]);

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="carousel-modal"
        onClose={closeModal}
        data-testid={dataTestID || modalCarouselCarTestID}
      >
        <div className="min-h-screen lg:px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="carousel-modal__overlay" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="carousel-modal__container w-responsive">
              <Swiper
                ref={ref}
                initialSlide={selectedIndex}
                className="vehicleSwiper"
                zoom={true}
                navigation={true}
                lazy={true}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                keyboard={{
                  enabled: true,
                }}
                centeredSlides={true}
                modules={[Zoom, Navigation, Pagination, Lazy, Keyboard]}
                slidesPerView={1}
              >
                {images?.map((image: IImage) => {
                  const url = image.url ? image.url : image.thumb;
                  return (
                    <SwiperSlide key={image.id}>
                      <div className="swiper-zoom-container">
                        <img
                          alt={image.label}
                          src={url}
                          className="swiper-lazy"
                        />
                        <div className="swiper-lazy-preloader swiper-lazy-preloader-white" />
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <button
                type="button"
                onClick={closeModal}
                className="carousel__close !right-3"
                tabIndex={-1}
              >
                <SvgImage name={carouselCloseIcon} />
              </button>
              <button
                type="button"
                onClick={() => {
                  ref.current?.swiper.zoom.out();
                }}
                className="carousel__close !right-24"
                tabIndex={-1}
              >
                <SvgImage name="MagnifyingGlassMinusIcon" />
              </button>
              <button
                type="button"
                onClick={() => {
                  ref.current?.swiper.zoom.in();
                }}
                className="carousel__close !right-36"
                tabIndex={-1}
              >
                <SvgImage name="MagnifyingGlassPlusIcon" />
              </button>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
