import { modalVinLocationTestID } from "components/app/dataTestIDs";
import { SvgImage } from "components/shared";
import "../styles.scss";

interface ModalVinLocationProps {
  dataTestID?: string;
}

export const ModalVinLocation = ({ dataTestID }: ModalVinLocationProps) => {
  return (
    <div
      className="vin-location"
      data-testid={dataTestID || modalVinLocationTestID}
    >
      <h5 className="vin-location__title">Vin Location</h5>

      <div className="vin-location__logo">
        <SvgImage name="VinLocationIcon" />
      </div>
      <ul className="vin-location__desc">
        <li>
          On the driver’s side at the corner of the dashboard and windshield
        </li>
        <li>
          On the driver’s side where the door latches when it’s closed (next to
          the door latch)
        </li>
        <li>Under the bonnet at the front of the engine block </li>
        <li>
          Behind the driver’s side tyre on the side of the frame that is toward
          the front of the car
        </li>
        <li>
          On the inside of the driver’s side door jamb (where the door connects
          to the body of the car)
        </li>
      </ul>
      <div className="vin-location__footer">
        <label>If you can not find your VIN on your car: </label>
        <p>Check the registration or insurance documents </p>
      </div>
    </div>
  );
};
