import { MouseEventHandler, useRef } from "react";

type ObservableProps = {
  hasInvokedCallback: boolean;
  timeoutCleanup: NodeJS.Timeout | null;
  previousScrollTop: number;
};

interface IInfiniteScrollProps {
  preventInvokingCallback?: boolean;
  threshold?: number;
  resetDelayMS?: number;
  onScrollHitBottom: () => void;
}

export const useInfiniteScroll = <T extends HTMLElement = HTMLDivElement>({
  preventInvokingCallback = false,
  threshold = 50,
  resetDelayMS = 300,
  onScrollHitBottom,
}: IInfiniteScrollProps) => {
  const observable = useRef<ObservableProps>({
    hasInvokedCallback: false,
    timeoutCleanup: null,
    previousScrollTop: 0,
  }).current;

  const onScroll: MouseEventHandler<T> = (event) => {
    const target = event.target as T;

    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;
    const scrollThreshold = scrollHeight - threshold;
    const scrollTopWithHeight = scrollTop + clientHeight;
    const isScrollingDown = observable.previousScrollTop < scrollTop;
    const hasReachedThreshold = scrollThreshold <= scrollTopWithHeight;

    if (isScrollingDown && hasReachedThreshold) {
      if (observable.hasInvokedCallback || preventInvokingCallback === true) {
        if (observable.timeoutCleanup) {
          clearTimeout(observable.timeoutCleanup);
        }
        // reset hasInvokedCallback
        observable.timeoutCleanup = setTimeout(() => {
          observable.hasInvokedCallback = false;
        }, resetDelayMS);
      } else {
        onScrollHitBottom();
        observable.hasInvokedCallback = true;
      }
    }

    observable.previousScrollTop = scrollTop;
  };

  return {
    onScroll,
  };
};
