import classNames from "classnames";
import React from "react";

interface ItemProps {
  title: string;
  value?: React.ReactNode;
  failedStatus?: boolean;
}

export const Item = ({ title, value, failedStatus }: ItemProps) => {
  return (
    <div className="flex justify-between text-sm text-lightGrey h-[50px] items-center border-b border-softPeach">
      <div className="inline-flex whitespace-nowrap items-center font-semibold">
        {title}
      </div>
      <div className={classNames([{ "text-[#C8372D]": failedStatus }])}>
        {value}
      </div>
    </div>
  );
};
