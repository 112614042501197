import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

import { ModalConfirm } from "components/app";
import { Button, Modal } from "components/shared";

import {
  ActionResult,
  ActionResultMessage,
  showErrorToast,
  showSuccessToast,
} from "utils";

import { MotorMarketService } from "api/client";

interface RemoveFromMotorMarketProps {
  auctionVehicleId: string;
  onRefresh?: () => void;
}

export const RemoveFromMotorMarket = ({
  auctionVehicleId,
  onRefresh,
}: RemoveFromMotorMarketProps) => {
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

  const { mutate: removeVehicle, isLoading: isRemovingVehicle } = useMutation(
    () => MotorMarketService.deleteVehicle(auctionVehicleId),
    {
      onSuccess: () => {
        showSuccessToast("Vehicle Removed");
        setIsRemoveModalOpen(false);
        onRefresh && onRefresh();
      },
      onError: () => {
        showErrorToast("Failed to Remove");
      },
    }
  );

  return (
    <>
      <Button
        type="button"
        className="!bg-white !w-64 shadow-md"
        onClick={() => setIsRemoveModalOpen(true)}
      >
        <span className="text-primary font-bold text-sm uppercase">
          Remove from MotorMarket
        </span>
      </Button>
      <Modal
        open={isRemoveModalOpen}
        closeModal={() => setIsRemoveModalOpen(false)}
        size="auto"
      >
        <ModalConfirm
          isLoading={isRemovingVehicle}
          title={
            ActionResultMessage[ActionResult.RemoveMotorMarketVehicle].title
          }
          description={
            ActionResultMessage[ActionResult.RemoveMotorMarketVehicle].message
          }
          onCancel={() => setIsRemoveModalOpen(false)}
          onConfirm={() => removeVehicle()}
        />
      </Modal>
    </>
  );
};
