import { useCallback, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import {
  Button,
  ButtonLink,
  Card,
  FieldCheckbox,
  FieldText,
  SvgImage,
  TextError,
} from "components/shared";

import { AuthContext, ILoginRequestProps } from "context/AuthContext";

import { KEEP_SIGNED_IN, showErrorToast } from "utils";
import { DataTestID } from "utils/dataTestIDs.utils";
import { getPageTitle } from "utils/pageSections";
import { pattern } from "utils/validation";

import { useLocalStorage, useNavigationByPermission } from "hooks";

import "./styles.scss";

interface LoginFormType {
  email: string;
  password: string;
}

export const Login = () => {
  const { isLoading, signIn } = useContext(AuthContext);
  const [localStorageKeepSignedIn] = useLocalStorage(KEEP_SIGNED_IN, null);
  const [keepSignedIn, setKeepSignedIn] = useState(!!localStorageKeepSignedIn);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const { navigateByPermission } = useNavigationByPermission();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const handleShowPwd = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const handleSignIn = async (data: LoginFormType) => {
    const payload: ILoginRequestProps = {
      ...data,
      keepSignedIn,
    };

    const result = await signIn(payload);

    if (result) {
      const permissions = result?.data.user.permissions;

      navigateByPermission(permissions);
    } else {
      showErrorToast("Failed to Login");
    }
  };

  return (
    <>
      <Helmet>
        <title>{getPageTitle("Login")}</title>
      </Helmet>
      <div className="auth-container">
        <div className="w-[423px]">
          <Card>
            <form onSubmit={handleSubmit(handleSignIn)}>
              <div className="auth-card__content">
                <span className="auth-card__title">Sign in</span>
                <div className="auth-card__form">
                  <FieldText
                    {...register("email", {
                      required: true,
                      pattern: pattern.email,
                    })}
                    placeholder="Enter email address"
                    data-testid={DataTestID.LoginEmail}
                  />
                  {errors.email?.type === "required" && (
                    <TextError error="Email is required" />
                  )}
                  {errors.email?.type === "pattern" && (
                    <TextError error="Please enter the correct email" />
                  )}
                </div>
                <div className="auth-card__form">
                  <FieldText
                    type={`${showPassword ? "text" : "password"}`}
                    {...register("password", { required: true })}
                    placeholder="Enter password"
                    data-testid={DataTestID.LoginPassword}
                  />
                  <span
                    className="auth-card__form__icon"
                    onClick={handleShowPwd}
                  >
                    <SvgImage
                      name={`${showPassword ? "EyeShowIcon" : "EyeIcon"}`}
                    />
                  </span>
                  {errors.password && (
                    <TextError error="Password is required" />
                  )}
                </div>
                <FieldCheckbox
                  checked={keepSignedIn}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setKeepSignedIn(e.target.checked)
                  }
                  label="Keep me signed in"
                  id="remember-me"
                  dataTestID={DataTestID.LoginKeepMeSignedIn}
                />
                <div className="auth-card__footer">
                  <div className="w-[130px]">
                    <Button
                      type="submit"
                      isLoading={isLoading}
                      dataTestID={DataTestID.LoginSignIn}
                    >
                      <span className="text-lighttxt text-sm font-bold">
                        SIGN IN
                      </span>
                    </Button>
                  </div>
                  <ButtonLink
                    onClick={() => {
                      navigate("/forgot-password");
                    }}
                    dataTestID={DataTestID.LoginForgotPassword}
                  >
                    Forgot Password?
                  </ButtonLink>
                </div>
              </div>
            </form>
          </Card>
        </div>
      </div>
    </>
  );
};
