import { IQueryKeyProps, IUser } from "types";

import { api, url } from "api";

export const UserService = {
  showUser: ({ queryKey }: IQueryKeyProps) =>
    api.get(`${url.users}/${queryKey[1]}`) as Promise<IUser>,

  updateUser: (data: any, userId: string) =>
    api.put(`${url.users}/${userId}`, data),
};
