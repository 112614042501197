import { useState } from "react";

import { FieldValuation } from "components/app";
import { DirectOfferLogModal } from "components/Modal/DirectOfferLogModal/DirectOfferLogModal";

import { formatNumber } from "utils";

type DirectOfferBuyLogProps = {
  offerID: string;
  offerAmount?: string;
};

export const DirectOfferBuyLog = ({
  offerID,
  offerAmount,
}: DirectOfferBuyLogProps) => {
  const [showDirectOfferLogModal, setShowDirectOfferLogModal] = useState(false);

  if (!offerID && !offerAmount) {
    return null;
  }

  return (
    <>
      <DirectOfferLogModal
        isOpen={showDirectOfferLogModal}
        onClose={() => setShowDirectOfferLogModal(false)}
        offerID={offerID}
      />
      <FieldValuation
        title="Direct Offer"
        value={`$ ${formatNumber(offerAmount)}`}
        onView={() => setShowDirectOfferLogModal(true)}
      />
    </>
  );
};
