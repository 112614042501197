import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { useContext, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import {
  CardTender,
  ModalTenderBidders,
  ModalTenderCreate,
  TenderActionsBar,
  TenderVehicleSection,
} from "components/app";
import { ErrorMessage } from "components/ErrorMessage";
import { NoPermissionMessage } from "components/NoPermissionMessage";
import { Breadcrumbs, Button, Modal } from "components/shared";

import { AuthContext } from "context/AuthContext";

import { TENDER_MENU } from "utils";
import { getPageTitle, SECTIONS } from "utils/pageSections";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import {
  IBuyerDealership,
  ITenderForm,
  ITenderSummaryResponse,
  NavigationMenuLabel,
} from "types";

import { TenderService } from "api/client";

export const TenderSellingSummary = () => {
  const { userID, hasPermission } = useContext(AuthContext);
  const params = useParams();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openBidderModal, setOpenBidderModal] = useState(false);

  const canUpdate = hasPermission(
    PermissionCategory.TENDER_SELLING,
    PermissionAction.UPDATE
  );

  const canView = hasPermission(
    PermissionCategory.TENDER_SELLING,
    PermissionAction.VIEW
  );

  const { data, isLoading, isError, refetch } =
    useQuery<ITenderSummaryResponse | null>(
      [`tender-seller-summary-${params.id}_${userID}`, params.id],
      TenderService.getTenderSummary,
      {
        cacheTime: 0,
        refetchOnWindowFocus: false,
        enabled: canView,
      }
    );

  const { data: buyers, refetch: refetchBuyers } = useQuery<{
    data: IBuyerDealership[];
  }>(
    [`tender-seller-summary-buyers-${userID}`, data?.data.tender_id],
    TenderService.getBuyers,
    {
      cacheTime: 0,
      enabled: !!data?.data.tender_id && (canView || canUpdate),
    }
  );

  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem("SCROLL_POSITION");
    if (savedScrollPosition) {
      document
        .getElementsByClassName("dashboard")[0]
        .scrollTo({ behavior: "smooth", top: parseInt(savedScrollPosition) });
      sessionStorage.removeItem("SCROLL_POSITION");
    }

    return () => {
      document.getElementsByClassName("dashboard")[0].scrollTo({ top: 0 });
    };
  }, []);

  const hasBidderSelected = useMemo(() => {
    return !!buyers?.data.find(({ is_selected }) => is_selected);
  }, [buyers?.data]);

  const handleEditTenderInfo = () => setOpenEditModal(true);

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    refetch();
  };

  const handleOpenBidderModal = () => setOpenBidderModal(true);

  const handleCloseBidderModal = () => {
    setOpenBidderModal(false);
    refetchBuyers();
  };

  const tenderFormData: ITenderForm | undefined = useMemo(() => {
    if (data) {
      const { name, ends_at, starts_at, location, notes } = data.data;
      return {
        name,
        startsAt: {
          date: DateTime.fromJSDate(new Date(starts_at)).toISODate(),
          time: DateTime.fromJSDate(new Date(starts_at)).toFormat("HH:mm"),
        },
        endsAt: {
          date: DateTime.fromJSDate(new Date(ends_at)).toISODate(),
          time: DateTime.fromJSDate(new Date(ends_at)).toFormat("HH:mm"),
        },
        location: location ?? "",
        notes: notes ?? "",
      };
    }
  }, [data]);

  return (
    <>
      <Helmet>
        <title>{getPageTitle("Selling", SECTIONS.TENDER)}</title>
      </Helmet>
      <div className="dashboard__container">
        <div className="flex justify-between w-full mb-15">
          <div className="flex flex-col flex-1 gap-2.5">
            <Breadcrumbs
              paths={[
                {
                  title: NavigationMenuLabel.TENDER,
                  route: TENDER_MENU.selling.route,
                },
                { title: "Selling", route: TENDER_MENU.selling.route },
                {
                  title: "Tender Summary",
                  route: `${TENDER_MENU.selling.route}/${params.id}/summary`,
                },
              ]}
            />
            <div className="title">{data?.data.name || ""}</div>
          </div>
          {data && (
            <TenderActionsBar
              data={data.data}
              bidderSelected={buyers?.data.some((buyer) => buyer.is_selected)}
              onRefresh={refetch}
            />
          )}
        </div>
        {!canView && (
          <NoPermissionMessage
            viewPermissionCategory={PermissionCategory.TENDER_SELLING}
          />
        )}
        {isLoading && canView && <span>Loading...</span>}
        {isError && <ErrorMessage />}
        {data && !isError && (
          <>
            <CardTender
              data={data.data}
              onClick={() => {}}
              onEdit={handleEditTenderInfo}
            />
            <div className="mt-16 py-4 flex items-center gap-10">
              <p className="text-2xl font-semibold text-txt">Bidders</p>
              <div className="w-185">
                <Button
                  type="button"
                  onClick={handleOpenBidderModal}
                  disabled={
                    (!hasBidderSelected && !canUpdate) ||
                    (hasBidderSelected && !canView)
                  }
                >
                  <b>{hasBidderSelected ? "VIEW" : "ADD"}</b>
                </Button>
              </div>
            </div>
            <TenderVehicleSection data={data.data} canView={canView} />
          </>
        )}
        <Modal open={openEditModal} closeModal={handleCloseEditModal}>
          <ModalTenderCreate
            existedData={tenderFormData}
            tenderStatus={data?.data.status || ""}
            onClose={handleCloseEditModal}
            tenderId={data?.data.tender_id}
          />
        </Modal>
        <Modal open={openBidderModal} closeModal={handleCloseBidderModal}>
          <ModalTenderBidders
            buyers={buyers?.data}
            tenderId={data?.data.tender_id || ""}
            tenderStatus={data?.data.status || ""}
            onClose={handleCloseBidderModal}
            canEdit={canUpdate}
          />
        </Modal>
      </div>
    </>
  );
};
