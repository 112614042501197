import { IDealershipDetailResponse, IQueryKeyProps } from "types";

import { api, url } from "api";

export const DealershipService = {
  getDealershipPool: ({ queryKey }: IQueryKeyProps) =>
    api.get(
      `${url.dealerships}/${queryKey[1]}/pools`,
      queryKey[2]
    ) as Promise<IDealershipDetailResponse>,
};
