import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { SearchBar } from "components/app";
import { DirectOfferDealershipSelection } from "components/DirectOffer/DirectOfferDealershipSelection";
import { DirectOfferRevoke } from "components/DirectOffer/DirectOfferRevoke";
import { Button, FieldText } from "components/shared";

import { AuthContext } from "context/AuthContext";

import { formatToNumber } from "utils";
import { directOffer } from "utils/directOffer";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import { IDirectOfferBuyer, IDirectOfferPayload } from "types/IDirectOffer";

import { DirectOfferService } from "api/client/DirectOfferService";

export interface StateProps {
  offerAmount: string;
  searchText: string;
  selectedBuyerIDs: string[];
  isSelectAll: boolean;
}

export const defaultStates: StateProps = {
  offerAmount: "",
  searchText: "",
  selectedBuyerIDs: [],
  isSelectAll: false,
};

export interface IDirectOfferFormProps {
  onRefresh?: () => void;
}

export const DirectOfferForm = ({ onRefresh }: IDirectOfferFormProps) => {
  const params = useParams();
  const { id: contractID } = params;
  const { hasPermission } = useContext(AuthContext);

  const [
    { offerAmount, searchText, selectedBuyerIDs, isSelectAll },
    setStates,
  ] = useState<StateProps>(defaultStates);

  const { data: { data } = {}, refetch } = useQuery<
    { data: IDirectOfferBuyer[] },
    Error
  >(["direct-offer-dealeships", contractID], DirectOfferService.getBuyers, {
    staleTime: 0,
  });

  const dealerships = useMemo(
    () =>
      data?.filter((buyer) =>
        buyer.name?.toLowerCase().includes(searchText?.toLowerCase())
      ) || [],
    [data, searchText]
  );

  const { mutate, isLoading: isSendOfferLoading } = useMutation({
    mutationFn: async (payload: IDirectOfferPayload) => {
      const { existingOffers, newOffers } = directOffer.offers(
        data || [],
        payload
      );

      if (existingOffers.length > 0) {
        const response = await DirectOfferService.updateOffer(
          {
            dealership_ids: existingOffers.map((item) => item.id),
            amount: payload.offer_amount,
          },
          contractID!
        );

        if (newOffers.length === 0) {
          return response;
        }
      }

      if (newOffers.length > 0) {
        return DirectOfferService.sendOffer(
          {
            ...payload,
            dealership_ids: newOffers.map((item) => item.id),
          },
          contractID!
        );
      }
    },

    onSuccess: () => {
      toast.success("Offer Sent", {
        autoClose: 2000,
        theme: "colored",
      });
      setStates(defaultStates);
      onRefresh && onRefresh();
      refetch();
    },
    onError: (error) => {
      toast.error("Offer Failed to Send", {
        autoClose: 2000,
        theme: "colored",
      });
    },
  });

  const onSubmitOffer = () => {
    mutate({
      offer_amount: formatToNumber(offerAmount || "0"),
      dealership_ids: selectedBuyerIDs || [],
    });
  };

  return (
    <div>
      <p className="text-label text-sm mb-10">
        Add or Edit your asking price and select the dealership/s to offer it
        to.
      </p>
      <div className="flex flex-col gap-5">
        <div>
          <p className="font-semibold mb-2">Offer Amount</p>
          <FieldText
            type="text"
            value={offerAmount}
            onChange={(e) =>
              setStates((prev) => ({ ...prev, offerAmount: e.target.value }))
            }
            placeholder="Enter Your Asking Price"
          />
        </div>
        <div className="direct-offer">
          <p className="font-semibold mb-2">Send Offer To</p>
          <div className="relative w-full mb-3">
            <SearchBar
              value={searchText}
              onSearch={(e) =>
                setStates((prev) => ({ ...prev, searchText: e.target.value }))
              }
            />
          </div>
          <DirectOfferDealershipSelection
            data={dealerships}
            isSelectAll={isSelectAll}
            selectedBuyerIDs={selectedBuyerIDs}
            setStates={setStates}
          />
          <div className="btn__direct__offer flex gap-5 mt-10">
            {hasPermission(
              PermissionCategory.DIRECT_OFFERS,
              PermissionAction.DELETE
            ) && (
              <DirectOfferRevoke
                contractID={contractID!}
                selectedBuyerIDs={selectedBuyerIDs}
                onRefresh={() => {
                  setStates(defaultStates);
                  onRefresh && onRefresh();
                  refetch();
                }}
              />
            )}
            <Button
              type="button"
              onClick={onSubmitOffer}
              isLoading={isSendOfferLoading}
              disabled={selectedBuyerIDs.length === 0 || !offerAmount}
            >
              <span>Send Offer</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
