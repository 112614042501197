import { CardDealership } from "components/app";

import { IDealershipDetail } from "types";

import "./styles.scss";

export interface ListDealershipsProps {
  data: IDealershipDetail[];
  onSelect: (id: string) => void;
  dataTestID?: string;
}

export const ListDealerships = ({
  data,
  onSelect,
  dataTestID,
}: ListDealershipsProps) => {
  return (
    <div className="list" data-testid={dataTestID}>
      {data.map((dealership) => (
        <CardDealership
          key={dealership.id}
          data={dealership}
          onClick={() => onSelect(dealership.id)}
          dataTestID={`${dataTestID}-card-${dealership.id}`}
        />
      ))}
    </div>
  );
};
