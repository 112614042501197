import { useEffect } from "react";

import { DirectOfferDealershipSelectionItem } from "components/DirectOffer/DirectOfferDealershipSelectionItem";
import { StateProps } from "components/DirectOffer/DirectOfferForm";
import { ButtonLink } from "components/shared";

import { IDirectOfferBuyer } from "types/IDirectOffer";

interface IDirectOfferDealershipSelectionProps {
  data: IDirectOfferBuyer[];
  isSelectAll: boolean;
  selectedBuyerIDs: string[];
  setStates: (value: React.SetStateAction<StateProps>) => void;
}

export const DirectOfferDealershipSelection = ({
  data,
  isSelectAll,
  selectedBuyerIDs,
  setStates,
}: IDirectOfferDealershipSelectionProps) => {
  const onSelect = (buyerID: string) => {
    const manageSelected = (prev: string[]) => {
      if (prev.includes(buyerID)) {
        return prev.filter((id) => id !== buyerID);
      }

      return [...prev, buyerID];
    };

    setStates((prev) => ({
      ...prev,
      selectedBuyerIDs: manageSelected(prev.selectedBuyerIDs),
    }));
  };

  useEffect(() => {
    setStates((prev) => ({
      ...prev,
      isSelectAll: selectedBuyerIDs.length === data.length,
    }));
  }, [selectedBuyerIDs, data]);

  return (
    <>
      <ButtonLink
        onClick={() =>
          setStates((prev) => ({
            ...prev,
            selectedBuyerIDs: prev.isSelectAll
              ? []
              : data?.map((buyer) => buyer.id),
          }))
        }
      >
        <span className="text-secondary">
          {isSelectAll ? "Deselect" : "Select"} All
        </span>
      </ButtonLink>
      <div className="mt-5">
        {data.map((dealership) => (
          <DirectOfferDealershipSelectionItem
            key={dealership.id}
            buyer={dealership}
            selected={selectedBuyerIDs.includes(dealership.id)}
            onSelect={onSelect}
          />
        ))}
      </div>
    </>
  );
};
