import { useState } from "react";

import { ButtonStatus } from "components/app";
import { DirectOfferBuy } from "components/DirectOffer/DirectOfferBuy";
import { DirectOfferReject } from "components/DirectOffer/DirectOfferReject";
import { DirectOfferLogModal } from "components/Modal/DirectOfferLogModal/DirectOfferLogModal";
import { SvgImage } from "components/shared";

import { LOSTSALE, REJECTED, REVOKED, formatAmount } from "utils";

interface IDirectOfferBuyNowProps {
  offerID: string;
  offerAmount?: string;
  status: string;
  contractID: string;
  requestID?: string;
  onRefresh?: () => void;
}

export const DirectOfferBuyNow = ({
  offerID,
  offerAmount,
  status,
  contractID,
  requestID,
  onRefresh,
}: IDirectOfferBuyNowProps) => {
  const [showDirectOfferLogModal, setShowDirectOfferLogModal] = useState(false);

  return (
    <>
      <div className="flex justify-between mt-2">
        <p className="flex">
          <span className="text-sm font-semibold">Buy Now</span>
          {!!offerID && (
            <span
              className="hover:cursor-pointer ml-2 m-1"
              onClick={() => setShowDirectOfferLogModal(true)}
            >
              <SvgImage name="EyeIcon" width={20} height={14} />
            </span>
          )}
        </p>
        <p className="text-sm">
          {status === REJECTED.key ? (
            <ButtonStatus status={status} label={REJECTED.label} />
          ) : status === LOSTSALE.key ? (
            <ButtonStatus status={status} label={LOSTSALE.label} />
          ) : (
            formatAmount(offerAmount) || "-"
          )}
        </p>
      </div>
      {!!offerID && (
        <DirectOfferLogModal
          isOpen={showDirectOfferLogModal}
          onClose={() => setShowDirectOfferLogModal(false)}
          offerID={offerID}
        />
      )}
      {![REJECTED.key, REVOKED.key, LOSTSALE.key].includes(status) && (
        <div className="direct-offer flex gap-5 mt-5">
          <DirectOfferReject
            offerID={offerID}
            contractID={contractID}
            requestID={requestID!}
          />
          <DirectOfferBuy
            offerID={offerID}
            contractID={contractID}
            onRefresh={onRefresh}
          />
        </div>
      )}
    </>
  );
};
