export const TermsAndConditions = () => {
  return (
    <div className="mb-5 text-sm">
      By continuing, the customer agrees to Motorplatform&nbsp;
      <a
        href="https://www.motorplatform.com.au/privacy-policy"
        target="_blank"
        rel="noreferrer"
        className="underline"
      >
        Terms and Conditions.
      </a>
    </div>
  );
};
