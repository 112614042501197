import { modalCompleteInspectionTestID } from "components/app/dataTestIDs";
import { Button } from "components/shared";
import "../styles.scss";

export interface ModalCompleteInspectionProps {
  onConfirm: () => void;
  closeModal: () => void;
  dataTestID?: string;
}

export const ModalCompleteInspection = ({
  onConfirm,
  closeModal,
  dataTestID,
}: ModalCompleteInspectionProps) => {
  const handleCancel = () => {
    closeModal();
  };

  const handleConfirm = () => {
    onConfirm();
    closeModal();
  };

  return (
    <div
      className="complete-inspection"
      data-testid={dataTestID || modalCompleteInspectionTestID}
    >
      <h5>Are you sure you want to complete this inspection?</h5>
      <p>Once you proceed, you cannot go back or edit your responses.</p>

      <div className="complete-inspection__footer !flex-row">
        <div className="btn__cancel !w-[50px] !mb-0">
          <Button type="button" onClick={handleCancel}>
            <span>No</span>
          </Button>
        </div>
        <div className="btn__confirm">
          <Button type="button" onClick={handleConfirm}>
            <span>Yes, proceed</span>
          </Button>
        </div>
      </div>
    </div>
  );
};
