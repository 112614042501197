import { useState } from "react";

import { SupportModal } from "components/Modal/SupportModal";
import { SvgImage } from "components/shared";

export const Support = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <SupportModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <div className="sidebar__menu-item" onClick={() => setIsOpen(true)}>
        <SvgImage name="Support" className="mr-4" />
        <span>Support</span>
      </div>
    </>
  );
};
