import { useMemo, useState } from "react";

import {
  FormConfirmVariant,
  FormSelectVariant,
  FormManualSearch,
} from "components/app";
import { modalSearchManualTestID } from "components/app/dataTestIDs";
import { ISearchFormType } from "components/app/Form/Search/FormManualSearch";

import { IVariant } from "types";
import "../styles.scss";

enum STEPS {
  VEHICLE_SEARCH = "SEARCH",
  SELECT_VARIANT = "SELECT_VARIANT",
  CONFIRM_VARIANT = "CONFIRM_VARIANT",
}

export interface ModalSearchManualProps {
  inspectionType: string;
  dataTestID?: string;
  onCreatedNew: (contract_id: string) => void;
}

export const ModalSearchManual = ({
  inspectionType,
  dataTestID,
  onCreatedNew,
}: ModalSearchManualProps) => {
  const [step, setStep] = useState<STEPS>(STEPS.VEHICLE_SEARCH);
  const [selectedSearchOptions, setSelectedSearchOptions] =
    useState<ISearchFormType>();
  const [selectedVariant, setSelecteVariant] = useState<IVariant>();

  const modalSearchTitle = useMemo(() => {
    switch (step) {
      case STEPS.VEHICLE_SEARCH:
        return "Manual Search";
      case STEPS.SELECT_VARIANT:
        return "Select Variant";
      case STEPS.CONFIRM_VARIANT:
        return "Is this your vehicle?";
      default:
        return "";
    }
  }, [step]);

  const handleStep = (curStep: string) => {
    if (curStep === STEPS.SELECT_VARIANT) {
      setStep(STEPS.VEHICLE_SEARCH);
    } else if (curStep === STEPS.CONFIRM_VARIANT) {
      setStep(STEPS.SELECT_VARIANT);
    }
  };

  return (
    <div
      className="manual-search"
      data-testid={dataTestID || modalSearchManualTestID}
    >
      <div className="search-form">
        <h3 className="search-form__title">{modalSearchTitle}</h3>
        {step === STEPS.VEHICLE_SEARCH && (
          <FormManualSearch
            onNextStep={(val) => {
              setSelectedSearchOptions(val);
              setStep(STEPS.SELECT_VARIANT);
            }}
          />
        )}
        {step === STEPS.SELECT_VARIANT && (
          <FormSelectVariant
            selectedSearchOptions={selectedSearchOptions}
            onNextStep={(val) => {
              setSelecteVariant(val);
              setStep(STEPS.CONFIRM_VARIANT);
            }}
            onBack={handleStep}
          />
        )}
        {step === STEPS.CONFIRM_VARIANT && (
          <FormConfirmVariant
            inspectionType={inspectionType}
            selectedVariant={selectedVariant}
            onNextStep={onCreatedNew}
            onBack={handleStep}
          />
        )}
      </div>
    </div>
  );
};
