import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import {
  DealershipInformation,
  Footer,
  LoadVehicleSummary,
  SellerInformation,
  SummaryHeader,
  ValuationsSection,
  VehicleInformation,
} from "components/app";
import { ErrorMessage } from "components/ErrorMessage";
import { NoPermissionMessage } from "components/NoPermissionMessage";
import { Banner, Breadcrumbs } from "components/shared";
import { VehicleComments } from "components/VehicleNotes";
import { VehiclePhotoContainer } from "components/VehiclePhotoContainer";

import { AuthContext } from "context/AuthContext";

import {
  DECLINED_VALUATION,
  FLOW_TYPE,
  LOSTSALE,
  REJECTED,
  REVOKED,
  VEHICLE_SUMMARY_TITLE,
  WHOLESALE_DEALERSHIP_MENU,
} from "utils";
import { getPageTitle, SECTIONS } from "utils/pageSections";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import { IVehicleSummaryResponse, NavigationMenuLabel } from "types";

import { ValuationService } from "api/client";

import "./styles.scss";

export const WholesaleNegotiationSummary = () => {
  const { userID, hasPermission } = useContext(AuthContext);
  const params = useParams();
  const { id: contractId, requestId } = params;

  const canView = hasPermission(PermissionCategory.WHOLESALE_INSPECTIONS, [
    PermissionAction.VIEW,
    PermissionAction.VIEW_ANY,
  ]);

  const { data, isLoading, isError, refetch } =
    useQuery<IVehicleSummaryResponse>(
      [
        `wholesale-summary-${contractId}`,
        contractId,
        {
          with: "dealership,owner,assessments,valuations,expenses,images,comment",
        },
        requestId,
        "negotiation",
      ],
      ValuationService.getValuationRequestSummary,
      {
        onError: (err) => {
          console.log("fetch summary error: ", err);
        },
        refetchOnWindowFocus: false,
        enabled: canView,
      }
    );

  const statusName = data?.data.status.name;

  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem("SCROLL_POSITION");
    if (savedScrollPosition) {
      document
        .getElementsByClassName("dashboard")[0]
        .scrollTo({ behavior: "smooth", top: parseInt(savedScrollPosition) });
      sessionStorage.removeItem("SCROLL_POSITION");
    }

    return () => {
      document.getElementsByClassName("dashboard")[0].scrollTo({ top: 0 });
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{getPageTitle("Valued", SECTIONS.WHOLESALE_DEALERSHIP)}</title>
      </Helmet>
      <div className="dashboard__container">
        <div className="dashboard__header">
          <Breadcrumbs
            paths={[
              {
                title: NavigationMenuLabel.WHOLESALE_DEALERSHIP,
                route: WHOLESALE_DEALERSHIP_MENU[0].route,
              },
              { title: "Valued", route: WHOLESALE_DEALERSHIP_MENU[1].route },
              {
                title: VEHICLE_SUMMARY_TITLE,
                route: `${WHOLESALE_DEALERSHIP_MENU[0].route}/valued/${contractId}/${requestId}/summary`,
              },
            ]}
          />
        </div>
        <div className="dashboard__content">
          {!canView ? (
            <NoPermissionMessage />
          ) : (
            <>
              {isLoading && <LoadVehicleSummary />}
              {isError && <ErrorMessage />}
              {data && !isError && (
                <>
                  <div className="dashboard__content--left">
                    <SummaryHeader
                      data={data.data}
                      onRefresh={refetch}
                      flow={FLOW_TYPE.WHOLESALE_DEALERSHIP}
                    />
                    <VehicleInformation
                      data={data.data}
                      defaultOpen
                      flow={FLOW_TYPE.WHOLESALE_DEALERSHIP}
                      refetch={refetch}
                      isLocked={
                        !hasPermission(
                          PermissionCategory.WHOLESALE_INSPECTIONS,
                          PermissionAction.UPDATE
                        )
                      }
                    />
                    <DealershipInformation data={data.data} />
                    <SellerInformation
                      data={data.data}
                      flow={
                        data?.data?.owner?.id
                          ? FLOW_TYPE.RETAIL_DEALERSHIP
                          : FLOW_TYPE.WHOLESALE_DEALERSHIP
                      }
                    />
                    <VehiclePhotoContainer data={data.data} />
                  </div>
                  <div className="dashboard__content--right">
                    <Banner
                      name={statusName!}
                      label={
                        statusName === DECLINED_VALUATION.key
                          ? DECLINED_VALUATION.label
                          : data.data.status.label
                      }
                    />
                    <div className="right-container">
                      {![REJECTED.key, REVOKED.key, LOSTSALE.key].includes(
                        statusName!
                      ) && (
                        <ValuationsSection
                          data={data.data}
                          onRefresh={refetch}
                          flow={FLOW_TYPE.WHOLESALE_DEALERSHIP}
                        />
                      )}
                      <Footer
                        data={data.data}
                        flow={FLOW_TYPE.WHOLESALE_DEALERSHIP}
                        canSellVehicle={hasPermission(
                          PermissionCategory.WHOLESALE_INSPECTIONS,
                          PermissionAction.SELL
                        )}
                      />
                      <VehicleComments contractID={data.data?.contract_id} />
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
