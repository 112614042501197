import { useMutation, useQuery } from "@tanstack/react-query";
import { ChangeEvent, KeyboardEvent, useContext, useState } from "react";

import { InformativeModal } from "components/Modal/InformativeModal";
import { ButtonLink, FieldText, Modal } from "components/shared";

import { AuthContext } from "context/AuthContext";
import { useDealership } from "context/DealershipContext";

import {
  ActionResult,
  ActionResultMessage,
  ACTIVE,
  convertToNumber,
  formatNumber,
  formatNumberWithDecimal,
  MAX_TENDER_BID,
  showErrorToast,
  showSuccessToast,
} from "utils";

import { ITenderVehicle, IVehicleSummary } from "types";

import { ReactComponent as EyeIcon } from "assets/images/eye-icon.svg";
import { ReactComponent as SendIcon } from "assets/images/send.svg";
import { ReactComponent as StarOutlineIcon } from "assets/images/star-outline.svg";
import { ReactComponent as StarIcon } from "assets/images/star.svg";

import { TenderService } from "api/client";

import { ModalAddNotes, ModalConfirm } from "../Modal";
import { ModalTenderBidsHistory } from "../Modal/ModalTenderBidsHistory";

interface TenderSectionProps {
  data: ITenderVehicle;
  inspectionData: IVehicleSummary;
  tenderStatus: string;
  tenderDealershipId: string;
  onRefresh: () => void;
  canBid: boolean;
}

export const TenderSection = ({
  data,
  // inspectionData,
  tenderStatus,
  tenderDealershipId,
  onRefresh,
  canBid = false,
}: TenderSectionProps) => {
  const { dealershipID, showAllDealerships } = useDealership();
  const { user, userID } = useContext(AuthContext);

  const [editedBid, setEditedBid] = useState("");
  const [openBidConfirmModal, setOpenBidConfirmModal] = useState(false);
  // const [showResearchVehicle, setShowResearchVehicle] =
  //   useState<boolean>(false);
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [isTenderModalOpen, setIsTenderModalOpen] = useState(false);
  const [isBidSuccessOpen, setIsBidSuccessOpen] = useState(false);
  const [note, setNote] = useState("");

  const { data: notes, refetch: refetchNotes } = useQuery(
    [`fetch_note_${data.tender_vehicle_id}_${userID}`, data.tender_vehicle_id],
    TenderService.getNotes,
    {
      cacheTime: 0,
    }
  );

  const { mutate: toggleFavoriteMutate } = useMutation(
    (payload: { dealership_ids: string[] }) =>
      TenderService.toggleVehicleSave(
        payload,
        data.tender_vehicle_id || "",
        data.is_saved
      ),
    {
      onSuccess: () => {
        onRefresh();
      },
      onError: () => {
        showErrorToast("Failed To Add Favorite");
      },
    }
  );
  const { mutate: placeBids, isLoading: isPlaceBidsLoading } = useMutation(
    (data: { id: string; payload: { amount: string | number } }) =>
      TenderService.placeBid(
        data.payload,
        data.id,
        showAllDealerships ? user?.data.dealership?.id : dealershipID
      ),
    {
      onSuccess: () => {
        onRefresh();
        setIsBidSuccessOpen(true);
        setEditedBid("");
      },
      onError: (error: { data: Error }) => {
        showErrorToast(error.data.message || "Failed to save");
      },
    }
  );
  const { mutate: addNotes } = useMutation(
    (payload: { comment: string }) =>
      TenderService.addNotes(payload, data.tender_vehicle_id || ""),
    {
      onSuccess: () => {
        refetchNotes();
        setNote("");
        showSuccessToast("Added notes successfully");
      },
      onError: () => {
        showErrorToast("Added notes failed");
      },
    }
  );

  const handleToggleFavorite = () => {
    toggleFavoriteMutate({ dealership_ids: [tenderDealershipId] });
  };

  const handleChangeBidAmount = (e: ChangeEvent<HTMLInputElement>) => {
    //remove all characters except numbers, dot and comma
    const bidValueWithFormat = e.target.value.replace(/[^0-9.,]/g, "");
    // remove comma so we can process the max tender bid
    const bidAmount = e.target.value.replace(/[^0-9.]/g, "");
    if (+bidAmount <= MAX_TENDER_BID) {
      setEditedBid(bidValueWithFormat);
    }
  };

  const handleOpenBidConfirm = () => {
    if (tenderStatus !== ACTIVE.key) {
      return;
    }

    handlePlaceBids();
  };

  const handleCloseBidConfirmModal = () => setOpenBidConfirmModal(false);
  const handlePlaceBids = () => {
    const payload = {
      id: data.tender_vehicle_id,
      payload: {
        amount: convertToNumber(editedBid),
      },
    };
    placeBids(payload);
  };

  const handleOpenNoteModal = () => setOpenNoteModal(true);
  const handleCloseNoteModal = () => setOpenNoteModal(false);
  const handleChangeNote = (e: ChangeEvent<HTMLInputElement>) => {
    setNote(e.target.value);
  };

  const handleEnterKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleAddNote();
    }
  };
  const handleAddNote = () => {
    if (note) {
      addNotes({ comment: note });
    }
  };

  const { is_saved } = data;
  // const { reference } = inspectionData;
  const latestNote = notes?.data[0];

  return (
    <div className="section !gap-0">
      <div className="section__header">
        <span className="!text-xl font-semibold text-txt">Tender</span>
      </div>
      <div className="py-5 mt-2 w-full flex items-center justify-between border-b border-softPeach">
        <span className="text-sm font-semibold text-txt">Save</span>
        <ButtonLink onClick={handleToggleFavorite}>
          {is_saved ? <StarIcon /> : <StarOutlineIcon />}
        </ButtonLink>
      </div>
      <div className="py-2.5 w-full flex items-center justify-between border-b border-softPeach">
        <div className="flex items-center">
          <span className="text-sm font-semibold text-txt mr-2">My Bid</span>
          {data?.bid?.amount && (
            <ButtonLink onClick={() => setIsTenderModalOpen(true)}>
              <EyeIcon className="cursor-pointer" />
            </ButtonLink>
          )}
        </div>
        <div>
          <span>{`$${formatNumber(data?.bid?.amount || 0)}`}</span>
        </div>
      </div>
      {canBid && (
        <div className="py-2.5 w-full flex items-center justify-between border-b border-softPeach">
          <div className="relative w-full">
            <p className="absolute left-2 top-2.5 font-normal text-txt">$</p>
            <FieldText
              max={MAX_TENDER_BID}
              disabled={tenderStatus !== ACTIVE.key}
              value={formatNumberWithDecimal(editedBid)}
              placeholder="Enter Bid"
              onChange={handleChangeBidAmount}
              className="shadow-md-inset h-10 outline-none pl-7 rounded-4 bg-white"
            />
            <div
              onClick={handleOpenBidConfirm}
              className={`h-full absolute top-0 right-0 rounded-4 flex justify-center items-center p-2 px-4 shadow cursor-pointer ${
                tenderStatus === ACTIVE.key && !isPlaceBidsLoading
                  ? "shadow-secondary bg-secondary"
                  : "bg-label"
              }`}
            >
              <span className="text-white mr-2 font-medium">PLACE BID</span>
              <SendIcon />
            </div>
          </div>
        </div>
      )}
      {/* {isFeatureAvailable(FEATURES.RESEARCH_VEHICLE) && (
        <>
          <div onClick={() => setShowResearchVehicle(!showResearchVehicle)}>
            <FieldValuation
              title="Research Vehicle"
              tool={
                <SvgImage
                  name="SelectDown"
                  className={`${
                    showResearchVehicle ? "transform rotate-180" : ""
                  }`}
                />
              }
            />
          </div>
          {showResearchVehicle && (
            <div>
              <a
                className="section__body--link"
                target="_blank"
                href={reference.car_sales}
                rel="noreferrer"
              >
                carsales.com.au
              </a>
              <a
                className="section__body--link"
                target="_blank"
                href={reference.red_book}
                rel="noreferrer"
              >
                redbook.com.au
              </a>
            </div>
          )}
        </>
      )} */}
      <div className="py-2.5 mt-2 w-full flex items-center justify-between">
        <span className="text-sm font-semibold text-txt">Notes</span>
        <ButtonLink onClick={handleOpenNoteModal}>
          <span className="text-secondary">{`View All >`}</span>
        </ButtonLink>
      </div>
      <FieldText
        className="shadow-md-inset h-10 rounded-4 w-full pl-3 mt-2 outline-none"
        placeholder="Enter your comments here"
        value={note}
        onChange={handleChangeNote}
        onKeyPress={handleEnterKeyPress}
      />
      {latestNote && (
        <div className="bg-white w-full py-3 px-4 flex flex-col mt-2.5 shadow-md rounded-10">
          <div className="w-full flex items-center justify-between">
            <b className="text-sm font-medium text-txt">
              {latestNote.commentator.name}
            </b>
            <span className="text-sm text-txt opacity-80">
              {latestNote.commented_at}
            </span>
          </div>
          <p className="text-sm text-txt mt-2">{latestNote.comment}</p>
        </div>
      )}
      <Modal open={openNoteModal} closeModal={handleCloseNoteModal} size="auto">
        <ModalAddNotes
          notes={notes?.data || []}
          onRefresh={refetchNotes}
          tenderVehicleId={data.tender_vehicle_id || ""}
        />
      </Modal>
      <Modal
        open={openBidConfirmModal}
        closeModal={handleCloseBidConfirmModal}
        size="auto"
      >
        <ModalConfirm
          title="Place Bid?"
          description="This action will confirm your bid amount, You will not be able to change or update it once it has been confirmed."
          onCancel={handleCloseBidConfirmModal}
          onConfirm={handlePlaceBids}
        />
      </Modal>
      <Modal
        open={isBidSuccessOpen}
        closeModal={() => {
          setIsBidSuccessOpen(false);
          onRefresh();
        }}
        size="auto"
      >
        <InformativeModal
          content={{
            title: ActionResultMessage[ActionResult.BidPlacedWithSuccess].title,
            message:
              ActionResultMessage[ActionResult.BidPlacedWithSuccess].message,
            icon: "SuccessIcon",
          }}
        />
      </Modal>
      <Modal
        open={isTenderModalOpen}
        closeModal={() => {
          setIsTenderModalOpen(false);
        }}
        title="Bids Log"
        size="auto"
      >
        <ModalTenderBidsHistory bidderID={data.tender_vehicle_id || ""} />
      </Modal>
    </div>
  );
};
