import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { Outlet, useParams } from "react-router-dom";

import { SvgImage } from "components/shared";

import { AuthContext } from "context/AuthContext";

import { CustomerInspectionService } from "api/client";

export const LayoutCustomer = () => {
  const { userID } = useContext(AuthContext);
  const params = useParams();

  const { data } = useQuery(
    [`verify_inspection_link_${userID}`, params.id],
    CustomerInspectionService.verifyInspectionLInk,
    {
      enabled: false,
    }
  );

  const logo = data?.data?.dealership?.logo_url;

  return (
    <div className="flex flex-col h-screen w-full relative">
      <div className="flex flex-1 overflow-hidden justify-center items-center bg-white md:bg-customer">
        <div className="relative flex justify-center pb-2 md:pt-5 bg-white h-full md:rounded-20 md:h-customer z-10 w-full md:w-customerLayout max-w-customerLayout">
          <div className="flex md:w-[395px] w-full flex-col justify-start">
            <div className="flex justify-center lg:shadow-none shadow-md h-[60px] items-center">
              {logo ? (
                <img src={logo} alt="logo" className="max-h-full" />
              ) : (
                <SvgImage name="CustomerLogoIcon" width={114} height={40} />
              )}
            </div>
            <Outlet />
          </div>
        </div>
        <div className="absolute bottom-[50px] right-10 hidden md:block">
          <SvgImage name="CustomerBGIcon" />
        </div>
      </div>
    </div>
  );
};
