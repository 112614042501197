import { useQuery } from "@tanstack/react-query";
import imageCompression from "browser-image-compression";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";

import { ModalPlatesLocations } from "components/app";
import { ButtonLink, Modal, SvgImage } from "components/shared";

import { ICustomerInspection, IImage, ISection } from "types";

import { CustomerInspectionService, InspectionService } from "api/client";

import "../../styles.scss";

interface IPhotoItemProps {
  title: string;
  inspectionId: string;
  placeholder: React.ReactNode;
  images: IImage[];
  onRefresh: () => void;
}

const PhotoItem: FC<IPhotoItemProps> = ({
  title,
  inspectionId,
  placeholder,
  images,
  onRefresh,
}) => {
  const fileInputField = useRef<HTMLInputElement>(null);
  const [previewImg, setPreviewImg] = useState<any>();
  const [imgIndex, setImgIndex] = useState<number>(-1);

  useEffect(() => {
    if (images) {
      setImgIndex(
        images.findIndex(
          (img) => img.label?.toLowerCase() === title.toLowerCase()
        )
      );
    }
  }, [images, title]);

  const handleNewFileUpload = async (e: any) => {
    if (e.target.files.length) {
      const imageFile = e.target.files[0];
      const previewImage = URL.createObjectURL(imageFile);

      if (title !== "Add More") {
        setPreviewImg(previewImage);
      }

      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(imageFile, options);

        const formData = new FormData();
        let uploadLabel = title;
        let uploadcategory = "masthead";
        if (title === "Add More") {
          uploadLabel = "Additional Images";
          uploadcategory = "additional";
        }
        formData.append("image", compressedFile);
        formData.append("category", uploadcategory);
        formData.append("label", uploadLabel);
        await CustomerInspectionService.uploadImages(formData, inspectionId);
        onRefresh();
      } catch (error) {
        setPreviewImg(null);
        toast.error((error as Error).message, {
          theme: "colored",
          autoClose: 2000,
        });
      }
    }
  };

  const handleImageClick = () => {
    if (fileInputField.current) {
      fileInputField.current.click();
    }
  };

  const handleRemoveFile = async (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    try {
      if (images[imgIndex]) {
        setImgIndex(-1);
        await CustomerInspectionService.removeImage(
          { image_id: images[imgIndex].id },
          inspectionId
        );
        onRefresh();
      } else {
        toast.error(`Image uploading. Please wait a moment and try again`, {
          theme: "colored",
          autoClose: 2000,
        });
      }
    } catch (err) {
      toast.error(`Something is wrong.`, {
        theme: "colored",
        autoClose: 2000,
      });
    }
  };

  if (imgIndex > -1 && previewImg) {
    setPreviewImg(null);
  }

  if (title === "Add More") {
    return (
      <div className="photo-item--empty" onClick={handleImageClick}>
        <div className="photo-item__content">
          {placeholder}
          <span>{title}</span>
        </div>
        <input
          type="file"
          ref={fileInputField}
          onChange={handleNewFileUpload}
          className="photo-item--upload"
          accept="image/*"
        />
      </div>
    );
  }

  if (imgIndex > -1 || previewImg) {
    return (
      <div className="photo-item">
        <div className="photo-item__content">
          <img
            className="photo-item__content__img"
            src={images[imgIndex] ? images[imgIndex].url : previewImg}
            alt="Car Img"
          />
          <div className="photo-item__content__delete">
            <button onClick={handleRemoveFile}>
              <SvgImage name="DeleteIcon" width={15} />
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="photo-item--empty" onClick={handleImageClick}>
      <div className="photo-item__content">
        {placeholder}
        <span>{title}</span>
      </div>
      <input
        type="file"
        ref={fileInputField}
        onChange={handleNewFileUpload}
        className="photo-item--upload"
        accept="image/*"
      />
    </div>
  );
};

interface IProps {
  data: ICustomerInspection | undefined;
  inspectionId: string;
  onRefresh: () => void;
}
export const UploadScreen: FC<IProps> = ({ data, inspectionId, onRefresh }) => {
  const [showPlatesModal, setShowPlatesModal] = useState<boolean>(false);

  const { data: { data: sectionData } = {} } = useQuery<
    { data: ISection[] },
    Error
  >(["fetchSection"], InspectionService.getSections, {
    refetchOnWindowFocus: false,
  });

  const handlePlatesModal = () => {
    setShowPlatesModal(true);
  };

  const allImages = useMemo(() => {
    if (data?.images) {
      let additionalIndex = 0;
      return data?.images.map((image) => {
        if (image.category === "additional") {
          additionalIndex++;
          return {
            ...image,
            label: `${image.label}-${additionalIndex - 1}`,
          };
        } else {
          return image;
        }
      });
    }

    return [];
  }, [data?.images]);

  if (!sectionData || !data) {
    return <></>;
  }

  const { images } = data;
  const additionalImages = images.filter(
    (img) => img.category === "additional"
  );

  return (
    <div className="take-picture__content">
      <div className="take-picture__content__photos">
        {[
          ...sectionData,
          ...additionalImages.map(
            (img, idx) =>
              ({
                label: `${img.label}-${idx}`,
                key: `additional-${idx}`,
              } as ISection)
          ),
          { key: "add-more", label: "Add More" } as ISection,
        ].map((section, index) => (
          <PhotoItem
            key={index}
            title={section.label}
            inspectionId={inspectionId}
            placeholder={
              section.key === "add-more" ? (
                <SvgImage name="AddMorePlaceholderImage" />
              ) : (
                <img src={section.placeholder} alt="Placeholder" />
              )
            }
            images={allImages}
            onRefresh={onRefresh}
          />
        ))}
      </div>
      <p className="take-picture__desc mb-5">
        If you want to know where to find this information, please click{" "}
        <ButtonLink onClick={handlePlatesModal}>here</ButtonLink>.
      </p>
      <Modal
        size="auto"
        open={showPlatesModal}
        closeModal={() => setShowPlatesModal(false)}
      >
        <ModalPlatesLocations />
      </Modal>
    </div>
  );
};
