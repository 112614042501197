import classNames from "classnames";

import { VehicleComment } from "types";

interface NoteProps {
  note: VehicleComment;
  showFullNote?: boolean;
}

export const Note = ({ note, showFullNote = true }: NoteProps) => {
  return (
    <div className="bg-white p-3 rounded-md border-b border-[#EFEFEF]">
      <div className="flex items-center justify-between pb-2">
        <span className="font-normal text-sm">{note?.commentator?.name}</span>
        <span className="text-xs">{note?.commented_at}</span>
      </div>
      <p
        className={classNames("text-sm text-txt break-words", {
          "line-clamp-3": !showFullNote,
        })}
      >
        {note?.comment}
      </p>
    </div>
  );
};
