import { FieldState } from "components/app";

import { IVehicleSummary } from "types";

interface DealershipInformationProps {
  data: IVehicleSummary;
}

export const DealershipInformation = ({ data }: DealershipInformationProps) => {
  const { license, name } = data.dealership || {};
  const { contact_name, contact_phone } = data.auction || {};

  return (
    <div className="section">
      <div className="section__header">
        <span>Dealership Information</span>
      </div>
      <div className="disclosure__pannel--wide">
        <FieldState name="Dealership Name" value={name} />
        <FieldState name="Dealer License No." value={license} />
        <FieldState name="Contact Name" value={contact_name} />
        <FieldState name="Contact Number" value={contact_phone} />
      </div>
    </div>
  );
};
