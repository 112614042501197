import { ButtonStatusTab } from "components/app";

import { IOption } from "types";

import { statusBarTestID } from "../dataTestIDs";

import "./styles.scss";

export interface StatusBarTabsProps {
  options: IOption[];
  currentStatus: string | number;
  onSelect: (status: string | number) => void;
  dataTestID?: string;
}

export const StatusBarTabs = ({
  options,
  currentStatus,
  onSelect,
  dataTestID,
}: StatusBarTabsProps) => {
  return (
    <div className="statusbar" data-testid={dataTestID || statusBarTestID}>
      {options
        .filter((option: IOption) => !option.isHidden)
        .map((option: IOption) => (
          <ButtonStatusTab
            key={option.key}
            selected={option.key === currentStatus}
            data={option}
            onClick={onSelect}
          />
        ))}
    </div>
  );
};
