export const textErrorTestID = "text-error-message";
export const paginationTestID = "pagination";
export const paginationLeftIconTestID = "pagination-left-icon";
export const paginationRightIconTestID = "pagination-right-icon";
export const modalDialogCloseButtonTestID = "modal-dialog-close-button";
export const svgImageTestID = "svg-image";
export const cardContainerTestID = "card-container";
export const breadcrumbsTestID = "breadcrumbs";
export const breadcrumbsFirstRouteTestID = "breadcrumbsFirstRoute";
export const accordionTestID = "accordion-container";
export const accordionButtonTestID = "accordion-button";
export const accordionPanelTestID = "accordion-panel";
export const accordionHeaderTestID = "accordion-header";
export const buttonLinkTestID = "button-link";
export const fieldTextAreaTestID = "field-text-area";
export const fieldTextTestID = "field-text";
export const fieldRadioTestID = "field-radio";
export const buttonTestID = "button";
export const bannerTestID = "banner";
export const tooltipTestID = "tooltip";
