import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  Button,
  ButtonLink,
  FieldCheckbox,
  FieldText,
  SvgImage,
} from "components/shared";

import { AuthContext } from "context/AuthContext";

import { CUSTOMER_INSPECTION_DATA } from "utils";

import { ICustomerInspection, IFormOption, IQuestionnaire } from "types";

import { CustomerInspectionService, InspectionService } from "api/client";

import { useLocalStorage } from "hooks";

interface IComment {
  name: string;
  selected: boolean;
}

export const FeaturesAndOptions = () => {
  const { userID } = useContext(AuthContext);
  const navigate = useNavigate();
  const params = useParams();
  const [savedData, saveInspection] = useLocalStorage(
    CUSTOMER_INSPECTION_DATA,
    null
  );

  const [answers, setAnswers] = useState<any[]>([]);
  const [addedComments, setAddedComments] = useState<IComment[]>([]);
  const [isNew, setIsNew] = useState<boolean>(false);

  const { data: questionnaire } = useQuery<{ data: IQuestionnaire[] }, Error>(
    [`fetchQuestionnaire_${userID}`],
    InspectionService.getQuestionnaire,
    {
      refetchOnWindowFocus: false,
    }
  );
  const { mutate, isLoading } = useMutation(
    (data: any) => CustomerInspectionService.saveAnswers(data, params.id!),
    {
      onSuccess: (res: any) => {
        toast.success("Saved Successfully", {
          autoClose: 2000,
          theme: "colored",
        });
        saveInspection(res.data);
        navigate("../conditions");
      },
      onError: () => {
        toast.error("Failed to Save", {
          autoClose: 2000,
          theme: "colored",
        });
      },
    }
  );

  const formData = questionnaire ? questionnaire.data[0].questionnaire : [];
  useEffect(() => {
    if (savedData) {
      const { assessments } = savedData as ICustomerInspection;
      if (assessments && assessments.length > 0) {
        const field = assessments[0].questionnaire[0];
        if (field.answers?.options) {
          const newAnswers = field.answers.options.map(
            (option) => option.option_slug
          );
          setAnswers(newAnswers);
          if (field.answers.comments) {
            const newComments: IComment[] = field.answers.comments.map((c) => ({
              name: c,
              selected: true,
            }));
            setAddedComments(newComments);
          }
        }
      }
    } else {
      navigate("../enter-kms");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedData]);

  const handleContinue = () => {
    const answersPayload = [];
    answersPayload.push({
      question_slug: "spec-and-options",
      options: answers,
      comments: addedComments.filter((c) => c.selected).map((c) => c.name),
    });
    const payload = {
      answers: answersPayload,
      type: "conditions",
    };
    mutate(payload);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleAddOption = useCallback(() => {
    setIsNew(true);
  }, []);

  const handleNewOption = useCallback(
    (e) => {
      if (e.key === "Enter") {
        setAddedComments([
          ...addedComments,
          { name: e.target.value, selected: true },
        ]);
        setIsNew(false);
      }
    },
    [addedComments]
  );

  const handleChange = (option: IFormOption) => {
    if (answers.includes(option.slug)) {
      setAnswers(answers.filter((a) => a !== option.slug));
    } else {
      setAnswers([...answers, option.slug]);
    }
  };
  const handleChangeComment = (comment: IComment) => {
    const index = addedComments.findIndex((c) => c.name === comment.name);
    if (index > -1) {
      const newComments = addedComments.slice();
      newComments.splice(index, 1, { ...comment, selected: !comment.selected });
      setAddedComments(newComments);
    }
  };

  return (
    <div className="flex flex-col justify-between text-primary w-full">
      <div className="absolute h-0 md:h-10 w-full left-0 border-b-[7px] border-softPeach">
        <div className="bg-primary h-[7px] mt-0 md:mt-33 w-3/5" />
      </div>
      <div className="flex-1 w-full flex flex-col overflow-auto px-5 mt-0 md:mt-[40px] max-h-[calc(100vh-130px)]">
        <div className="text-black text-sm pt-7">
          <ButtonLink onClick={handleGoBack}>
            {"< Back to Compliance"}
          </ButtonLink>
        </div>
        <h5 className="text-primary font-bold text-23 mt-4 mb-0">
          Vehicle Inspection
        </h5>
        <div>
          <label className="text-primary text-sm font-bold leading-10">
            STEP 3/5 - FEATURES AND OPTIONS
          </label>
          <p className="text-label text-sm font-normal">
            Please make sure all the information is correct. The more
            information you enter, the more accurate valuation you will receive.
          </p>
        </div>
        <div className="view-specification__content mt-3">
          <p>Check all that apply:</p>
          {formData[0]?.options.map((option) => (
            <FieldCheckbox
              checked={answers.includes(option.slug)}
              onChange={() => handleChange(option)}
              label={option.title}
              key={option.slug}
            />
          ))}
          {addedComments.map((comment, idx) => (
            <FieldCheckbox
              checked={comment.selected}
              onChange={() => handleChangeComment(comment)}
              label={comment.name}
              key={`${comment.name}_${idx}`}
            />
          ))}
          {isNew && (
            <div className="option__new">
              <FieldText type="text" autoFocus onKeyDown={handleNewOption} />
            </div>
          )}
          <ButtonLink onClick={handleAddOption}>
            <div className="option__add">
              <SvgImage name="AddIcon" />
              <span className="option__add__title">ADD MORE</span>
            </div>
          </ButtonLink>
        </div>
      </div>
      <div className="customer__bottom-bar">
        <Button type="submit" isLoading={isLoading} onClick={handleContinue}>
          <span className="font-bold">Continue</span>
        </Button>
      </div>
    </div>
  );
};
