import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";
import { FC, Fragment } from "react";

import { SvgImage } from "components/shared";

interface ModalContainerProps {
  title?: string;
  description?: string;
  canClose?: boolean;
  isOpen: boolean;
  size?: "auto" | "responsive";
  onClose: () => void;
}

export const ModalContainer: FC<ModalContainerProps> = ({
  title,
  description,
  canClose = true,
  isOpen,
  size = "responsive",
  onClose,
  children,
}) => (
  <Transition appear show={isOpen} as={Fragment}>
    <Dialog
      as="div"
      className="fixed inset-0 z-10 overflow-y-auto"
      onClose={onClose}
    >
      <div className="min-h-screen lg:px-4 text-center">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 opacity-60 bg-black" />
        </Transition.Child>
        <span className="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
          as={Fragment}
        >
          <div
            className={classNames(
              "inline-block my-8 text-left align-middle transition-all transform shadow-xl rounded-20 bg-white",
              {
                "w-auto": size === "auto",
                "w-responsive": size !== "auto",
              }
            )}
          >
            <Dialog.Title
              as="div"
              className={classNames(
                "relative flex items-center justify-center pt-12",
                { "pt-10 pb-4": !!title }
              )}
            >
              <div className="flex flex-col justify-center text-center px-5 w-full">
                <h3
                  className={classNames("text-xl font-semibold text-darktxt", {
                    "pb-2": !!description,
                  })}
                >
                  {title}
                </h3>
                {description && (
                  <p className="text-sm text-label w-min min-w-fit">
                    {description}
                  </p>
                )}
              </div>
              {canClose && (
                <button className="absolute top-5 right-5" onClick={onClose}>
                  <SvgImage name="CloseIcon" />
                </button>
              )}
            </Dialog.Title>
            <div className="px-7 md:px-8 pb-6">{children}</div>
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition>
);
