import { useMutation } from "@tanstack/react-query";
import { ChangeEvent, useEffect, useState } from "react";

import { Button, ButtonLink, FieldCheckbox } from "components/shared";

import { ACTIVE, CLOSED, SCHEDULED, showSuccessToast } from "utils";

import { IBuyerDealership } from "types";

import { TenderService } from "api/client";

import { SearchBar } from "../Field";

interface ModalTenderBiddersProps {
  buyers: IBuyerDealership[] | undefined;
  tenderId: string;
  tenderStatus: string;
  canEdit?: boolean;
  onClose: () => void;
}

export const ModalTenderBidders = ({
  buyers,
  tenderId,
  tenderStatus,
  canEdit = true,
  onClose,
}: ModalTenderBiddersProps) => {
  const [searchTxt, setSearchTxt] = useState("");
  const [isSelectedAll, setSelectedAll] = useState(false);
  const [dealerships, setDealerships] = useState<IBuyerDealership[]>([]);
  const [deleteDealerships, setDeleteDealerships] = useState<string[]>([]);
  const [addDealerships, setAddDealerships] = useState<string[]>([]);

  const isTenderOpen = tenderStatus !== CLOSED.key;

  const isExistingBidder = (id: string) => {
    return (
      [SCHEDULED.key, ACTIVE.key].includes(tenderStatus) &&
      !!buyers?.find(
        (dealership) =>
          dealership.is_selected && dealership.dealership.id === id
      )
    );
  };

  const {
    mutate: addBuyers,
    isLoading: isAdding,
    isSuccess: isAdded,
  } = useMutation(
    (payload: { dealership_ids: string[] }) =>
      TenderService.addBuyers(tenderId, payload),
    {
      onSuccess: () => {
        setAddDealerships([]);
      },
      onError: (err) => {
        console.log("Added buyers error: ", err);
      },
    }
  );

  const {
    mutate: deleteBuyers,
    isLoading: isDeleting,
    isSuccess: isDeleted,
  } = useMutation(
    (payload: { dealership_ids: string[] }) =>
      TenderService.deleteBuyers(tenderId, payload),
    {
      onSuccess: () => {
        setDeleteDealerships([]);
      },
      onError: (err) => {
        console.log("Deleted buyers error: ", err);
      },
    }
  );

  useEffect(() => {
    if (!isAdding && !isDeleting && (isAdded || isDeleted)) {
      showSuccessToast("Saved successfully");
      onClose();
    }
  }, [isAdded, isDeleted, isAdding, isDeleting]);

  useEffect(() => {
    if (buyers) {
      const filteredDealerships = buyers
        .filter((dealership) => dealership.dealership.name.includes(searchTxt))
        .map((dealership) => ({
          ...dealership,
          is_selected: dealership.is_selected,
        }));
      setDealerships(filteredDealerships);
      let isSelectedAll = true;
      filteredDealerships.forEach((dealership) => {
        if (!dealership.is_selected) {
          isSelectedAll = false;
        }
      });
      setSelectedAll(isSelectedAll);
    }
  }, [buyers, searchTxt]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTxt(e.target.value);
  };

  const handleSelectAll = () => {
    if (!isSelectedAll) {
      const updatedDealerships = dealerships.slice();
      updatedDealerships.forEach((dealership) => {
        dealership.is_selected = true;
      });
      setDealerships(updatedDealerships);

      setAddDealerships(
        dealerships.map((dealership) => dealership.dealership.id)
      );
      setSelectedAll(true);
    } else {
      const updatedDealerships = dealerships.slice();
      updatedDealerships.forEach((dealership) => {
        dealership.is_selected = false;
      });
      setDealerships(updatedDealerships);

      setDeleteDealerships(
        dealerships.map((dealership) => dealership.dealership.id)
      );
      setSelectedAll(false);
    }
  };

  const handleConfirm = () => {
    if (deleteDealerships.length) {
      deleteBuyers({ dealership_ids: deleteDealerships });
    }

    const finalDealerships = dealerships
      .filter((dealership) => dealership.is_selected)
      .map((dealership) => dealership.dealership.id);
    if (finalDealerships.length > 0) {
      addBuyers({ dealership_ids: finalDealerships });
    }
  };

  const handleChange = (value: boolean, buyer: IBuyerDealership) => {
    const updatedDealerships = dealerships.slice();
    const selectedId = updatedDealerships.findIndex(
      (dealership) => dealership.dealership.id === buyer.dealership.id
    );
    updatedDealerships[selectedId].is_selected = value;
    setDealerships(updatedDealerships);

    const isCheckedAll = dealerships.every((item) => item.is_selected);
    setSelectedAll(isCheckedAll);

    if (value) {
      deleteDealerships.includes(buyer.dealership.id)
        ? setDeleteDealerships(
            deleteDealerships.filter((id) => id !== buyer.dealership.id)
          )
        : setAddDealerships([...addDealerships, buyer.dealership.id]);
    } else {
      addDealerships.includes(buyer.dealership.id)
        ? setAddDealerships(
            addDealerships.filter((id) => id !== buyer.dealership.id)
          )
        : setDeleteDealerships([...deleteDealerships, buyer.dealership.id]);
    }
  };

  return (
    <div className="flex flex-col items-center gap-5 w-672">
      <p className="text-xl font-bold text-txt leading-10">Bidders</p>
      <p className="text-base text-label text-left w-full">
        Select the buyers you would like to participate to this tender from the
        list below.
      </p>
      <div className="relative w-full">
        <SearchBar onSearch={handleSearch} disabled={!canEdit} />
      </div>
      <div className="w-full flex flex-row-reverse">
        <ButtonLink
          onClick={handleSelectAll}
          disabled={!isTenderOpen || !canEdit}
        >
          <span
            className={
              isTenderOpen && canEdit
                ? "text-secondary"
                : "cursor-default text-[#9696A0]"
            }
          >
            {isSelectedAll ? "Deselect All" : "Select All"}
          </span>
        </ButtonLink>
      </div>
      <div className="flex flex-col w-full max-h-400 overflow-auto">
        {dealerships.map((dealership) => (
          <FieldCheckbox
            key={dealership.dealership.id}
            checked={dealership.is_selected}
            label={dealership.dealership.name}
            disabled={
              !isTenderOpen ||
              isExistingBidder(dealership.dealership.id) ||
              !canEdit
            }
            onChange={(e) => handleChange(e.target.checked, dealership)}
          />
        ))}
      </div>
      <div className="flex justify-center w-full">
        <div className="w-150">
          {isTenderOpen && (
            <Button
              isLoading={isAdding || isDeleting}
              onClick={handleConfirm}
              disabled={!canEdit}
            >
              <span className="text-sm font-bold">CONFIRM</span>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
