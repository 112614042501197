import { useMutation } from "@tanstack/react-query";
import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import { Button, FieldText, TextError } from "components/shared";

import { SearchContext } from "context/SearchContext";

import { checkIsValidNumber, formatNumber } from "utils";

import { VehicleService } from "api/client";

import "./styles.scss";
import { modalEditKMTestID } from "../dataTestIDs";

export interface ModalEditKMProps {
  onCloseModal: () => void;
  contract_id: string;
  odometer?: string;
  dataTestID?: string;
}

export const ModalEditKM = ({
  onCloseModal,
  contract_id,
  odometer,
  dataTestID,
}: ModalEditKMProps) => {
  const { setIsUpdated } = useContext(SearchContext);

  const [isNumber, setIsNumber] = useState(true);
  const [odometerVal, setOdometerVal] = useState(odometer || "");
  const inputRef = useRef<HTMLInputElement>(null);

  const { mutate } = useMutation(
    (payload: any) => VehicleService.updateVehicle(payload, contract_id),
    {
      onSuccess: () => {
        toast.success("Saved successfully!", {
          autoClose: 2000,
          theme: "colored",
        });
        setIsUpdated(contract_id, true);
        onCloseModal();
      },
      onError: () => {
        toast.error("Failed to Send", {
          autoClose: 2000,
          theme: "colored",
        });
      },
    }
  );

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    setOdometerVal(odometer || "");
  }, [odometer]);

  const handleChangeOdometer = (value: string) => {
    setOdometerVal(value.replaceAll(",", ""));
    setIsNumber(checkIsValidNumber(value.replaceAll(",", "")));
  };

  const handleSubmitOdometer = () => {
    mutate({ odometer: odometerVal.replaceAll(",", ""), type: "odometer" });
  };

  return (
    <div
      className="valuation-add--secondary"
      data-testid={dataTestID || modalEditKMTestID}
    >
      <div className="valuation-add__txt">
        <FieldText
          type="text"
          value={formatNumber(odometerVal)}
          onChange={(e) => handleChangeOdometer(e.target.value)}
          placeholder="Enter kilometres"
          ref={inputRef}
        />
        {isNumber === false && <TextError error="Please enter numbers only" />}
      </div>
      <div className="valuation-add__tool">
        <Button type="button" onClick={handleSubmitOdometer}>
          <span className="valuation-add__tool__title">SAVE</span>
        </Button>
      </div>
    </div>
  );
};
