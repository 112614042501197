import { ButtonStatus } from "components/app/Button";
import { Card, SvgImage } from "components/shared";

import { ACQUIRED, convertToDate } from "utils";

import { ITenderVehicle } from "types";

import { CardInfoChip } from "../CardTenderVehicle/CardInfoChip";

interface CardTenderVehicleSelectProps {
  data: ITenderVehicle;
  selected: boolean;
  onClick: () => void;
}

export const CardTenderVehicleSelect = ({
  data,
  selected,
  onClick,
}: CardTenderVehicleSelectProps) => {
  const {
    images,
    acquisitions,
    vehicle: {
      name,
      description,
      compliance: { vin, registration_plate },
    },
    owner,
    seller,
    retailed,
    created_by,
  } = data;

  const createdAtFromAcquisition =
    acquisitions?.acquisition?.created_at?.replaceAll(" - ", " ");
  const formattedCreatedAt =
    createdAtFromAcquisition && convertToDate(createdAtFromAcquisition, true);
  const isExternal = data?.seller?.id && !data.owner?.id;

  return (
    <Card onClick={onClick}>
      <div className={`card-vehicle ${selected && "bg-lighterBlue"}`}>
        <div className="card-vehicle__img">
          {!images || images.length === 0 ? (
            <SvgImage name="NoImageSvg" />
          ) : (
            <img src={images[0].thumb} alt="Vehicle" />
          )}
          <div className="card-vehicle__status">
            <ButtonStatus
              status={ACQUIRED.key}
              amount={acquisitions?.acquisition?.amount || ""}
              label={ACQUIRED.label}
            />
          </div>
        </div>
        <div className="card-vehicle__info">
          <div className="card-vehicle__section">
            <span className="card-vehicle__txt--title">{name}</span>
            <CardInfoChip label="VIN" value={vin} />
            <span className="card-vehicle__txt--desc">{description}</span>
          </div>
          <div className="card-vehicle__section relative">
            <CardInfoChip label="REGO" value={registration_plate} />
            <CardInfoChip
              label={`${isExternal ? "Dealership" : "Customer"} Name`}
              value={isExternal ? seller?.name : owner?.full_name}
            />
            <CardInfoChip label="Staff Name" value={created_by?.name} />
            <span className="card-vehicle__txt--desc">
              {formattedCreatedAt}
            </span>
            <div
              className={`absolute top-10 right-7 w-24 h-4 text-xs font-bold text-center text-primary rounded-4 ${
                retailed ? "bg-softPeach" : "bg-lightDustBlue"
              }`}
            >
              {retailed ? "RETAIL" : "WHOLESALE"}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
