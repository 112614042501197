import classNames from "classnames";
import { useState } from "react";

import { LoadingSpinner } from "components/Loading/LoadingSpinner";

interface ImageLoaderProps {
  src?: string;
  alt: string;
  className?: string;
  style?: React.CSSProperties;
}

export const ImageLoader = ({
  src,
  alt,
  className,
  style,
}: ImageLoaderProps) => {
  const [loading, setLoading] = useState(true);

  return (
    <>
      {loading && (
        <div className="absolute">
          <LoadingSpinner />
        </div>
      )}
      <img
        className={classNames(className, { "opacity-0": loading })}
        src={src}
        alt={alt}
        style={style}
        onLoad={() => {
          setLoading(false);
        }}
        onError={() => {
          setLoading(false);
        }}
      />
    </>
  );
};
