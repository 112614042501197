import { buttonStatusTestID } from "components/app/dataTestIDs";
import "../styles.scss";

export interface ButtonStatusProps {
  status: string;
  label: string;
  amount?: string | number;
  dataTestID?: string;
}

export const ButtonStatus = ({
  status,
  label,
  amount,
  dataTestID,
}: ButtonStatusProps) => {
  return (
    <div
      className={`status--${status}`}
      data-testid={dataTestID || buttonStatusTestID}
    >
      {!!amount && (
        <span className={`status-amount--${status}`}>
          ${" "}
          {Number(amount).toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}
        </span>
      )}
      <span>{label}</span>
    </div>
  );
};
