import { PermissionCategory } from "utils/userPermissions";

export const NoPermissionMessage = ({
  viewPermissionCategory,
}: {
  viewPermissionCategory?: PermissionCategory;
}) => {
  const getDataCategory = () => {
    switch (viewPermissionCategory) {
      case PermissionCategory.RETAIL_INSPECTIONS:
      case PermissionCategory.WHOLESALE_INSPECTIONS:
        return "inspections";
      case PermissionCategory.TENDER_SELLING:
      case PermissionCategory.TENDER_BUYING:
        return "tenders";
      case PermissionCategory.MOTOR_MARKET_SELLING:
      case PermissionCategory.MOTOR_MARKET_BUYING:
        return "listings";
      default:
        return "cars";
    }
  };

  return (
    <span className="flex items-center py-16 text-label text-lg">
      {`Your current permissions do not allow you to view any ${getDataCategory()}. To gain
      access, please consult your admin.`}
    </span>
  );
};
