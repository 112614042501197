import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

import { RemoveTenderVehicleModal } from "components/Modal/RemoveVehicleModal/RemoveVehicleModal";
import { Button } from "components/shared";

import { showSuccessToast } from "utils";

import { TenderService } from "api/client";

type RemoveFromTenderProps = {
  tenderID: string;
  contractID: string;
};

export const RemoveFromTender = ({
  tenderID,
  contractID,
}: RemoveFromTenderProps) => {
  const [openRemoveModal, setOpenRemoveModal] = useState(false);

  const { mutate } = useMutation(
    (dataparam: { contract_ids: string[] }) =>
      TenderService.deleteVehicleFromTender(dataparam, tenderID ?? ""),
    {
      onSuccess: () => {
        showSuccessToast("Vehicle Removed");
      },
      onError: () => {
        showSuccessToast("Failed to Remove");
      },
    }
  );

  const handleRemoveFromTender = () => {
    mutate({ contract_ids: [contractID ?? ""] });
  };

  return (
    <>
      <div className="w-52">
        <Button variant="secondary" onClick={() => setOpenRemoveModal(true)}>
          <b>REMOVE FROM TENDER</b>
        </Button>
      </div>
      <RemoveTenderVehicleModal
        open={openRemoveModal}
        onConfirm={handleRemoveFromTender}
        onCancel={() => setOpenRemoveModal(false)}
      />
    </>
  );
};
