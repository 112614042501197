import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { ModalVinLocation } from "components/app";
import {
  Button,
  FieldText,
  TextError,
  ButtonLink,
  Modal,
  SvgImage,
} from "components/shared";

import { CUSTOMER_SEARCHED_VEHICLE } from "utils";

import { VehicleSearchService } from "api/client";

import { useLocalStorage } from "hooks";

interface IVin {
  plate: string;
}

export const FindVehicleByVin = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [isSearching, setIsSearching] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, saveVehicle] = useLocalStorage(CUSTOMER_SEARCHED_VEHICLE, null);
  const [showVinLocationModal, setVinLocationModal] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      plate: "",
    },
  });

  const handleFindVehicle = async (data: IVin) => {
    try {
      setIsSearching(true);
      const searchResult = await VehicleSearchService.searchPlate(
        data,
        params.id || ""
      );
      if (searchResult) {
        setIsSearching(false);
        toast.success("Vehicle found!", {
          autoClose: 2000,
          theme: "colored",
        });
        saveVehicle(searchResult.data);
        navigate("../vehicle-confirmation");
      }
    } catch (err) {
      setIsSearching(false);
      toast.error("Failed to Find Vehicle", {
        theme: "colored",
        autoClose: 2000,
      });
    }
  };

  const handleRego = () => {
    navigate(`../find-vehicle`);
  };

  const handleVinLocationModal = () => {
    setVinLocationModal(true);
  };

  const handleVin = (value: string) => {
    setValue("plate", value.replace(/\s/g, "").toUpperCase());
  };

  return (
    <div className="flex flex-col justify-between text-primary w-full mb-5 h-full">
      <div className="flex-1 w-full flex flex-col overflow-auto px-5">
        <div className="font-medium text-sm mt-7">
          <h5 className="font-bold text-2xl mt-95 mb-5">Find your vehicle</h5>
          <p className="mb-5 text-label font-normal">
            Please enter the following details:
          </p>
          <div className="mb-3">
            <FieldText
              title="VIN"
              type="text"
              {...register("plate", { required: true })}
              placeholder="XXXXXXXXXXXXXXXXX"
              onChange={(e) => handleVin(e.target.value)}
            />
            {errors.plate && <TextError error="Please check your entry." />}
          </div>
        </div>

        <div className="font-medium text-sm mt-7">
          <div className="mb-11">
            <ButtonLink onClick={handleVinLocationModal}>
              <p className="text-label font-normal underline">
                Where can I find my VIN?
              </p>
            </ButtonLink>
          </div>
          <div className="text-darkKnight font-semibold text-sm block mb-2">
            Or &nbsp;
            <ButtonLink onClick={handleRego}>
              <p className="underline">Enter REGO</p>
            </ButtonLink>
          </div>
        </div>
        <div className="mt-auto">
          <Button
            isLoading={isSearching}
            type="submit"
            onClick={handleSubmit(handleFindVehicle)}
          >
            <span className="uppercase text-white text-sm font-bold">
              Search
            </span>
          </Button>
        </div>
        <div className="flex flex-row items-center mt-5 space-x-1 justify-center">
          <p className="text-label font-norma text-sm">powered by</p>
          <SvgImage name="MPLogo" className="mt-1" width={116} height={39} />
        </div>
      </div>
      <Modal
        size="auto"
        open={showVinLocationModal}
        closeModal={() => setVinLocationModal(false)}
      >
        <ModalVinLocation />
      </Modal>
    </div>
  );
};
