import classNames from "classnames";
import { MouseEvent } from "react";

export interface LinkButtonProps {
  underline?: boolean;
  disabled?: boolean;
  dataTestID?: string;
  children: React.ReactNode;
  onClick?: () => void;
}

export const LinkButton = ({
  underline,
  disabled,
  dataTestID,
  children,
  onClick = () => {},
}: LinkButtonProps) => {
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (disabled) return;

    e.stopPropagation();
    onClick();
  };

  return (
    <button
      className={classNames(
        "text-secondary text-sm cursor-pointer font-medium",
        {
          "pointer-events-none": disabled,
          underline: underline,
        }
      )}
      onClick={handleClick}
      data-testid={dataTestID}
    >
      {children}
    </button>
  );
};
