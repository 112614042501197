import { buttonBackToTopTestID } from "components/app/dataTestIDs";
import { Button, SvgImage } from "components/shared";

import "../styles.scss";

export interface ButtonBackToTopProps {
  onScroll: () => void;
  dataTestID?: string;
}

export const ButtonBackToTop = ({
  onScroll,
  dataTestID,
}: ButtonBackToTopProps) => {
  return (
    <div className="button-up">
      <Button
        type="button"
        onClick={onScroll}
        dataTestID={dataTestID || buttonBackToTopTestID}
      >
        <div className="button-up__content">
          <SvgImage name="BackToTopIcon" />
        </div>
      </Button>
    </div>
  );
};
