export const theSidebarTestID = "the-sidebar";
export const navItemTestID = "nav-item";
export const navHeaderTestID = "nav-header";

export enum NavigationTestID {
  RetailDealership = "retaildealership-nav",
  RetailDealershipUnvalued = "retaildealership-nav-unvalued",
  RetailDealershipValued = "retaildealership-nav-valued",
  RetailDealershipStocklist = "retaildealership-nav-stocklist",
  RetailDealershipCustomer = "retaildealership-nav-customer",
  WholesaleDealership = "wholesaledealership-nav",
  WholesaleDealershipUnvalued = "wholesaledealership-nav-unvalued",
  WholesaleDealershipValued = "wholesaledealership-nav-valued",
  WholesaleDealershipStocklist = "wholesaledealership-nav-stocklist",
  WholesaleDealershipClients = "wholesaledealership-nav-clients",
  Tender = "tender-nav",
  TenderSelling = "tender-nav-selling",
  TenderBuying = "tender-nav-buying",
  MotorMarket = "motormarket-nav",
  MotorMarketMyListings = "motormarket-nav-mylistings",
  MotorMarketBuying = "motormarket-nav-buying",
}
