import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { FieldState } from "components/app";
import { formConfirmVariantTestID } from "components/app/dataTestIDs";
import { Button, ButtonLink, SvgImage } from "components/shared";

import { useDealership } from "context/DealershipContext";

import { IVariant, IVehicle } from "types";

import { DealershipInspectionService, VehicleSearchService } from "api/client";

export interface FormConfirmVariantProps {
  selectedVariant?: IVariant;
  searchedVehicle?: IVehicle;
  inspectionType: string;
  onNextStep: (contract_id: string) => void;
  onBack: (step: string) => void;
  dataTestID?: string;
}

export const FormConfirmVariant = ({
  selectedVariant,
  searchedVehicle,
  inspectionType,
  onNextStep,
  onBack,
  dataTestID,
}: FormConfirmVariantProps) => {
  const { dealershipID, showAllDealerships } = useDealership();
  const [selectedVehicle, setSelectedVehicle] = useState<IVehicle>();

  const { mutate, isLoading } = useMutation(
    (data: IVehicle) =>
      DealershipInspectionService.startInspection({
        ...data,
        inspection_type: inspectionType,
        nvic: data?.identifier?.nvic || data?.nvic,
        dealership_id: !showAllDealerships ? dealershipID : undefined,
      }),
    {
      onSuccess: (res: any) => {
        onNextStep(res.data.contract_id);
      },
      onError: () => {},
    }
  );

  useEffect(() => {
    if (selectedVariant) {
      VehicleSearchService.searchNvic(selectedVariant.variant_id)
        .then((res: any) => {
          setSelectedVehicle(res.data);
        })
        .catch(() => {
          toast.error("Failed to search vehicle", {
            autoClose: 2000,
            theme: "colored",
          });
        });
    }
  }, [selectedVariant]);

  useEffect(() => {
    if (searchedVehicle) {
      setSelectedVehicle(searchedVehicle);
    }
  }, [searchedVehicle]);

  const handleGoBack = () => {
    onBack("CONFIRM_VARIANT");
  };

  return (
    <div
      className="vehicle-confirm"
      data-testid={dataTestID || formConfirmVariantTestID}
    >
      <div className="vehicle-confirm__wrapper">
        <FieldState
          name="Vehicle Year"
          value={selectedVehicle?.specification?.year}
        />
        <FieldState
          name="Badge"
          value={selectedVehicle?.specification?.variant}
        />
        <FieldState
          name="Vehicle Make"
          value={selectedVehicle?.specification?.make}
        />
        <FieldState name="Fuel" value={selectedVehicle?.specification?.fuel} />
        <FieldState
          name="Vehicle Model"
          value={selectedVehicle?.specification?.model}
        />
        <FieldState
          name="Transmission"
          value={selectedVehicle?.specification?.transmission}
        />
        <FieldState
          name="Series"
          value={selectedVehicle?.specification?.series}
        />
        <FieldState
          name="Seats"
          value={selectedVehicle?.specification?.seats}
        />
        <FieldState
          name="Body Type"
          value={selectedVehicle?.specification?.body_type}
        />
      </div>
      <div className="vehicle-confirm__footer">
        <div className="vehicle-confirm__footer__tool">
          <Button
            type="submit"
            isLoading={isLoading}
            disabled={!selectedVehicle}
            onClick={() => mutate(selectedVehicle!)}
          >
            <span className="vehicle-confirm__footer__tool__title">
              Yes, proceed
            </span>
          </Button>
        </div>
      </div>
      <div className="vehicle-confirm__back">
        <ButtonLink onClick={handleGoBack}>
          <SvgImage name="LeftRoundedIcon" />
        </ButtonLink>
      </div>
    </div>
  );
};
