import { FC, useState, useRef, useCallback } from "react";

import { ButtonBackToTop } from "components/app";

export interface ScrollViewProps {
  children: React.ReactNode;
  showTopButton?: boolean;
  onCloseSidebar: () => void;
}

const SCROLL_HEIGHT = 300;

export const ScrollView: FC<ScrollViewProps> = ({
  children,
  showTopButton = true,
  onCloseSidebar,
}) => {
  const scrollContainer = useRef<HTMLDivElement>(null);

  const [visible, setVisible] = useState<boolean>(false);

  const handleScroll = useCallback((e) => {
    const scrolled = e.target.scrollTop;

    if (scrolled > SCROLL_HEIGHT) {
      setVisible(true);
    } else if (scrolled <= SCROLL_HEIGHT) {
      setVisible(false);
    }
  }, []);

  const handleBackToTop = useCallback(() => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollTo({ behavior: "smooth", top: 0 });
    }
  }, []);

  return (
    <div
      ref={scrollContainer}
      className="dashboard"
      onScroll={handleScroll}
      onClick={onCloseSidebar}
    >
      {children}
      {visible && showTopButton && (
        <ButtonBackToTop onScroll={handleBackToTop} />
      )}
    </div>
  );
};
